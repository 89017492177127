import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DeviceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 4H5C3.89543 4 3 4.89543 3 6V15H1V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V15H21V6C21 4.89543 20.1046 4 19 4ZM19 15H5V6H19V15ZM3 18V17H21V18H3Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
