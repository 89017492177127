import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <g clipPath="url(#clip0_1668_33497)">
        <path
          d="M11.1025 2C16.0705 2 20.1025 6.032 20.1025 11C20.1025 15.968 16.0705 20 11.1025 20C6.13454 20 2.10254 15.968 2.10254 11C2.10254 6.032 6.13454 2 11.1025 2ZM11.1025 18C14.9695 18 18.1025 14.867 18.1025 11C18.1025 7.132 14.9695 4 11.1025 4C7.23454 4 4.10254 7.132 4.10254 11C4.10254 14.867 7.23454 18 11.1025 18ZM19.5875 18.071L22.4165 20.899L21.0015 22.314L18.1735 19.485L19.5875 18.071Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1668_33497">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.102539)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
