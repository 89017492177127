import { mnemonicGenerate } from '@polkadot/util-crypto';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const secrets = require('./secrets')
const EthCrypto = require('eth-crypto');

export const createSeedWordsAndSSS = () => {
  const seedWord = mnemonicGenerate()

  return sssSeedWords(seedWord)
}

export const sssSeedWords = (seedWords: string) => {
  const s = secrets.str2hex(seedWords)
  const shares = secrets.share(s, 3, 2)

  return shares
}

export const combineKey = (sharedA: string, sharedB: string) => {
  const comb = secrets.combine([sharedA, sharedB])
  return secrets.hex2str(comb)
}

export const getNewShare = (sharedB: string, sharedC: string) => {
  return secrets.newShare(3, [sharedB, sharedC])
}

export const calcSharedC = (sharedA: string, sharedB: string) => {
  return secrets.newShare(2, [sharedB, sharedA])
}

export const encryptWithPublicKey = async (message: string, publicKey: string) => {
  const cipherObj = await EthCrypto.encryptWithPublicKey(
    publicKey,
    message
  );
  return EthCrypto.cipher.stringify(cipherObj)
}

export async function decryptMessage(privateKey: string, cipherMessage: string) {
  const cipherObj = EthCrypto.cipher.parse(cipherMessage)

  const message = await EthCrypto.decryptWithPrivateKey(privateKey, cipherObj)
  return message
}

export function generateKey() {
  const entropyWords = mnemonicGenerate()
  const length = entropyWords.length < 128 ? 128 - entropyWords.length : entropyWords.length
  const padding = _.repeat('0', length)
  const entropy = Buffer.from(entropyWords + padding, 'utf-8'); // must contain at least 128 chars
  const identity = EthCrypto.createIdentity(entropy);

  return identity
}

export const getUuid = () => {
  return uuidv4().replaceAll('-', '')
}

