import { ObservableStore } from '@metamask/obs-store';
import { ethErrors } from 'eth-rpc-errors';
import EventEmitter from 'events';
import log from 'loglevel';
import { TypeRegistry } from '@polkadot/types';
import { MESSAGE_TYPE } from '../constants/netEnums';
import { getUser } from '../services/PopupService';
import { combineSignKey } from '../services/loginService';
import { KeyPairType } from '../services/WalletService';
const { Keyring } = require('@polkadot/api');

export default class PolkadotTransactionManager extends EventEmitter {
  constructor() {
    super();
    this.store = new ObservableStore({
      unapprovedPolkadotTxnMsgs: {},
      unapprovedPolkadotTxnCount: 0,
    });
    this.messages = [];
  }

  get unapprovedPolkadotMsgCount() {
    return Object.keys(this.getUnapprovedMsgs()).length;
  }

  getUnapprovedMsgs() {
    return this.messages
      .filter(message => message.status === 'unapproved')
      .reduce((result, message) => {
        result[message.id] = message;
        return result;
      }, {});
  }

  addUnapprovedMessageAsync(messageParameters, request, messageId) {
    return new Promise((resolve, reject) => {
      this.addUnapprovedMessage(messageParameters, request, messageId);
      this.once(`${messageId}:finished`, data => {
        switch (data.status) {
          case 'signed':
            return resolve(data.rawSig);
          case 'rejected':
            return reject(
              ethErrors.provider.userRejectedRequest(
                'Clover Message Signature: User denied message signature.',
              ),
            );
          default:
            return reject(
              new Error(
                `Clover Message Signature: Unknown problem: ${JSON.stringify(
                  messageParameters,
                )}`,
              ),
            );
        }
      });
    });
  }

  addUnapprovedMessage(messageParameters, request, messageId) {
    log.debug(
      `Polkadot MessageManager addUnapprovedMessage: ${JSON.stringify(
        messageParameters,
      )}`,
    );

    // create txData obj with parameters and meta data
    const time = Date.now();
    const messageData = {
      id: messageId,
      msgParams: messageParameters,
      time,
      status: 'unapproved',
      type: MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION,
    };

    if (request) {
      messageData.origin = request.origin;
    }
    this.addMsg(messageData);

    // signal update
    this.emit('update');
    return messageId;
  }

  addMsg(message) {
    this.messages.push(message);
    this._saveMsgList();
  }

  getMsg(messageId) {
    return this.messages.find(message => message.id === messageId);
  }

  approveMessage(messageId) {
    this.setMsgStatusApproved(messageId);
    return Promise.resolve(this.getMsg(messageId).msgParams);
  }

  setMsgStatusApproved(messageId) {
    this._setMsgStatus(messageId, 'approved');
  }

  setMsgStatusSigned(messageId, rawSig) {
    const message = this.getMsg(messageId);
    message.rawSig = rawSig;
    this._updateMsg(message);
    this._setMsgStatus(messageId, 'signed');
  }

  prepMsgForSigning(messageParameters) {
    delete messageParameters.metamaskId;
    return Promise.resolve(messageParameters);
  }

  rejectMsg(messageId) {
    this._setMsgStatus(messageId, 'rejected');
  }

  _setMsgStatus(messageId, status) {
    const message = this.getMsg(messageId);
    if (!message)
      throw new Error(
        `PolkadotTransactionManager - Message not found for id: "${messageId}".`,
      );
    message.status = status;
    this._updateMsg(message);
    this.emit(`${messageId}:${status}`, message);
    if (status === 'rejected' || status === 'signed') {
      this.emit(`${messageId}:finished`, message);
    }
  }

  _updateMsg(message_) {
    const index = this.messages.findIndex(
      message => message.id === message_.id,
    );
    if (index !== -1) {
      this.messages[index] = message_;
    }
    this._saveMsgList();
  }

  _saveMsgList() {
    const unapprovedPolkadotTxnMsgs = this.getUnapprovedMsgs();
    const unapprovedPolkadotMessageCount = Object.keys(
      unapprovedPolkadotTxnMsgs,
    ).length;
    this.store.updateState({
      unapprovedPolkadotTxnMsgs,
      unapprovedPersonalMsgCount: unapprovedPolkadotMessageCount,
    });
    this.emit('updateBadge');
  }

  _sign = async message => {
    const userSession = getUser();
    const seedWords = await combineSignKey(
      userSession.sharedA,
      userSession.userId,
      userSession.token,
    );
    const keyring = new Keyring({ type: KeyPairType.sr25519 });
    const accountPair = keyring.addFromUri(seedWords);

    const registry = new TypeRegistry();
    registry.setSignedExtensions(message.signedExtensions);
    const signature = registry
      .createType('ExtrinsicPayload', message, {
        version: message.version,
      })
      .sign(accountPair);
    return signature;
  };

  _validateDappTxnObject = transaction => {
    if (
      transaction === undefined ||
      transaction === null ||
      transaction === ''
    ) {
      throw new Error(`The request requires transaction payload.`);
    }
    const {
      address,
      blockHash,
      blockNumber,
      genesisHash,
      method,
      specVersion,
    } = transaction;
    if (address === undefined || address === null || address === '') {
      throw new Error(`The request requires transaction address.`);
    }

    if (blockHash === undefined || blockHash === null || blockHash === '') {
      throw new Error(`The request requires blockHash.`);
    }
    if (
      blockNumber === undefined ||
      blockNumber === null ||
      blockNumber === ''
    ) {
      throw new Error(`The request requires blockNumber.`);
    }
    if (
      genesisHash === undefined ||
      genesisHash === null ||
      genesisHash === ''
    ) {
      throw new Error(`The request requires genesisHash.`);
    }
    if (method === undefined || method === null || method === '') {
      throw new Error(`The request requires method.`);
    }
    if (
      specVersion === undefined ||
      specVersion === null ||
      specVersion === ''
    ) {
      throw new Error(`The request requires specVersion.`);
    }
  };

  async signPolkadotMsg(message) {
    if (!message) {
      throw new Error(`PolkadotTransactionManager - Empty Message to sign.`);
    }
    this._validateDappTxnObject(message);
    return await this._sign(message);
  }
}
