import { makeStyles, Tooltip } from '@material-ui/core';
import React, { useRef } from 'react';

const useToolTipStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.4,
    color: theme.palette.common.white,
    marginTop: 4,
  },
}));

export const ToastTooltip = (props: any) => {
  const { open, children, message, icon, placement } = props;
  const nodeRef = useRef(null);
  const classes = useToolTipStyles();
  return (
    <Tooltip
      ref={nodeRef}
      open={open}
      title={
        <div className={classes.content}>
          {icon && icon}
          {message}
        </div>
      }
      placement={placement}
    >
      <div ref={nodeRef}>{children}</div>
    </Tooltip>
  );
};
