import axios from 'axios';
import {
  cloverToErc20,
  erc20ToClover,
  erc20ToSakura,
  sakuraToErc20,
  getCrossChainItemByChainPair
} from '../utils/crossChain'
import {
  useTransactionStateUpdate,
  TransactionStatus,
} from '../services/TransactionService'
import { IS_DEV } from '../constants/api'
import {CUSTOM_EVMS, getMulChainURL} from "../constants/netEnums";
import store from '../state'

import { useClaimClv } from '../services/TransactionService'
import {getApiByToken, getLatestBlockNumber} from "./DotApiService";
import {TransferType} from "../components/Transfer";
const BRIDGE = require('../contracts/CloverBridge.json');
const Web3 = require('web3');
const dayjs = require('dayjs')
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export const REFRESH_INTERVAL = 5 * 60 * 1000;
export const REFRESH_TXN_LEAST_DURATION = 5 * 60 * 1000;
const clvTxnInfoApiAddress = IS_DEV ? 'https://clover-testnet.subscan.io/api/scan/extrinsic' : 'https://clover.subscan.io/api/scan/extrinsic'

function useRefreshPendingTransactionStatus(): (txnHash: string) => void {
  const refreshWeb3TxStatus = useRefreshWeb3TxStatus()
  const refreshClvToErc20TxStatus = useRefreshClvToErc20TxStatus()
  const refreshErc20ToClvTxStatus = useRefreshErc20ToClvTxStatus()
  const refreshPolkadotTxStatus = useRefreshPolkadotTxStatus()
  return async (txnHash: string) => {
    const {...storeData} = {...store.getState()}
    const transactionArr = storeData.transaction.transactions
    const transaction: any = transactionArr.find(t => t.txnHash === txnHash)

    if (transaction === undefined || transaction.status !== TransactionStatus.PENDING) {
      return
    }

    if ((transaction.txnType === TransferType.ERC20 || transaction.txnType === TransferType.ETH_FAMILY) && transaction.from.startsWith('0x')) {
      await refreshWeb3TxStatus(transaction)
    } else if (transaction.txnType === TransferType.CROSS_CHAIN) {
      if (cloverToErc20(transaction.crossFrom, transaction.crossTo) || sakuraToErc20(transaction.crossFrom, transaction.crossTo)) {
        await refreshClvToErc20TxStatus(transaction)
      } else if (erc20ToClover(transaction.crossFrom, transaction.crossTo) || erc20ToSakura(transaction.crossFrom, transaction.crossTo)) {
        await refreshErc20ToClvTxStatus(transaction)
      }
    } else if (transaction.txnType === TransferType.POLKA_FAMILY) {
      await refreshPolkadotTxStatus(transaction)
    }
  }
};

function useRefreshWeb3TxStatus(): (transaction: any) => void {
  const updateTransactionState = useTransactionStateUpdate()
  return async (transaction: any) => {
    const chainUrl = getMulChainURL(transaction.tokenSymbol, CUSTOM_EVMS);
    const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl))
    const txnHash = transaction.txnHash
    const receipt = await web3.eth.getTransactionReceipt(transaction.txnHash)
    if (receipt === null) {
      return
    }

    if (receipt.status) {
      updateTransactionState(transaction, txnHash, TransactionStatus.SUCCESS);
    } else {
      updateTransactionState(transaction, txnHash, TransactionStatus.FAIL);
    }
  }
};

const getTransactionInfoFromScan = async (txnHash: string) => {
  try {
    const { data } = await axios.post(clvTxnInfoApiAddress, {
      hash: txnHash
    });

    const burnEvent = data.data.event.find((e: any) => e.event_id === 'ElasticBurned' && e.module_id === 'cloverclaims')
    if (burnEvent === undefined) {
      return undefined
    }

    return {
      blockNum: burnEvent.block_num,
      index: burnEvent.event_idx
    }
  } catch (e) {
    return undefined
  }
}

function useRefreshClvToErc20TxStatus(): (transaction: any) => void {
  const updateTransactionState = useTransactionStateUpdate()
  return async (transaction: any) => {
    const crossToChain = getCrossChainItemByChainPair(transaction.crossFrom, transaction.crossTo)
    if (crossToChain === undefined) {
      return
    }

    const txnInfo = await getTransactionInfoFromScan(transaction.txnHash)
    if (txnInfo === undefined) {
      return
    }

    const chainUrl = getMulChainURL(crossToChain.chain, CUSTOM_EVMS)
    const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl))
    const contract = new web3.eth.Contract(BRIDGE.abi, crossToChain.bridgeAddress)
    const res = await contract.methods.hasMinted(txnInfo.blockNum, txnInfo.index).call();

    if (res) {
      updateTransactionState(transaction, transaction.txnHash, TransactionStatus.SUCCESS);
    }
  }
};

function useRefreshErc20ToClvTxStatus(): (transaction: any) => void {
  const updateTransactionState = useTransactionStateUpdate()
  const claimClv = useClaimClv()
  return async (transaction: any) => {
    const chainUrl = getMulChainURL(transaction.crossFrom, CUSTOM_EVMS);
    const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl))
    const receipt = await web3.eth.getTransactionReceipt(transaction.txnHash)
    if (receipt === null) {
      return
    }

    if (receipt.status) {
      try {
        await claimClv(transaction, web3, transaction.to, transaction.txnHash);
      } catch (e) {
        // nothing to do
        console.log(e)
      }
    } else {
      updateTransactionState(transaction, transaction.txnHash, TransactionStatus.FAIL);
    }
  }
};

function useRefreshPolkadotTxStatus(): (transaction: any) => void {
  const updateTransactionState = useTransactionStateUpdate()
  return async (transaction: any) => {
    const api = await getApiByToken(transaction.tokenSymbol)
    const nowBlockNumber = await getLatestBlockNumber(transaction.tokenSymbol)
    let latestBlockNumber = transaction.latestBlockNumber
    const transactionReceiptAsync = async (num: number) => {
      const blockHash = await api.rpc.chain.getBlockHash(num);
      const signedBlock = await api.rpc.chain.getBlock(blockHash);
      const allRecords = await api.query.system.events.at(signedBlock.block.header.hash);
      signedBlock.block.extrinsics.forEach((ex: any, index: number) => {
        if (ex.hash.toHex() === transaction.txnHash) {
          allRecords
              .filter(({ phase }: any) =>
                  phase.isApplyExtrinsic &&
                  phase.asApplyExtrinsic.eq(index)
              )
              .forEach(({ event }: any) => {
                if (api.events.system.ExtrinsicSuccess.is(event)) {
                  updateTransactionState(transaction, transaction.txnHash, TransactionStatus.SUCCESS);

                } else if (api.events.system.ExtrinsicFailed.is(event)) {
                  updateTransactionState(transaction, transaction.txnHash, TransactionStatus.FAIL);
                }
          });
        }
      });
      if (!((nowBlockNumber - latestBlockNumber) < 10)) {
        await transactionReceiptAsync(latestBlockNumber++)
      }
    }
    await transactionReceiptAsync(latestBlockNumber)
  }
}

const txnDuration = (txn: any) => {
  const txnLastUpdateDate = dayjs(txn.date);
  const nowDate = dayjs(new Date());
  const duration = dayjs.duration(nowDate.diff(txnLastUpdateDate));
  const seconds = Math.round(duration.asSeconds());
  return seconds
}

const needRefreshTxnStatus = (txn: any) => {
  const seconds = txnDuration(txn)
  return seconds * 1000 > REFRESH_TXN_LEAST_DURATION;
}

export function useRefreshAllPendingTxStatus(): () => void {
  const refreshPendingTransactionStatus = useRefreshPendingTransactionStatus()
  return async () => {
    const {...storeData} = {...store.getState()}
    const transactionArr = storeData.transaction.transactions
    const pendingTxns = transactionArr.filter(t => t.status === TransactionStatus.PENDING && needRefreshTxnStatus(t))
    for (const txn of pendingTxns) {
      await refreshPendingTransactionStatus(txn.txnHash)
    }
  }
};



