import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ReturnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M19.9,11.5H5H19.9z M5,11.5l4.8,4.8L5,11.5z M5,11.5l4.8-4.8L5,11.5z" />
      </g>
      <g>
        <polygon points="8.8,17.3 2.9,11.5 8.8,5.6 10.9,7.7 8.6,10 19.9,10 19.9,13 8.6,13 10.9,15.2 	" />
      </g>
    </SvgIcon>
  );
};
