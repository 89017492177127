import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WalletIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M17.5 6.06667V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V13.9333C17.9917 13.6417 18.3333 13.1167 18.3333 12.5V7.5C18.3333 6.88333 17.9917 6.35833 17.5 6.06667ZM16.6667 7.5V12.5H10.8333V7.5H16.6667ZM4.16667 15.8333V4.16667H15.8333V5.83333H10.8333C9.91667 5.83333 9.16667 6.58333 9.16667 7.5V12.5C9.16667 13.4167 9.91667 14.1667 10.8333 14.1667H15.8333V15.8333H4.16667Z"
        fill="currentColor"
      />
      <path
        d="M13.3333 11.25C14.0236 11.25 14.5833 10.6904 14.5833 10C14.5833 9.30964 14.0236 8.75 13.3333 8.75C12.6429 8.75 12.0833 9.30964 12.0833 10C12.0833 10.6904 12.6429 11.25 13.3333 11.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
