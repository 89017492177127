/* get account address, get account balance related function put here */

import { spec } from '@edgeware/node-types';
import BN from 'bn.js';
import cloverTypes from '@clover-network/node-types';
import { getPolkadotNetworkByTokenSymbol } from './AssetServcie';
import { dotTypes } from '../dotTypes/dot-types';
import { typesChain } from '@phala/typedefs';
import { KeyPairType } from './WalletService';

const { WsProvider, ApiPromise } = require('@polkadot/api');
const { Keyring } = require('@polkadot/api');

export const createApi = (tokenSymbol, types = undefined) => {
  const network = getPolkadotNetworkByTokenSymbol(tokenSymbol);
  if (!network) {
    return {};
  }

  const api = new Promise(async (resolve, reject) => {
    try {
      const wsProvider = new WsProvider(network.networkURL);
      let api;
      if (tokenSymbol === 'PHA') {
        api = await ApiPromise.create({
          provider: wsProvider,
          typesChain,
        });
      } else if (!types) {
        api = await ApiPromise.create({
          provider: wsProvider,
        });
      } else if (tokenSymbol === 'EDG') {
        api = await ApiPromise.create({
          provider: wsProvider,
          ...types,
        });
      } else {
        api = await ApiPromise.create({
          provider: wsProvider,
          types,
        });
      }

      return resolve(api);
    } catch (e) {
      reject(e.toString());
    }
  });

  return api;
};

let clvApi;
let skuApi;
let ksmApi;
let edgApi;
let dotApi;
let cruApi;
let karApi;
let sdnApi;
let phaApi;
let bncApi;
let kiltApi;
let kmaApi;
export const getCloverApi = async () => {
  if (clvApi) {
    return clvApi;
  }

  clvApi = await createApi('CLV', cloverTypes);
  return clvApi;
};

export const getSakuraApi = async () => {
  if (skuApi) {
    return skuApi;
  }

  skuApi = await createApi('SKU', cloverTypes);
  return skuApi;
};

export const getKsmApi = async () => {
  if (ksmApi) {
    return ksmApi;
  }

  ksmApi = await createApi('KSM');
  return ksmApi;
};

export const getEdgApi = async () => {
  if (edgApi) {
    return edgApi;
  }

  edgApi = await createApi('EDG', spec);
  return edgApi;
};

export const getDotApi = async () => {
  if (dotApi) {
    return dotApi;
  }

  dotApi = await createApi('DOT', dotTypes);
  return dotApi;
};

export const getCruApi = async () => {
  if (cruApi) {
    return cruApi;
  }
  cruApi = await createApi('CRU');
  return cruApi;
};

export const getKarApi = async () => {
  if (karApi) {
    return karApi;
  }
  karApi = await createApi('KAR');
  return karApi;
};

export const getSdnApi = async () => {
  if (sdnApi) {
    return sdnApi;
  }
  sdnApi = await createApi('SDN');
  return sdnApi;
};

export const getPhaApi = async () => {
  if (phaApi) {
    return phaApi;
  }
  phaApi = await createApi('PHA');
  return phaApi;
};

export const getBncApi = async () => {
  if (bncApi) {
    return bncApi;
  }
  bncApi = await createApi('BNC');
  return bncApi;
};

export const getKiltApi = async () => {
  if (kiltApi) {
    return kiltApi;
  }
  kiltApi = await createApi('KILT');
  return kiltApi;
};

export const getKmaApi = async () => {
  if (kmaApi) {
    return kmaApi;
  }
  kmaApi = await createApi('KMA');
  return kmaApi;
};

export const getApiByToken = async tokenSymbol => {
  if (tokenSymbol === 'KSM') {
    return await getKsmApi();
  } else if (tokenSymbol === 'EDG') {
    return await getEdgApi();
  } else if (tokenSymbol === 'DOT') {
    return await getDotApi();
  } else if (tokenSymbol === 'CLV') {
    return await getCloverApi();
  } else if (tokenSymbol === 'SKU') {
    return await getSakuraApi();
  } else if (tokenSymbol === 'CRU') {
    return await getCruApi();
  } else if (tokenSymbol === 'KAR') {
    return await getKarApi();
  } else if (tokenSymbol === 'SDN') {
    return await getSdnApi();
  } else if (tokenSymbol === 'PHA') {
    return await getPhaApi();
  } else if (tokenSymbol === 'BNC') {
    return await getBncApi();
  } else if (tokenSymbol === 'KILT') {
    return await getKiltApi();
  } else if (tokenSymbol === 'KMA') {
    return await getKmaApi();
  }

  throw new Error('Invalid api.');
};

export const getAddress = seedWords => {
  try {
    const keyring = new Keyring({ type: KeyPairType.sr25519 });
    keyring.setSS58Format(42);
    const pairAlice = keyring.addFromUri(seedWords);
    const { address } = pairAlice;
    return address;
  } catch (err) {
    throw new Error('Error in Polkadot getAddress');
  }
};

let decimals = {};

export const getPolkadotDecimals = async tokenSymbol => {
  if (decimals[tokenSymbol]) {
    return decimals[tokenSymbol];
  }

  const api = await getApiByToken(tokenSymbol);
  const { tokenDecimals } = await api.rpc.system.properties();
  decimals[tokenSymbol] = tokenDecimals.value[0].toNumber();
  return decimals[tokenSymbol];
};

export const getLatestBlockNumber = async tokenSymbol => {
  const api = await getApiByToken(tokenSymbol);
  const lastHeader = await api.rpc.chain.getHeader();
  return lastHeader.number.toNumber();
};

export const signTransaction = async (seedWords, keypairType, transaction) => {
  const { to, amountBn, from } = transaction;

  const api = await getApiByToken(transaction.tokenSymbol);
  //replace this with commented line once edgeware upgrade mainnet
  //const { nonce } = await api.query.system.account(address);
  const nonce = await api.rpc.system.accountNextIndex(from);
  const keyring = new Keyring({ type: keypairType });
  const accountPair = keyring.addFromUri(seedWords);
  let signTransaction;
  if (api.tx.currencies) {
    signTransaction = await api.tx.currencies
      .transfer(to, transaction.metadata.token, new BN(amountBn))
      .sign(accountPair, { nonce });
  } else {
    signTransaction = await api.tx.balances
      .transfer(to, new BN(amountBn))
      .sign(accountPair, { nonce });
  }
  return signTransaction;
};
