import { createAction } from '@reduxjs/toolkit'
import { AccountInfo, WalletAccount } from './types';

export const accountList = createAction<{accountList: any}>('wallet/acountList')
export const accountInfo = createAction<{accountInfo: AccountInfo}>('wallet/acountInfo')
export const wrongNetwork = createAction<{wrongNetwork: boolean}>('wallet/wrongNetwork')
export const stakeInfo = createAction<{stakeInfo: any}>('wallet/stakeInfo')
export const auctionInfos = createAction<{auctionInfos: any}>('wallet/auctionInfos')
export const loginAccount = createAction<{loginAccount: WalletAccount}>('wallet/loginAccount')
export const importedAccounts = createAction<{importedAccounts: Array<WalletAccount>}>('wallet/importedAccounts')
