import React, { useState } from 'react';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { Button, Typography } from '@material-ui/core';
import { ethers } from 'ethers';
import _ from 'lodash';
import { useImportAccountWithSeedWords } from '../../services/AccountService';
import {
  ChainSS58,
  getSubstrateAddress,
  KeyPairType,
} from '../../services/WalletService';
import { useAccounts } from '../../state/account/hooks';
import { useHistory } from 'react-router-dom';
import { InputField } from 'components/InputField/InputField';
import { toastDismiss, toastLoading } from '../../services/toastService';
const { isValidMnemonic } = ethers.utils;

interface ImportAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportAccountDialog = ({ isOpen, onClose }: ImportAccountDialogProps) => {
  const history = useHistory();
  const [seedPhrase, setSeedPhrase] = useState('');
  const [seedPhraseError, setSeedPhraseError] = useState('');
  const [selectKeypair, setSelectKeypair] = useState<string>(
    KeyPairType.sr25519,
  );
  const [importedKeypair, setImportedKeypair] = useState<any>([]);

  const accounts = useAccounts();
  const importAccount = useImportAccountWithSeedWords();

  const onDismiss = () => {
    onClose();
  };

  const parseSeedPhrase = (seedPhrase: string) =>
    (seedPhrase || '').trim().toLowerCase().match(/\w+/gu)?.join(' ') || '';

  const handleSeedPhraseChange = (seedPhrase: string) => {
    setSeedPhrase(seedPhrase);
    if (seedPhrase) {
      const parsedSeedPhrase = parseSeedPhrase(seedPhrase);
      const wordCount = parsedSeedPhrase.split(/\s/u).length;

      if (wordCount % 3 !== 0 || wordCount > 24 || wordCount < 12) {
        setSeedPhraseError(t('import-account-dialog.seed-phrase-error'));
      } else if (!isValidMnemonic(parsedSeedPhrase)) {
        setSeedPhraseError(t('import-account-dialog.invalid-seed-phrase'));
      } else {
        const clvSrAddress = getSubstrateAddress(
          parsedSeedPhrase,
          ChainSS58.CLV,
          KeyPairType.sr25519,
        );
        const clvEdAddress = getSubstrateAddress(
          parsedSeedPhrase,
          ChainSS58.CLV,
          KeyPairType.ed25519,
        );
        const imported = [];
        let canSelected: string = '';
        if (_.find(accounts, a => a.address === clvSrAddress)) {
          imported.push(KeyPairType.sr25519);
          canSelected = KeyPairType.ed25519;
        }
        if (_.find(accounts, a => a.address === clvEdAddress)) {
          imported.push(KeyPairType.ed25519);
          canSelected = KeyPairType.sr25519;
        }
        setSeedPhraseError('');
        setSelectKeypair(
          imported.length === 2
            ? ''
            : imported.length === 1
            ? canSelected
            : KeyPairType.sr25519,
        );
        setImportedKeypair(imported);
      }
    }
  };

  const handleSelectKeypair = (keypair: string) => {
    if (_.includes(importedKeypair, keypair)) {
      return;
    }
    setSelectKeypair(keypair);
  };

  const isValid = () => {
    if (seedPhrase === '' || importedKeypair.length === 2 || !selectKeypair) {
      return false;
    }
    return !seedPhraseError;
  };

  const handleImport = async () => {
    if (isValid()) {
      try {
        toastLoading('Loading...');
        await importAccount(seedPhrase, selectKeypair);
        onDismiss();
        history.push('/homePage/wallet');
      } catch (e) {
        console.log(e)
      } finally {
        toastDismiss();
      }
    }
  };

  return (
    <DialogModal
      maxWidth="lg"
      open={isOpen}
      onClose={onDismiss}
      disabled={!isValid()}
      cancelText={t('import-account-dialog.cancel-button')}
      handleText={t('import-account-dialog.handle-button')}
      handleDialog={handleImport}
      hideClose={true}
    >
      <Typography variant="h2">{t('import-account-dialog.title')}</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginTop: 10, marginBottom: 30 }}
      >
        {t('import-account-dialog.subtitle')}
      </Typography>
      <InputField
        errorInfo={seedPhraseError}
        placeHolder={t('import-account-dialog.enter-seed-phrase-placeholder')}
        value={seedPhrase}
        type="password"
        onChange={(event: any) => {
          handleSeedPhraseChange(event.target.value);
        }}
      />

      <Typography variant="h5">
        {t('import-account-dialog.advanced')}{' '}
        <Typography variant="body2" component="span" color="textSecondary">
          {t('import-account-dialog.keypair-crypto-Type')}
        </Typography>
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 8,
          marginBottom: 36,
        }}
      >
        <Button
          variant={selectKeypair === KeyPairType.sr25519 ? 'contained' : 'text'}
          disabled={_.includes(importedKeypair, KeyPairType.sr25519)}
          onClick={() => handleSelectKeypair(KeyPairType.sr25519)}
        >
          {_.upperFirst(KeyPairType.sr25519)}
          {_.includes(importedKeypair, KeyPairType.sr25519) && '(imported)'}
        </Button>
        <Button
          variant={selectKeypair === KeyPairType.ed25519 ? 'contained' : 'text'}
          disabled={_.includes(importedKeypair, KeyPairType.ed25519)}
          onClick={() => handleSelectKeypair(KeyPairType.ed25519)}
        >
          {_.upperFirst(KeyPairType.ed25519)}
          {_.includes(importedKeypair, KeyPairType.ed25519) && '(imported)'}
        </Button>
      </div>
    </DialogModal>
  );
};

export default ImportAccountDialog;
