import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import log from 'loglevel';
import { broadcastChannelOptions } from '../../utils/utils';
import { makeStyles } from '@material-ui/core';

const useAuthEndStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
}));

export default function AuthEndPage() {
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    let bc;
    const openId = query.get('openId');
    const userId = query.get('userId');
    const token = query.get('token');

    try {
      if (!openId) {
        throw new Error('No openId.');
      }

      bc = new BroadcastChannel(
        `clover_login_channel_${openId}`,
        broadcastChannelOptions,
      );
      if (!userId || !token) {
        bc.postMessage({
          data: {
            message: 'Login failed.',
          },
        });
      } else {
        bc.postMessage({
          data: {
            userId,
            token,
          },
        });
      }
    } catch (error) {
      log.info(error, error.message);
      if (bc) bc.close();
      window.close();
    }
  }, [query]);

  const classes = useAuthEndStyles();

  return <div className={classes.root}></div>;
}
