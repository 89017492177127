import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { AppState } from '../index';
import { accounts, currentAccount, balance, prices, balances } from './actions';

export function useAccounts(): Array<any> {
  return useSelector((state: AppState) => state.account.accounts);
}

export function useAccountsUpdate(): (accs: Array<any>) => void {
  const dispatch = useDispatch();
  return useCallback(
    (accs: Array<any>) => dispatch(accounts({ accounts: accs })),
    [dispatch],
  );
}

export function useCurrentAccount(): any {
  return useSelector((state: AppState) => state.account.currentAccount);
}

export function useUserSession(): any {
  return useSelector((state: AppState) => state.user.userSession);
}

export function useCurrentAccountUpdate(): (acc: any) => void {
  const dispatch = useDispatch();
  return useCallback(
    (acc: any) => dispatch(currentAccount({ currentAccount: acc })),
    [dispatch],
  );
}

export function useBalanceUpdate(): (bal: any) => void {
  const dispatch = useDispatch();
  return useCallback(
    (bal: any) => dispatch(balance({ balance: bal })),
    [dispatch],
  );
}

export function useBalancesUpdate(): (bal: any) => void {
  const dispatch = useDispatch();
  return useCallback(
    (bal: any) => dispatch(balances({ balances: bal })),
    [dispatch],
  );
}

export function usePrices(): Array<any> {
  return useSelector((state: AppState) => state.account.prices);
}

export function usePricesUpdate(): (priceData: any) => void {
  const dispatch = useDispatch();
  return useCallback(
    (priceData: any) => dispatch(prices({ prices: priceData })),
    [dispatch],
  );
}
