import React from 'react';
import { t } from 'modules/i18n/intl';
import TokenLogoWithChain from '../TokenLogoWithChain';
import { DialogModal } from 'components/DialogModal';
import { InputField } from 'components/InputField/InputField';
import { Typography } from '@material-ui/core';

interface ConfirmAddTokenDialogProps {
  isOpen: boolean;
  onClose: () => void;
  confirmAddToken: () => void;
  token: any;
}

const ConfirmAddTokenDialog = ({
  isOpen,
  onClose,
  confirmAddToken,
  token,
}: ConfirmAddTokenDialogProps) => {
  return (
    <DialogModal
      title={t('add-custom-token-dialog.title')}
      open={isOpen}
      onReturn={onClose}
      onClose={onClose}
      handleDialog={confirmAddToken}
      handleText={t('add-custom-token-dialog.button')}
    >
      <Typography variant="h2" style={{ marginTop: 20, marginBottom: 40 }}>
        {t('add-custom-token-dialog.confirm-text')}
      </Typography>
      <InputField
        label={t('add-custom-token-dialog.token')}
        leftContent={
          <TokenLogoWithChain
            tokenAddress={token.tokenAddress}
            chain={token.chain}
          />
        }
        value={token.symbol}
        disabled
      />
      <InputField
        label={t('add-custom-token-dialog.balance')}
        value={t('add-custom-token-dialog.token-amount', {
          amount: token.amount,
          symbol: token.symbol,
        })}
        disabled
      />
    </DialogModal>
  );
};

export default ConfirmAddTokenDialog;
