import { makeStyles, Theme, Typography } from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { LogoIcon } from 'modules/icons/logo/LogoIcon';

const useTextLogoStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(9.5, 0, 0, 6),
    height: 24,
    boxSizing: 'content-box',
  },
  logoIcon: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  clover: {
    marginLeft: theme.spacing(3),
  },
}));

export const TextLogo = () => {
  const classes = useTextLogoStyles();

  return (
    <div className={classes.root}>
      <LogoIcon className={classes.logoIcon} />
      <Typography variant="h2" className={classes.clover}>
        {t('clover')}
      </Typography>
    </div>
  );
};
