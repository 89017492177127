import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { t } from 'modules/i18n/intl';
import { Typography } from '@material-ui/core';
import { useConfirmStyles } from './useConfirmStyles';
import classNames from 'classnames';
import { DialogActionsModal } from 'components/DialogModal/DialogActionsModal';
import { LogoLoadingIcon } from 'modules/icons/loading/LogoLoadingIcon';
import { DataLoadingIcon } from 'modules/icons/loading/DataLoadingIcon';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import { BroadcastChannel } from 'broadcast-channel';
import log from 'loglevel';
import { broadcastChannelOptions } from '../../utils/utils';
import {
  POPUP_LOADED,
  POPUP_RESULT,
  TRANSACTION_TYPES,
  TRANSACTION_ENVELOPE_TYPES,
  getNetByChainId,
  CUSTOM_EVMS,
  MESSAGE_TYPE,
} from '../../constants/netEnums';
import { shortenAddress } from '../../utils/helper';
import { getEthFee, getGasInfo } from '../../services/FeeService';
import {
  EthNativeGasAmount,
  EthFamilyDecimals,
} from '../../services/AssetServcie';
import { getEvmBalance } from '../../services/WalletService';
import { getErc20Balance } from '../../services/tokenService';
import { createTxnUIObject } from '../../controllers/transactions/polkadot-sign-txn';

export default function ConfirmPage() {
  const [channelName, setChannelName] = useState('');
  const [transType, setTransType] = useState('');
  const [currentConfirmModal, setCurrentConfirmModal] = useState({});
  const [net, setNet] = useState({});
  const [amount, setAmount] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [balanceObj, setBalanceObj] = useState({});
  const [insufficientErr, setInsufficientErr] = useState('');
  const [currentIndex, setCurrentIndex] = useState(1);
  const [signMsg, setSignMsg] = useState('');
  const [polkadotSignTxn, setPolkadotSignTxn] = useState({});

  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const setEthFamilyFee = useCallback(async () => {
    try {
      const gasFee = await getGasInfo(net.ticker, CUSTOM_EVMS);

      const feeBn = gasFee * 1.05 * EthNativeGasAmount;
      const fee = toRealNumber(feeBn, 18);

      const txnFee = {
        gasPrice: gasFee,
        feeBn: feeBn.toFixed(),
        decimals: EthFamilyDecimals,
        fee: fee.toFixed(6),
      };
      setTxnFee(txnFee);
    } catch (e) {
      console.log(e);
    }
  }, [net?.ticker]);

  const getFeeObj = useMemo(
    () => (net?.ticker === 'ETH' ? txnFee.feeSelected : txnFee),
    [net?.ticker, txnFee],
  );

  const checkBalanceSufficient = useCallback(
    balanceObj => {
      const amountBn = toBigNumber(amount, EthFamilyDecimals);
      const fee = toBigNumber(getFeeObj.feeBn, 0);
      const balance = toBigNumber(balanceObj.balTicker, 0);

      if (currentConfirmModal.contractParams.erc20) {
        const balTokenBn = toBigNumber(balanceObj.balToken, 0);
        return balTokenBn.gte(amountBn) && balance.gte(fee);
      }

      const totalBn = amountBn.plus(fee);

      return totalBn.lte(balance);
    },
    [amount, currentConfirmModal?.contractParams?.erc20, getFeeObj],
  );

  const getTotalStr = () => {
    if (_.isEmpty(currentConfirmModal) && _.isEmpty(net)) {
      return '';
    }

    const amountBn = toBigNumber(amount, EthFamilyDecimals);
    const fee = toBigNumber(getFeeObj.feeBn, 0);
    let totalBn = amountBn.plus(fee);
    if (
      currentConfirmModal?.contractParams?.erc20 &&
      currentConfirmModal.methodParams
    ) {
      return `${amount} ${getDisplayInfo().symbol} + ${toRealNumber(
        getFeeObj.feeBn,
        EthFamilyDecimals,
      ).toFixed()} ${net.ticker}`;
    }

    return `${toRealNumber(totalBn.toFixed(), EthFamilyDecimals).toFixed()} ${
      net.ticker
    }`;
  };

  const getBalance = useCallback(async () => {
    let balToken;
    let balTicker;
    if (currentConfirmModal.contractParams?.erc20) {
      balToken = await getErc20Balance(
        currentConfirmModal.txParams.to,
        currentConfirmModal.txParams.from,
        net.ticker,
      );
    }
    const res = await getEvmBalance(
      currentConfirmModal.txParams.from,
      net.ticker,
      CUSTOM_EVMS,
    );
    balTicker = res.balance;

    return {
      balToken: balToken?.toString(),
      balTicker: balTicker.toString(),
    };
  }, [
    currentConfirmModal.contractParams?.erc20,
    currentConfirmModal.txParams?.from,
    currentConfirmModal.txParams?.to,
    net?.ticker,
  ]);

  const setDatas = useCallback(async () => {
    if (_.isEmpty(net) || _.isEmpty(currentConfirmModal.txParams)) {
      return;
    }
    if (net.ticker !== 'ETH') {
      await setEthFamilyFee();
    } else {
      const ethFee = await getEthFee(EthNativeGasAmount);
      setTxnFee(ethFee);
    }

    const res = await getBalance();
    setBalanceObj(res);
  }, [currentConfirmModal.txParams, getBalance, net, setEthFamilyFee]);

  const needFeeSelect = useMemo(() => net?.ticker === 'ETH', [net?.ticker]);

  const getDisplayInfo = () => {
    if (_.isEmpty(currentConfirmModal) && _.isEmpty(net)) {
      return {};
    }

    let symbol = net.ticker;
    let toAddress = currentConfirmModal.txParams.to;

    if (
      currentConfirmModal?.contractParams?.erc20 &&
      currentConfirmModal.methodParams
    ) {
      toAddress = currentConfirmModal.methodParams[0].value;
      symbol = currentConfirmModal.contractParams.symbol;
    }

    return {
      toAddress,
      symbol,
    };
  };

  const setAmountFromData = data => {
    if (data.contractParams?.erc20 && data.methodParams) {
      setAmount(
        toRealNumber(data.methodParams[1].value, EthFamilyDecimals).toFixed(),
      );
    } else {
      setAmount(toRealNumber(data.txParams.value, EthFamilyDecimals).toFixed());
    }
  };

  const isLoading = useMemo(() => {
    if (
      _.isEmpty(currentConfirmModal) ||
      (currentConfirmModal.type === MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION &&
        _.isEmpty(polkadotSignTxn))
    ) {
      return true;
    }

    if (!transType || transType === '') {
      return true;
    }

    // sign transType should not show loading
    if (transType !== TRANSACTION_TYPES.STANDARD_TRANSACTION) {
      return false;
    }

    // when transType is transaction and fee is empty show loading
    if (_.isEmpty(txnFee)) {
      return true;
    }

    return false;
  }, [transType, txnFee, currentConfirmModal, polkadotSignTxn]);

  useEffect(() => {
    const channel = `clover_channel_${query.get('instanceId')}`;
    setChannelName(channel);
    const bc = new BroadcastChannel(channel, broadcastChannelOptions);
    bc.addEventListener('message', async ev => {
      const {
        type,
        msgParams,
        txParams,
        origin,
        balance,
        selectedCurrency,
        tokenRates,
        jwtToken,
        whiteLabel,
        currencyData,
        network,
        networkDetails,
        gasFees,
      } = ev.data || {};
      log.debug('confirm ev.data:', ev.data);
      if (ev.data && ev.data.id.toString() !== query.get('id')) {
        return;
      }

      const net = getNetByChainId(ev?.data?.chainId, CUSTOM_EVMS);
      setNet(net);
      setTransType(type);
      if (_.isEmpty(ev.data.msgParams)) {
        setAmountFromData(ev.data);
      }

      const currentConfirmModal = {
        type,
        msgParams,
        txParams,
        origin,
        balance,
        selectedCurrency,
        tokenRates,
        jwtToken,
        whiteLabel,
        currencyData,
        network,
        networkDetails,
        gasFees,
        id: ev.data.id.toString(),
        contractParams: ev.data.contractParams,
        methodParams: ev.data.methodParams,
      };
      setCurrentConfirmModal(currentConfirmModal);
      log.debug('currentConfirmModal:', currentConfirmModal);
      bc.close();
    });
    bc.postMessage({ data: { type: POPUP_LOADED, id: query.get('id') } });
  }, [query]);

  const setPolkadotTxnDatas = useCallback(async () => {
    const txn = await createTxnUIObject(
      currentConfirmModal.msgParams.msgParams,
    );
    setPolkadotSignTxn(txn);
  }, [currentConfirmModal?.msgParams?.msgParams]);

  useEffect(() => {
    if (_.isEmpty(net) || _.isEmpty(currentConfirmModal.txParams)) {
      return;
    }

    const selectedAddress = currentConfirmModal.txParams.from;
    log.info('selectedAddress:', selectedAddress);

    setDatas();
  }, [net, currentConfirmModal, setDatas]);

  useEffect(() => {
    if (currentConfirmModal.type !== MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION) {
      return;
    }

    setPolkadotTxnDatas();
  }, [currentConfirmModal, setPolkadotTxnDatas]);

  useEffect(() => {
    if (_.isEmpty(currentConfirmModal.msgParams)) {
      return;
    }

    if (
      currentConfirmModal.type === MESSAGE_TYPE.PERSONAL_SIGN ||
      currentConfirmModal.type === MESSAGE_TYPE.ETH_SIGN
    ) {
      setSignMsg(currentConfirmModal.msgParams.msgParams.message);
    } else if (currentConfirmModal.type === MESSAGE_TYPE.ETH_SIGN_TYPED_DATA) {
      if (
        Array.isArray(currentConfirmModal.msgParams.msgParams.typedMessages)
      ) {
        setSignMsg(
          JSON.stringify(currentConfirmModal.msgParams.msgParams.typedMessages),
        );
      } else if (
        typeof currentConfirmModal.msgParams.msgParams.typedMessages ===
        'string'
      ) {
        setSignMsg(currentConfirmModal.msgParams.msgParams.typedMessages);
      }
    } else if (currentConfirmModal.type === MESSAGE_TYPE.SOLANA_SIGN) {
      setSignMsg(JSON.stringify(currentConfirmModal.msgParams.msgParams));
    } else if (currentConfirmModal.type === MESSAGE_TYPE.POLKADOT_SIGN) {
      setSignMsg(JSON.stringify(currentConfirmModal.msgParams.msgParams));
    }
  }, [net, currentConfirmModal]);

  useEffect(() => {
    if (_.isEmpty(balanceObj)) {
      return;
    }

    const sufficient = checkBalanceSufficient(balanceObj);
    if (!sufficient) {
      setInsufficientErr('Insufficient Balance.');
    }
  }, [balanceObj, txnFee, checkBalanceSufficient]);

  const approve = async ({
    gasPrice,
    gas,
    customNonceValue,
    id,
    maxFeePerGas,
    maxPriorityFeePerGas,
  }) => {
    const bc = new BroadcastChannel(channelName, broadcastChannelOptions);

    let txEnvelopeType;
    if (maxPriorityFeePerGas && maxFeePerGas) {
      txEnvelopeType = TRANSACTION_ENVELOPE_TYPES.FEE_MARKET;
    } else {
      txEnvelopeType = TRANSACTION_ENVELOPE_TYPES.LEGACY;
    }
    await bc.postMessage({
      data: {
        type: POPUP_RESULT,
        gasPrice,
        maxFeePerGas,
        maxPriorityFeePerGas,
        txEnvelopeType,
        gas,
        id,
        txType: transType,
        customNonceValue,
        approve: true,
      },
    });
    bc.close();
  };

  const deny = async () => {
    const bc = new BroadcastChannel(channelName, broadcastChannelOptions);
    await bc.postMessage({
      data: {
        type: POPUP_RESULT,
        id: query.get('id'),
        txType: transType,
        approve: false,
      },
    });
    bc.close();
  };

  const classes = useConfirmStyles();

  const getToAddress = () => {
    if (polkadotSignTxn) {
      if (polkadotSignTxn.dest) {
        if (polkadotSignTxn.dest.id) {
          return polkadotSignTxn.dest.id;
        }

        return polkadotSignTxn.dest;
      }
    }

    return 'Unknown';
  };
  return (
    <div className={classes.root}>
      {isLoading && <LogoLoadingIcon className={classes.loading} />}
      {!isLoading && transType === TRANSACTION_TYPES.STANDARD_TRANSACTION && (
        <div>
          <div className={classes.network}>
            {net?.nickname ? net.nickname : 'Unknown Network'}
          </div>
          <div className={classes.item}>
            <div className={classes.row}>
              {t('confirm.from')}
              <Typography className={classes.info}>
                {shortenAddress(currentConfirmModal.txParams.from)}
              </Typography>
            </div>
            <div className={classes.row}>
              {t('confirm.to')}
              <Typography className={classes.info}>
                {shortenAddress(getDisplayInfo().toAddress ?? '')}
              </Typography>
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.row}>
              {t('confirm.amount')}
              <Typography className={classes.info}>
                {`${amount} ${getDisplayInfo().symbol}`}
              </Typography>
            </div>
            <div className={`${classes.row} ${classes.borderNone}`}>
              {t('confirm.gas-fee')}
              {!needFeeSelect && _.isEmpty(txnFee) ? (
                <DataLoadingIcon />
              ) : (
                <Typography className={classes.info}>
                  {needFeeSelect
                    ? ``
                    : `${txnFee.fee} ${getDisplayInfo().symbol}`}
                </Typography>
              )}
            </div>
            {needFeeSelect && txnFee.gasList && (
              <div className={classes.gasList}>
                {txnFee.gasList.map((fee, index) => (
                  <div
                    key={`fee_${index}`}
                    className={classNames(
                      classes.gas,
                      currentIndex === index && classes.currentGas,
                    )}
                    onClick={() => {
                      setTxnFee({
                        ...txnFee,
                        feeSelected: txnFee.gasList[index],
                      });
                      setCurrentIndex(index);
                    }}
                  >
                    <Typography variant="h4">{fee.level}</Typography>
                    <Typography variant="body2">{fee.fee}</Typography>
                    <Typography variant="body2">${fee.total}</Typography>
                  </div>
                ))}
              </div>
            )}
            <div className={classes.item}>
              <div className={classes.row}>
                {t('confirm.total')}
                {!needFeeSelect && _.isEmpty(txnFee) ? (
                  <DataLoadingIcon />
                ) : (
                  <Typography className={classes.info}>
                    {getTotalStr()}
                  </Typography>
                )}
              </div>
              {insufficientErr !== '' && (
                <div className={classes.error}>{insufficientErr}</div>
              )}
            </div>
          </div>
        </div>
      )}
      {!isLoading &&
        currentConfirmModal.type === MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION && (
          <div>
            <div className={classes.network}>
              {net?.nickname ? net.nickname : 'Unknown Network'}
            </div>
            <div className={classes.item}>
              <div className={classes.row}>
                {t('confirm.to')}
                <Typography className={classes.info}>
                  {shortenAddress(getToAddress())}
                </Typography>
              </div>
              <div className={classes.row}>
                {t('confirm.method')}
                <Typography className={classes.info}>
                  {polkadotSignTxn.method}
                </Typography>
              </div>
              <div className={classes.row}>
                {t('confirm.nonce')}
                <Typography className={classes.info}>
                  {polkadotSignTxn.nonce}
                </Typography>
              </div>
              <div className={classes.row}>
                {t('confirm.value')}
                <Typography className={classes.info}>
                  {polkadotSignTxn.value}
                </Typography>
              </div>
            </div>
          </div>
        )}
      {!isLoading &&
        !_.isEmpty(currentConfirmModal.msgParams) &&
        currentConfirmModal.type !== MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION && (
          <div>
            <div className={classes.title}>{t('confirm.sign-message')}</div>
            <div className={classes.item}>
              {currentConfirmModal.type !== MESSAGE_TYPE.SOLANA_SIGN &&
                currentConfirmModal.type !== MESSAGE_TYPE.POLKADOT_SIGN &&
                currentConfirmModal.type !==
                  MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION && (
                  <div className={classes.row}>
                    {t('confirm.from')}
                    <Typography className={classes.info}>
                      {shortenAddress(
                        currentConfirmModal.msgParams.msgParams.from,
                      )}
                    </Typography>
                  </div>
                )}
              <div className={classNames(classes.row, classes.column)}>
                {t('confirm.message-to-sign')}{' '}
                <Typography className={classes.info}>{signMsg}</Typography>
              </div>
            </div>
          </div>
        )}
      {!isLoading && (
        <div className={classes.footer}>
          <DialogActionsModal
            onCancel={deny}
            cancelText={t('action.deny')}
            handleText={t('action.approve')}
            disabled={!_.isEmpty(balanceObj) && insufficientErr !== ''}
            handleDialog={() =>
              !_.isEmpty(currentConfirmModal.msgParams)
                ? approve({
                    id: currentConfirmModal.id,
                  })
                : approve({
                    gasPrice: '0x' + getFeeObj.gasPrice.toString(16), //currentConfirmModal.txParams.maxFeePerGas,
                    gas: currentConfirmModal.txParams.gas,
                    customNonceValue: undefined,
                    id: currentConfirmModal.id,
                    maxFeePerGas: currentConfirmModal.txParams.maxFeePerGas,
                    maxPriorityFeePerGas:
                      currentConfirmModal.txParams.maxPriorityFeePerGas,
                  })
            }
          />
        </div>
      )}
    </div>
  );
}
