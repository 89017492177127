import { IS_DEV } from './api'

export const CLOVER_NETWORK = {
  text: 'Clover',
  value: 'Clover',
  networkURL: IS_DEV ? 'wss://api.clover.finance/' : 'wss://api-ivy-elastic.clover.finance',
  ethHttpURL: IS_DEV ? 'https://rpc.clover.finance' : 'https://rpc-ivy.clover.finance',
  networkPort: '',
  networkFullUrl: IS_DEV ? 'wss://api.clover.finance/' : 'wss://api-ivy-elastic.clover.finance',
  transactionUrl: 'https://apps.ownstack.cn/#/explorer',
  unit: 'CLV',
  decimal: 18,
};

export const SAKURA_NETWORK = {
  text: 'Sakura',
  value: 'Sakura',
  networkURL: IS_DEV ? 'wss://sku-dev-api.clover.finance' : 'wss://rpc.sakura.clover.finance',
  networkPort: '',
  networkFullUrl: IS_DEV ? 'wss://sku-dev-api.clover.finance' : 'wss://rpc.sakura.clover.finance',
  unit: 'SKU',
  decimal: 18
};

export const POLKADOT_NETWORK = {
  text: 'Polkadot',
  value: 'Polkadot',
  networkURL: 'wss://rpc.polkadot.io',
  networkPort: '',
  networkFullUrl: 'wss://rpc.polkadot.io',
  unit: 'DOT',
  decimal: 10,
};

export const KUSAMA_NETWORK = {
  text: 'Kusama',
  value: 'Kusama',
  networkURL: 'wss://kusama-rpc.polkadot.io/',
  networkPort: '',
  networkFullUrl: 'wss://kusama-rpc.polkadot.io/',
  unit: 'KSM',
  decimal: 12
};

export const EDGEWARE_NETWORK = {
  text: 'Edgeware',
  value: 'Edgeware',
  networkURL: 'wss://edgeware.clovernode.com',
  networkPort: '',
  networkFullUrl: 'wss://edgeware.clovernode.com',
  unit: 'EDG',
  decimal: 18
};

export const CRUST_NETWORK = {
  text: 'Crust',
  value: 'Crust',
  networkURL: 'wss://rpc.crust.network',
  networkPort: '',
  networkFullUrl: 'wss://rpc.crust.network',
  unit: 'CRU',
  decimal: 12,
};

export const KARURA_NETWORK = {
  text: 'Karura',
  value: 'Karura',
  networkURL: 'wss://karura-rpc-0.aca-api.network',
  networkPort: '',
  networkFullUrl: 'wss://karura-rpc-0.aca-api.network',
  unit: 'KAR',
  decimal: 12,
  paraId: 2000,
};

export const SHIDEN_NETWORK = {
  text: 'Shiden',
  value: 'Shiden',
  networkURL: IS_DEV ? 'wss://rpc.shibuya.astar.network' : 'wss://shiden.api.onfinality.io/public-ws',
  networkPort: '',
  networkFullUrl: IS_DEV ? 'wss://rpc.shibuya.astar.network' : 'wss://shiden.api.onfinality.io/public-ws',
  unit: 'SDN',
  decimal: 18,
  paraId: 2007,
};

export const KHALA_NETWORK = {
  text: 'Khala',
  value: 'Khala',
  networkURL: 'wss://khala-api.phala.network/ws',
  networkPort: '',
  networkFullUrl: 'wss://khala-api.phala.network/ws',
  unit: 'PHA',
  decimal: 12,
  paraId: 2004,
};

export const BIFROST_NETWORK = {
  text: 'Bifrost',
  value: 'Bifrost',
  networkURL: 'wss://bifrost-parachain.api.onfinality.io/public-ws',
  networkPort: '',
  networkFullUrl: 'wss://bifrost-parachain.api.onfinality.io/public-ws',
  unit: 'BNC',
  decimal: 12,
  paraId: 2001,
};

export const KILT_NETWORK = {
  text: 'KILT Spiritnet',
  value: 'KILT Spiritnet',
  networkURL: IS_DEV ? 'wss://full-nodes.kilt.io:9944' : 'wss://spiritnet.kilt.io/',
  networkPort: '',
  networkFullUrl: IS_DEV ? 'wss://full-nodes.kilt.io:9944' : 'wss://spiritnet.kilt.io/',
  unit: 'KILT',
  decimal: 15,
  paraId: 2086,
};

export const CALAMARI_NETWORK = {
  text: 'Calamari Parachain',
  value: 'Calamari Parachain',
  networkURL: 'wss://smoothie.calamari.systems/',
  networkPort: '',
  networkFullUrl: 'wss://smoothie.calamari.systems/',
  unit: 'KMA',
  decimal: 12,
  paraId: 2084,
};

export const LOCALHOST_NETWORK = {
  text: 'Localhost',
  value: 'localhost',
  networkURL: 'ws://127.0.0.1',
  networkPort: '9944',
  networkFullUrl: 'ws://127.0.0.1:9944',
  unit: 'DOT',
  decimal: 10,
};

export const DOT_NETWORK_LIST = [
  CLOVER_NETWORK,
  POLKADOT_NETWORK,
  KUSAMA_NETWORK,
  EDGEWARE_NETWORK,
  SAKURA_NETWORK,
  CRUST_NETWORK,
  KARURA_NETWORK,
  SHIDEN_NETWORK,
  KHALA_NETWORK,
  BIFROST_NETWORK,
  KILT_NETWORK,
  CALAMARI_NETWORK
];

export const DEV_DOT_NETWORK_LIST = [
  CLOVER_NETWORK,
  POLKADOT_NETWORK,
  KUSAMA_NETWORK,
  LOCALHOST_NETWORK,
];

export const DEFAULT_NETWORK = POLKADOT_NETWORK;
// Custom Network Validation

export const CUSTOM = 'custom';
export const HTTPS = 'https';
export const LOCALHOST = 'localhost';
export const WS = 'ws';
export const WSS = 'wss';
export const DEFAULT_NON_SSL_PORT = '9944';
export const DEFAULT_SSL_PORT = '443';
