const KUSAMA_CHAIN = {
  name: 'Kusama',
  genesisHash: '0xb0a8d493285c2df73290dfb7e61f870f17b41801197a149ca93654499ea3dafe',
  icon: 'polkadot',
  specVersion: 1056,
  ss58Format: 2,
  tokenDecimals: 12,
  tokenSymbol: 'KSM',
  types: {
    Keys: 'SessionKeys5',
  },
};

const CLOVER_CHAIN = {
  name: 'Clover',
  genesisHash: '0xe923f1773f4197a862cb4fc4a562f93585b26131dadbc943d524a82bbeecd2eb',
  icon: 'polkadot',
  specVersion: 1,
  ss58Format: 42,
  tokenDecimals: 18,
  tokenSymbol: 'CLV',
  types: {},
};

const CLOVER_CHAIN_DEV = {
  name: 'Clover',
  genesisHash: '0xdd97e5ad3f0015f2dc45c9467b0fd36a2b7f4b9a7bc65e8111d49d6cf19c8927',
  icon: 'polkadot',
  specVersion: 14,
  ss58Format: 42,
  tokenDecimals: 18,
  tokenSymbol: 'CLV',
  types: {},
};

const ACALA_CHAIN = {
  name: 'Acala',
  genesisHash: '0xc963328a9ce0911b4e6531c60aafda597b05dc4e25bf10d71e9591a0313f5388',
  icon: 'polkadot',
  specVersion: 1056,
  ss58Format: 42,
  tokenDecimals: 18,
  tokenSymbol: 'ACA',
  types: {},
};

const POLKADOT_CHAIN = {
  name: 'Polkadot',
  genesisHash: '0x91b171bb158e2d3848fa23a9f1c25182fb8e20313b2c1eb49219da7a70ce90c3',
  icon: 'polkadot',
  specVersion: 31,
  ss58Format: 7,
  tokenSymbol: 'DOT',
  tokenDecimals: 10,
};

const SAKURA_CHAIN_DEV = {
  name: 'Sakura',
  genesisHash: '0xdd97e5ad3f0015f2dc45c9467b0fd36a2b7f4b9a7bc65e8111d49d6cf19c8927',
  icon: 'polkadot',
  specVersion: 14,
  ss58Format: 42,
  tokenDecimals: 18,
  tokenSymbol: 'SKU',
  types: {},
};

const SAKURA_CHAIN = {
  name: 'Sakura',
  genesisHash: '0x7b0f142a9299b0886595992f8cac58814c8956de6a31c77caca95db01370fc2c',
  icon: 'polkadot',
  specVersion: 1,
  ss58Format: 42,
  tokenDecimals: 18,
  tokenSymbol: 'SKU',
  types: {},
};

const CRUST_CHAIN = {
  name: 'Crust',
  genesisHash: '0x8b404e7ed8789d813982b9cb4c8b664c05b3fbf433309f603af014ec9ce56a8c',
  icon: 'polkadot',
  specVersion: 7,
  ss58Format: 66,
  tokenDecimals: 12,
  tokenSymbol: 'CRU',
  types: {},
};

const KARURA_CHAIN = {
  name: 'Karura',
  genesisHash: '0xbaf5aabe40646d11f0ee8abbdc64f4a4b7674925cba08e4a05ff9ebed6e2126b',
  icon: 'polkadot',
  ss58Format: 8,
  tokenDecimals: 12,
  tokenSymbol: 'KAR',
  types: {},
};

const SHIDEN_CHAIN = {
  name: 'Shiden',
  genesisHash: '0xf1cf9022c7ebb34b162d5b5e34e705a5a740b2d0ecc1009fb89023e62a488108',
  icon: 'polkadot',
  ss58Format: 5,
  tokenDecimals: 18,
  tokenSymbol: 'SDN',
  types: {},
};

const KHALA_CHAIN = {
  name: 'Khala',
  genesisHash: '0xd43540ba6d3eb4897c28a77d48cb5b729fea37603cbbfc7a86a73b72adb3be8d',
  icon: 'polkadot',
  ss58Format: 30,
  tokenDecimals: 12,
  tokenSymbol: 'PHA',
  types: {},
};

const BIFROST_CHAIN = {
  name: 'Bifrost',
  genesisHash: '0x9f28c6a68e0fc9646eff64935684f6eeeece527e37bbe1f213d22caa1d9d6bed',
  icon: 'polkadot',
  ss58Format: 6,
  tokenDecimals: 12,
  tokenSymbol: 'BNC',
  types: {},
};

const KILT_CHAIN = {
  name: 'KILT Spiritnet',
  genesisHash: '0x411f057b9107718c9624d6aa4a3f23c1653898297f3d4d529d9bb6511a39dd21',
  icon: 'polkadot',
  ss58Format: 38,
  tokenDecimals: 15,
  tokenSymbol: 'KILT',
  types: {},
};

const CALAMARI_CHAIN = {
  name: 'Calamari Parachain',
  genesisHash: '0x4ac80c99289841dd946ef92765bf659a307d39189b3ce374a92b5f0415ee17a1',
  icon: 'polkadot',
  ss58Format: 78,
  tokenDecimals: 12,
  tokenSymbol: 'KMA',
  types: {},
};

export const CHAIN = [
  KUSAMA_CHAIN,
  CLOVER_CHAIN,
  ACALA_CHAIN,
  POLKADOT_CHAIN,
  CLOVER_CHAIN_DEV,
  SAKURA_CHAIN,
  SAKURA_CHAIN_DEV,
  CRUST_CHAIN,
  KARURA_CHAIN,
  SHIDEN_CHAIN,
  KHALA_CHAIN,
  BIFROST_CHAIN,
  KILT_CHAIN,
  CALAMARI_CHAIN
];
export const findChain = genesisHash => {
  const selectedChain = CHAIN.find(x => x.genesisHash === genesisHash);
  if (selectedChain) {
    return selectedChain;
  }
  return undefined;
};

export const findChainByName = name => {
  const selectedChain = CHAIN.find(x => x.name === name);
  return selectedChain;
};
