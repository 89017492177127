import { ReactNode, useState, useCallback } from 'react';
import { t } from 'modules/i18n/intl';
import { Button, DialogActions, makeStyles, Theme } from '@material-ui/core';
import { variantType } from './DialogModal';
import { DataLoadingIcon } from 'modules/icons/loading/DataLoadingIcon';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    '& button': {
      width: '50%',
      margin: '0 auto',
    },
  },
  warning: {
    backgroundColor: '#FF6D5F',
    '&:hover': {
      backgroundColor: '#FF6D5F',
    },
  },
}));

interface IDialogActionsModalProps {
  onCancel: () => void;
  cancelText?: string;
  disabled?: boolean;
  handleText?: ReactNode;
  handleIcon?: ReactNode;
  handleVariant?: variantType;
  handleDialog?: () => void;
  isWarning?: boolean;
  onceClick?: boolean;
}

export const DialogActionsModal = ({
  onCancel,
  cancelText,
  disabled,
  handleText,
  handleIcon,
  handleVariant = 'contained',
  handleDialog,
  isWarning,
  onceClick,
}: IDialogActionsModalProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (onceClick) {
      setLoading(true);
    }
    handleDialog && handleDialog();
  }, [onceClick, handleDialog]);

  return (
    <DialogActions className={classes.root}>
      <Button variant="outlined" size="large" onClick={onCancel}>
        {cancelText ? cancelText : t('action.cancel')}
      </Button>
      <Button
        classes={{ root: isWarning ? classes.warning : '' }}
        variant={handleVariant}
        size="large"
        startIcon={handleIcon}
        disabled={!!disabled || loading}
        onClick={handleClick}
      >
        {loading ? (
          <DataLoadingIcon />
        ) : handleText ? (
          handleText
        ) : (
          t('action.continue')
        )}
      </Button>
    </DialogActions>
  );
};
