import { ComposedStore, ObservableStore } from '@metamask/obs-store';
import assert from 'assert';
import { PollingBlockTracker } from 'eth-block-tracker';
import {
  createBlockRefRewriteMiddleware,
  createBlockTrackerInspectorMiddleware,
  createFetchMiddleware,
  providerFromEngine,
  providerFromMiddleware,
} from 'eth-json-rpc-middleware';
import EthQuery from 'eth-query';
import EventEmitter from 'events';
import { JsonRpcEngine, mergeMiddleware } from 'json-rpc-engine';
import log from 'loglevel';
import {
  createEventEmitterProxy,
  createSwappableProxy,
} from 'swappable-obj-proxy';

import {
  DEV_INFURA_ID,
  MAINNET,
  MAINNET_CHAIN_ID,
  RPC,
  NETWORK_TYPE_TO_ID_MAP,
  INFURA_PROVIDER_TYPES,
} from '../../constants/netEnums';
import { createInfuraClient } from './createInfuraClient';
import { createJsonRpcClient } from './createJsonRpcClient';
import createMetamaskMiddleware from './createMetamaskMiddleware';

// defaults and constants
const defaultProviderConfig = {
  type: MAINNET,
  ticker: 'ETH',
  chainId: MAINNET_CHAIN_ID,
};
const defaultNetworkDetailsState = {
  EIPS: { 1559: undefined },
};

export default class NetworkController extends EventEmitter {
  /**
   * @constructor
   * @param {Object} opts
   */
  constructor(options = {}) {
    super();
    this.defaultMaxListeners = 100;
    const providerConfig = options.provider || defaultProviderConfig;
    log.info(providerConfig);

    this.providerStore = new ObservableStore(providerConfig);
    this.networkStore = new ObservableStore('loading');
    this.networkDetails = new ObservableStore(
      options.networkDetails || {
        ...defaultNetworkDetailsState,
      },
    );
    this.store = new ComposedStore({
      provider: this.providerStore,
      network: this.networkStore,
      networkDetails: this.networkDetails,
    });
    this.on('networkDidChange', this.lookupNetwork);
    // provider and block tracker
    this._provider = null;
    this._blockTracker = null;
    // provider and block tracker proxies - because the network changes
    this._providerProxy = null;
    this._blockTrackerProxy = null;
  }

  setInfuraProjectId(projectId = DEV_INFURA_ID) {
    if (!projectId || typeof projectId !== 'string') {
      throw new Error('Invalid Infura project ID');
    }

    this._infuraProjectId = projectId;
  }

  getNetworkIdentifier() {
    const { type, rpcUrl } = this.getProviderConfig();
    if (type === RPC) return rpcUrl;
    return type;
  }

  /**
   * Helper method for initializing provider
   */
  initializeProvider(providerParameters) {
    this._baseProviderParams = providerParameters;
    const { rpcUrl, chainId, ticker, nickname, host } =
      this.getProviderConfig();

    // todo : maybe change
    let finalType = RPC;
    // let finalType = type || host
    // if (!NETWORK_TYPE_TO_ID_MAP[finalType]) {
    //   finalType = RPC
    // }
    this._configureProvider({
      type: finalType,
      rpcUrl: rpcUrl || host,
      chainId,
      ticker,
      nickname,
    });
    this.lookupNetwork();
    return this._providerProxy;
  }

  /**
   * Returns proxies so the references will always be good
   */
  getProviderAndBlockTracker() {
    const provider = this._providerProxy;
    const blockTracker = this._blockTrackerProxy;
    return { provider, blockTracker };
  }

  /**
   * Method to return the latest block for the current network
   * @returns {Object} Block header
   */
  getLatestBlock() {
    return new Promise((resolve, reject) => {
      const { provider } = this.getProviderAndBlockTracker();
      const ethQuery = new EthQuery(provider);
      ethQuery.sendAsync(
        { method: 'eth_getBlockByNumber', params: ['latest', false] },
        (err, block) => {
          if (err) {
            return reject(err);
          }
          return resolve(block);
        },
      );
    });
  }

  /**
   * Method to check if the block header contains fields that indicate EIP 1559
   * support (baseFeePerGas).
   * @returns {Promise<boolean>} true if current network supports EIP 1559
   */
  async getEIP1559Compatibility() {
    const { EIPS } = this.networkDetails.getState();
    // log.info('checking eip 1559 compatibility', EIPS[1559])
    if (EIPS[1559] !== undefined) {
      return EIPS[1559];
    }
    const latestBlock = await this.getLatestBlock();
    const supportsEIP1559 =
      latestBlock && latestBlock.baseFeePerGas !== undefined;
    this.setNetworkEIPSupport(1559, supportsEIP1559);
    return supportsEIP1559;
  }

  /**
   * For checking network when restoring connectivity
   */
  verifyNetwork() {
    if (this.isNetworkLoading()) this.lookupNetwork();
  }

  /**
   * Get network state
   */
  getNetworkState() {
    return this.networkStore.getState();
  }

  /**
   * Set network state
   * @param {string} network
   * @param {Object} type
   */
  setNetworkState(network) {
    this.networkStore.putState(network);
    // if (network === 'loading') {
    //   this.networkStore.putState(network)
    //   return
    // }
    // if (!type) {
    //   return
    // }
    // let cachedNetwork
    // if (force) {
    //   cachedNetwork = network
    // } else if (networks.networkList[type] && networks.networkList[type].chainId) {
    //   cachedNetwork = networks.networkList[type].chainId
    // } else cachedNetwork = network
    // this.networkStore.putState(cachedNetwork)
  }

  setNetworkEIPSupport(EIPNumber, isSupported) {
    this.networkDetails.updateState({
      EIPS: {
        [EIPNumber]: isSupported,
      },
    });
  }

  clearNetworkDetails() {
    this.networkDetails.putState({ ...defaultNetworkDetailsState });
  }

  /**
   * Return networking loading status
   */
  isNetworkLoading() {
    return this.getNetworkState() === 'loading';
  }

  /**
   * Return network type
   */
  lookupNetwork() {
    // Prevent firing when provider is not defined.
    if (!this._provider) {
      log.warn(
        'NetworkController - lookupNetwork aborted due to missing provider',
      );
      return;
    }
    const chainId = this.getCurrentChainId();
    if (!chainId) {
      log.warn(
        'NetworkController - lookupNetwork aborted due to missing chainId',
      );
      this.setNetworkState('loading');
      // keep network details in sync with network state
      this.clearNetworkDetails();
      return;
    }
    const ethQuery = new EthQuery(this._provider);
    const initialNetwork = this.getNetworkState();

    ethQuery.sendAsync({ method: 'net_version' }, (error, network) => {
      const currentNetwork = this.getNetworkState();
      if (initialNetwork === currentNetwork) {
        if (error) {
          this.setNetworkState('loading');
          // keep network details in sync with network state
          this.clearNetworkDetails();
          return;
        }
        log.info(`web3.getNetwork returned ${network}`);
        this.setNetworkState(network);
        // look up EIP-1559 support
        this.getEIP1559Compatibility();
      }
    });
  }

  getCurrentChainId() {
    const { chainId: configChainId } = this.getProviderConfig();
    return /*NETWORK_TYPE_TO_ID_MAP[type]?.chainId ||*/ configChainId;
  }

  setRpcTarget(rpcUrl, chainId, ticker = 'ETH', nickname = '', rpcPrefs) {
    this.setProviderConfig({
      type: RPC,
      rpcUrl,
      chainId,
      ticker,
      nickname,
      rpcPrefs,
    });
  }

  /**
   * Set provider
   * @param {string} type
   */
  async setProviderType(type, rpcUrl = '', ticker = 'ETH', nickname = '') {
    assert.notStrictEqual(
      type,
      RPC,
      'NetworkController - cannot call "setProviderType" with type \'rpc\'. use "setRpcTarget"',
    );
    assert.ok(
      NETWORK_TYPE_TO_ID_MAP[type] !== undefined,
      `NetworkController - Unknown rpc type "${type}"`,
    );
    const { chainId, ...rest } = NETWORK_TYPE_TO_ID_MAP[type];
    const providerConfig = { type, rpcUrl, ticker, nickname, chainId, ...rest };
    this.setProviderConfig(providerConfig);
  }

  /**
   * Reset network connection
   */
  resetConnection() {
    this.setProviderConfig(this.getProviderConfig());
  }

  // /**
  //  * Setter for providerConfig
  //  */
  // set providerConfig(providerConfig) {
  //   this.providerStore.updateState(providerConfig)
  //   this._switchNetwork(providerConfig)
  // }

  setProviderConfig(config) {
    // this.previousProviderStore.updateState(this.getProviderConfig())
    this.providerStore.updateState(config);
    this._switchNetwork(config);
  }

  /**
   * Getter for providerConfig
   */
  getProviderConfig() {
    return this.providerStore.getState();
  }

  _switchNetwork(options) {
    this.setNetworkState('loading');
    this.clearNetworkDetails();
    this._configureProvider(options);
    this.emit('networkDidChange');
  }

  _configureProvider(options) {
    const { type, rpcUrl, chainId } = options;
    // infura type-based endpoints
    const isInfura = INFURA_PROVIDER_TYPES.includes(type);
    if (isInfura) {
      this._configureInfuraProvider(type, this._infuraProjectId);
      // url-based rpc endpoints
    } else if (type === 'rpc') {
      this._configureStandardProvider(rpcUrl, chainId);
    } else {
      throw new Error(
        `NetworkController - _configureProvider - unknown type "${type}"`,
      );
    }
  }

  _configureInfuraProvider({ type }) {
    log.info('NetworkController - configureInfuraProvider', type);
    const networkClient = createInfuraClient({ network: type });
    this._setNetworkClient(networkClient);
  }

  _configureLocalhostProvider() {
    log.info('NetworkController - configureLocalhostProvider');
    const networkClient = createLocalhostClient();
    this._setNetworkClient(networkClient);
  }

  _configureStandardProvider(rpcUrl, chainId) {
    log.info('NetworkController - configureStandardProvider', rpcUrl);
    const networkClient = createJsonRpcClient({ rpcUrl, chainId });
    // hack to add a 'rpc' network with chainId
    this._setNetworkClient(networkClient);
  }

  _setNetworkClient({ networkMiddleware, blockTracker }) {
    const metamaskMiddleware = createMetamaskMiddleware(
      this._baseProviderParams,
    );
    const engine = new JsonRpcEngine();
    engine.push(metamaskMiddleware);
    engine.push(networkMiddleware);
    const provider = providerFromEngine(engine);
    this._setProviderAndBlockTracker({ provider, blockTracker });
  }

  _setProviderAndBlockTracker({ provider, blockTracker }) {
    // update or intialize proxies
    if (this._providerProxy) {
      this._providerProxy.setTarget(provider);
    } else {
      this._providerProxy = createSwappableProxy(provider);
    }
    if (this._blockTrackerProxy) {
      this._blockTrackerProxy.setTarget(blockTracker);
    } else {
      this._blockTrackerProxy = createEventEmitterProxy(blockTracker, {
        eventFilter: 'skipInternal',
      });
    }
    // set new provider and blockTracker
    this._provider = provider;
    provider.setMaxListeners(100);
    this._blockTracker = blockTracker;
  }
}

function createLocalhostClient() {
  const fetchMiddleware = createFetchMiddleware({
    rpcUrl: 'https://localhost:8545/',
  });
  const blockProvider = providerFromMiddleware(fetchMiddleware);
  const blockTracker = new PollingBlockTracker({
    provider: blockProvider,
    pollingInterval: 1000,
  });

  const networkMiddleware = mergeMiddleware([
    createBlockRefRewriteMiddleware({ blockTracker }),
    createBlockTrackerInspectorMiddleware({ blockTracker }),
    fetchMiddleware,
  ]);
  return { networkMiddleware, blockTracker };
}
