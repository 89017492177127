import Clover from '../images/networks-logo/clover.svg';
import Sakura from '../images/networks-logo/sakura.svg';
import Ethmainnet from '../images/networks-logo/ethmainnet.svg';
import Bsc from '../images/networks-logo/bsc.svg';
import Ftm from '../images/networks-logo/fantom.svg';
import Ht from '../images/networks-logo/heco.svg';
import xDai from '../images/networks-logo/xdai.svg';
import Matic from '../images/networks-logo/matic.svg';
import Okt from '../images/networks-logo/okt.svg';
import Avax from '../images/networks-logo/avalanche.svg';
import DOT from '../images/networks-logo/polkadot.svg';
import KSM from '../images/networks-logo/kusama.svg';
import EDG from '../images/networks-logo/edgeware.svg';
import PPAY from '../images/networks-logo/edgeware.svg';
import KLP from '../images/networks-logo/kupulu.svg';
import PCX from '../images/networks-logo/chainx.svg';
import CRU from '../images/networks-logo/crust.svg';
import KAR from '../images/networks-logo/karura.svg';
import SDN from '../images/networks-logo/shiden.svg';
import PHA from '../images/networks-logo/khala.svg';
import BNC from '../images/networks-logo/bifrost.svg';
import KILT from '../images/networks-logo/kilt.svg';
import KMA from '../images/networks-logo/calamari.svg';
import MOVR from '../images/networks-logo/moonriver.svg';
import TRX from '../images/networks-logo/trx.svg';
import SOL from '../images/networks-logo/solana.svg';
import Unconnect from '../images/networks-logo/unconnect.svg';

import { IS_DEV } from './api';

const TEST_EVM_NETWORKS = [
  {
    label: 'Ethereum Ropsten',
    logo: Ethmainnet,
    symbol: 'ETH',
    addressExplorer: 'https://ropsten.etherscan.io/address/',
    tokenExplorer: 'https://ropsten.etherscan.io/token/',
    transferExplorer: 'https://ropsten.etherscan.io/tx/',
  },
  {
    label: 'BSC Testnet',
    logo: Bsc,
    symbol: 'BNB',
    addressExplorer: 'https://testnet.bscscan.com/address/',
    tokenExplorer: 'https://testnet.bscscan.com/token/',
    transferExplorer: 'https://testnet.bscscan.com/tx/',
  },
  {
    label: 'Fantom Testnet',
    logo: Ftm,
    symbol: 'FTM',
    addressExplorer: 'https://testnet.ftmscan.com/address/',
    tokenExplorer: 'https://testnet.ftmscan.com/token/',
    transferExplorer: 'https://testnet.ftmscan.com/tx/',
  },
  {
    label: 'Heco Testnet',
    logo: Ht,
    symbol: 'HT',
    addressExplorer: 'https://testnet.hecoinfo.com/address/',
    tokenExplorer: 'https://testnet.hecoinfo.com/token/',
    transferExplorer: 'https://testnet.hecoinfo.com/tx/',
  },
  {
    label: 'xDai',
    logo: xDai,
    symbol: 'XDAI',
    addressExplorer: 'https://blockscout.com/xdai/mainnet/address/',
    tokenExplorer: 'https://blockscout.com/xdai/mainnet/address/',
    transferExplorer: 'https://blockscout.com/xdai/mainnet/tx/',
  },
  {
    label: 'Matic',
    logo: Matic,
    symbol: 'MATIC',
    addressExplorer: 'https://explorer-mumbai.maticvigil.com/search?q=',
    tokenExplorer: 'https://explorer-mumbai.maticvigil.com/search?q=',
    transferExplorer: 'https://explorer-mumbai.maticvigil.com/search?q=',
  },
  {
    label: 'OKEX',
    logo: Okt,
    symbol: 'OKT',
    addressExplorer: 'https://www.oklink.com/okexchain-test/address/',
    tokenExplorer: 'https://www.oklink.com/okexchain-test/address/',
    transferExplorer: 'https://www.oklink.com/okexchain-test/tx/',
  },
  {
    label: 'Moonriver_TESTNET',
    logo: MOVR,
    symbol: 'MOVR',
    tokenExplorer: 'https://moonbase-blockscout.testnet.moonbeam.network/address/',
    transferExplorer: 'https://moonbase-blockscout.testnet.moonbeam.network/tx/',
    addressExplorer: 'https://moonbase-blockscout.testnet.moonbeam.network/address/',
  },
];

export const GET_EVM_LOGO = (symbol, evmNetworks) => {
  const chain = evmNetworks.find(c => c.symbol.toLowerCase() === symbol.toLowerCase());
  if (chain && chain.logo) {
    return chain.logo;
  }
  return Unconnect;
};

export const GET_POL_LOGO = (symbol) => {
  const chain = POLKADOT_NETWORKS.find(c => c.symbol.toLowerCase() === symbol.toLowerCase());
  if (chain && chain.logo) {
    return chain.logo;
  }
  return Unconnect;
};

export const EVM_NETWORKS = IS_DEV ? TEST_EVM_NETWORKS : [
  {
    label: 'Ethereum Mainnet',
    logo: Ethmainnet,
    symbol: 'ETH',
    addressExplorer: 'https://etherscan.io/address/',
    tokenExplorer: 'https://etherscan.io/token/',
    transferExplorer: 'https://etherscan.io/tx/',
  },
  {
    label: 'BNB Smart Chain',
    logo: Bsc,
    symbol: 'BNB',
    addressExplorer: 'https://bscscan.com/address/',
    tokenExplorer: 'https://bscscan.com/token/',
    transferExplorer: 'https://bscscan.com/tx/',
  },
  {
    label: 'Fantom Mainnet',
    logo: Ftm,
    symbol: 'FTM',
    addressExplorer: 'https://ftmscan.com/address/',
    tokenExplorer: 'https://ftmscan.com/token/',
    transferExplorer: 'https://ftmscan.com/tx/',
  },
  {
    label: 'Heco',
    logo: Ht,
    symbol: 'HT',
    addressExplorer: 'https://hecoinfo.com/address/',
    tokenExplorer: 'https://hecoinfo.com/token/',
    transferExplorer: 'https://hecoinfo.com/tx/',
  },
  {
    label: 'xDai',
    logo: xDai,
    symbol: 'XDAI',
    addressExplorer: 'https://blockscout.com/xdai/mainnet/address/',
    tokenExplorer: 'https://blockscout.com/xdai/mainnet/address/',
    transferExplorer: 'https://blockscout.com/xdai/mainnet/tx/',
  },
  {
    label: 'Matic Mainnet',
    logo: Matic,
    symbol: 'MATIC',
    addressExplorer: 'https://explorer-mainnet.maticvigil.com/search?q=',
    tokenExplorer: 'https://explorer-mainnet.maticvigil.com/search?q=',
    transferExplorer: 'https://explorer-mainnet.maticvigil.com/search?q=',
  },
  {
    label: 'OKEX Mainnet',
    logo: Okt,
    symbol: 'OKT',
    addressExplorer: 'https://www.oklink.com/okexchain/address/',
    tokenExplorer: 'https://www.oklink.com/okexchain/address/',
    transferExplorer: 'https://www.oklink.com/okexchain/tx/',
  },
  {
    label: 'Moonriver',
    logo: MOVR,
    symbol: 'MOVR',
    tokenExplorer: 'https://blockscout.moonriver.moonbeam.network/address/',
    transferExplorer: 'https://blockscout.moonriver.moonbeam.network/tx/',
    addressExplorer: 'https://blockscout.moonriver.moonbeam.network/address/',
  },
  {
    label: 'Avalanche',
    disabled: true,
    logo: Avax,
    symbol: 'AVAX',
  },
];

export const POLKADOT_NETWORKS = [
  {
    label: 'Clover Mainnet',
    logo: Clover,
    symbol: 'CLV',
    addressExplorer: IS_DEV ? 'https://clover-testnet.subscan.io/account/' : 'https://clover.subscan.io/account/',
    transferExplorer: IS_DEV ? 'https://clover-testnet.subscan.io/extrinsic/' : 'https://clover.subscan.io/extrinsic/',
  },
  {
    label: 'Sakura',
    logo: Sakura,
    symbol: 'SKU',
    addressExplorer: IS_DEV ? 'https://sakura.subscan.io/account/' : 'https://sakura.subscan.io/account/',
    transferExplorer: IS_DEV ? 'https://sakura.subscan.io/extrinsic/' : 'https://sakura.subscan.io/extrinsic/',
  },
  {
    label: 'Polkadot Mainnet',
    logo: DOT,
    symbol: 'DOT',
    addressExplorer: 'https://polkadot.subscan.io/account/',
    transferExplorer: 'https://polkadot.subscan.io/extrinsic/',
  },
  {
    label: 'Kusama',
    logo: KSM,
    symbol: 'KSM',
    addressExplorer: 'https://kusama.subscan.io/account/',
    transferExplorer: 'https://kusama.subscan.io/extrinsic/',
  },
  {
    label: 'Edgeware',
    logo: EDG,
    symbol: 'EDG',
    addressExplorer: 'https://edgeware.subscan.io/account/',
    transferExplorer: 'https://edgeware.subscan.io/extrinsic/',
  },
  {
    label: 'Crust',
    logo: CRU,
    symbol: 'CRU',
    transferExplorer: 'https://crust.subscan.io/extrinsic/',
    addressExplorer: 'https://crust.subscan.io/account/',
  },
  {
    label: 'Karura',
    logo: KAR,
    symbol: 'KAR',
    transferExplorer: 'https://karura.subscan.io/extrinsic/',
    addressExplorer: 'https://karura.subscan.io/account/',
  },
  {
    label: 'Shiden',
    logo: SDN,
    symbol: 'SDN',
    transferExplorer: IS_DEV ? 'https://shibuya.subscan.io/extrinsic/' : 'https://shiden.subscan.io/extrinsic/',
    addressExplorer: IS_DEV ? 'https://shibuya.subscan.io/account/' : 'https://shiden.subscan.io/account/',
  },
  {
    label: 'Khala',
    logo: PHA,
    symbol: 'PHA',
    transferExplorer: 'https://Khala.subscan.io/extrinsic/',
    addressExplorer: 'https://Khala.subscan.io/account/',
  },
  {
    label: 'Bifrost',
    logo: BNC,
    symbol: 'BNC',
    transferExplorer: 'https://Bifrost.subscan.io/extrinsic/',
    addressExplorer: 'https://Bifrost.subscan.io/account/',
  },
  {
    label: 'KILT Spiritnet',
    logo: KILT,
    symbol: 'KILT',
    transferExplorer: IS_DEV ? 'https://kilt-testnet.subscan.io/extrinsic/' : 'https://spiritnet.subscan.io/extrinsic/',
    addressExplorer: IS_DEV ? 'https://kilt-testnet.subscan.io/account/' : 'https://spiritnet.subscan.io/account/',
  },
  {
    label: 'Calamari Parachain',
    logo: KMA,
    symbol: 'KMA',
    transferExplorer: 'https://calamari.subscan.io/extrinsic/',
    addressExplorer: 'https://calamari.subscan.io/account/',
  },
  {
    label: 'Plasm',
    logo: PPAY,
    disabled: true,
    symbol: 'PPAY',
  },
  {
    label: 'Kulupu',
    logo: KLP,
    disabled: true,
    symbol: 'KLP',
  },
  {
    label: 'ChainX',
    logo: PCX,
    disabled: true,
    symbol: 'PCX',
  },
];

export const SOLANA_NETWORK = {
  label: 'Solana',
  logo: SOL,
  symbol: 'SOL',
  addressExplorer: 'https://solscan.io/account/',
  transferExplorer: 'https://solscan.io/tx/',
  decimals: 9,
};

export const TRON_NETWORK = {
  label: 'Tron',
  logo: TRX,
  symbol: 'TRX',
  addressExplorer: `${IS_DEV ? 'https://shasta.tronscan.org/#/address/' : 'https://tronscan.io/#/address/'}`,
  transferExplorer: `${IS_DEV ? 'https://shasta.tronscan.org/#/transaction/' : 'https://tronscan.io/#/transaction/'}`,
  decimals: 6,
};
