import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <g clipPath="url(#clip0_1672_1884)">
        <path
          d="M7.83343 10.7813L7.83343 2.66656L9.16656 2.66656L9.16703 10.7808L12.7426 7.20524L13.6854 8.14805L8.49999 13.3335L3.31454 8.14805L4.25735 7.20524L7.83343 10.7813V10.7813Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1672_1884">
          <rect
            width="16"
            height="16"
            fill="currentColor"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
