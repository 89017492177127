import React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { SOCIAL_ICONS, SOCIALS } from '../../constants';
import { TwitterIcon } from 'modules/icons/socialIcon/TwitterIcon';
import { TelegramIcon } from 'modules/icons/socialIcon/TelegramIcon';
import { DiscordIcon } from 'modules/icons/socialIcon/DiscordIcon';
import { MediumIcon } from 'modules/icons/socialIcon/MediumIcon';

const useSocialStyles = makeStyles<Theme>(theme => ({
  social: {
    margin: theme.spacing(0, -4.5),
  },
  item: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const iconsMap = {
  [`${SOCIAL_ICONS.twitter}`]: <TwitterIcon />,
  [`${SOCIAL_ICONS.telegram}`]: <TelegramIcon />,
  [`${SOCIAL_ICONS.discord}`]: <DiscordIcon />,
  [`${SOCIAL_ICONS.medium}`]: <MediumIcon />,
};

export const Social = () => {
  const classes = useSocialStyles();

  return (
    <div className={classes.social}>
      {SOCIALS.map(item => (
        <IconButton key={item.name}>
          <a
            className={classes.item}
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            {iconsMap[item.name]}
          </a>
        </IconButton>
      ))}
    </div>
  );
};
