import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <g clipPath="url(#clip0_2056_15173)">
        <path
          d="M10.5 23.8667C4.83333 22.8667 0.5 17.9333 0.5 12C0.5 5.4 5.9 0 12.5 0C19.1 0 24.5 5.4 24.5 12C24.5 17.9333 20.1667 22.8667 14.5 23.8667L13.8333 23.3333H11.1667L10.5 23.8667Z"
          fill="url(#paint0_linear_2056_15173)"
        />
        <path
          d="M17.1667 15.3333L17.7 12H14.5V9.66663C14.5 8.73329 14.8333 7.99996 16.3 7.99996H17.8333V4.93329C16.9667 4.79996 16.0333 4.66663 15.1667 4.66663C12.4333 4.66663 10.5 6.33329 10.5 9.33329V12H7.5V15.3333H10.5V23.8C11.1667 23.9333 11.8333 24 12.5 24C13.1667 24 13.8333 23.9333 14.5 23.8V15.3333H17.1667Z"
          fill="var(--fill-color, white)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2056_15173"
          x1="12.5"
          y1="23.1693"
          x2="12.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--start-color, #0062E0)" />
          <stop offset="1" stopColor="var(--end-color, #19AFFF)" />
        </linearGradient>
        <clipPath id="clip0_2056_15173">
          <rect
            width="24"
            height="24"
            fill="var(--fill-color, white)"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
