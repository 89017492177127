import { makeStyles, Theme } from '@material-ui/core';

export const useCollectionsStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(12),
    marginBottom: theme.spacing(12),
  },
  noCollection: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    height: 320,
    borderRadius: 8,
    backgroundColor: theme.palette.grey.A100,
  },
  collections: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -4, 0, 0),
    height: `calc(100vh - ${theme.spacing(39)}px)`,
    overflow: 'auto',
  },
  collection: {
    width: 240,
    height: 310,
    padding: theme.spacing(4, 3),
    margin: theme.spacing(2, 4, 2, 0),
    backgroundColor: theme.palette.grey[400],
    borderRadius: 8,
    position: 'relative',
    cursor: 'pointer',
  },
  image: { width: 215, height: 215, borderRadius: 12 },
  info: {
    margin: theme.spacing(4, 0, 2, 0),
    padding: theme.spacing(1, 8, 0, 0),
    position: 'relative',
    width: '100%',
    height: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chain: { width: 24, height: 24, position: 'absolute', top: 0, right: 0 },
}));
