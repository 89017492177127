import React, { useCallback, useEffect, useState } from 'react';
import { t, tHTML } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { DialogActionsModal } from 'components/DialogModal/DialogActionsModal';
import { InputField } from 'components/InputField/InputField';
import _ from 'lodash';
import {
  deviceInfoUpload,
  startMigration,
  useSaveUser,
} from '../../services/loginService';
import { combineKey, getNewShare, getUuid } from '../../utils/cryptoUtils';
import eventEmitter, {
  EVENT_MIGRATE_REQUEST_ACCEPT,
  EVENT_MIGRATE_REQUEST_CANCEL,
  EVENT_MIGRATE_REQUEST_REJECT,
} from '../../services/eventService';
import { UserSession } from '../../state/user/types';
import { getShare } from '../../services/backendService';
import { getEthAddress } from '../../services/WalletService';
import { setUser } from '../../services/PopupService';
import { DataLoadingIcon } from 'modules/icons/loading/DataLoadingIcon';
import { saveUserInfo } from 'services/LocalstorageService';

interface MigrationRequestDialogProps {
  isOpen: boolean;
  onClose: (state: string) => void;
  devices: Array<any>;
  userId: number;
  userName: string;
  email: string;
  loginType: string;
  token: string;
  publicKey: string;
  onlyRecover?: boolean;
  fromPopup?: boolean;
}

const tabText = ['My old device is available', 'I can’t reach my old device'];

const MigrationRequestDialog = ({
  isOpen,
  onClose,
  userId,
  token,
  publicKey,
  userName,
  email,
  loginType,
  onlyRecover = false,
  fromPopup = false,
}: MigrationRequestDialogProps) => {
  const [tab, setTab] = useState(0);
  const [errorInfo, setErrorInfo] = useState('');
  const [avLoading, setAvLoading] = useState(false);
  const [value, setValue] = useState('');
  const saveUser = useSaveUser();

  useEffect(() => {
    if (onlyRecover) {
      setTab(1);
    }
  }, [onlyRecover]);

  const handleTabChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const onAccept = useCallback(
    (share: string) => {
      if (!isOpen) {
        return;
      }

      const userSession: UserSession = {
        userId,
        token,
        userName,
        email,
        loginType,
        publicKey,
        uuid: getUuid(),
        sharedA: share,
      };

      saveUser(userSession);
      deviceInfoUpload(userSession);
      onClose('ok');
    },
    [
      isOpen,
      saveUser,
      onClose,
      publicKey,
      token,
      userId,
      userName,
      email,
      loginType,
    ],
  );

  const onReject = useCallback(() => {
    console.log('Migration request is rejected.');
    onClose('reject');
  }, [onClose]);

  useEffect(() => {
    eventEmitter.once(EVENT_MIGRATE_REQUEST_ACCEPT, onAccept);
    eventEmitter.once(EVENT_MIGRATE_REQUEST_REJECT, onReject);

    return () => {
      eventEmitter.off(EVENT_MIGRATE_REQUEST_ACCEPT, onAccept);
      eventEmitter.off(EVENT_MIGRATE_REQUEST_REJECT, onReject);
    };
  }, [onAccept, onReject]);

  const onRequest = () => {
    if (avLoading) {
      return;
    }
    startMigration(userId, token);
    setAvLoading(true);
  };

  const onCancel = () => {
    eventEmitter.emit(EVENT_MIGRATE_REQUEST_CANCEL);
    onClose('cancel');
  };

  const onInputChange = (event: any) => {
    setValue(event.target.value);
  };

  const onContinue = () => {
    if (_.isEmpty(value.trim())) {
      return;
    }

    getShare(userId, token)
      .then((sharedB: string) => {
        const newShare = getNewShare(value.trim(), sharedB);

        const seedWords = combineKey(sharedB, value.trim());

        try {
          getEthAddress(seedWords);
        } catch (e) {
          throw new Error('Invalid share C');
        }

        const userSession: UserSession = {
          userId,
          token,
          userName,
          email,
          loginType,
          publicKey,
          uuid: getUuid(),
          sharedA: newShare,
        };

        if (fromPopup) {
          userSession.uuid = 'sdk';
          setUser(userSession);
          saveUserInfo(userSession)
          onClose(seedWords);
        } else {
          saveUser(userSession);
          deviceInfoUpload(userSession);
          onClose('okRecover');
        }
      })
      .catch(e => {
        console.log(e);
        setErrorInfo(`Failed. Error: ${e.message}`);
      });
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      maxWidth="lg"
      noSpace={true}
      hideClose={true}
    >
      {!onlyRecover && (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="fullWidth"
          style={{ marginTop: -20 }}
        >
          {tabText.map(item => (
            <Tab key={item} label={item} />
          ))}
        </Tabs>
      )}
      {tab === 0 && (
        <div style={{ padding: '20px 30px 36px 30px' }}>
          <div style={{ marginTop: 12, minHeight: 230 }}>
            <Typography
              variant="h2"
              style={{ marginBottom: 12, lineHeight: '42px' }}
            >
              {t('approve-new-device-dialog.title')}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: 16, lineHeight: '20px' }}
            >
              {t('approve-new-device-dialog.content')}
            </Typography>
            <Typography>{tHTML('approve-new-device-dialog.note')}</Typography>
          </div>
          <DialogActionsModal
            onCancel={onCancel}
            handleText={
              avLoading ? (
                <DataLoadingIcon />
              ) : (
                t('approve-new-device-dialog.request-verification')
              )
            }
            handleDialog={onRequest}
          />
        </div>
      )}
      {tab === 1 && (
        <div style={{ padding: '20px 30px 36px 30px' }}>
          <div style={{ marginTop: 12, minHeight: 230 }}>
            <Typography
              variant="h2"
              style={{ marginBottom: 12, lineHeight: '42px' }}
            >
              {t('approve-new-device-dialog.recover-account-title')}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: 16, lineHeight: '20px' }}
            >
              {t('approve-new-device-dialog.recover-account-content')}
            </Typography>
            <InputField
              errorInfo={errorInfo}
              placeHolder={t(
                'approve-new-device-dialog.recover-account-placeholder',
              )}
              value={value}
              onChange={onInputChange}
            />
          </div>
          <DialogActionsModal onCancel={onCancel} handleDialog={onContinue} />
        </div>
      )}
    </DialogModal>
  );
};

export default MigrationRequestDialog;
