import _ from 'lodash';
import { getTrxAddressFromMnemonic } from './TrxService';
import { ChainSS58, getSubstrateAddress } from './WalletService';
import { getUserSessionFromSessionStorage } from './LocalstorageService';
import { combineSignKey } from './loginService';

var CryptoJS = require('crypto-js');

export const migrationAccount = async (account: any) => {
  const copyAccount = _.cloneDeep(account)

  if (copyAccount && copyAccount.currentAccount) {
    await addUnExistAddress(copyAccount.currentAccount);
    hideAsset(copyAccount.currentAccount);
  }

  if (copyAccount.accounts) {
    for (let a of copyAccount.accounts) {
      await addUnExistAddress(a);
      hideAsset(a);
    }
  }
  return copyAccount;
};

const addUnExistAddress = async (account: any) => {
  const userSession = getUserSessionFromSessionStorage();
  let bytes = CryptoJS.AES.decrypt(account.seedWords, userSession.publicKey);
  let seedWords = bytes.toString(CryptoJS.enc.Utf8);
  if (account.isLoginAccount) {
    seedWords = await combineSignKey(userSession.sharedA, userSession.userId, userSession.token);
    if (seedWords === null) {
      throw new Error('Invalid seed words.');
    }
  }

  if (!account.trxAddress) {
    const trxAddress = await getTrxAddressFromMnemonic(seedWords);
    account.trxAddress = trxAddress;
  }
  if (!account.karAddress) {
    const karAddress = getSubstrateAddress(seedWords, ChainSS58.KAR, account.keypairType);
    account.karAddress = karAddress;
  }
  if (!account.sdnAddress) {
    const sdnAddress = getSubstrateAddress(seedWords, ChainSS58.SDN, account.keypairType);
    account.sdnAddress = sdnAddress;
  }
  if (!account.phaAddress) {
    const phaAddress = getSubstrateAddress(seedWords, ChainSS58.PHA, account.keypairType);
    account.phaAddress = phaAddress;
  }
  if (!account.bncAddress) {
    const bncAddress = getSubstrateAddress(seedWords, ChainSS58.BNC, account.keypairType);
    account.bncAddress = bncAddress;
  }
  if (!account.kiltAddress) {
    const kiltAddress = getSubstrateAddress(seedWords, ChainSS58.KILT, account.keypairType);
    account.kiltAddress = kiltAddress;
  }
  if (!account.kmaAddress) {
    const kmaAddress = getSubstrateAddress(seedWords, ChainSS58.KMA, account.keypairType);
    account.kmaAddress = kmaAddress;
  }
};

const hideAsset = (account: any) => {
  if (!_.includes(account.hiddenAssets, 'CRU')) {
    account.hiddenAssets.push('CRU');
  }
  if (!_.includes(account.hiddenAssets, 'TRX')) {
    account.hiddenAssets.push('TRX');
  }
  if (!_.includes(account.hiddenAssets, 'KAR')) {
    account.hiddenAssets.push('KAR');
  }
  if (!_.includes(account.hiddenAssets, 'SDN')) {
    account.hiddenAssets.push('SDN');
  }
  if (!_.includes(account.hiddenAssets, 'MOVR')) {
    account.hiddenAssets.push('MOVR');
  }
  if (!_.includes(account.hiddenAssets, 'PHA')) {
    account.hiddenAssets.push('PHA');
  }
  if (!_.includes(account.hiddenAssets, 'BNC')) {
    account.hiddenAssets.push('BNC');
  }
  if (!_.includes(account.hiddenAssets, 'KILT')) {
    account.hiddenAssets.push('KILT');
  }
  if (!_.includes(account.hiddenAssets, 'KMA')) {
    account.hiddenAssets.push('KMA');
  }
};
