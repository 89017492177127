import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RemoveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <path
        d="M16.0294 0.470588C15.595 0.0361991 14.871 0.0361991 14.2919 0.470588L8.5 6.26244L2.56335 0.325792C2.12896 -0.108597 1.40498 -0.108597 0.825792 0.325792C0.391403 0.760181 0.391403 1.62896 0.825792 2.06335L6.76244 8L0.825792 13.9367C0.391403 14.371 0.391403 15.2398 0.825792 15.6742C1.26018 16.1086 2.12896 16.1086 2.56335 15.6742L8.5 9.73756L14.4367 15.6742C14.871 16.1086 15.7398 16.1086 16.1742 15.6742C16.6086 15.2398 16.6086 14.5158 16.1742 13.9367L10.2376 8L16.1742 2.06335C16.6086 1.62896 16.6086 0.904977 16.0294 0.470588Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
