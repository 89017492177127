import React, { useEffect, useState } from 'react';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { useCurrentAccount } from '../../state/account/hooks';
import { useAccountContractAssetsUpdate } from '../../services/AssetServcie';
import {
  EMPTY_ADDR,
  isValidAddress,
  checkExistingAddresses,
  tokenInfoGetter,
} from '../../services/tokenService';
import { InputField } from 'components/InputField/InputField';
import _ from 'lodash';
import { EVM_NETWORKS } from '../../constants/networks';
import ConfirmAddTokenDialog from './ConfirmAddTokenDialog';
import BigNumber from 'bignumber.js';
import { SelectField } from 'components/SelectField/SelectField';

interface AddTokenCustomDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddCustomTokenDialog = ({
  isOpen,
  onClose,
}: AddTokenCustomDialogProps) => {
  const currentAccount = useCurrentAccount();
  const updateAccountContractAssets = useAccountContractAssetsUpdate();
  const [contractAssets, setContractAssets] = useState<any>([]);
  const [selectNetwork, setSelectNetwork] = useState<any>(EVM_NETWORKS[0]);
  const [customAddress, setCustomAddress] = useState('');
  const [customAddressError, setCustomAddressError] = useState('');
  const [customSymbol, setCustomSymbol] = useState('');
  const [customSymbolError, setCustomSymbolError] = useState('');
  const [customDecimals, setCustomDecimals] = useState(0);
  const [customDecimalsError, setCustomDecimalsError] = useState('');
  const [customName, setCustomName] = useState('');
  const [customBalance, setCustomBalance] = useState(0);

  const [disabled, setDisabled] = useState(true);
  const [showConfirmAddTokenDialog, setShowConfirmAddTokenDialog] =
    useState(false);

  useEffect(() => {
    setContractAssets(
      currentAccount && currentAccount.contractAssets
        ? currentAccount.contractAssets
        : [],
    );
  }, [currentAccount]);

  const attemptToAutoFillTokenParams = async (
    address: string,
    chain: string,
  ) => {
    let getTokenInfo = tokenInfoGetter(chain);
    const {
      symbol = '',
      decimals = '0',
      name = '',
      balance = 0,
    } = await getTokenInfo(address, currentAccount.evmAddress);
    handleCustomSymbolChange(symbol || '');
    handleCustomDecimalsChange(decimals);
    setCustomName(name);
    setCustomBalance(balance);
  };

  const handleCustomSymbolChange = (value: string) => {
    const customSymbol = value.trim();
    const symbolLength = customSymbol.length;
    let customSymbolError = '';

    if (symbolLength <= 0 || symbolLength >= 12) {
      customSymbolError = t('add-custom-token-dialog.token-not-found');
    }
    setCustomSymbol(customSymbol);
    setCustomSymbolError(customSymbolError);
  };

  const handleCustomDecimalsChange = (value: string) => {
    const customDecimals = value.trim();
    const validDecimals =
      customDecimals !== null &&
      customDecimals !== '' &&
      _.toNumber(customDecimals) >= 0 &&
      _.toNumber(customDecimals) <= 36;
    let customDecimalsError = '';

    if (!validDecimals) {
      customDecimalsError = t('add-custom-token-dialog.decimal-error');
    }
    setCustomDecimals(_.toNumber(customDecimals));
    setCustomDecimalsError(customDecimalsError);
  };

  const handleCustomAddressChange = (value: string, chain: string) => {
    setCustomAddress(value);
    const customAddress = value.trim();
    setDisabled(true);
    setCustomAddressError('');
    setCustomSymbolError('');
    setCustomDecimalsError('');
    setCustomSymbol('');
    setCustomDecimals(0);

    switch (true) {
      case !isValidAddress(customAddress):
        setCustomAddressError('');
        setCustomSymbolError('');
        setCustomDecimalsError('');
        setCustomSymbol('');
        setCustomDecimals(0);
        break;
      case checkExistingAddresses(
        customAddress,
        currentAccount.contractAssets,
        chain,
      ):
        setCustomAddressError(t('add-custom-token-dialog.token-already-added'));
        break;
      default:
        if (customAddress !== EMPTY_ADDR) {
          attemptToAutoFillTokenParams(customAddress, chain);
          setDisabled(false);
        }
    }
  };

  const isValid = () => {
    if (
      customAddress === '' ||
      customSymbol === '' ||
      customDecimals === 0 ||
      customAddressError !== '' ||
      customSymbolError !== '' ||
      customDecimalsError !== ''
    ) {
      return false;
    }
    return true;
  };

  const handleAddToken = () => async () => {
    const token = {
      tokenAddress: customAddress,
      chain: selectNetwork.symbol,
      decimals: customDecimals,
      symbol: customSymbol,
      name: customName,
      balance: new BigNumber(customBalance).toFixed(),
      amount: new BigNumber(
        customBalance / Math.pow(10, customDecimals),
      ).toFixed(),
      isAdd: true,
    };
    const assets = _.concat(contractAssets, [_.assignIn(token)]);
    try {
      await updateAccountContractAssets(currentAccount.address, assets);
      setShowConfirmAddTokenDialog(false);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = () => {
    setShowConfirmAddTokenDialog(true);
  };

  const closeConfirm = () => {
    setShowConfirmAddTokenDialog(false);
  };

  return (
    <>
      <DialogModal
        title={t('add-custom-token-dialog.title')}
        open={isOpen}
        onReturn={onClose}
        onClose={onClose}
        disabled={!isValid() || disabled}
        handleDialog={handleConfirm}
      >
        <SelectField
          label={t('add-custom-token-dialog.network')}
          value={selectNetwork}
          options={EVM_NETWORKS.map(network => ({
            label: network.label,
            icon: <img src={network.logo} alt={network.label} />,
            value: network,
          }))}
          handleChange={event => {
            const network = event.target.value;
            setSelectNetwork(network);
            handleCustomAddressChange(customAddress, network.symbol);
          }}
        />
        <InputField
          label={t('add-custom-token-dialog.token-contract-address')}
          errorInfo={customAddressError}
          placeHolder={t('add-custom-token-dialog.token-contract-address')}
          value={customAddress}
          onChange={(event: any) => {
            handleCustomAddressChange(event.target.value, selectNetwork.symbol);
          }}
        />
        <InputField
          label={t('add-custom-token-dialog.token-symmbol')}
          errorInfo={customSymbolError}
          placeHolder={t('add-custom-token-dialog.token-symmbol')}
          value={customSymbol}
          disabled
        />
        <InputField
          label={t('add-custom-token-dialog.decimals')}
          errorInfo={customDecimalsError}
          placeHolder="0"
          value={customDecimals}
          disabled
        />
      </DialogModal>
      {showConfirmAddTokenDialog && (
        <ConfirmAddTokenDialog
          isOpen={showConfirmAddTokenDialog}
          onClose={closeConfirm}
          confirmAddToken={handleAddToken()}
          token={{
            tokenAddress: customAddress,
            chain: selectNetwork.symbol,
            decimals: customDecimals,
            symbol: customSymbol,
            name: customName,
            balance: new BigNumber(customBalance).toFixed(),
            amount: new BigNumber(
              customBalance / Math.pow(10, customDecimals),
            ).toFixed(),
            isAdd: true,
          }}
        />
      )}
    </>
  );
};

export default AddCustomTokenDialog;
