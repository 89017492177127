import { makeStyles, Theme } from '@material-ui/core';

export const useSettingsStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  header: {},
  content: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(12),
  },
  column: {
    flex: 1,
    margin: '0 -1px',
  },
  divid: {
    margin: theme.spacing(0, 21),
    borderLeft: `1px solid ${theme.palette.grey.A200}`,
    width: 1,
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6.5),
    '& svg': {
      marginRight: theme.spacing(3),
    },
  },
  device: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 2),
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey.A100,
    },
  },
  currentDevice: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.spacing(2, 2, 0, 0),
  },
  active: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginLeft: 6,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.text.primary,
    '& svg': {
      width: 20,
      height: 20,
      marginRight: theme.spacing(3),
    },
  },
  detail: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(4, 4, 2, 4),
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  item: {
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
    '& > p': {
      color: theme.palette.text.primary,
    },
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  privacyWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: 12,
    gridRowGap: 12,
    color: theme.palette.text.primary,
    position: 'relative',
  },
  privacyItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    background: theme.palette.grey.A100,
    borderRadius: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    cursor: 'pointer',
    '& > span': {
      marginTop: '6px',
    },
    '& svg': {
      color: theme.palette.text.secondary,
    },
  },
  setted: {
    cursor: 'default !important',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    '& > div svg': {
      marginRight: 6,
      color: theme.palette.primary.main,
    },
    '& > span': {
      color: theme.palette.text.primary,
      opacity: 0.5,
    },
  },
  setPassword: {
    position: 'relative',
    '&:after': {
      content: '" "',
      display: 'inline-block',
      position: 'absolute',
      left: '50%',
      bottom: -12,
      border: '6px solid #EF4C56',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopColor: 'transparent',
    },
  },
  setPasswordRemind: {
    position: 'absolute',
    zIndex: 1,
    top: '100%',
    left: 0,
    marginTop: '10px',
    backgroundColor: theme.palette.common.black,
    borderRadius: '8px',
    minWidth: '408px',
    color: theme.palette.error.main,
    fontSize: '12px',
    lineHeight: '18px',
    border: '1px solid #EF4C56',
    display: 'flex',
    padding: '12px 20px',
    '& > div': {
      marginLeft: '10px',
    },
  },
}));
