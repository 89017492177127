import React from 'react';
import { t } from 'modules/i18n/intl';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { DialogModal } from 'components/DialogModal';
import { getTokenPrice } from '../../services/AssetServcie';
import { useCurrentAccount } from '../../state/account/hooks';
import { useSelectedAsset } from '../../state/app/hooks';
import { SendConfirmIcon } from 'modules/icons/SendConfirmIcon';

interface TransferConfirmDialogProps {
  from: string;
  to: string;
  amount: string;
  txnFee: string;
  crossFee?: string;
  total: string;
  token: string;
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  title: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  row: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    textAlign: 'right',
    wordBreak: 'break-all',
    '& h5': {
      minWidth: 80,
      color: theme.palette.common.white,
      textAlign: 'left',
      marginRight: theme.spacing(2),
    },
  },
}));

const TransferConfirmDialog = ({
  from,
  to,
  token,
  crossFee,
  txnFee,
  amount,
  total,
  isOpen,
  onClose,
  onSend,
}: TransferConfirmDialogProps) => {
  const currentAccount = useCurrentAccount();
  const selectedAsset = useSelectedAsset();

  const classes = useStyles();

  return (
    <DialogModal
      open={isOpen}
      title={t('transfer-confirm-dialog.title')}
      onClose={onClose}
      handleText={t('transfer-confirm-dialog.send')}
      handleDialog={onSend}
      onceClick={true}
    >
      <Typography variant="h4" className={classes.title}>
        <SendConfirmIcon />
        {`-${amount} ${token} `}{' '}
        {currentAccount[token]?.marketData?.USDT && (
          <Typography>{`($${getTokenPrice(
            currentAccount[token]?.marketData?.USDT,
            amount,
          ).toFixed(2)})`}</Typography>
        )}
      </Typography>
      <div className={classes.row}>
        <Typography variant="h5">
          {t('transfer-confirm-dialog.from')}
        </Typography>
        <Typography variant="body1">{from}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h5">{t('transfer-confirm-dialog.to')}</Typography>
        <Typography variant="body1">{to}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h5">
          {t('transfer-confirm-dialog.txn-fee')}
        </Typography>
        <Typography variant="body1">
          {txnFee} {crossFee ? ` + ${crossFee}` : ''}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h5">
          {t('transfer-confirm-dialog.total')}
        </Typography>
        <Typography variant="body1">
          {total} {token} {selectedAsset.chain ? ` + ${txnFee}` : ''}
        </Typography>
      </div>
    </DialogModal>
  );
};

export default TransferConfirmDialog;
