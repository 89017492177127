import { Box, BoxProps } from '@material-ui/core';
import { LoadingProps } from '@redux-requests/react';
import React from 'react';
import { Spinner } from '../Spinner';

interface IQueryLoadingProps extends LoadingProps {
  size?: number;
}

interface IQueryLoadingCenteredProps extends BoxProps {
  size?: number;
}

export const QueryLoading = ({ size }: IQueryLoadingProps) => {
  return <Spinner size={size} />;
};

export const QueryLoadingAbsolute = () => {
  return <Spinner centered={true} />;
};

export const QueryLoadingCentered = ({
  size = 40,
  ...props
}: IQueryLoadingCenteredProps) => {
  return (
    <Box display="flex" justifyContent="center" {...props}>
      <Spinner size={size} />
    </Box>
  );
};
