import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RejectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <path
        d="M8.49999 7.05732L11.8 3.75732L12.7427 4.69999L9.44266 7.99999L12.7427 11.3L11.8 12.2427L8.49999 8.94266L5.19999 12.2427L4.25732 11.3L7.55732 7.99999L4.25732 4.69999L5.19999 3.75732L8.49999 7.05732Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
