import React, { useState } from 'react';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { Typography } from '@material-ui/core';
import {
  getColorByPasswordStrength,
  getPasswordStrength,
} from '../../utils/helper';
import { deviceInfoUpload } from '../../services/loginService';
import { useUserSession } from '../../state/user/hooks';
import { UserSession } from '../../state/user/types';
import { InputField } from 'components/InputField/InputField';
var CryptoJS = require('crypto-js');

interface SetRecoveryPasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SetRecoveryPasswordDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: SetRecoveryPasswordDialogProps) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const userSession = useUserSession();
  const userInfo = localStorage.getItem('userInfo') ?? '{}';

  const onDismiss = () => {
    onClose();
  };
  const handleConfirm = () => {
    const userSessionWithPassword: UserSession = {
      ...userSession,
      password: password,
    };
    const info = {
      ...(userInfo !== null ? JSON.parse(userInfo) : {}),
      [userSession.userId]: {
        sharedA: CryptoJS.AES.encrypt(
          userSession.sharedA,
          userSession.publicKey,
        ).toString(),
        uuid: userSession.uuid,
        password: CryptoJS.AES.encrypt(
          password,
          userSession.publicKey,
        ).toString(),
      },
    };
    localStorage.setItem('userInfo', JSON.stringify(info));
    deviceInfoUpload(userSessionWithPassword);
    onConfirm();
  };

  const handleConfirmPasswordChange = (value: string) => {
    let error = '';
    value = value.length > 128 ? confirmPassword : value;

    if (password !== value) {
      error = t('set-recovery-password-dialog.passwordsDontMatch');
    }
    setConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  const handlePasswordChange = (value: string) => {
    let error: any = '';
    let strength: any = '';
    let confirmError = '';

    if (value && value.length < 8) {
      strength = '';
      error = (
        <>
          <span>{t('set-recovery-password-dialog.passwordLow')}</span>
        </>
      );
    } else {
      value = value.length > 128 ? password : value;
      error = '';
      strength = (
        <>
          <Typography color="textSecondary">
            {t('set-recovery-password-dialog.passwordStrength')}&nbsp;
            <span
              style={{
                color: getColorByPasswordStrength(getPasswordStrength(value)),
              }}
            >
              {t(getPasswordStrength(value))}
            </span>
          </Typography>
        </>
      );
    }

    if (confirmPassword && value !== confirmPassword) {
      confirmError = t('set-recovery-password-dialog.passwordsDontMatch');
    }
    setPassword(value);
    setPasswordError(error);
    setPasswordStrength(strength);
    setConfirmPasswordError(confirmError);
  };

  const isValid = () => {
    if (!password || !confirmPassword || password !== confirmPassword) {
      return false;
    }

    if (password.length < 8) {
      return false;
    }

    return (!passwordError || password.length >= 8) && !confirmPasswordError;
  };

  return (
    <DialogModal
      subtitle={t('set-recovery-password-dialog.title')}
      open={isOpen}
      onClose={onDismiss}
      disabled={!isValid()}
      handleDialog={handleConfirm}
      hideClose
    >
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: 20 }}
      >
        {t('set-recovery-password-dialog.tip')}
      </Typography>
      <InputField
        label={t('set-recovery-password-dialog.newPassword')}
        value={password}
        errorInfo={passwordError}
        placeHolder={t('set-recovery-password-dialog.newPassword')}
        type="password"
        onChange={(e: any) => handlePasswordChange(e.target.value)}
        appendContent={<div>{passwordStrength}</div>}
      />
      <InputField
        label={t('set-recovery-password-dialog.confirmPassword')}
        value={confirmPassword}
        errorInfo={confirmPasswordError}
        placeHolder={t('set-recovery-password-dialog.confirmPassword')}
        type="password"
        onChange={(e: any) => handleConfirmPasswordChange(e.target.value)}
      />
    </DialogModal>
  );
};

export default SetRecoveryPasswordDialog;
