import { createReducer } from '@reduxjs/toolkit';
import { showLogin, showPopup, requestChainName } from './actions';

export interface ApiState {
  showLogin: boolean,
  popupPayload: any,
  popupRequest: any,
  requestChainName: string,
}

const initialState: ApiState = {
  showLogin: false,
  popupPayload: {},
  popupRequest: {},
  requestChainName: ''
}

export default createReducer(initialState, builder =>
  builder
  .addCase(showLogin, (state, action) => {
    const { showLogin } = action.payload
    state.showLogin = showLogin

  })
  .addCase(showPopup, (state, action) => {
    const { payload, request } = action.payload
    state.popupPayload = payload
    state.popupRequest = request
  })
  .addCase(requestChainName, (state, action) => {
    const { requestChainName } = action.payload
    state.requestChainName = requestChainName
  })
);
