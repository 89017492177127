import React from 'react';
import { t } from 'modules/i18n/intl';
import { DialogActions, makeStyles, Theme } from '@material-ui/core';
import QRCode from 'qrcode-react';
import { DialogModal } from 'components/DialogModal';
import { CopyAddress } from 'components/CopyAddress/CopyAddress';

interface ReceiveDialogProps {
  address: string;
  token: string;
  isOpen: boolean;
  onClose: () => void;
}
const useStyles = makeStyles<Theme>(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
}));

const ReceiveDialog = ({
  address,
  token,
  isOpen,
  onClose,
}: ReceiveDialogProps) => {
  const classes = useStyles();

  return (
    <DialogModal
      title={t('receive-dialog.title', { token: token })}
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.content}>
        <QRCode value={address} size={240} />
      </div>
      <DialogActions>
        <CopyAddress address={address} />
      </DialogActions>
    </DialogModal>
  );
};

export default ReceiveDialog;
