import { ObservableStore } from '@metamask/obs-store'
import { ethErrors } from 'eth-rpc-errors'
import EventEmitter from 'events'
import log from 'loglevel'
import nacl__default from 'tweetnacl';

import { MESSAGE_TYPE } from '../constants/netEnums'
import { getUser } from '../services/PopupService';

export default class SolanaMessageManager extends EventEmitter {
  
  constructor() {
    super()
    this.store = new ObservableStore({
      unapprovedSolanaMsgs: {},
      unapprovedSolanaCount: 0,
    })
    this.messages = []
  }

  get unapprovedSolanaMsgCount() {
    return Object.keys(this.getUnapprovedMsgs()).length
  }

  getUnapprovedMsgs() {
    return this.messages
      .filter((message) => message.status === 'unapproved')
      .reduce((result, message) => {
        result[message.id] = message
        return result
      }, {})
  }

  addUnapprovedMessageAsync(messageParameters, request, messageId) {
    return new Promise((resolve, reject) => {
      this.addUnapprovedMessage(messageParameters, request, messageId)
      this.once(`${messageId}:finished`, (data) => {
        switch (data.status) {
          case 'signed':
            return resolve(data.rawSig)
          case 'rejected':
            return reject(ethErrors.provider.userRejectedRequest('Clover Message Signature: User denied message signature.'))
          default:
            return reject(new Error(`Clover Message Signature: Unknown problem: ${JSON.stringify(messageParameters)}`))
        }
      })
    })
  }

  
  addUnapprovedMessage(messageParameters, request, messageId) {
    log.debug(`Solana MessageManager addUnapprovedMessage: ${JSON.stringify(messageParameters)}`)
  
    // create txData obj with parameters and meta data
    const time = Date.now()
    const messageData = {
      id: messageId,
      msgParams: messageParameters,
      time,
      status: 'unapproved',
      type: MESSAGE_TYPE.SOLANA_SIGN,
    }

    if(request) {
      messageData.origin = request.origin
    }
    this.addMsg(messageData)

    // signal update
    this.emit('update')
    return messageId
  }

  addMsg(message) {
    this.messages.push(message)
    this._saveMsgList()
  }

  getMsg(messageId) {
    return this.messages.find((message) => message.id === messageId)
  }

  approveMessage(messageId) {
    this.setMsgStatusApproved(messageId)
    return Promise.resolve(this.getMsg(messageId).msgParams)
  }

  setMsgStatusApproved(messageId) {
    this._setMsgStatus(messageId, 'approved')
  }

  setMsgStatusSigned(messageId, rawSig) {
    const message = this.getMsg(messageId)
    message.rawSig = rawSig
    this._updateMsg(message)
    this._setMsgStatus(messageId, 'signed')
  }

  prepMsgForSigning(messageParameters) {
    delete messageParameters.metamaskId
    return Promise.resolve(messageParameters)
  }

  rejectMsg(messageId) {
    this._setMsgStatus(messageId, 'rejected')
  }

  _setMsgStatus(messageId, status) {
    const message = this.getMsg(messageId)
    if (!message) throw new Error(`SolanaMessageManager - Message not found for id: "${messageId}".`)
    message.status = status
    this._updateMsg(message)
    this.emit(`${messageId}:${status}`, message)
    if (status === 'rejected' || status === 'signed') {
      this.emit(`${messageId}:finished`, message)
    }
  }

  _updateMsg(message_) {
    const index = this.messages.findIndex((message) => message.id === message_.id)
    if (index !== -1) {
      this.messages[index] = message_
    }
    this._saveMsgList()
  }

  _saveMsgList() {
    const unapprovedSolanaMsgs = this.getUnapprovedMsgs()
    const unapprovedSolanaMessageCount = Object.keys(unapprovedSolanaMsgs).length
    this.store.updateState({ unapprovedSolanaMsgs, unapprovedPersonalMsgCount: unapprovedSolanaMessageCount })
    this.emit('updateBadge')
  }

  _sign = (message, fromWallet) => {
    const tempArr = [];

    for (const key in message) {
      tempArr.push(message[key]);
    }

    const signData = Buffer.of(...tempArr);
    const signature = nacl__default.sign.detached(signData, fromWallet.secretKey);

    const sigArr = [];
    for (const key in signature) {
      sigArr.push(signature[key]);
    }
    const sigNature = Buffer.of(...sigArr);
    return sigNature;
  }

  signSolanaMsg(message) {
    if (!message) {
      throw new Error(`SolanaMessageManager - Empty Message to sign.`)
    }

    const solWallet = getUser().solWallet
    if (!solWallet) {
      throw new Error(`SolanaMessageManager - Invalid solana wallet.`)
    }

    if (Array.isArray(message)) {
      const results = []
      for (const item of message) {
        results.push(this._sign(item, solWallet))
      }

      return results
    } else {
      return this._sign(message, solWallet)
    }
  }
}
