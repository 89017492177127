import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { t } from 'modules/i18n/intl';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { getSendNftLevels } from '../../services/nftService';
import { useCurrentAccount } from '../../state/account/hooks';
import { DialogModal } from 'components/DialogModal';
import { InputField } from 'components/InputField/InputField';
import classNames from 'classnames';

interface INFTSendDialogProps {
  nftList: Array<any>;
  collectionName: string;
  currentSelectNft: any;
  currentCollection: any;
  isOpen: boolean;
  onClose: () => void;
  buildTx: (item: any) => object;
  changeSelNft: () => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  gasList: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.spacing(2),
    display: 'flex',
    marginBottom: theme.spacing(6),
  },
  gas: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.grey.A700,
    cursor: 'pointer',
    padding: theme.spacing(4, 5),
  },
  currentGas: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: theme.spacing(2),
  },
}));

const NFTSendDialog = ({
  currentSelectNft,
  currentCollection,
  isOpen,
  onClose,
  buildTx,
  changeSelNft,
}: INFTSendDialogProps) => {
  const [toAddress, setToAddress] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const currentAccount = useCurrentAccount();
  const [selectFeeKey, setSelectFeeKey] = useState('');
  const [gasSymbol, setGasSymbol] = useState('');
  const [gasRate, setGasRate] = useState(0);
  const [displayArr, setDisplayArr] = useState<any>([]);
  const getFee = useCallback(async () => {
    const feeLevels = await getSendNftLevels(
      currentAccount.accountId,
      currentAccount.requestPrivateKey,
      currentCollection.network.id,
    );
    const tempArr = Object.keys(feeLevels['levels']);
    setGasSymbol(feeLevels['feeAsset']['symbol']);
    if (feeLevels['feeAsset']['price']) {
      setGasRate(feeLevels['feeAsset']['price']['rate']);
    }
    if (tempArr.length === 1) {
      setSelectFeeKey(tempArr[0]);
    } else {
      setSelectFeeKey(tempArr[1]);
    }
    let displayArr = [];
    for (const feeKey in feeLevels['levels']) {
      displayArr.push({ key: feeKey, value: feeLevels['levels'][feeKey] });
    }
    setDisplayArr(displayArr);
  }, [
    currentAccount.accountId,
    currentAccount.requestPrivateKey,
    currentCollection.network.id,
  ]);

  useEffect(() => {
    getFee();
  }, [getFee]);

  const onToAddressChange = (e: any) => {
    const regAddress = new RegExp(currentCollection.network.addressRegex);
    const inputStr = e.target.value;
    setToAddress(inputStr);
    setToAddressErr(
      inputStr.length > 0
        ? regAddress.test(e.target.value)
          ? ''
          : t('trans-confirm-dialog.invalid-address')
        : '',
    );
  };

  const btnStatus = useMemo(
    () => toAddress === '' || toAddressErr !== '' || selectFeeKey === '',
    [toAddress, toAddressErr, selectFeeKey],
  );

  const handleDialog = () => {
    let selectFee = 0;
    displayArr.forEach((item: any) => {
      if (item.key === selectFeeKey) {
        selectFee = item.value;
      }
    });
    buildTx({
      toAddress: toAddress,
      fee: selectFee,
      feeSymbol: gasSymbol,
    });
  };

  const canChangeNFT = useMemo(
    () => currentCollection.tokens.length > 1 && changeSelNft,
    [currentCollection.tokens.length, changeSelNft],
  );

  const handleChangeNFT = useCallback(() => {
    if (canChangeNFT) {
      changeSelNft();
    }
  }, [canChangeNFT, changeSelNft]);

  const classes = useStyles();
  return (
    <DialogModal
      title={t('send-nft-dialog.title')}
      open={isOpen}
      onClose={onClose}
      disabled={btnStatus}
      handleDialog={handleDialog}
    >
      <div
        style={{ cursor: canChangeNFT ? 'pointer' : 'normal' }}
        onClick={handleChangeNFT}
      >
        <InputField
          leftContent={
            <img src={currentSelectNft.image} alt="" width={60} height={60} />
          }
          value={
            currentSelectNft.name ??
            `${currentCollection.name} ${currentSelectNft.id}`
          }
          disabled
        />
      </div>
      <InputField
        label={t('send-nft-dialog.recipient')}
        value={toAddress}
        errorInfo={toAddressErr}
        placeHolder={t('send-nft-dialog.recipient-placeholder')}
        onChange={e => onToAddressChange(e)}
      />
      {displayArr.length === 1 ? (
        <Typography variant="h5" component="div" className={classes.row}>
          <Typography>{t('send-nft-dialog.txn-fee')}</Typography>
          <Typography color="textSecondary">
            {displayArr[0].value.toFixed(6)}
            {gasSymbol}
          </Typography>
        </Typography>
      ) : (
        <>
          <Typography>{t('send-nft-dialog.txn-fee')}</Typography>
          <div className={classes.gasList}>
            {displayArr.map((fee: any, index: number) => (
              <div
                key={`fee_${index}`}
                className={classNames(
                  classes.gas,
                  selectFeeKey === fee.key && classes.currentGas,
                )}
                onClick={() => {
                  setSelectFeeKey(fee.key);
                }}
              >
                <Typography variant="h4">{fee.key}</Typography>
                <Typography variant="body2">
                  {fee.value.toFixed(6)} {gasSymbol}
                </Typography>
                <Typography variant="body2">
                  ${(fee.value * gasRate).toFixed(2)} {fee.value.toFixed(6)}{' '}
                  {gasSymbol}
                </Typography>
              </div>
            ))}
          </div>
        </>
      )}
    </DialogModal>
  );
};
export default NFTSendDialog;
