import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 4C12.1943 4 14.2925 5.07296 16.248 6.83586C16.9151 7.43729 17.5186 8.07937 18.0499 8.7217C18.3705 9.1093 18.5982 9.41425 18.7228 9.5959L19 10L18.7228 10.4041C18.5982 10.5858 18.3705 10.8907 18.0499 11.2783C17.5186 11.9206 16.9151 12.5628 16.248 13.1642C14.2925 14.9271 12.1943 16 9.99996 16C7.80567 16 5.70743 14.9271 3.75199 13.1642C3.08489 12.5628 2.48136 11.9206 1.95007 11.2783C1.62947 10.8907 1.40186 10.5858 1.27723 10.4041L1 10L1.27723 9.5959C1.40186 9.41425 1.62947 9.1093 1.95007 8.7217C2.48136 8.07937 3.08489 7.43729 3.75199 6.83586C5.70743 5.07296 7.80567 4 9.99996 4ZM16.7757 9.63768C16.2892 9.04953 15.7362 8.46115 15.1295 7.91414C13.4452 6.39579 11.6935 5.5 9.99996 5.5C8.30645 5.5 6.55471 6.39579 4.87052 7.91414C4.26376 8.46115 3.7107 9.04953 3.22427 9.63768C3.11921 9.76465 3.02213 9.88585 2.93338 10C3.02213 10.1142 3.11921 10.2354 3.22427 10.3623C3.7107 10.9505 4.26376 11.5389 4.87052 12.0858C6.55471 13.6042 8.30645 14.5 9.99996 14.5C11.6935 14.5 13.4452 13.6042 15.1295 12.0858C15.7362 11.5389 16.2892 10.9505 16.7757 10.3623C16.8808 10.2354 16.9778 10.1142 17.0666 10C16.9778 9.88585 16.8808 9.76465 16.7757 9.63768ZM6.78198 10C6.78198 11.6568 8.22274 13 9.99996 13C11.7773 13 13.218 11.6568 13.218 10C13.218 8.34314 11.7773 7 9.99996 7C8.22274 7 6.78198 8.34314 6.78198 10ZM11.609 10C11.609 10.8285 10.8886 11.5 9.99996 11.5C9.11139 11.5 8.39098 10.8285 8.39098 10C8.39098 9.17155 9.11139 8.5 9.99996 8.5C10.8886 8.5 11.609 9.17155 11.609 10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
