import * as API from '../constants/api';
import { TRON_NETWORK } from '../constants/networks';
import * as bip32 from 'bip32';
import { BigNumber } from 'bignumber.js';
import { IS_DEV } from '../constants/api';

export const fullNode = IS_DEV
  ? 'https://api.shasta.trongrid.io'
  : 'https://api.trongrid.io';

const TronWeb = require('tronweb');
const bip39 = require('bip39');
export const getTrxAddressFromMnemonic = async (seedWord: string) => {
  const seed = await bip39.mnemonicToSeed(seedWord);
  const node = bip32.fromSeed(seed);
  const child: any = node.derivePath(`m/44'/195'/0'/0/0`);
  const privateKey = child.privateKey.toString('hex');
  const address = TronWeb.address.fromPrivateKey(privateKey);
  return address;
};

export const getTrxBalance = async (address: string) => {
  try {
    const tronWeb = new TronWeb(fullNode, fullNode, fullNode);
    const balance = await tronWeb.trx.getBalance(address);
    const base = new BigNumber(10).pow(TRON_NETWORK.decimals);
    const amount = new BigNumber(balance.toString()).div(base).toString();
    return {
      address,
      token: 'TRX',
      balance,
      amount,
      marketData: {},
      status: API.SUCCESS,
    };
  } catch (error) {
    return {
      address,
      token: 'TRX',
      balance: '0',
      amount: '0',
      marketData: {},
      status: API.FAILURE,
    };
  }
};

export const checkTrxAddress = (address: string) => {
  const tronWeb = new TronWeb(fullNode, fullNode, fullNode);
  return tronWeb.isAddress(address);
};

export const getTronEnergyAndBindwidth = async (address: string) => {
  const tronWeb = new TronWeb(fullNode, fullNode, fullNode);
  const {
    EnergyLimit = 0,
    EnergyUsed = 0,
    freeNetLimit,
    NetLimit = 0,
    freeNetUsed = 0,
    NetUsed = 0,
  } = await tronWeb.trx.getAccountResources(address);
  return {
    energy: EnergyLimit,
    energyUsed: EnergyUsed,
    netLimit: !!freeNetLimit ? freeNetLimit + NetLimit : NetLimit,
    netUsed: NetUsed + freeNetUsed,
  };
};
