import contracts from '@metamask/contract-metadata';

const erc20Contracts = {
  ...contracts,
  '0xC4C2614E694cF534D407Ee49F8E44D125E4681c4': {
    name: 'Chain Games',
    logo: 'chain.png',
    erc20: true,
    symbol: 'CHAIN',
    decimals: 18,
  },
};

export default erc20Contracts;
