import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CollectionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 57 56">
      <path
        opacity="0.4"
        d="M50.2041 3H6.79586C5.13905 3 3.48225 4.33333 3.48225 6C3.48225 7.66667 4.80769 9 6.4645 9H50.2041C51.861 9 53.1864 7.66667 53.1864 6C53.1864 4.33333 51.861 3 50.2041 3ZM56.5 34.3333V28L53.5178 14C53.5178 13 52.5237 12.3333 51.5296 12.3333H5.47041C4.47633 12.3333 3.48225 13 3.15089 14L0.5 28V34.3333H3.48225V48.6667C3.48225 51 5.47041 53 7.78994 53H30.3225C32.642 53 34.6302 51 34.6302 48.6667V34.3333H46.8905V50C46.8905 51.6667 48.216 53 49.8728 53C51.5296 53 52.855 51.6667 52.855 50V34.3333H56.5ZM28.3343 46.6667H9.77811V34.3333H28.3343V46.6667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
