import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SendIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M10.9194 6.776L5.18144 12.514L4.23877 11.5713L9.9761 5.83333H4.91944V4.5H12.2528V11.8333H10.9194V6.776V6.776Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
