import { addHexPrefix, BN, privateToAddress, pubToAddress, stripHexPrefix } from 'ethereumjs-util'

export const isMain = window.self === window.top

export const getIFrameOrigin = () => {
  const originHref = window.location.ancestorOrigins?.length > 0 ? window.location.ancestorOrigins[0] : document.referrer
  return originHref
}

export function formatDate(inputDate) {
  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(inputDate)
  const day = date.getDate()
  const month = monthList[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

export function formatTime(time) {
  return new Date(time).toTimeString().slice(0, 8)
}

export const formatPastTx = (x, lowerCaseSelectedAddress) => {
  // todo change later
  return {

  }
}

export async function getEthTxStatus(hash, web3) {
  const receipt = await web3.eth.getTransactionReceipt(hash)
  if (receipt === null) return 'pending'
  if (receipt && receipt.status) return 'confirmed'
  if (receipt && !receipt.status) return 'rejected'
  return undefined
}

export function BnMultiplyByFraction(targetBN, numerator, denominator) {
  const numberBN = new BN(numerator)
  const denomBN = new BN(denominator)
  return targetBN.mul(numberBN).div(denomBN)
}

/**
 * Converts a BN object to a hex string with a '0x' prefix
 *
 * @param {BN} inputBn The BN to convert to a hex string
 * @returns {string} A '0x' prefixed hex string
 *
 */
 export function bnToHex(inputBn) {
  return addHexPrefix(inputBn.toString(16))
}

/**
 * Converts a hex string to a BN object
 *
 * @param {string} inputHex A number represented as a hex string
 * @returns {Object} A BN object
 *
 */
export function hexToBn(inputHex) {
  return new BN(stripHexPrefix(inputHex), 16)
}

export const GAS_LIMITS = {
  // maximum gasLimit of a simple send
  SIMPLE: addHexPrefix((21_000).toString(16)),
  // a base estimate for token transfers.
  BASE_TOKEN_ESTIMATE: addHexPrefix((100_000).toString(16)),
}

export function getChainType(chainId) {
  return 'testnet'
  // todo change later
  // if (chainId === MAINNET_CHAIN_ID || chainId === Number.parseInt(MAINNET_CHAIN_ID, 16)) {
  //   return 'mainnet'
  // }
  // if (TEST_CHAINS.includes(chainId) || TEST_CHAINS_NUMERIC_IDS.includes(chainId)) {
  //   return 'testnet'
  // }
  // return 'custom'
}

export function getEtherScanHashLink(txHash, network) {
  // todo change later
  // if (!SUPPORTED_NETWORK_TYPES[network]) return ''
  // return `${SUPPORTED_NETWORK_TYPES[network].blockExplorer}/tx/${txHash}`
}

export async function validateImageUrl(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    if (img.complete) {
      resolve(true)
    } else {
      img.addEventListener('load', () => {
        resolve(true)
      })
      img.addEventListener('error', () => {
        reject()
      })
    }
  })
}

export const broadcastChannelOptions = {
  // type: 'localstorage', // (optional) enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
  webWorkerSupport: false, // (optional) set this to false if you know that your channel will never be used in a WebWorker (increases performance)
}

export class UserError extends Error {}


