import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: 84,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  nft: {
    width: 60,
    height: 60,
    borderRadius: theme.spacing(2),
    marginRight: 10,
  },
}));
export const NFTsList = (props: any) => {
  const classes = useStyles();
  const nftList = props.nftList ?? [];
  const collectionName = props.collectionName ?? '';
  const listItems = nftList.map((item: any) => (
    <div
      className={classes.root}
      key={item.id}
      onClick={() => {
        props.onSelectItem(item);
      }}
    >
      <img
        className={classes.nft}
        src={item.image}
        alt={item.name ?? `${collectionName} ${item.id}`}
      />
      <span>{item.name ?? `${collectionName} ${item.id}`}</span>
    </div>
  ));
  return listItems;
};
