import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import log from 'loglevel';
import { broadcastChannelOptions } from '../../utils/utils';
import { LogoLoadingIcon } from 'modules/icons/loading/LogoLoadingIcon';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    '& svg': {
      width: 100,
      height: 100,
    },
  },
}));

export default function RedirectPage() {
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    let bc;
    let preopenInstanceId = query.get('preopenInstanceId');

    try {
      if (!preopenInstanceId) {
        throw new Error('No preopenInstanceId');
      }
      bc = new BroadcastChannel(
        `preopen_channel_${preopenInstanceId}`,
        broadcastChannelOptions,
      );
      bc.addEventListener('message', ev => {
        const { preopenInstanceId: oldId, payload, message } = ev.data;
        if (oldId === preopenInstanceId && payload && payload.url) {
          window.location.href = payload.url;
        } else if (
          oldId === preopenInstanceId &&
          message === 'setup_complete'
        ) {
          bc.postMessage({
            data: {
              preopenInstanceId: preopenInstanceId,
              message: 'popup_loaded',
            },
          });
        }
        if (ev.error && ev.error !== '') {
          log.error(ev.error);
          bc.close();
        }
      });
    } catch (error) {
      log.info(error, 'something went wrong');
      if (bc) bc.close();
      window.close();
    }
  }, [query]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LogoLoadingIcon />
    </div>
  );
}
