import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DataLoadingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <path
        fill="currentColor"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          accumulate="none"
          additive="replace"
          attributeName="transform"
          attributeType="XML"
          calcMode="linear"
          dur="1s"
          fill="remove"
          from="0 50 50"
          repeatCount="indefinite"
          restart="always"
          to="360 50 50"
          type="rotate"
        ></animateTransform>
      </path>
    </SvgIcon>
  );
};
