import { makeStyles, Theme } from '@material-ui/core';

export const useLoginStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  container: {
    width: 920,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      padding: '0 30px',
    },
  },
  back: {
    backgroundColor: '#020405',
    backgroundImage:
      'linear-gradient(to right, #721DB6 19.7%, #48C0E2 61.45%, #3DDBBE 99.07%)',
    opacity: 0.32,
    filter: 'blur(300px)',
    transform: 'rotate(180deg)',
    '-webkit-transform': 'translateZ(0)',
    position: 'absolute',
    top: '-25%',
    left: -125,
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    height: 440,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: '100%',
    },
  },
  title: {
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      fontSize: '32px',
      margin: '20px 0',
    },
  },
  landing: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      height: 'unset',
      flex: 'unset',
      padding: '40px 0 70px',
    },
  },
  logoIcon: {
    width: 50,
    height: 50,
    color: theme.palette.common.white,
  },
  login: {
    flex: 1,
  },
  tip: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-all',
      marginBottom: theme.spacing(2.5),
    },
  },
  googleButton: {
    maxWidth: 428,
    marginBottom: theme.spacing(4.5),
    '& svg': {
      marginRight: theme.spacing(2.5),
    },
  },
  social: {
    maxWidth: 448,
    margin: '0 -10px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  iconButton: {
    width: 130,
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      flex: 1,
    },
  },
  otherLogin: {
    margin: theme.spacing(10, 0),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing(15),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(5),
    },
  },
  note: {
    marginTop: theme.spacing(10),
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.grey[700],
    },
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
    },
  },
  twitterIcon: {
    color: '#1D9BF0',
  },

  networksMarginTop: {
    marginTop: theme.spacing(10),
  },
  popupNote: {
    '& em': {
      fontStyle: 'normal',
    },
    color: 'rgba(255,255,255,0.4)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
    },
  }
}));
