import React from 'react';
import { t } from 'modules/i18n/intl';
import { NFTsList } from './NFTsList';
import { DialogModal } from 'components/DialogModal';

interface INFTSelectDialogProps {
  nftList: Array<any>;
  collectionName: string;
  isOpen: boolean;
  onClose: () => void;
  onSelectItem: () => object;
}

const NFTSelectDialog = ({
  nftList,
  collectionName,
  isOpen,
  onClose,
  onSelectItem,
}: INFTSelectDialogProps) => {
  return (
    <DialogModal
      open={isOpen}
      title={t('select-nft-dialog.title')}
      onClose={onClose}
    >
      <NFTsList
        nftList={nftList}
        collectionName={collectionName}
        onSelectItem={onSelectItem}
      />
    </DialogModal>
  );
};

export default NFTSelectDialog;
