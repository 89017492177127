import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="4"
          y1="7"
          x2="12.5"
          y2="7"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
