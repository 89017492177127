import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SendConfirmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 28 28">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="14" r="14" fill="#06E594" />
        <path
          d="M8.77496 19.6494L19.2244 9.19989M19.2244 9.19989H14.8246M19.2244 9.19989V13.5997"
          stroke="black"
        />
      </svg>
    </SvgIcon>
  );
};
