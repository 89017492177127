import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { CloseIcon } from 'modules/icons/CloseIcon';
import { ReturnIcon } from 'modules/icons/ReturnIcon';
import { DialogActionsModal } from './DialogActionsModal';

const useDialogStyles = makeStyles<Theme>(theme => ({
  space: {
    padding: '0 30px 36px 30px',
  },
  noSpace: {
    padding: 0,
  },
  title: {
    fontSize: 16,
    marginBottom: '10px',
    lineHeight: '40px',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtitle: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: 26,
    lineHeight: 1.4,
    marginBottom: theme.spacing(5),
  },
}));

type maxWidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

export type variantType = 'contained' | 'outlined' | 'text';

interface IDialogModalProps {
  noSpace?: boolean;
  maxWidth?: maxWidthType;
  title?: string;
  subtitle?: string;
  open: boolean;
  onReturn?: () => void;
  onClose: () => void;
  children: ReactNode;
  disabled?: boolean;
  handleDialog?: () => void;
  cancelText?: string;
  handleText?: string;
  handleIcon?: ReactNode;
  handleVariant?: variantType;
  hideClose?: boolean;
  isWarning?: boolean;
  onceClick?: boolean;
}

export const DialogModal = ({
  noSpace = false,
  title,
  subtitle,
  maxWidth = 'md',
  open,
  onReturn,
  onClose,
  children,
  disabled,
  handleDialog,
  cancelText,
  handleText,
  handleIcon,
  handleVariant = 'contained',
  hideClose,
  isWarning,
  onceClick,
}: IDialogModalProps) => {
  const classes = useDialogStyles();

  return (
    <Dialog
      classes={{ paper: noSpace ? classes.noSpace : classes.space }}
      maxWidth={maxWidth}
      open={open}
      onClose={hideClose ? onClose : () => {}}
    >
      {(title || !hideClose || subtitle) && (
        <DialogTitle disableTypography>
          <div className={classes.title}>
            {!hideClose && (
              <IconButton className={classes.closeIcon} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
            {title && title}
            {onReturn && (
              <IconButton onClick={onReturn}>
                <ReturnIcon />
              </IconButton>
            )}
          </div>
          {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {handleDialog && (
        <DialogActionsModal
          onCancel={onClose}
          cancelText={cancelText}
          disabled={disabled}
          handleText={handleText}
          handleIcon={handleIcon}
          handleVariant={handleVariant}
          handleDialog={handleDialog}
          isWarning={isWarning}
          onceClick={onceClick}
        />
      )}
    </Dialog>
  );
};
