import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCollectionsStyles } from './useCollectionsStyles';
import { t } from 'modules/i18n/intl';
import { Button, Typography } from '@material-ui/core';
import { AddIcon } from 'modules/icons/AddIcon';
import { CollectionsIcon } from 'modules/icons/CollectionsIcon';
import { NFTPlaceHolderIcon } from 'modules/icons/NFTPlaceHolderIcon';
import ReceiveDialog from '../../components/Dialogs/ReceiveDialog';
import { useCurrentAccount } from '../../state/account/hooks';
import { getCollections } from '../../services/nftService';
import { useSelectedCollectionUpdate } from '../../state/app/hooks';
import { setSelectedCollectionToSessionStorage } from '../../services/LocalstorageService';
import _ from 'lodash';
import { LoadingContent } from 'components/LoadingContent';

export default function CollectionsComponent() {
  const history = useHistory();
  const [showReceiveDialog, setShowReceiveDialog] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const currentAccount = useCurrentAccount();

  const updateSelectedCollection = useSelectedCollectionUpdate();
  const [loading, setLoading] = useState(true);

  const loadCollections = useCallback(async () => {
    const collections = await getCollections(
      currentAccount.accountId,
      currentAccount.requestPrivateKey,
    );
    setCollectionList(collections);
    setLoading(false);
  }, [currentAccount.accountId, currentAccount.requestPrivateKey]);

  useEffect(() => {
    let interval;
    async function init() {
      if (_.isEmpty(currentAccount)) {
        return;
      }
      loadCollections();
      interval = setInterval(async () => {
        loadCollections();
      }, 15000);
    }
    init();
    return () => {
      clearInterval(interval);
    };
  }, [currentAccount, loadCollections]);

  const classes = useCollectionsStyles();

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <Typography variant="h3">{t('collections.title')}</Typography>
        {collectionList.length > 0 && (
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => setShowReceiveDialog(true)}
          >
            {t('collections.receive-collection')}
          </Button>
        )}
      </div>
      {collectionList.length === 0 ? (
        <>
          {loading ? (
            <LoadingContent message={t('collections.loading')} />
          ) : (
            <div className={classes.noCollection}>
              <CollectionsIcon style={{ width: 56, height: 56 }} />
              <Typography variant="h4" style={{ margin: '20px 0' }}>
                {t('collections.show-collections')}
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => setShowReceiveDialog(true)}
              >
                {t('collections.receive-collection')}
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className={classes.collections}>
          {collectionList.map((collection, index) =>
            collection.tokens.map(token => (
              <div
                className={classes.collection}
                key={`${collection.address}_${token.address}`}
                onClick={() => {
                  updateSelectedCollection(collection);
                  setSelectedCollectionToSessionStorage(collection);
                  history.push(`/homepage/collectionDetail/${token.id}`);
                }}
              >
                {token.image ? (
                  <img
                    className={classes.image}
                    src={token.image}
                    alt={token.name}
                  />
                ) : (
                  <NFTPlaceHolderIcon className={classes.image} />
                )}
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.info}
                >
                  {token.name}
                  <img
                    src={collection.network.logoURI}
                    className={classes.chain}
                    alt={collection.network.symbol}
                  />
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {collection.name}
                </Typography>
              </div>
            )),
          )}
        </div>
      )}
      {showReceiveDialog && (
        <ReceiveDialog
          address={currentAccount.evmAddress}
          token="NFT"
          isOpen={showReceiveDialog}
          onClose={() => setShowReceiveDialog(false)}
        />
      )}
    </div>
  );
}
