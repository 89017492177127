import _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';

import {
  prices,
  accounts,
  balance,
  currentAccount,
  balances,
  account
} from './actions'
import { Account } from './types';

export interface AccountState {
  accounts: Array<Account>,
  currentAccount: any
  prices: any
}

const initialState: AccountState = {
  accounts: [], // all accounts
  currentAccount: {},
  prices: {}
}

const updateTokenBalance = (tokenSymbol: string, account: any, balance: any) => {
  const cachedMarketData = account[tokenSymbol] ? account[tokenSymbol].marketData : undefined
  account[tokenSymbol] = balance
  if (cachedMarketData && cachedMarketData.USDT) {
    account[tokenSymbol].marketData = cachedMarketData
  }
}

export default createReducer(initialState, builder =>
  builder
  .addCase(prices, (state, action) => {
    const { prices } = action.payload

    const currentAccount = {...state.currentAccount}
    if (currentAccount.address === prices.clvAddress) {
      _.forEach(_.keys(prices.prices), sym => {
        if (currentAccount && currentAccount[sym] && prices.prices[sym] && prices.prices[sym].USDT >= 0) {
          currentAccount[sym].marketData = {
            USDT: prices.prices[sym].USDT
          }
        }
      })
    }
    state.prices = prices
    state.currentAccount = currentAccount
  })
  .addCase(accounts, (state, action) => {
    const { accounts } = action.payload
    state.accounts = accounts
  })
  .addCase(balance, (state, action) => {
    const {balance} = action.payload
    const currentAccount = {...state.currentAccount}
    if (currentAccount.address === balance.clvAddress) {
      const cachedMarketData = currentAccount[balance.token] ? currentAccount[balance.token].marketData : undefined
      currentAccount[balance.token] = balance.balance
      if (cachedMarketData && cachedMarketData.USDT) {
        currentAccount[balance.token].marketData = cachedMarketData
      }
      const accounts = [...state.accounts]
      const accountIdxToUpdate = accounts.findIndex(a => a.address === currentAccount.address);
      if (accountIdxToUpdate !== -1) {
        accounts[accountIdxToUpdate] = currentAccount
      }
      state.accounts = accounts
      state.currentAccount = currentAccount
    }
  })
  .addCase(currentAccount, (state, action) => {
    const {currentAccount} = action.payload
    state.currentAccount = currentAccount
  })
  .addCase(balances, (state, action) => {
    const { balances } = action.payload

    const data = balances.balances
    const currentAccount = {...state.currentAccount}
    if (currentAccount.address === balances.clvAddress) {
      _.forEach(data, (balance) => {
        updateTokenBalance(balance.token, currentAccount, balance)
      })
      currentAccount.contractAssets = balances.contractAssets
      const accounts = [...state.accounts]
      const accountIdxToUpdate = accounts.findIndex(a => a.address === currentAccount.address);
      if (accountIdxToUpdate !== -1) {
        accounts[accountIdxToUpdate] = currentAccount
      }
      state.accounts = accounts
      state.currentAccount = currentAccount
    }
  })
  .addCase(account, (state, action) => {
    const { account } = action.payload
    state.accounts = account.accounts
    state.currentAccount = account.currentAccount
  })
);
