import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_9098_109000)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4995 4.5H9.49951V9.55195H4.5V14.6046H9.49951V19.5007H14.4995V14.448H9.49998V9.55261H14.4995V4.5ZM19.5001 9.55195H14.5001V14.6046H19.5001V9.55195Z" fill="black"/>
      <defs>
        <linearGradient id="paint0_linear_9098_109000" x1="24" y1="-1.43051e-06" x2="1.43051e-06" y2="24" gradientUnits="userSpaceOnUse">
          <stop offset="0.395833" stop-color="#A9FFE0"/>
          <stop offset="1" stop-color="#86D5FF"/>
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
