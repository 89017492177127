import deepmerge from 'deepmerge'
import log from 'loglevel'

export const get = async (url, options_ = {}) => {
  const defaultOptions = {
    mode: 'cors',
    headers: {},
  }
  
  const options = deepmerge.all([defaultOptions, options_, { method: 'GET' }])
  const response = await fetch(url, options)
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const post = (url, data = {}, options_ = {}, customOptions = {}) => {
  const defaultOptions = {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
  
  const options = deepmerge.all([defaultOptions, options_, { method: 'POST' }])

  options.body = customOptions.isUrlEncodedData ? data : JSON.stringify(data)

  if (options.headers['Content-Type'] === undefined) {
    delete options.headers['Content-Type']
  }

  return promiseTimeout(
    customOptions.timeout || 30000,
    fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw response
    })
  )
}

export const patch = async (url, data = {}, options_ = {}, customOptions = {}) => {
  const defaultOptions = {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
  const options = deepmerge.all([defaultOptions, options_, { method: 'PATCH' }])
 
  options.body = customOptions.isUrlEncodedData ? data : JSON.stringify(data)
  if (options.headers['Content-Type'] === undefined) {
    delete options.headers['Content-Type']
  }
  const response = await fetch(url, options)
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const remove = async (url, _data = {}, options_ = {}, customOptions = {}) => {
  const defaultOptions = {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
  
  const options = deepmerge.all([defaultOptions, options_, { method: 'DELETE' }])
  const response = await fetch(url, options)
  if (response.ok) {
    return response.json()
  }
  throw response
}

export const promiseRace = (url, options, timeout) => {
  log.info('promise race', url)
  return Promise.race([
    get(url, options),
    new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('timeout'))
      }, timeout)
    }),
  ])
}

export const promiseTimeout = (ms, promise) => {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error(`Timed out in ${ms}ms`))
    }, ms)
  })
  return Promise.race([promise, timeout])
}
