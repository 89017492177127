import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EyeCloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 15">
      <g clipPath="url(#clip0_1840_4071)">
        <path
          d="M18.8216 13.7988L1.06901 0.102832C1.02032 0.064359 0.964421 0.0357272 0.904516 0.0185717C0.844612 0.0014162 0.78187 -0.00392711 0.719872 0.00284685C0.657875 0.00962081 0.597837 0.0283794 0.543186 0.0580516C0.488535 0.0877237 0.440341 0.127728 0.401356 0.175781L0.10449 0.541699C0.0654136 0.589769 0.0363254 0.644971 0.0188899 0.704147C0.00145439 0.763322 -0.00398613 0.82531 0.00287964 0.886563C0.00974541 0.947816 0.0287827 1.00713 0.0589022 1.06112C0.0890216 1.1151 0.129632 1.16269 0.17841 1.20117L17.931 14.8972C17.9797 14.9356 18.0356 14.9643 18.0955 14.9814C18.1554 14.9986 18.2181 15.0039 18.2801 14.9972C18.3421 14.9904 18.4022 14.9716 18.4568 14.9419C18.5115 14.9123 18.5597 14.8723 18.5986 14.8242L18.8955 14.4583C18.9346 14.4102 18.9637 14.355 18.9811 14.2959C18.9985 14.2367 19.004 14.1747 18.9971 14.1134C18.9903 14.0522 18.9712 13.9929 18.9411 13.9389C18.911 13.8849 18.8704 13.8373 18.8216 13.7988ZM8.81097 4.29111L12.8124 7.37842C12.7465 5.62236 11.2954 4.21875 9.5 4.21875C9.2684 4.21918 9.03748 4.24343 8.81097 4.29111ZM10.189 10.7092L6.18757 7.62188C6.25377 9.37764 7.70485 10.7812 9.5 10.7812C9.73158 10.7808 9.96249 10.7566 10.189 10.7092ZM9.5 3.28125C12.4286 3.28125 15.1134 4.89258 16.5633 7.5C16.2079 8.14171 15.7688 8.73466 15.2571 9.26367L16.3775 10.1279C17.0013 9.46939 17.5297 8.72888 17.9479 7.92744C18.0158 7.79487 18.0512 7.6484 18.0512 7.49985C18.0512 7.3513 18.0158 7.20484 17.9479 7.07227C16.3365 3.97236 13.1494 1.875 9.5 1.875C8.4105 1.875 7.37117 2.08008 6.39389 2.42607L7.77165 3.48926C8.33391 3.36328 8.90983 3.28125 9.5 3.28125ZM9.5 11.7188C6.57142 11.7188 3.88686 10.1074 2.43667 7.5C2.79255 6.8583 3.2322 6.26544 3.74436 5.73662L2.62399 4.87236C2.00032 5.5308 1.47194 6.27122 1.05387 7.07256C0.985957 7.20513 0.950574 7.3516 0.950574 7.50015C0.950574 7.6487 0.985957 7.79516 1.05387 7.92773C2.66377 11.0276 5.85092 13.125 9.5 13.125C10.5895 13.125 11.6288 12.9185 12.6061 12.5739L11.2284 11.511C10.6661 11.6367 10.0905 11.7188 9.5 11.7188Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1840_4071">
          <rect width="19" height="15" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
