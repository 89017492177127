import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CollectionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M18 5.3C18 5 17.7 4.8 17.4 4.8H3.5C3.2 4.8 2.9 5 2.8 5.3L2 9.5V11.4H2.9V15.7C2.9 16.4 3.5 17 4.2 17H11C11.8 17 12.4 16.4 12.4 15.7V11.4H16.2V16.1C16.2 16.6 16.6 17 17.1 17C17.6 17 18 16.6 18 16.1V11.4H18.9V9.5L18 5.3ZM10.4 15.1H4.8V11.4H10.4V15.1ZM17.3 9.9H3.5V8.9L4.2 6.6C4.3 6.4 4.5 6.3 4.7 6.3H16.1C16.3 6.3 16.6 6.4 16.6 6.6L17.3 8.9V9.9ZM17 2H3.9C3.4 2 2.9 2.4 2.9 2.9C2.9 3.4 3.3 3.8 3.8 3.8H17C17.5 3.8 17.9 3.4 17.9 2.9C17.9 2.4 17.5 2 17 2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
