import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { save, load } from 'redux-localstorage-simple';

import wallet from './wallet/reducer';
import api from './api/reducer';
import user from './user/reducer';
import account from './account/reducer';
import app from './app/reducer';
import transaction from './transaction/reducer';
import popup from './popup/reducer';
import { i18nSlice } from 'modules/i18n/i18nSlice';

// states that are auto persisted to localstorage and reloade
const PERSISTED_KEYS: string[] = ['application', 'settings'];

const i18nPersistConfig = {
  key: 'i18n',
  storage: storage,
};

const i18n = persistReducer(i18nPersistConfig, i18nSlice.reducer);

const store = configureStore({
  reducer: { i18n, wallet, api, user, account, app, transaction, popup },
  middleware: [
    ...getDefaultMiddleware({ thunk: false }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
