import { ReactNode, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import '../../../../src/assets/fonts/fonts.css';
import { mainTheme } from 'modules/theme/mainTheme';
import { useLocale } from 'modules/i18n/useLocale';
import { locales } from '../../i18n';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';

interface IAppBaseProps {
  children: ReactNode;
}

export const AppBase = ({ children }: IAppBaseProps) => {
  const [initDone, setInitDone] = useState(false);
  const { locale } = useLocale();

  useEffect(() => {
    setInitDone(false);
    intl
      .init({
        currentLocale: locale,
        locales,
        fallbackLocale: 'en-US',
      })
      .then(() => {
        setInitDone(true);
      });
  }, [locale]);
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {!initDone ? <QueryLoadingAbsolute /> : children}
    </ThemeProvider>
  );
};
