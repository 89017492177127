import { makeStyles, Theme } from '@material-ui/core';

export const useConfirmStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(4.5, 4, 10, 4),
    backgroundColor: theme.palette.background.default,
  },
  loading: {
    width: 100,
    height:100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-50px',
    marginTop: '-50px'
  },
  network: {
    background: 'rgba(255,255,255, 0.12)',
    height: 46,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(-4.5, -4, 4, -4),
    padding: theme.spacing(0, 4),
    fontSize: 14,
    color: theme.palette.text.secondary,
    '&:before': {
      content: '" "',
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: '#ffbd08',
      marginRight: 8,
    },
  },
  title: {
    fontSize: 24,
    lineHeight: 1.2,
    margin: theme.spacing(8, 0, 8, 0),
  },
  item: {
    borderRadius: 12,
    paddingBottom: theme.spacing(5),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    lineHeight: '24px',
    paddingBottom: theme.spacing(5),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
    marginBottom: theme.spacing(5),
    wordBreak: 'break-all',
    color: theme.palette.text.secondary,
    fontFamily: 'Inter-Medium',
  },
  borderNone: {
    border: 'none',
    marginBottom: 0,
  },
  column: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& $info': {
      marginTop: 10,
    },
  },
  info: {
    color: theme.palette.text.primary,
  },
  gasList: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.spacing(2),
    display: 'flex',
    marginBottom: theme.spacing(6),
    '& p': {
      marginTop: theme.spacing(1),
    }
  },
  gas: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.grey.A700,
    cursor: 'pointer',
    padding: theme.spacing(4, 5),
  },
  currentGas: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: theme.spacing(2),
  },
  error: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
  footer: {
    position: 'fixed',
    width: 'calc(100% - 32px)',
    bottom: 16,
    backgroundColor: theme.palette.background.default,
  },
}));
