import React, { useState, useEffect } from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { CopyIcon } from 'modules/icons/CopyIcon';
import { ReturnIcon } from 'modules/icons/ReturnIcon';
import { LinkIcon } from 'modules/icons/LinkIcon';
import { CorrectIcon } from 'modules/icons/CorrectIcon';

import { shortenAddress } from '../../utils/helper';
import { useHistory } from 'react-router-dom';
import { ToastTooltip } from '../ToastTooltip';

import { useCurrentAccount } from '../../state/account/hooks';
import { useSelectedAsset } from '../../state/app/hooks';
import { CUSTOM_EVMS } from '../../constants/netEnums';
import { getAddressFromAccount } from '../../services/WalletService';
import { getExplorerUrl } from '../../services/AssetServcie';

const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  action: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: 10,
    color: theme.palette.text.primary,
  },
}));

export default function Header(props: any): React.ReactElement {
  const { title, goBack, explorerUrl, address } = props;
  const history = useHistory();
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');

  const selectedAsset = useSelectedAsset();
  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (address) {
      setCurrentAddress(address);
    } else {
      const addr = getAddressFromAccount(
        selectedAsset.chain ?? selectedAsset.tokenSymbol,
        currentAccount,
        CUSTOM_EVMS,
      );
      setCurrentAddress(addr);
    }
  }, [address, selectedAsset, currentAccount]);

  const copyAddress = (addr: string) => {
    navigator.clipboard.writeText(addr);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  const classes = useHeaderStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="h3"
        component="div"
        className={classes.title}
        onClick={() => (goBack ? goBack() : history.goBack())}
      >
        <ReturnIcon style={{ marginRight: 10 }} /> {title}
      </Typography>
      {address && (
        <div className={classes.action}>
          <ToastTooltip
            open={copyTooltipOpen}
            message="Copied!"
            icon={<CorrectIcon />}
          >
            <Button
              variant="outlined"
              startIcon={<CopyIcon />}
              onClick={() => {
                setCopyTooltipOpen(true);
                copyAddress(currentAddress);
              }}
            >
              {shortenAddress(currentAddress)}
            </Button>
          </ToastTooltip>
          <Button
            variant="outlined"
            onClick={() => {
              if (explorerUrl) {
                window.open(`${explorerUrl}/address/${currentAddress}`);
              } else if (
                getExplorerUrl(
                  selectedAsset.chain ?? selectedAsset.tokenSymbol,
                  'address',
                  currentAddress,
                ) !== ''
              ) {
                window.open(
                  getExplorerUrl(
                    selectedAsset.chain ?? selectedAsset.tokenSymbol,
                    'address',
                    currentAddress,
                  ),
                );
              }
            }}
          >
            <LinkIcon />
          </Button>
        </div>
      )}
    </div>
  );
}
