import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ReceiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M5.57202 10.2379L11.31 4.49992L12.2527 5.44258L6.51535 11.1806H11.572V12.5139L4.23869 12.5139L4.23869 5.18058H5.57202L5.57202 10.2379Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
