import LocalMessageDuplexStream from 'post-message-stream'
import log from 'loglevel'
import setupMultiplex from '../controllers/utils/setupMultiplex'
import CloverController from '../controllers/CloverController'
import { createNetworkWithNetwork, ETH_ROPSTEN_NETWORK } from '../constants/netEnums'
import { getIFrameOrigin, isMain } from '../utils/utils'
import store from '../state'
import { showPopup } from '../state/popup/actions'

// todo change when need open popup
function triggerUi(type, payload, request) {
  log.info(`TRIGGERUI:${type}`, payload, request)
  store.dispatch(showPopup({ payload, request: {...request, params: undefined} }))
}

function loadCloverManager() {
  const cloverWebManager = {}

  const cloverController = new CloverController({
    initState: {
      NetworkController: {
        //todo should be change
        provider: createNetworkWithNetwork(ETH_ROPSTEN_NETWORK),
      },
    },
    showUnconfirmedMessage: triggerUi.bind(window, 'showUnconfirmedMessage'),
    unlockAccountMessage: triggerUi.bind(window, 'unlockAccountMessage'),
    showUnapprovedTx: triggerUi.bind(window, 'showUnapprovedTx'),
    openPopup: triggerUi.bind(window, 'bindopenPopup'),
    storeProps: () => {
      const { selectedAddress, wallet } = store.getState()
      return { selectedAddress, wallet }
    },
    storeDispatch: () => store.dispatch,
  })
  const metamaskStream = new LocalMessageDuplexStream({
    name: 'clv_iframe_metamask',
    target: 'clv_inject_metamask',
    targetWindow: window.parent,
  })

  const communicationStream = new LocalMessageDuplexStream({
    name: 'clv_iframe_comm',
    target: 'clv_inject_comm',
    targetWindow: window.parent,
  })

  cloverWebManager.metamaskMux = setupMultiplex(metamaskStream)
  cloverWebManager.communicationMux = setupMultiplex(communicationStream)
  cloverWebManager.communicationMux.setMaxListeners(50)
  cloverWebManager.cloverController = cloverController
  const providerOutStream = cloverWebManager.metamaskMux.getStream('provider')

  cloverController.setupUntrustedCommunication(providerOutStream, getIFrameOrigin())
  return cloverWebManager
}

const cloverWebManager = !isMain ? loadCloverManager() : {}

export default cloverWebManager
