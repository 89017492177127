import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'modules/i18n/intl';
import { NFTPlaceHolderIcon } from 'modules/icons/NFTPlaceHolderIcon';
import { buildNftTx, sendNftTx } from '../../services/nftService';
import Header from 'components/Header';
import { alpha, Button, IconButton, Typography } from '@material-ui/core';
import { LinkIcon } from 'modules/icons/LinkIcon';
import { useCurrentAccount } from '../../state/account/hooks';
import { useSelectedCollection } from '../../state/app/hooks';
import _ from 'lodash';
import { shortenAddress } from '../../utils/helper';
import NFTSendDialog from '../../components/Dialogs/NFTSendDialog';
import NFTSendConfirmDialog from '../../components/Dialogs/NFTSendConfirmDialog';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import { useUserSession } from '../../state/user/hooks';
import * as bip32 from 'bip32';
import { useCollectionDetailStyles } from './useCollectionDetailStyles';
import { SendIcon } from 'modules/icons/SendIcon';
import { combineSignKey } from 'services/loginService';

export default function CollectionDetailComponent() {
  const { nftId } = useParams();
  const userSession = useUserSession();
  const [currentNft, setCurrentNft] = useState({});
  const [showSendDialog, setShowSendDialog] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [selFee, setSelFee] = useState('');
  const [buildTxData, setBuildTxData] = useState({});
  const [showSendNftConfirmDialog, setShowSendNftConfirmDialog] =
    useState(false);
  const currentAccount = useCurrentAccount();
  const selectedCollection = useSelectedCollection();

  const signTxn = (signature, privateKey) => {
    const key = Buffer.from(privateKey, 'hex');
    const child = bip32.fromPrivateKey(key, Buffer.alloc(32), null);
    const sig = child.sign(Buffer.from(signature, 'hex'));
    return sig.toString('hex');
  };

  useEffect(() => {
    if (!selectedCollection) {
      return;
    }
    setCurrentNft(
      _.find(selectedCollection.tokens, token => token.id === nftId),
    );
  }, [selectedCollection, nftId]);

  const classes = useCollectionDetailStyles();

  return (
    <div className={classes.root}>
      <Header title={t('collection-detail.title')} />
      {currentNft && (
        <div className={classes.content}>
          {currentNft.image ? (
            <img
              className={classes.collection}
              src={currentNft.image}
              alt={currentNft.name}
            />
          ) : (
            <NFTPlaceHolderIcon className={classes.collection} />
          )}
          <div className={classes.info}>
            <Typography variant="h1">
              {currentNft.name ??
                `${selectedCollection?.name} # ${currentNft.id}`}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {shortenAddress(selectedCollection?.address)}
            </Typography>
            <Button
              variant="contained"
              size="large"
              fullWidth
              startIcon={<SendIcon />}
              style={{ marginTop: 42, marginBottom: 72 }}
              onClick={() => setShowSendDialog(true)}
            >
              {t('action.send')}
            </Button>
            <Typography variant="body1" className={classes.infoTitle}>
              {t('collection-detail.details.title')}
            </Typography>
            <div className={classes.infoCard}>
              <div className={classes.infoRow}>
                <Typography variant="body1">
                  {t('collection-detail.details.contract-address')}
                </Typography>
                <Typography variant="body1" component="div">
                  {shortenAddress(selectedCollection?.address)}
                  <IconButton
                    onClick={() => {
                      window.open(
                        `${selectedCollection?.network?.blockExplorerURI}/address/${selectedCollection?.address}`,
                      );
                    }}
                  >
                    <LinkIcon style={{ color: `${alpha('#fff', 0.4)}` }} />
                  </IconButton>
                </Typography>
              </div>
              <div className={classes.infoRow}>
                <Typography variant="body1">
                  {t('collection-detail.details.token-id')}
                </Typography>
                <Typography variant="body1">{currentNft.id}</Typography>
              </div>
              <div className={classes.infoRow}>
                <Typography variant="body1">
                  {t('collection-detail.details.blockchain')}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    width={16}
                    height={16}
                    src={selectedCollection?.network?.logoURI}
                    alt=""
                  />
                  <Typography variant="body1" style={{ marginLeft: 6 }}>
                    {selectedCollection?.network?.name}
                  </Typography>
                </Typography>
              </div>
            </div>
            {currentNft.attributes && (
              <div>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('collection-detail.characteristics.title')}
                </Typography>
                <div className={classes.infoCard}>
                  {currentNft.attributes.map((attr, index) => (
                    <div className={classes.infoRow} key={attr.trait_type}>
                      <Typography variant="body1">{attr.trait_type}</Typography>
                      <Typography variant="body1">{attr.value}</Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {currentNft.description && (
              <div>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('collection-detail.description.title')}
                </Typography>
                <div className={classes.infoCard}>
                  <Typography className={classes.infoRow} variant="body1">
                    {currentNft.description}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showSendDialog && (
        <NFTSendDialog
          currentSelectNft={currentNft}
          currentCollection={selectedCollection}
          isOpen={showSendDialog}
          buildTx={async resData => {
            const query = {
              type: 'erc721_transfer',
              data: {
                from: currentAccount.evmAddress,
                to: resData.toAddress,
                tokenAddress: currentNft.address,
                tokenId: currentNft.id,
                networkId: selectedCollection.network.id,
              },
              fee: resData.fee,
            };
            const buildData = await buildNftTx(
              currentAccount.accountId,
              currentAccount.requestPrivateKey,
              query,
            );
            setToAddress(resData.toAddress);
            setSelFee(`${resData.fee.toFixed(6)} ${resData.feeSymbol}`);
            setBuildTxData(buildData);
            setShowSendNftConfirmDialog(true);
          }}
          onClose={() => setShowSendDialog(false)}
        />
      )}
      {showSendNftConfirmDialog && (
        <NFTSendConfirmDialog
          currentSelectNft={currentNft}
          currentCollection={selectedCollection}
          isOpen={showSendNftConfirmDialog}
          toAddress={toAddress}
          selFee={selFee}
          sendTx={async () => {
            let bytes = CryptoJS.AES.decrypt(
              currentAccount.seedWords,
              userSession.publicKey,
            );
            let seedWords = bytes.toString(CryptoJS.enc.Utf8);
            if (currentAccount.isLoginAccount) {
              seedWords = await combineSignKey(
                userSession.sharedA,
                userSession.userId,
                userSession.token,
              );
              if (seedWords === null) {
                throw new Error('Invalid seed words.');
              }
            }
            const ethWallet = ethers.Wallet.fromMnemonic(seedWords);
            const hash = signTxn(
              buildTxData.signatureHash[0],
              ethWallet.privateKey.replace('0x', ''),
            );
            const query = {
              networkId: selectedCollection.network.id,
              serialized: buildTxData.serialized,
              signature: [hash],
            };
            await sendNftTx(
              currentAccount.accountId,
              currentAccount.requestPrivateKey,
              query,
            );
            setShowSendDialog(false);
            setShowSendNftConfirmDialog(false);
          }}
          onClose={() => setShowSendNftConfirmDialog(false)}
        />
      )}
    </div>
  );
}
