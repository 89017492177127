import _ from 'lodash';
import {
  POLKADOT_NETWORKS,
  EVM_NETWORKS,
  SOLANA_NETWORK,
  TRON_NETWORK,
} from '../constants/networks';
import NONE from '../images/networks-logo/token_none.svg';
import { DOT_NETWORK_LIST } from '../constants/apiNetworks';
import { getTokenList } from '../utils/helper';
import { Account } from '../state/account/types';
import {
  useAccounts,
  useAccountsUpdate,
  useCurrentAccount,
  useCurrentAccountUpdate,
} from '../state/account/hooks';
import { saveStoreToLocal } from './LocalstorageService';
import { useCallback } from 'react';
import { mergeAccounts } from './AccountService';

export const mainAssets = [
  'CLV',
  'SKU',
  'DOT',
  'ETH',
  'BNB',
  'FTM',
  'KSM',
  'HT',
  'EDG',
  'XDAI',
  'MATIC',
  'OKT',
  'SOL',
  'CRU',
  'TRX',
  'KAR',
  'SDN',
  'PHA',
  'MOVR',
  'BNC',
  'KILT',
  'KMA',
];
export const EthFamilyDecimals = 18;
export const EthNativeGasAmount = 21000;

export const getPolkadotNetworkByTokenSymbol = (tokenSymbol: string) => {
  return DOT_NETWORK_LIST.find(n => n.unit === tokenSymbol);
};

export function getMainTokenLogo(symbol: string) {
  if (!symbol) {
    return NONE;
  }
  if (SOLANA_NETWORK.symbol === symbol) {
    return SOLANA_NETWORK.logo;
  } else if (TRON_NETWORK.symbol === symbol) {
    return TRON_NETWORK.logo;
  }
  const ret = [...EVM_NETWORKS, ...POLKADOT_NETWORKS].find(
    n => n.symbol.toLowerCase() === symbol.toLowerCase(),
  );
  return ret && ret.logo ? ret.logo : NONE;
}

export const polkadotAsset = (asset: any) => {
  return (
    !asset.contractAddress &&
    !_.isEmpty(getPolkadotNetworkByTokenSymbol(asset.tokenSymbol))
  );
};

export const getTokenPrice = (marketPrice: number, amount: string) => {
  const amountRMB = parseFloat(amount) * marketPrice;
  return !isNaN(amountRMB) ? amountRMB : 0;
};

export const getConfigTokenLogo = (address: string, chain: string) => {
  const allTokens = _.mapKeys(
    _.pickBy(
      getTokenList(),
      token => token.chain.toLowerCase() === chain.toLowerCase(),
    ),
    (value, key) => key.toLowerCase(),
  );
  const token = allTokens[address.toLowerCase()];
  if (token && token.logo !== '') {
    return `images/tokens/${token.chain.toLowerCase()}/${token.logo}`;
  }
  return 'images/networks-logo/token_none.svg';
};

export function getNetBySymbol(symbol: string) {
  if (!symbol) {
    return 'Unknown Net';
  }
  if (SOLANA_NETWORK.symbol === symbol) {
    return SOLANA_NETWORK.label;
  } else if (TRON_NETWORK.symbol === symbol) {
    return TRON_NETWORK.label;
  }
  const ret = [...EVM_NETWORKS, ...POLKADOT_NETWORKS].find(
    n => n.symbol.toLowerCase() === symbol.toLowerCase(),
  );
  return ret && ret.label ? ret.label : 'Unknown Net';
}

export const getExplorerUrl = (symbol: string, type: string, addr: string) => {
  if (SOLANA_NETWORK.symbol === symbol) {
    const solUrl =
      type === 'address'
        ? SOLANA_NETWORK.addressExplorer
        : SOLANA_NETWORK.transferExplorer;
    if (solUrl) {
      return `${solUrl}${addr}`;
    }
    return '';
  } else if (TRON_NETWORK.symbol === symbol) {
    const trxUrl = type === 'address'
      ? TRON_NETWORK.addressExplorer
      : TRON_NETWORK.transferExplorer;
    if (trxUrl) {
      return `${trxUrl}${addr}`;
    }
    return '';
  }
  const network: any = [...EVM_NETWORKS, ...POLKADOT_NETWORKS].find(
    c => c.symbol.toLowerCase() === symbol.toLowerCase(),
  );
  if (!network) {
    return '';
  }
  switch (type) {
    case 'address':
      return network.addressExplorer ? `${network.addressExplorer}${addr}` : '';
    case 'token':
      return network.tokenExplorer ? `${network.tokenExplorer}${addr}` : '';
    case 'transfer':
      return network.transferExplorer
        ? `${network.transferExplorer}${addr}`
        : '';
  }
};

export function useAccountHiddenAssetsUpdate(): (
  address: string,
  hiddenAssets: Array<string>,
) => Account | null {
  const updateAccounts = useAccountsUpdate();
  const accounts = useAccounts();
  const currentAccount = useCurrentAccount();
  const updateCurrentAccount = useCurrentAccountUpdate();

  return useCallback(
    (address: string, hiddenAssets: Array<string>) => {
      if (_.isEmpty(address)) {
        return null;
      }
      let newAccount;
      if (currentAccount.address === address) {
        newAccount = { ...currentAccount, ...{ hiddenAssets } };
      }

      updateAccounts(mergeAccounts(accounts, newAccount));
      updateCurrentAccount(newAccount);
      saveStoreToLocal();
      return newAccount;
    },
    [accounts, currentAccount, updateAccounts, updateCurrentAccount],
  );
}

export function useAccountContractAssetsUpdate(): (
  address: string,
  contractAssets: Array<object>,
) => Account | null {
  const updateAccounts = useAccountsUpdate();
  const accounts = useAccounts();
  const currentAccount = useCurrentAccount();
  const updateCurrentAccount = useCurrentAccountUpdate();

  return useCallback(
    (address: string, contractAssets: Array<object>) => {
      if (_.isEmpty(address)) {
        return null;
      }
      let newAccount;
      if (currentAccount.address === address) {
        newAccount = { ...currentAccount, ...{ contractAssets } };
      }

      updateAccounts(mergeAccounts(accounts, newAccount));
      updateCurrentAccount(newAccount);
      saveStoreToLocal();
      return newAccount;
    },
    [accounts, currentAccount, updateAccounts, updateCurrentAccount],
  );
}
