import axios from 'axios';
import { userSession } from '../state/user/actions'
import { emptyUserSession } from '../state/user/reducer'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { logoutWallet } from './logoutService';

export function useSetInterceptor(): () => void {
  const dispatch = useDispatch()
  const history = useHistory()

  return useCallback(() => {
    axios.interceptors.response.use(response => {
        return response;
      }, error => {
      console.log(error)
      if (error.response.status === 401) {
        dispatch(userSession({ userSession: emptyUserSession }));
        logoutWallet(history)
      }

      return Promise.reject(error);
    });
  }, [dispatch, history])
}
