import { t } from 'modules/i18n/intl';
import { Button } from '@material-ui/core';
import { GoogleIcon } from 'modules/icons/socialIcon/GoogleIcon';
import { FacebookIcon } from 'modules/icons/socialIcon/FacebookIcon';
import { TwitterIcon } from 'modules/icons/socialIcon/TwitterIcon';
import { DiscordLogoIcon } from 'modules/icons/socialIcon/DiscordLogoIcon';
import { useLoginStyles } from './useLoginStyles';

interface ISocialAccountProps {
  handleGoogleLogin: () => void;
  hanldeFaceBookLogin: () => void;
  handleTwitterLogin: () => void;
  handleDiscordLogin: () => void;
}

export const SocialAccount = ({
  handleGoogleLogin,
  hanldeFaceBookLogin,
  handleTwitterLogin,
  handleDiscordLogin,
}: ISocialAccountProps) => {
  const classes = useLoginStyles();

  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        fullWidth
        onClick={handleGoogleLogin}
        className={classes.googleButton}
      >
        <GoogleIcon />
        <span>{t('authorization.google-login')}</span>
      </Button>
      <div className={classes.social}>
        <Button
          variant="outlined"
          size="large"
          className={classes.iconButton}
          onClick={hanldeFaceBookLogin}
        >
          <FacebookIcon />
        </Button>
        <Button
          variant="outlined"
          size="large"
          className={classes.iconButton}
          onClick={handleTwitterLogin}
        >
          <TwitterIcon className={classes.twitterIcon} />
        </Button>
        <Button
          variant="outlined"
          size="large"
          className={classes.iconButton}
          onClick={handleDiscordLogin}
        >
          <DiscordLogoIcon />
        </Button>
      </div>
    </div>
  );
};
