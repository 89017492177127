import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TwitterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 25">
      <path
        d="M29.0538 3.3416C27.9877 3.83098 26.8533 4.15233 25.6904 4.2944C26.9219 3.52782 27.8367 2.33779 28.2662 0.944C27.1194 1.64791 25.8596 2.14456 24.5435 2.4116C24.0024 1.81008 23.3426 1.3293 22.6065 1.0001C21.8703 0.670899 21.074 0.500557 20.2688 0.5C17.0351 0.5 14.4129 3.212 14.4129 6.5588C14.4129 7.034 14.4628 7.4948 14.5628 7.9388C9.69434 7.6856 5.37795 5.2748 2.48687 1.6064C1.96556 2.53607 1.69252 3.58634 1.6945 4.6544C1.6945 6.7556 2.72958 8.6108 4.30004 9.698C3.36797 9.66672 2.45744 9.40722 1.64691 8.942V9.0164C1.64691 11.9528 3.66591 14.402 6.3464 14.9564C5.84483 15.0998 5.32582 15.1717 4.80449 15.17C4.42734 15.17 4.05852 15.134 3.7016 15.062C4.44638 17.4692 6.60933 19.2212 9.17323 19.268C7.96817 20.2515 6.57941 20.9806 5.08946 21.412C3.59951 21.8435 2.03878 21.9685 0.5 21.7796C3.16491 23.5534 6.28733 24.4992 9.48019 24.5C20.2569 24.5 26.1485 15.2696 26.1485 7.262C26.1485 6.998 26.1425 6.7352 26.133 6.4772C27.2776 5.6228 28.271 4.5572 29.0538 3.3416Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
