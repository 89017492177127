import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 86 86">
      <path
        d="M49.75 61.2857C49.1533 61.2857 48.581 61.0449 48.159 60.6162C47.7371 60.1876 47.5 59.6062 47.5 59V33.8571C47.5 33.2509 47.7371 32.6696 48.159 32.2409C48.581 31.8122 49.1533 31.5714 49.75 31.5714C50.3467 31.5714 50.919 31.8122 51.341 32.2409C51.7629 32.6696 52 33.2509 52 33.8571V59C52 59.6062 51.7629 60.1876 51.341 60.6162C50.919 61.0449 50.3467 61.2857 49.75 61.2857ZM36.25 61.2857C35.6533 61.2857 35.081 61.0449 34.659 60.6162C34.2371 60.1876 34 59.6062 34 59V33.8571C34 33.2509 34.2371 32.6696 34.659 32.2409C35.081 31.8122 35.6533 31.5714 36.25 31.5714C36.8467 31.5714 37.419 31.8122 37.841 32.2409C38.2629 32.6696 38.5 33.2509 38.5 33.8571V59C38.5 59.6062 38.2629 60.1876 37.841 60.6162C37.419 61.0449 36.8467 61.2857 36.25 61.2857ZM72.25 22.4286H61V17.8571C61 14.0766 57.9963 11 54.3018 11H31.75C28.0285 11 25 14.0766 25 17.8571V22.4286H13.75C13.1533 22.4286 12.581 22.6694 12.159 23.098C11.7371 23.5267 11.5 24.1081 11.5 24.7143C11.5 25.3205 11.7371 25.9019 12.159 26.3305C12.581 26.7592 13.1533 27 13.75 27H72.25C72.8467 27 73.419 26.7592 73.841 26.3305C74.2629 25.9019 74.5 25.3205 74.5 24.7143C74.5 24.1081 74.2629 23.5267 73.841 23.098C73.419 22.6694 72.8467 22.4286 72.25 22.4286ZM29.5 17.8571C29.5 16.5977 30.5103 15.5714 31.75 15.5714H54.3018C55.5348 15.5714 56.5 16.5749 56.5 17.8571V22.4286H29.5V17.8571ZM58.759 75H27.2545C23.533 75 20.5045 71.9234 20.5045 68.1429V33.8229C20.5045 33.2166 20.7416 32.6353 21.1635 32.2066C21.5855 31.778 22.1578 31.5371 22.7545 31.5371C23.3512 31.5371 23.9235 31.778 24.3455 32.2066C24.7674 32.6353 25.0045 33.2166 25.0045 33.8229V68.1429C25.0045 69.4046 26.0147 70.4286 27.2545 70.4286H58.759C59.3557 70.4286 59.928 70.1878 60.35 69.7591C60.7719 69.3305 61.009 68.7491 61.009 68.1429V33.9166C61.009 33.3104 61.2461 32.729 61.668 32.3003C62.09 31.8717 62.6623 31.6309 63.259 31.6309C63.8557 31.6309 64.428 31.8717 64.85 32.3003C65.2719 32.729 65.509 33.3104 65.509 33.9166V68.1429C65.509 71.9234 62.4805 75 58.759 75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
