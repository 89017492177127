import { makeStyles, Theme } from '@material-ui/core';

export const useWalletStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(12),
    paddingLeft: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(12),
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  gridHead: {
    display: 'grid',
    gridTemplateColumns: '30% 30% 25% 15%',
    alignItems: 'center',
    borderBottom: '1px solid rgba(28, 40, 51, 0.8)',
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 4),
  },
  gridBody: {
    height: `calc(100vh - ${theme.spacing(47)}px)`,
    overflow: 'auto',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '30% 30% 25% 15%',
    alignItems: 'center',
    borderBottom: '1px solid rgba(28, 40, 51, 0.8)',
    height: 72,
    padding: theme.spacing(0, 4),
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover $copyIcon': {
      display: 'block',
    },
    '&:hover $btns': {
      display: 'flex',
    },
  },
  copyIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
    display: 'none',
  },
  asset: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      marginRight: 14,
    },
  },
  chainLogo: {
    position: 'relative',
    width: 30,
    height: 30,
    marginRight: 14,
    '&  img:last-child': {
      position: 'absolute',
      bottom: 0,
      right: -5,
    },
  },
  btns: {
    position: 'absolute',
    top: 16,
    right: 0,
    alignItems: 'center',
    paddingRight: 20,
    display: 'none',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
}));
