export const SUCCESS = 200;
export const FAILURE = 500;
export const PERIOD = 10;

export const IS_LOCALHOST = process.env.REACT_APP_ENV === 'localhost';

export const IS_DEV = process.env.REACT_APP_ENV === 'dev' || IS_LOCALHOST;

export const GOOGLE_CLIENT_ID = IS_DEV
  ? '924876871804-5v389u9i8ufge0ugqlug6rdpmksfi132.apps.googleusercontent.com'
  : '745435757666-cumnkk00k5vepfia5mudrbkc9t1o4psk.apps.googleusercontent.com';
