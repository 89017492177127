import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M8.07409 8.7071C8.46462 8.31657 8.46462 7.68341 8.07409 7.29288L5.95253 5.17132C5.69222 4.91101 5.69222 4.48897 5.95253 4.22866V4.22866C6.21284 3.96835 6.63489 3.96835 6.8952 4.22866L9.95943 7.29288C10.35 7.68341 10.35 8.31657 9.95943 8.7071L6.8952 11.7713C6.63489 12.0316 6.21284 12.0316 5.95253 11.7713V11.7713C5.69222 11.511 5.69222 11.089 5.95253 10.8287L8.07409 8.7071Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
