import BN from 'bn.js';
import { ethers } from "ethers";
import {BigNumber} from "bignumber.js";
import _ from 'lodash'
import { getMulChainURL } from '../constants/netEnums'
import {getApiByToken, getCloverApi, getPolkadotDecimals} from './DotApiService'
import {toBigNumber, toRealNumber} from '../utils/numberUtils'
import {getContractAtAddress} from "../utils/tokenUtils";
import {getGasResult} from "./externalService";

const HttpProvider = require('ethjs-provider-http');
const DEFAULT_GAS_LIMIT = 1000000;

export const getPolkadotFee = async (from, to, amount, token) => {
  try {
    const api = await getApiByToken(token);
    const decimals = await getPolkadotDecimals(token)
    const amountBnStr = toBigNumber(amount, decimals).toFixed()
    const result = await api.tx.balances.transfer(to ?? from, new BN(amountBnStr)).paymentInfo(from);
    return { totalFee: result.partialFee.toString()};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getPolkadotFee', error);
    return { totalFee: '0' };
  }
}

let gasInfo = {}
let gasResult = {}
export const getGasInfo = async (tokenSymbol, customEvms) => {
  if (gasInfo[tokenSymbol]) {
    return gasInfo[tokenSymbol]
  }

  const chainUrl = getMulChainURL(tokenSymbol, customEvms);
  const ethersProvider = new ethers.providers.Web3Provider(new HttpProvider(chainUrl));
  const gas = await ethersProvider.getGasPrice();
  gasInfo[tokenSymbol] = gas.toNumber()
  return gasInfo[tokenSymbol]
}

export const getWeb3GasLimit = async ({ chain, from, to, amount, tokenAddress, decimal }) => {
  try{
    const contract = getContractAtAddress(tokenAddress, chain)
    const sendValue = (new BigNumber(amount === '' ? 0 : amount).times(new BigNumber(Math.pow(10, decimal)))).toFixed(0);
    const transferFun = contract.methods['transfer']
    const gasInfo = await transferFun(to === '' ? from : to, sendValue).estimateGas({ from: from })
    return parseInt(gasInfo * 1.1)
  } catch (e) {
    console.error(e)
    return DEFAULT_GAS_LIMIT;
  }
}

export const getCrossTxnFee = async ({network, crossChainToChain}) => {
  const api = await getCloverApi();
  const crossTrxFee = await api.query.cloverClaims.bridgeFees(network);
  const crossTrxFeeList = _.map(crossTrxFee, fee => {
    return parseFloat(fee.toString()) / Math.pow(10, 18)
  })
  if (crossChainToChain === 'CLV') {
    return crossTrxFeeList[0]
  } else {
    return crossTrxFeeList[1]
  }
}

export const getEthFee = async (gasLimit) => {
  let txnFee = {
    gasList: [],
    feeSelected: {}
  }
  try {
    if (_.isEmpty(gasResult)) {
      const result = await getGasResult()
      gasResult = result.gasResult
    }

    const slowFee = gasResult.levels.length > 1 ? _.find(gasResult.levels, level => level.level === 'Slow') : _.find(gasResult.levels, level => level.level === 'Normal')
    const averageFee =_.find(gasResult.levels, level => level.level === 'Normal')
    const fastFee = gasResult.levels.length > 1 ? _.find(gasResult.levels, level => level.level === 'Fast') : _.find(gasResult.levels, level => level.level === 'Normal')
    const gasList = [
      {
        level: 'Slow',
        gasPrice: slowFee.gasPrice,
        feeBn: slowFee.gasPrice * gasLimit,
        fee: toRealNumber(slowFee.gasPrice * gasLimit, 18).toFixed(6),
        total: toRealNumber(gasResult.usdPrice * slowFee.gasPrice * gasLimit, 18).toFixed(2)
      }, {
        level: 'Average',
        gasPrice: averageFee.gasPrice,
        feeBn: averageFee.gasPrice * gasLimit,
        fee: toRealNumber(averageFee.gasPrice * gasLimit, 18).toFixed(6),
        total: toRealNumber(gasResult.usdPrice * averageFee.gasPrice * gasLimit, 18).toFixed(2)
      }, {
        level: 'Fast',
        gasPrice: fastFee.gasPrice,
        feeBn: fastFee.gasPrice * gasLimit,
        fee: toRealNumber(fastFee.gasPrice * gasLimit, 18).toFixed(6),
        total: toRealNumber(gasResult.usdPrice * fastFee.gasPrice * gasLimit, 18).toFixed(2)
      }
    ]

    txnFee = {
      gasList,
      feeSelected: gasList[1]
    }
    return txnFee
  } catch (e) {
    return txnFee
  }
}
