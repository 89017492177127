import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/RouteConfiguration';
import { generatePath, Route } from 'react-router';

const LOGIN_PATH = '/';

export const AuthorizationRoutesConfig: { [key: string]: RouteConfiguration } =
  {
    Login: {
      path: LOGIN_PATH,
      generatePATH: () => generatePath(LOGIN_PATH),
    },
  };

const LoadableLoginContainer: LoadableComponent<any> = loadable(
  async () => import('./screens/Login').then(module => module.Login),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export const AuthorizationRoutes = () => {
  return (
    <Route
      path={AuthorizationRoutesConfig.Login.path}
      exact={true}
      component={LoadableLoginContainer}
    />
  );
};
