import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M9.81038 3.36226C10.5602 1.93768 12.6001 1.93768 13.35 3.36226L21.6173 19.0684C22.3183 20.4003 21.3525 22 19.8475 22H3.31289C1.80783 22 0.842061 20.4003 1.54309 19.0684L9.81038 3.36226Z"
        fill="#FF8A8A"
      />
      <path
        d="M12.5238 8.81818H10.8882L11.0174 16.0717H12.3946L12.5238 8.81818ZM11.7035 19.0945C12.2454 19.0945 12.6978 18.652 12.6978 18.1001C12.6978 17.5582 12.2454 17.1108 11.7035 17.1108C11.1566 17.1108 10.7092 17.5582 10.7092 18.1001C10.7092 18.652 11.1566 19.0945 11.7035 19.0945Z"
        fill="white"
      />
    </SvgIcon>
  );
};
