import axios from 'axios';
import { IS_DEV, IS_LOCALHOST } from '../constants/api';

const baseUrl = !IS_DEV
  ? 'https://web-wallet-api.clover.finance/api/ww'
  : IS_LOCALHOST
  ? 'http://localhost:4500/api/ww'
  : 'https://web-wallet-dev-api.clover.finance/api/ww';
const authBaseUrl = !IS_DEV
  ? 'https://web-wallet-api.clover.finance/auth'
  : IS_LOCALHOST
  ? 'http://localhost:4500/auth'
  : 'https://web-wallet-dev-api.clover.finance/auth';

export function getLoginUrl(provider: string, openId: number) {
  return `${authBaseUrl}/${provider}?openId=${openId}`;
}

function constructUrl(
  path: string,
  userId: number,
  token: string,
  uuid?: string,
) {
  return `${baseUrl}${path}?userId=${userId}&token=${token}&uuid=${uuid}`;
}

export async function googleAuth(googleTokenId: string) {
  const resp = await axios.post(`${baseUrl}/auth/google`, {
    token: googleTokenId,
  });

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data.data;
}

export async function uploadShare(
  cipherShare: string,
  userId: number,
  token: string,
) {
  const resp = await axios.post(constructUrl('/account/share', userId, token), {
    share: cipherShare,
  });

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }
}

export async function uploadDeviceInfo(
  info: string,
  uuid: string,
  userId: number,
  token: string,
) {
  const resp = await axios.post(constructUrl('/device', userId, token), {
    uuid,
    info,
  });

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }
}

export async function getMigrationVerifyRequest(userId: number, token: string) {
  const resp = await axios.get(
    constructUrl(`/migration/verifyRequest/forUser`, userId, token),
  );
  if (!resp.data.success) {
    return null;
  }

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data.data;
}

export async function getDeviceInfoForUser(userId: number, token: string) {
  const resp = await axios.get(constructUrl('/device/forUser', userId, token));
  return resp.data.data;
}

export async function getShare(userId: number, token: string) {
  try {
    const ret = await axios.get(constructUrl('/account/share', userId, token));
    if (ret.data.success) {
      return ret.data.share;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getInfoForLogin(userId: number, token: string) {
  try {
    const ret = await axios.get(
      constructUrl('/account/forLogin', userId, token),
    );
    if (ret.data.success) {
      return ret.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function sendMigrateRequest(
  loginPublickKey: string,
  loginDeviceInfo: string,
  userId: number,
  token: string,
) {
  const resp = await axios.post(constructUrl('/migration', userId, token), {
    loginPublickKey,
    loginDeviceInfo,
  });

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data.data.requestId;
}

export async function getMigrateResult(
  requestId: number,
  userId: number,
  token: string,
) {
  const resp = await axios.get(
    constructUrl(`/migration/${requestId}/result`, userId, token),
  );
  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data.data;
}

export async function getMigrateRecord(
  requestId: number,
  userId: number,
  token: string,
) {
  const resp = await axios.get(
    constructUrl(`/migration/${requestId}`, userId, token),
  );
  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data.data;
}

export async function acceptMigReq(
  paramObj: object,
  userId: number,
  token: string,
) {
  const resp = await axios.post(
    constructUrl('/migration/accept', userId, token),
    paramObj,
  );

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data;
}

export async function rejectMigReq(
  paramObj: object,
  userId: number,
  token: string,
) {
  const resp = await axios.post(
    constructUrl('/migration/reject', userId, token),
    paramObj,
  );

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data;
}

export async function getDeviceAndMigrationRequest(
  userId: number,
  token: string,
  uuid: string,
) {
  const resp = await axios.get(
    constructUrl('/account/deviceAndRequest', userId, token, uuid),
  );

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data;
}

export async function deleteMigrationDevice(
  requestId: number,
  deviceUuid: string,
  userId: number,
  token: string,
) {
  const resp = await axios.post(
    constructUrl(`/migration/${requestId}/delete`, userId, token, deviceUuid),
    {
      deviceUuid: deviceUuid,
    },
  );

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data;
}

export async function deleteDevice(
  deviceId: number,
  userId: number,
  token: string,
) {
  const resp = await axios.post(
    constructUrl(`/device/${deviceId}/delete`, userId, token),
  );

  if (!resp.data.success) {
    throw new Error(resp.data.message);
  }

  return resp.data;
}
