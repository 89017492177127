import { createReducer } from '@reduxjs/toolkit';

import {
  restoreFromStorage,
  enterPageFirstRefresh,
  selectedAsset,
  selectedCollection,
  shareC,
} from './actions'

export interface AppState {
  restoreFromStorage: boolean,
  enterPageFirstRefresh: boolean,
  selectedAsset: any,
  selectedCollection: any,
  shareC: {
    userId?: number,
    shareC?: string
  }
}

const initialState: AppState = {
  restoreFromStorage: false,
  enterPageFirstRefresh: true,
  selectedAsset: {},
  selectedCollection: {},
  shareC: {},
}

export default createReducer(initialState, builder =>
  builder
  .addCase(restoreFromStorage, (state, action) => {
    const { isRestore } = action.payload
    state.restoreFromStorage = isRestore
  })
  .addCase(enterPageFirstRefresh, (state, action) => {
    const { isFirstRefresh } = action.payload
    state.enterPageFirstRefresh = isFirstRefresh
  })
  .addCase(selectedAsset, (state, action) => {
    const { asset } = action.payload
    state.selectedAsset = asset
  })
  .addCase(selectedCollection, (state, action) => {
    const { collection } = action.payload
    state.selectedCollection = collection
  })
  .addCase(shareC, (state, action) => {
    const { share } = action.payload
    state.shareC = share
  })
);
