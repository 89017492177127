import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TelegramIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 18">
      <path
        d="M8.16175 11.5614L7.81768 16.4591C8.30995 16.4591 8.52316 16.2451 8.77883 15.9881L11.0868 13.7559L15.8692 17.3002C16.7463 17.7949 17.3642 17.5344 17.6008 16.4836L20.74 1.59755L20.7409 1.59667C21.0191 0.284535 20.272 -0.228567 19.4174 0.0933279L0.965644 7.24254C-0.293653 7.73722 -0.274586 8.44767 0.751572 8.76957L5.46895 10.2545L16.4265 3.31578C16.9422 2.9702 17.411 3.16141 17.0254 3.50699L8.16175 11.5614Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
