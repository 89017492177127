import { makeStyles, Theme } from '@material-ui/core';

export const useMyAccountStyles = makeStyles<Theme>(theme => ({
  root: {
    marginTop: 52,
    boxSizing: 'content-box',
    height: 48,
    background:
      'linear-gradient(270deg, rgba(243, 245, 247, 0.2) 0%, rgba(246, 247, 249, 0.2) 48.36%, rgba(246, 247, 249, 0.2) 100%)',
    borderRadius: theme.spacing(0, 2, 2, 0),
    padding: theme.spacing(0, 2.5, 0, 7.5),
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    cursor: 'pointer',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  alias: {
    margin: theme.spacing(0, 2.5),
    width: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  myAccount: {
    position: 'absolute',
    top: 0,
    left: 240,
    background: theme.palette.background.paper,
    width: 420,
    maxHeight: 320,
    padding: '28px 30px',
    borderRadius: 12,
    zIndex: 100,
    boxShadow: '0px 20px 28px rgba(28, 40, 51, 0.03)',
  },
  list: {
    maxHeight: 200,
    padding: theme.spacing(0, 5),
    margin: theme.spacing(0, -5),
    overflowY: 'auto',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    padding: theme.spacing(0, 3),
    borderRadius: theme.spacing(2),
    margin: theme.spacing(0, -3),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover $copyIcon': {
      display: 'block',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    '& svg': {
      width: 16,
      height: 16,
      marginRight: theme.spacing(2.5),
    },
  },
  name: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 12,
      height: 12,
      marginLeft: theme.spacing(2),
    },
  },
  address: {
    color: theme.palette.text.secondary,
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
  },
  copyIcon: {
    marginLeft: 8,
    display: 'none',
  },
  balance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
  },
  amount: {
    lineHeight: '22px',
    color: theme.palette.text.secondary,
    marginLeft: '5px',
  },
  action: {
    display: 'flex',
    marginRight: -6,
    '& svg': {
      color: theme.palette.text.secondary,
    },
  },
  importButton: {
    marginTop: theme.spacing(3),
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  facebookIcon: {
    '--start-color': '#C8CBCE',
    '--end-color': '#C8CBCE',
    '--fill-color': '#48525B',
  },
  googleIcon: {
    '--google-color-1': '#C8CBCE',
    '--google-color-2': '#C8CBCE',
    '--google-color-3': '#C8CBCE',
    '--google-color-4': '#C8CBCE',
  },
  discordIcon: {
    '--discord-color': '#C8CBCE',
    '--fill-color': '#48525B',
  },
  twitterIcon: {
    '&': {
      color: '#C8CBCE',
    },
  },
}));
