import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QuestionMarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#FFACAC" />
      <path
        d="M8.83727 12.7571H10.4928V12.6278C10.5028 11.5739 10.8458 11.0916 11.6562 10.5945C12.5709 10.0476 13.1427 9.30185 13.1427 8.16335C13.1427 6.48295 11.8351 5.42898 9.93599 5.42898C8.19593 5.42898 6.784 6.39347 6.73429 8.28267H8.50417C8.54892 7.34801 9.22505 6.90057 9.92605 6.90057C10.6867 6.90057 11.2982 7.40767 11.2982 8.18821C11.2982 8.88423 10.8358 9.36151 10.2343 9.73935C9.35431 10.2862 8.84721 10.8381 8.83727 12.6278V12.7571ZM9.7073 15.8594C10.2939 15.8594 10.7961 15.3722 10.801 14.7656C10.7961 14.169 10.2939 13.6818 9.7073 13.6818C9.10076 13.6818 8.60858 14.169 8.61355 14.7656C8.60858 15.3722 9.10076 15.8594 9.7073 15.8594Z"
        fill="white"
      />
    </SvgIcon>
  );
};
