import React from 'react';
import {
  getConfigTokenLogo,
  getMainTokenLogo,
} from '../../services/AssetServcie';
import { makeStyles, Theme } from '@material-ui/core';

interface TokenLogoWithChainProps {
  tokenAddress: string;
  chain: string;
  crossTokenIcon?: string;
  logoWidth?: string;
  chainWidth?: string;
}

interface NftLogoWithChainProps {
  nftIcon: string;
  chainIcon: string;
  logoWidth?: string;
  chainWidth?: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: (props: any) => ({
    position: 'relative',
    width: props.logoWidth,
    height: props.logoWidth,
    marginRight: 14,
  }),
  logo: (props: any) => ({
    width: props.logoWidth,
    height: props.logoWidth,
  }),
  chainLogo: (props: any) => ({
    position: 'absolute',
    width: `${props.chainWidth} !important`,
    height: `${props.chainWidth} !important`,
    bottom: 0,
    right: -5,
  }),
}));

export default function TokenLogoWithChain({
  logoWidth = '30px',
  chainWidth = '16px',
  tokenAddress,
  chain,
  crossTokenIcon,
}: TokenLogoWithChainProps) {
  const classes = useStyles({
    logoWidth,
    chainWidth,
  });
  const logoName = getConfigTokenLogo(tokenAddress, chain);
  return (
    <div className={classes.root}>
      <img
        className={classes.logo}
        src={crossTokenIcon ?? require(`../../${logoName}`).default}
        alt={logoName}
      />
      <img className={classes.chainLogo} src={getMainTokenLogo(chain)} alt="" />
    </div>
  );
}

export function NftLogoWithChain({
  logoWidth = '30px',
  chainWidth = '16px',
  nftIcon,
  chainIcon,
}: NftLogoWithChainProps) {
  const classes = useStyles({
    logoWidth,
    chainWidth,
  });
  return (
    <div className={classes.root}>
      <img className={classes.logo} src={nftIcon} alt="" />
      <img className={classes.chainLogo} src={chainIcon} alt="" />
    </div>
  );
}
