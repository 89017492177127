import _ from 'lodash'
import { getContractAtAddress } from '../utils/tokenUtils'
import { isErc20Asset } from '../utils/helper'

export const EMPTY_ADDR = '0x0000000000000000000000000000000000000000'
const DEFAULT_SYMBOL = ''
const DEFAULT_DECIMALS = '0'
const DEFAULT_NAME = ''
const DEFAULT_BALANCE = 0

export const isValidAddress = (address: string) => {
    return (/^0x[0-9a-fA-F]{40}$/.test(address))
}

export const addHexPrefix = (str: string) => {
    if (typeof str !== 'string' || str.match(/^-?0x/u)) {
        return str
    }

    if (str.match(/^-?0X/u)) {
        return str.replace('0X', '0x')
    }

    if (str.startsWith('-')) {
        return str.replace('-', '-0x')
    }

    return `0x${str}`
}

export function checkExistingAddresses(address: string, list = [], chain = '') {
    if (!address) {
        return false
    }
    const matchesAddress = (obj: any) => {
        if (chain) {
            return obj.chain === chain && obj.tokenAddress.toLowerCase() === address.toLowerCase()
        }
        return obj.tokenAddress.toLowerCase() === address.toLowerCase()
    }

    return list.some(matchesAddress)
}

export function tokenInfoGetter(chain: string): (tokenAddress: string, address: string) => any {
    let obj = {
        symbol: '',
        decimals: '0',
        name: '',
        balance: 0
    }
    return async (tokenAddress: string, address: string) => {
        if (_.isEmpty(tokenAddress) || _.isEmpty(address)) {
            return null
        }
        obj = await getSymbolAndDecimals(tokenAddress, address, chain)
        return obj
    }
}

export async function getSymbolAndDecimals(tokenAddress: string, address: string, networkOrChain: string) {
    let symbol, decimals, name, balance
    try {
        symbol = await getSymbol(tokenAddress, networkOrChain)
        decimals = await getDecimals(tokenAddress, networkOrChain)
        name = await getName(tokenAddress, networkOrChain)
        balance = isErc20Asset(tokenAddress) ? await getErc20Balance(tokenAddress, address, networkOrChain) : DEFAULT_BALANCE
    } catch (error) {
        console.log(
            `symbol() and decimal() calls for token at address ${tokenAddress} resulted in error:`,
            error,
        )
    }

    return {
        symbol: symbol || DEFAULT_SYMBOL,
        decimals: decimals || DEFAULT_DECIMALS,
        name: name || DEFAULT_NAME,
        balance: balance,
    }
}

async function getSymbol(tokenAddress: string, chain: string) {
    let symbol = await getSymbolFromContract(tokenAddress, chain)
    return symbol
}

async function getSymbolFromContract(tokenAddress: string, chain: string) {
    const contract = getContractAtAddress(tokenAddress, chain)
    try {
        const fun = contract.methods['symbol']
        const result = await fun().call()
        return result
    } catch (error) {
        console.log(
            `symbol() call for token at address ${tokenAddress} resulted in error:`,
            error,
        )
        return undefined
    }
}

async function getDecimals(tokenAddress: string, chain: string) {
    let decimals = await getDecimalsFromContract(tokenAddress, chain)
    return decimals
}

async function getDecimalsFromContract(tokenAddress: string, chain: string) {
    const contract = getContractAtAddress(tokenAddress, chain)
    try {
        const fun = contract.methods['decimals']
        const result = await fun().call()
        return result
    } catch (error) {
        console.log(
            `decimals() call for token at address ${tokenAddress} resulted in error:`,
            error,
        )
        return undefined
    }
}

async function getName(tokenAddress: string, chain: string) {
    let name = await getNameFromContract(tokenAddress, chain)
    return name
}

async function getNameFromContract(tokenAddress: string, chain: string) {
    const contract = getContractAtAddress(tokenAddress, chain)
    try {
        const fun = contract.methods['name']
        const result = await fun().call()
        return result
    } catch (error) {
        console.log(
            `name() call for token at address ${tokenAddress} resulted in error:`,
            error,
        )
        return undefined
    }
}

export async function getErc20Balance(tokenAddress: string, address: string, chain: string) {
    const contract = getContractAtAddress(tokenAddress, chain)
    try {
        const fun = contract.methods['balanceOf']
        const result = await fun(address).call()
        return result
    } catch (error) {
        console.log(
            `balance() call at address ${address} resulted in error:`,
            error,
        )
        return undefined
    }
}
