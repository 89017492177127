import { useCallback } from 'react';
import { useTransactionsUpdate } from '../state/transaction/hooks';
import { saveStoreToLocal } from './LocalstorageService';
import { useUpdateBalance } from './BalanceWatcherServicer';
import { CUSTOM_EVMS, getNetByChainId } from '../constants/netEnums';
import { getCloverApi } from './DotApiService';
import { ethers } from 'ethers';
import { getCrossChainItemByChainPair } from '../utils/crossChain';
import { combineSignKey } from './loginService';
import { useUserSession } from '../state/user/hooks';
import store from '../state';
import { getSignature, host } from './AccountService';
import axios from 'axios';

const dayjs = require('dayjs');
const ethUtil = require('ethereumjs-util');
const { decodeAddress } = require('@polkadot/util-crypto');
var CryptoJS = require('crypto-js');
export enum TransactionStatus {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAIL = 'Failed',
}

export const updateTransactionObj = (
  transaction: any,
  txnHash: string,
  txnStatus: TransactionStatus,
) => ({
  ...transaction,
  status: txnStatus,
  date: dayjs().format(),
  txnHash,
});

export const mergeTransactions = (
  newTransaction: any,
  transactions: Array<any>,
) => {
  // remove Pending duplicate TXN and overide with new Status
  const pendingTransactionIndex = transactions.findIndex(
    (x: any) =>
      x.txnHash === newTransaction.txnHash ||
      (newTransaction.nonce && x.nonce === newTransaction.nonce),
  );
  if (pendingTransactionIndex > -1) {
    transactions.splice(pendingTransactionIndex, 1, newTransaction);
    return transactions;
  } else {
    return [newTransaction, ...transactions];
  }
};

export function useTransactionStateUpdate(): (
  transaction: any,
  txnHash: string,
  txnStatus: TransactionStatus,
) => void {
  const transactonsUpdate = useTransactionsUpdate();

  return useCallback(
    (transaction: any, txnHash: string, txnStatus: TransactionStatus) => {
      const { transactions } = store.getState().transaction;
      const newTransaction = updateTransactionObj(
        transaction,
        txnHash,
        txnStatus,
      );
      let newTransactionArr = mergeTransactions(newTransaction, [
        ...transactions,
      ]);
      transactonsUpdate(newTransactionArr);
      saveStoreToLocal();
    },
    [transactonsUpdate],
  );
}

const bytesToHex = (bytes: any) => {
  for (var hex = [], i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16));
    hex.push((bytes[i] & 0xf).toString(16));
  }
  return '0x' + hex.join('');
};

const sign = (dataToSign: string, privateKey: string) => {
  const msg = Buffer.from(dataToSign);
  const msgHash = ethUtil.hashPersonalMessage(msg);
  const sig = ethUtil.ecsign(
    msgHash,
    new Buffer(privateKey.replace('0x', ''), 'hex'),
  );
  return ethUtil.toRpcSig(sig.v, sig.r, sig.s);
};

export function useClaimClv(): (
  transaction: any,
  web3: any,
  toAddress: string,
  txnHash: string,
) => any {
  const updateTransactionState = useTransactionStateUpdate();
  const userSession = useUserSession();
  const updateBalance = useUpdateBalance();

  return async (
    transaction: any,
    web3: any,
    toAddress: string,
    txnHash: string,
  ) => {
    const { currentAccount } = store.getState().account;
    let bytes = CryptoJS.AES.decrypt(
      currentAccount.seedWords,
      userSession.publicKey,
    );
    let seedWords = bytes.toString(CryptoJS.enc.Utf8);
    if (currentAccount.isLoginAccount) {
      seedWords = await combineSignKey(
        userSession.sharedA,
        userSession.userId,
        userSession.token,
      );
      if (seedWords === null) {
        throw new Error('Invalid seed words.');
      }
    }
    const api = await getCloverApi();
    const eth = ethers.Wallet.fromMnemonic(seedWords);
    const crossChainItem: any = getCrossChainItemByChainPair(
      transaction.crossFrom,
      transaction.crossTo,
    );
    return new Promise(async (resolve, reject) => {
      const data = await api.query.cloverClaims.elasticClaims(
        crossChainItem.chainNameForBg,
        txnHash,
      );
      if (data.isSome && data.value[2].isTrue) {
        // console.log('clv claimed: ', toAddress, txnHash, transaction)
        await updateTransactionState(
          transaction,
          txnHash,
          TransactionStatus.SUCCESS,
        );
        updateBalance('', CUSTOM_EVMS);
        resolve(true);
      } else if (
        !transaction.claimSent &&
        data.isSome &&
        data.value[2].isFalse
      ) {
        const claimAddress = bytesToHex(decodeAddress(toAddress));
        const signature = sign(
          `Pay CLVs to the Clover account:${claimAddress.slice(
            2,
          )}${txnHash.slice(2)}`,
          eth.privateKey,
        );

        const signedTransaction = api.tx.cloverClaims.claimElastic(
          crossChainItem.chainNameForBg,
          toAddress,
          txnHash,
          signature,
        );
        await signedTransaction.send();
        await updateTransactionState(
          { ...transaction, claimSent: true },
          txnHash,
          TransactionStatus.PENDING,
        );
        // console.log('clv claim requested, waiting for claim: ', toAddress, txnHash, transaction)
        resolve(false);
      } else {
        // console.log('clv claim info not on chain yet: ', toAddress, txnHash, transaction)
        resolve(false);
      }
    });
  };
}

export async function getTransactions(
  currentAccount: any,
  assetId: string,
  requestPrivateKey: string,
  selectedAsset: any,
) {
  const val = `get|/api/wallets/${currentAccount.accountId}/txs?assetId=${assetId}|{}`;
  const signature = getSignature(val, requestPrivateKey);
  const headers = {
    'X-Signature': signature,
    'X-Identity': currentAccount.accountId,
  };

  try {
    const resp = await axios.get(
      `${host}wallets/${currentAccount.accountId}/txs?assetId=${assetId}`,
      {
        headers: headers,
      },
    );

    if (resp.status !== 200) {
      return [];
    }
    const filterReceiveData = resp.data.filter((item: any) => {
      return (
        item.instructions[0].data.to.toLowerCase() ===
        selectedAsset.address.toLowerCase()
      );
    });
    const resultArr: any = [];
    filterReceiveData.forEach((item: any) => {
      const data = item.instructions[0].data;
      const resultObj = {
        isReceive: true,
        receiveType: item.type,
        tokenSymbol: selectedAsset.tokenSymbol,
        to: data.to,
        from: data.from,
        date: dayjs(item.time).format(),
        amount: data.quantity,
        status: getStatus(item.type, item.status, item.height),
        txnHash: item.txid,
        networkSymbol: getNetworkSymbol(
          currentAccount,
          data.assetId,
          selectedAsset,
        ),
      };
      resultArr.push(resultObj);
    });
    return resultArr;
  } catch (e) {
    return [];
  }
}

const getNetworkSymbol = (
  currentAccount: any,
  assetId: number,
  selectedAsset: any,
) => {
  let symbol = null;
  currentAccount.queryAssets.forEach((item: any) => {
    if (item.id === assetId) {
      if (!item.token) {
        symbol = item.symbol;
      } else {
        symbol = getNetByChainId(
          `0x${parseInt(item.network.params.chainId).toString(16)}`,
          CUSTOM_EVMS,
        ).ticker;
      }
    }
  });
  return symbol;
};
const getStatus = (type: string, status: number, height: number) => {
  let str = '';
  if (type === 'crossChain') {
    if (status === 2) {
      str = 'Success';
    } else if (status === 0) {
      str = 'Failed';
    } else {
      str = 'Pending';
    }
  } else {
    if (height === -1) {
      str = 'Pending';
    } else {
      if (status === 1) {
        str = 'Success';
      } else {
        str = 'Failed';
      }
    }
  }
  return str;
};
