import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CorrectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="-6 -4 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.765 2.17063L4.71447 9.84786C4.55779 10.0045 4.40111 10.1612 4.08776 10.1612C3.93108 10.1612 3.61772 10.0045 3.46105 9.84786L0.170806 5.9309C-0.14255 5.61755 0.0141278 5.14751 0.327484 4.83416C0.484162 4.67748 0.64084 4.67748 0.797518 4.67748C0.954196 4.67748 1.26755 4.83416 1.26755 4.99084L4.08776 8.1244L10.6682 1.07388C10.9816 0.760528 11.4516 0.760528 11.765 1.07388C12.0783 1.38724 12.0783 1.85728 11.765 2.17063Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
