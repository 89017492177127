import React, { useEffect, useState } from 'react';
import { t, tHTML } from 'modules/i18n/intl';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { DialogModal } from 'components/DialogModal';
import {
  LoginResult,
  loginFromBackend,
  loginForPopup,
} from '../../services/loginService';
import { CUSTOM_EVMS, getNetByChainId, LoginType } from '../../constants/netEnums';
import { SocialAccount } from 'pages/login/SocialAccount';
import { useLoginStyles } from 'pages/login/useLoginStyles';
import { SelectField } from 'components/SelectField/SelectField';
import { EVM_NETWORKS } from 'constants/networks';
import cloverWebManager from 'services/CloverWebManager';
import { useRequestChainName } from '../../state/popup/hooks';
import { InputField } from 'components/InputField/InputField';
import { ArrowRightIcon } from 'modules/icons/ArrowRightIcon';

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    borderColor: theme.palette.grey.A700,
    marginBottom: theme.spacing(5),
    backgroundColor: 'transparent',
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.grey.A700,
    },
  },
}));
interface PopupLoginDialogProps {
  isOpen: boolean;
  onClose: (state: string) => void;
  onLoginSuccess: (result: LoginResult) => void;
}

const PopupLoginDialog = ({
  isOpen,
  onClose,
  onLoginSuccess,
}: PopupLoginDialogProps) => {
  const [selectNetwork, setSelectNetwork] = useState<any>(EVM_NETWORKS[0]);
  const customOutlinedStyles = useOutlinedInputStyles()
  const requestChainName = useRequestChainName()

  useEffect(() => {
    const states = (cloverWebManager as any).cloverController.getProviderNetworkState()
    const evm = getNetByChainId(states.chainId, CUSTOM_EVMS)
    const network = EVM_NETWORKS.find(x => x.symbol === evm?.ticker)
    network && setSelectNetwork(network)
    console.log(states)
  }, [])

  const onDismiss = () => {
    onClose('cancel');
  };

  const onSuccess = async (res: any) => {
    try {
      onLoginSuccess(res);
      onClose('success');
    } catch (e) {
      console.log(e);
    }
  };
  const onFailure = (error: any) => {
    console.log(error);
    onClose('failed');
  };
  const classes = useLoginStyles();

  return (
    <DialogModal
      title={t('popup-dialog.title')}
      open={isOpen}
      onClose={onDismiss}
      maxWidth="xs"
    >
      <SocialAccount
        handleGoogleLogin={() => {
          loginFromBackend(
            LoginType.GOOGLE,
            loginForPopup,
            onSuccess,
            onFailure,
          );
        }}
        hanldeFaceBookLogin={() => {
          loginFromBackend(
            LoginType.FACEBOOK,
            loginForPopup,
            onSuccess,
            onFailure,
          );
        }}
        handleTwitterLogin={() => {
          loginFromBackend(
            LoginType.TWITTER,
            loginForPopup,
            onSuccess,
            onFailure,
          );
        }}
        handleDiscordLogin={() => {
          loginFromBackend(
            LoginType.DISCORD,
            loginForPopup,
            onSuccess,
            onFailure,
          );
        }}
      />
      {
        requestChainName &&
        <div>
          <Typography variant="body1" className={classes.otherLogin}>
            {t('popup-dialog.other-login')}
            <ArrowRightIcon style={{ transform: 'rotate(90deg)' }} />
          </Typography>
          <InputField
            placeHolder={t('popup-dialog.enter-email-placholder')}
            disabled
            value={''}
          />
          <Button variant="contained" size="large" fullWidth disabled>
            {t('popup-dialog.continue-with-email')}
          </Button>
          <Typography variant="body2" className={classes.note}>
            {tHTML('popup-dialog.note')}
          </Typography>
        </div>
      }
      {
        !requestChainName &&
        <div>
          <div className={classes.networksMarginTop}></div>
          <SelectField
              label={t('add-custom-token-dialog.network')}
              value={selectNetwork}
              options={EVM_NETWORKS.map(network => ({
                label: network.label,
                icon: <img src={network.logo} alt={network.label} />,
                value: network,
              }))}
              customOutlinedStyles={customOutlinedStyles}
              handleChange={event => {
                const network = event.target.value;
                setSelectNetwork(network);
                const evm: any = (CUSTOM_EVMS as any).find((x: any) => x.ticker === network.symbol);
                (cloverWebManager as any).cloverController.setCustomRpc(evm.rpcUrl, evm.chainId, evm.ticker, evm.nickname);
              }}
            />
          <Typography variant="body2" className={classes.popupNote}>
            {tHTML('popup-dialog.note')}
          </Typography>
        </div>
      }
    </DialogModal>
  );
};

export default PopupLoginDialog;
