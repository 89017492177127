import { useState, useMemo } from 'react';
import {
  Button,
  ClickAwayListener,
  IconButton,
  Typography,
} from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import {
  useAccounts,
  useCurrentAccount,
  useUserSession,
} from 'state/account/hooks';
import { SuccessIcon } from 'modules/icons/SuccessIcon';
import { GoogleIcon } from 'modules/icons/socialIcon/GoogleIcon';
import { FacebookIcon } from 'modules/icons/socialIcon/FacebookIcon';
import { DiscordLogoIcon } from 'modules/icons/socialIcon/DiscordLogoIcon';
import { TwitterIcon } from 'modules/icons/socialIcon/TwitterIcon';
import { AccountIcon } from 'modules/icons/AccountIcon';
import { ArrowRightIcon } from 'modules/icons/ArrowRightIcon';
import { Account } from 'state/account/types';
import { showNewAlias } from 'utils/helper';
import { useMyAccountStyles } from './useMyAccountStyles';
import { CopyIcon } from 'modules/icons/CopyIcon';
import { LinkIcon } from 'modules/icons/LinkIcon';
import { getExplorerUrl } from 'services/AssetServcie';
import { useAccountTotal } from 'services/WalletService';
import { ToastTooltip } from 'components/ToastTooltip';
import { SendIcon } from 'modules/icons/SendIcon';
import { CorrectIcon } from 'modules/icons/CorrectIcon';

interface IAccountProps {
  handleChangeAccount: (account: Account) => void;
  handleImportAccount: () => void;
  logout: () => void;
}

export const MyAccount = ({
  handleChangeAccount,
  handleImportAccount,
  logout,
}: IAccountProps) => {
  const classes = useMyAccountStyles();

  const currentAccount: Account = useCurrentAccount();
  const accounts: Account[] = useAccounts();
  const userSession = useUserSession();
  const totalAccount = useAccountTotal();

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAway = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const [copyIndex, setCopyIndex] = useState(-1);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

  const handleCopy = (e: any, account: Account, index: number) => {
    e.stopPropagation();
    setCopyIndex(index);
    setCopyTooltipOpen(true);
    navigator.clipboard.writeText(account.address);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  const handleLink = (account: Account) => {
    if (getExplorerUrl('CLV', 'address', account.address) !== '') {
      window.open(getExplorerUrl('CLV', 'address', account.address));
    }
  };

  const loginAccountIcon = useMemo(() => {
    const loginType = userSession.loginType;
    if (loginType === 'google') {
      return <GoogleIcon className={classes.googleIcon} />;
    } else if (loginType === 'facebook') {
      return <FacebookIcon className={classes.facebookIcon} />;
    } else if (loginType === 'twitter') {
      return <TwitterIcon className={classes.twitterIcon} />;
    } else if (loginType === 'discord') {
      return <DiscordLogoIcon className={classes.discordIcon} />;
    }
    // eslint-disable-next-line
  }, [userSession]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <div className={classes.account} onClick={handleClick}>
          {currentAccount.isLoginAccount ? loginAccountIcon : <AccountIcon />}
          <Typography className={classes.alias}>
            {showNewAlias(currentAccount.alias)}
          </Typography>
          <ArrowRightIcon />
        </div>
        {open && (
          <div className={classes.myAccount}>
            <Typography variant="subtitle1">{t('my-account.title')}</Typography>
            <div className={classes.list}>
              {accounts.map((account: Account, index: number) => (
                <div
                  key={account.address}
                  className={classes.item}
                  onClick={() => {
                    handleChangeAccount(account);
                  }}
                >
                  <div className={classes.info}>
                    {account.isLoginAccount ? (
                      loginAccountIcon
                    ) : (
                      <AccountIcon />
                    )}
                    <div>
                      <Typography variant="body2" className={classes.name}>
                        {showNewAlias(account.alias)}{' '}
                        {account.address === currentAccount.address && (
                          <span className={classes.amount} style={{}}>
                            {`($${totalAccount().toFixed(2)})`}
                          </span>
                        )}
                        {currentAccount.address === account.address && (
                          <SuccessIcon />
                        )}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.balance}>
                    <div className={classes.action}>
                      <IconButton
                        size="small"
                        onClick={() => handleLink(account)}
                      >
                        <LinkIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              className={classes.importButton}
              onClick={handleImportAccount}
              startIcon={<SendIcon />}
            >
              {t('my-account.import-account')}
            </Button>
            <Button variant="text" size="large" fullWidth onClick={logout}>
              {t('my-account.logout')}
            </Button>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
