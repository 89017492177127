import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { ethers } from 'ethers';
import { formatBalance } from '@polkadot/util';
import { isAddress } from '@ethersproject/address';

import TransferConfirmDialog from '../../components/Dialogs/TransferConfirmDialog';
import TransferComponent, { TransferType } from '../../components/Transfer';
import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useCurrentAccount } from '../../state/account/hooks';
import { useUserSession } from '../../state/user/hooks';
import { INPUT_NUMBER_REGEX } from '../../constants/regex';
import {
  CUSTOM_EVMS,
  getMulChainURL,
  getChainIdBySymbol,
} from '../../constants/netEnums';
import {
  getAddressFromAccount,
  validAddress,
} from '../../services/WalletService';
import {
  getGasInfo,
  getWeb3GasLimit,
  getCrossTxnFee,
  getPolkadotFee,
  getEthFee,
} from '../../services/FeeService';
import { combineSignKey } from '../../services/loginService';
import { EthNativeGasAmount } from '../../services/AssetServcie';
import {
  useTransactionStateUpdate,
  TransactionStatus,
  useClaimClv,
} from '../../services/TransactionService';
import {
  getApiByToken,
  getPolkadotDecimals,
} from '../../services/DotApiService';
import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import {
  getCrossChainItemByChainPair,
  cloverToErc20,
  erc20ToClover,
  sakuraToErc20,
  erc20ToSakura,
} from '../../utils/crossChain';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';
import { logoutWallet } from '../../services/logoutService';

const Web3 = require('web3');
const Tx = require('ethereumjs-tx');
const dayjs = require('dayjs');
const { Keyring } = require('@polkadot/api');
const BRIDGE = require('../../contracts/CloverBridge.json');
const CLOVER = require('../../contracts/CloverToken.json');
var CryptoJS = require('crypto-js');
export default function TransferCrossChainPage() {
  const history = useHistory();
  const [openTransferConfirm, setOpenTransferConfirm] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const [amountErr, setAmountErr] = useState('');
  const [confirming, setConfirming] = useState(false);
  const [gasLimit, setGasLimit] = useState(EthNativeGasAmount);
  const [crossChainTxnFee, setCrossChainTxnFee] = useState(0);

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const userSession = useUserSession();
  const claimClv = useClaimClv();
  const updateTransactionState = useTransactionStateUpdate();
  const updateBalance = useUpdateBalance();
  const crossChainFromChain = selectedAsset.chain ?? selectedAsset.tokenSymbol;
  const fromAddress = !selectedAsset.chain
    ? selectedAsset.tokenSymbol === 'CLV'
      ? currentAccount.address
      : currentAccount.skuAddress
    : getAddressFromAccount(selectedAsset.chain, currentAccount, CUSTOM_EVMS);
  const [crossChainToChain, setCrossChainToChain] = useState('');
  const [decimals, setDecimals] = useState(selectedAsset.decimals ?? 0);

  const getGasLimit = useCallback(async () => {
    const crossChainItem = getCrossChainItemByChainPair(
      crossChainFromChain,
      crossChainToChain,
    );
    try {
      const obj = {
        chain: selectedAsset.chain ?? 'ETH',
        from: fromAddress,
        to: selectedAsset.chain ? crossChainItem.bridgeAddress : toAddress,
        amount,
        tokenAddress: selectedAsset.tokenAddress,
        decimal: decimals,
      };
      const limit = await getWeb3GasLimit(obj);
      setGasLimit(limit);
    } catch (e) {
      console.log(e);
    }
  }, [
    amount,
    crossChainFromChain,
    crossChainToChain,
    decimals,
    fromAddress,
    toAddress,
    selectedAsset.chain,
    selectedAsset.tokenAddress,
  ]);

  const getCrossChainTxnFee = useCallback(
    async crossChainToChain => {
      const crossChainItem = getCrossChainItemByChainPair(
        crossChainFromChain,
        crossChainToChain,
      );
      const crossTxnFee = await getCrossTxnFee({
        network: crossChainItem.chainNameForBg,
        crossChainToChain,
      });
      setCrossChainTxnFee(crossTxnFee);
    },
    [crossChainFromChain],
  );

  const setClvFee = useCallback(
    async amount => {
      try {
        const from = getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        );
        const fee = await getPolkadotFee(
          from,
          from,
          amount,
          selectedAsset.tokenSymbol,
        );
        const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
        const txnFee = {
          feeBn: fee.totalFee,
          decimals,
          fee: toRealNumber(fee.totalFee, decimals).toFixed(6),
        };
        setTxnFee(txnFee);
      } catch (e) {
        console.log(e);
      }
    },
    [currentAccount, selectedAsset.tokenSymbol],
  );

  const setBnbFee = useCallback(async () => {
    try {
      const gasFee = await getGasInfo(selectedAsset.chain, CUSTOM_EVMS);

      const feeBn = gasFee * 1.05 * gasLimit;
      const fee = toRealNumber(feeBn, 18);

      const txnFee = {
        gasPrice: gasFee,
        feeBn: feeBn.toFixed(),
        decimals: decimals,
        fee: fee.toFixed(6),
      };
      setTxnFee(txnFee);
    } catch (e) {
      console.log(e);
    }
  }, [decimals, gasLimit, selectedAsset.chain]);

  const getFeeObj = () => {
    return selectedAsset.chain === 'ETH' ? txnFee.feeSelected : txnFee;
  };

  const onToAddressChange = e => {
    const input = e.target.value.trim();
    setToAddress(input);

    if (crossChainToChain === 'CLV' || crossChainToChain === 'SKU') {
      if (!input || !validAddress(input, selectedAsset)) {
        setToAddressErr('Invalid address.');
        return;
      }
    } else {
      if (!input || !(input.startsWith('0x') && isAddress(input))) {
        setToAddressErr('Invalid address.');
        return;
      }
    }
    setToAddressErr('');
  };

  const checkCLVBalanceSufficient = async () => {
    const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
    const amountBn = toBigNumber(amount, decimals);
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const totalBn = amountBn.plus(fee);
    if (totalBn.lte(balance)) {
      setTotal(toRealNumber(totalBn.toFixed(), decimals).toFixed(4));
    }
    return totalBn.lte(balance);
  };

  const checkBalanceSufficient = () => {
    const amountBn = toBigNumber(amount, decimals);
    const balance = toBigNumber(selectedAsset.balance, 0);
    if (amountBn.lte(balance)) {
      setTotal(toRealNumber(amountBn.toFixed(), decimals).toFixed(4));
    }
    return amountBn.lte(balance);
  };

  const checkFeeSufficient = () => {
    const fee = toBigNumber(getFeeObj().feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.chain]?.balance,
      0,
    );
    if (fee.lte(balance)) {
      setTotal(toRealNumber(fee.toFixed(), decimals).toFixed(4));
    }
    return fee.lte(balance);
  };

  const onAmountChange = e => {
    const input = e.target.value.trim();
    if (input !== '' && !INPUT_NUMBER_REGEX.test(input)) {
      return;
    }
    setAmountErr('');
    setAmount(input);
  };

  const onMax = async () => {
    const fee = toBigNumber(getFeeObj().feeBn, 0);
    const balance = toBigNumber(
      selectedAsset.balance ??
        currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const amountBn = balance.minus(fee);
    const amount = amountBn.lt(0)
      ? '0'
      : toRealNumber(amountBn.toFixed(), decimals).toString(10);
    setAmount(amount);
  };

  const btnDisabled = useMemo(
    () =>
      amount === '' ||
      toAddress === '' ||
      toAddressErr !== '' ||
      amountErr !== '' ||
      _.isEmpty(txnFee) ||
      confirming,
    [amount, toAddress, toAddressErr, amountErr, txnFee, confirming],
  );

  const onConfirm = () => {
    if (crossChainFromChain === 'CLV' || crossChainFromChain === 'SKU') {
      setConfirming(true);
      checkCLVBalanceSufficient()
        .then(ret => {
          if (ret) {
            if (amount < crossChainTxnFee + 10) {
              setAmountErr(`At least ${crossChainTxnFee + 10}`);
            } else {
              setOpenTransferConfirm(true);
            }
          } else {
            setAmountErr('Insufficient balance.');
          }
        })
        .finally(() => {
          setConfirming(false);
        });
    } else {
      if (!checkFeeSufficient()) {
        setAmountErr(`Insufficient Gas Fee ${selectedAsset.chain}`);
      } else if (checkBalanceSufficient()) {
        if (amount < crossChainTxnFee + 10) {
          setAmountErr(`At least ${crossChainTxnFee + 10}`);
        } else {
          setOpenTransferConfirm(true);
        }
      } else {
        setAmountErr('Insufficient balance.');
      }
    }
  };

  const burnClvErc20 = async () => {
    let txnHash;
    let tx;
    try {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      const crossChainItem = getCrossChainItemByChainPair(
        crossChainFromChain,
        crossChainToChain,
      );
      const eth = ethers.Wallet.fromMnemonic(seedWords);
      const chainUrl = getMulChainURL(selectedAsset.chain, CUSTOM_EVMS);
      const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl));
      var contract = new web3.eth.Contract(
        CLOVER.abi,
        crossChainItem.tokenAddress,
        { from: fromAddress },
      );
      const nonce = await web3.eth.getTransactionCount(fromAddress, 'pending');
      const actualAmountValue = toBigNumber(
        amount.toString(),
        selectedAsset.decimals,
      );
      const amountBn = actualAmountValue.toFixed();
      const rawTx = {
        from: fromAddress,
        nonce: '0x' + nonce.toString(16),
        gasPrice: '0x' + parseInt(getFeeObj().gasPrice).toString(16),
        gasLimit: '0x' + parseInt(gasLimit).toString(16),
        to: crossChainItem.tokenAddress,
        chainId: getChainIdBySymbol(selectedAsset.chain, CUSTOM_EVMS),
        data: contract.methods
          .transfer(crossChainItem.bridgeAddress, amountBn)
          .encodeABI(),
        value: '0x0',
      };
      tx = new Tx(rawTx);
      tx.sign(new Buffer(eth.privateKey.replace('0x', ''), 'hex'));
      let txnHash = undefined;
      let transaction = undefined;
      web3.eth
        .sendSignedTransaction('0x' + tx.serialize().toString('hex'))
        .on('transactionHash', function (hash) {
          txnHash = hash;
          formatBalance.setDefaults({ unit: selectedAsset.chain });
          transaction = {
            isErc20: true,
            from: fromAddress,
            to: toAddress,
            amount,
            amountBn,
            feeBn: getFeeObj().feeBn,
            crossFee: `${crossChainTxnFee} ${selectedAsset.tokenSymbol}`,
            fee: `${toBigNumber(getFeeObj().fee, 0)} ${selectedAsset.chain}`,
            total: amount,
            totalBn: amountBn,
            nonce: '0x' + nonce.toString(16),
            gasPrice: '0x' + parseInt(getFeeObj().gasPrice).toString(16),
            gasLimit: '0x' + parseInt(gasLimit).toString(16),
            tokenSymbol: selectedAsset.tokenSymbol,
            networkSymbol: selectedAsset.chain,
            sendDate: dayjs().format(),
            crossFrom: crossChainFromChain,
            crossTo: crossChainToChain,
            txnType: TransferType.CROSS_CHAIN,
            internal: { address: currentAccount.address },
            token: selectedAsset.tokenSymbol,
          };
          updateTransactionState(
            transaction,
            txnHash,
            TransactionStatus.PENDING,
          );
          history.push(
            `/homePage/activityDetail/${selectedAsset.tokenSymbol}/${fromAddress}`,
          );
        })
        .on('receipt', function (receipt) {
          if (txnHash && transaction) {
            if (!receipt.status) {
              updateTransactionState(
                transaction,
                txnHash,
                TransactionStatus.FAIL,
              );
            } else {
              checkClvErc20Burned(
                transaction,
                txnHash,
                web3,
                toAddress,
                seedWords,
              );
            }
          }
        })
        .on('error', function (error) {
          console.log(error);
          if (txnHash && transaction) {
            updateTransactionState(
              transaction,
              txnHash,
              TransactionStatus.FAIL,
            );
          }
        });
    } catch (e) {
      if (tx && txnHash) {
        await updateTransactionState(tx, txnHash, TransactionStatus.FAIL);
        return;
      }
      console.error(e);
      throw new Error('Failed to submit transaction');
    }
  };

  async function checkClvErc20Burned(tx, txnHash, web3, toAddress, seedWords) {
    await updateTransactionState(tx, txnHash, TransactionStatus.PENDING);
    const transactionReceiptAsync = function (resolve, reject) {
      web3.eth.getTransactionReceipt(txnHash, async (error, receipt) => {
        if (error) {
          await updateTransactionState(tx, txnHash, TransactionStatus.FAIL);
          reject(error);
        } else if (receipt === null) {
          setTimeout(() => transactionReceiptAsync(resolve, reject), 15000);
        } else {
          if (receipt.status) {
            const claimed = await claimClv(tx, web3, toAddress, txnHash);
            if (claimed) {
              resolve();
            } else {
              setTimeout(() => transactionReceiptAsync(resolve, reject), 15000);
            }
          } else {
            await updateTransactionState(tx, txnHash, TransactionStatus.FAIL);
            reject(receipt);
          }
        }
      });
    };

    return new Promise(transactionReceiptAsync);
  }

  const burnClv = async () => {
    try {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      const api = await getApiByToken(crossChainFromChain);
      const keyring = new Keyring({ type: currentAccount.keypairType });
      const signer = keyring.addFromUri(seedWords);
      const actualAmountValue = toBigNumber(
        amount.toString(),
        selectedAsset.decimals ?? 18,
      );
      const amountBn = actualAmountValue.toFixed();
      const nonce = await api.rpc.system.accountNextIndex(fromAddress);
      const feeValue = toBigNumber(getFeeObj().feeBn, 0);
      const totalAmount = actualAmountValue.plus(feeValue);
      const totalBn = totalAmount.toFixed();
      const total = toBigNumber(getFeeObj().fee, 0).plus(amount).toFixed();

      const transaction = {
        from: fromAddress,
        to: toAddress,
        nonce: '0x' + nonce.toString(16),
        amount,
        amountBn,
        feeBn: getFeeObj().feeBn,
        fee: `${toBigNumber(getFeeObj().fee, 0)
          .plus(crossChainTxnFee)
          .toFixed()} CLV`,
        total,
        totalBn: totalBn,
        tokenSymbol: selectedAsset.tokenSymbol,
        networkSymbol: selectedAsset.tokenSymbol,
        sendDate: dayjs().format(),
        crossFrom: crossChainFromChain,
        crossTo: crossChainToChain,
        txnType: TransferType.CROSS_CHAIN,
        internal: { address: currentAccount.address },
        token: selectedAsset.tokenSymbol,
      };

      const crossChainItem = getCrossChainItemByChainPair(
        crossChainFromChain,
        crossChainToChain,
      );
      const signedTransaction = await api.tx.cloverClaims
        .burnElastic(crossChainItem.chainNameForBg, toAddress, amountBn)
        .sign(signer, { nonce });
      const txnHash = signedTransaction.hash.toHex();
      updateTransactionState(transaction, txnHash, TransactionStatus.PENDING);
      setOpenTransferConfirm(false);
      history.push(
        `/homePage/activityDetail/${
          selectedAsset.tokenSymbol
        }/${getAddressFromAccount(
          crossChainFromChain,
          currentAccount,
          CUSTOM_EVMS,
        )}`,
      );
      signedTransaction.send(async ({ events = [], status }) => {
        let index = -1;
        let blockHash;
        if (status.isFinalized) {
          blockHash = `${status.asFinalized}`;
          events.forEach(({ phase, event: { data, method, section } }, idx) => {
            if (`${section}.${method}` === 'cloverClaims.ElasticBurned') {
              index = phase.asApplyExtrinsic.toNumber();
            }
          });
          if (index !== -1) {
            const signedBlock = await api.rpc.chain.getBlock(blockHash);
            const blockNum = parseInt(signedBlock.block.header.number, 10);
            getBep20Mined(transaction, index, blockNum, txnHash);
          }
        }
        if (
          status.isError ||
          status.isDropped ||
          status.isInvalid ||
          status.isUsurped
        ) {
          updateTransactionState(transaction, txnHash, TransactionStatus.FAIL);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  async function getBep20Mined(tx, index, blockNum, txnHash) {
    const crossToChain = getCrossChainItemByChainPair(tx.crossFrom, tx.crossTo);
    if (crossToChain === undefined) {
      throw new Error('Not supported cross to chain');
    }
    const chainUrl = getMulChainURL(crossToChain.chain, CUSTOM_EVMS);
    const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl));
    const contract = new web3.eth.Contract(
      BRIDGE.abi,
      crossToChain.bridgeAddress,
    );
    const hasMintedAsync = async function (resolve, reject) {
      const res = await contract.methods.hasMinted(blockNum, index).call();
      if (!res) {
        setTimeout(() => hasMintedAsync(resolve, reject), 15000);
      } else {
        updateTransactionState(tx, txnHash, TransactionStatus.SUCCESS);
        updateBalance('', CUSTOM_EVMS);
        resolve(true);
      }
    };

    return new Promise(hasMintedAsync);
  }

  const onSend = async () => {
    if (
      cloverToErc20(crossChainFromChain, crossChainToChain) ||
      sakuraToErc20(crossChainFromChain, crossChainToChain)
    ) {
      await burnClv();
    }
    if (
      erc20ToClover(crossChainFromChain, crossChainToChain) ||
      erc20ToSakura(crossChainFromChain, crossChainToChain)
    ) {
      await burnClvErc20();
    }
  };

  const setDatas = useCallback(async () => {
    let crossToChain
    if (crossChainToChain !== '') {
      crossToChain = crossChainToChain
    } else {
      crossToChain = selectedAsset.chain
      ? selectedAsset.tokenSymbol
      : 'ETH';
    }

    setCrossChainToChain(crossToChain);
    if (selectedAsset.chain === 'ETH') {
      const ethFee = await getEthFee(gasLimit);
      setTxnFee(ethFee);
    } else if (selectedAsset.chain === 'BNB') {
      await setBnbFee();
    } else {
      await setClvFee();
    }

    const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
    setDecimals(decimals);
  }, [
    gasLimit,
    setClvFee,
    setBnbFee,
    selectedAsset,
    crossChainToChain
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
  }, [history, selectedAsset, updateSelectedAsset]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    setDatas();
  }, [selectedAsset, setDatas]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    if (crossChainFromChain !== 'CLV' && crossChainFromChain !== 'SKU') {
      getGasLimit();
    }
    if (crossChainToChain) {
      getCrossChainTxnFee(crossChainToChain);
    }
  }, [
    crossChainFromChain,
    crossChainToChain,
    getCrossChainTxnFee,
    getGasLimit,
    selectedAsset,
  ]);

  return (
    <div>
      <TransferComponent
        title="Cross-chain"
        toAddress={toAddress}
        toAddressErr={toAddressErr}
        onToAddressChange={onToAddressChange}
        onMax={onMax}
        amount={amount}
        amountErr={amountErr}
        onAmountChange={onAmountChange}
        transferType={TransferType.CROSS_CHAIN}
        txnFee={txnFee}
        btnDisabled={btnDisabled}
        onConfirm={onConfirm}
        total
        setTransactionFee={setTxnFee}
        crossChainTxnFee={crossChainTxnFee}
        crossChainToChain={crossChainToChain}
        setCrossChainToChain={chain => {
          setCrossChainToChain(chain);
          setAmountErr('');
          setToAddressErr('');
        }}
      />
      {openTransferConfirm && (
        <TransferConfirmDialog
          isOpen={openTransferConfirm}
          token={selectedAsset.tokenSymbol}
          from={fromAddress}
          to={toAddress}
          crossFee={
            crossChainFromChain === 'CLV' || crossChainFromChain === 'SKU'
              ? ''
              : `${crossChainTxnFee} ${selectedAsset.tokenSymbol}`
          }
          txnFee={
            crossChainFromChain === 'CLV' || crossChainFromChain === 'SKU'
              ? `${toBigNumber(getFeeObj().fee, 0)
                  .plus(crossChainTxnFee)
                  .toFixed()} ${selectedAsset.tokenSymbol}`
              : `${getFeeObj().fee} ${selectedAsset.chain}`
          }
          total={total}
          amount={amount}
          onClose={() => setOpenTransferConfirm(false)}
          onSend={onSend}
        />
      )}
    </div>
  );
}
