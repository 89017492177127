import { bnToBn, formatNumber } from '@polkadot/util';
import { getApiByToken } from '../../services/DotApiService';
import { findChain } from '../../constants/chain';

export const mortalityAsString = async (exERA, hexBlockNumber) => {
  try {
    if (exERA.isImmortalEra) {
      return 'immortal';
    }
    const blockNumber = bnToBn(hexBlockNumber);
    const mortal = exERA.asMortalEra;
    return `mortal, valid from #${formatNumber(mortal.birth(blockNumber))} to #${formatNumber(
      mortal.death(blockNumber),
    )}`;
  } catch (err) {
    return 'working on mortality';
  }
};

export const decodeMethod = async (data, isDecoded, chain, sVersion) => {
  let json = null;
  let method = null;

  try {
    if (isDecoded && sVersion) {
      const api = await getApiByToken(chain.tokenSymbol)
      method = api.registry.createType('Call', data);
      json = method.toJSON();
    }
  } catch (error) {
    json = null;
    method = null;
  }

  return { json, method };
};

export const createTxnUIObject = async txnPayload => {
  const {
    address, blockHash, blockNumber, genesisHash, method, specVersion
  } = txnPayload;
  const chain = findChain(genesisHash);
  const { era, nonce, tip } = txnPayload;

  const sVersion = bnToBn(specVersion).toNumber();
  const mortality = await mortalityAsString(era, blockNumber);

  let result = {
    address,
    blockHash,
    chain: undefined,
    sVersion,
    nonce: formatNumber(nonce),
    tip: formatNumber(tip),
    method,
  };
  if (chain) {
    const decodedMethod = await decodeMethod(method, true, chain, sVersion);
    const {
      method: { section, method: methodName, meta },
      json,
    } = decodedMethod;

    const note = meta?.documentation?.join(' ') ?? '';
    const { dest, value } = json.args;

    result = {
      ...result,
      chain: chain.name,
      mortality,
      section,
      method: `${section}.${methodName}`,
      dest,
      value: value !== undefined ? bnToBn(value).toNumber() : undefined,
      note,
    };
  }

  return result
};
