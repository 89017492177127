import ethAssetsJson from '../chainAssets/eth-assets.json';
import ethTestAssetsJson from '../chainAssets/eth-test-assets.json';
import bscAssetsJson from '../chainAssets/bsc-assets.json';
import bscTestAssetsJson from '../chainAssets/bsc-test-assets.json';
import { IS_DEV } from '../constants/api';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { PALETTE } from 'modules/theme/mainTheme';
const { checkAddress } = require('@polkadot/util-crypto');

export const EMAIL_REG =
  /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
export const isErc20Asset = tokenAddress =>
  tokenAddress && tokenAddress.startsWith('0x');

export const checkAddressByFormat = (address, ss58Format) => {
  const result = checkAddress(address, ss58Format);
  return result[0];
};

export function shortenText(text = '', length = 12) {
  if (typeof text === 'number') text = new BigNumber(text.toString()).toFixed();
  if (text.length <= length) return text;
  return `${text.slice(0, length)}...`;
}

export function effectiveBalance(balance) {
  balance = new BigNumber(balance).toFixed();
  if (!balance || balance === '0') {
    return 0;
  }
  if (balance.split('.').length === 1) {
    return balance;
  }
  const integer = balance.split('.')[0];
  const decimal = balance.split('.')[1];
  if (integer > 0) {
    const str = decimal.substr(0, 4);
    return str === '0000' ? integer : parseFloat(`${integer}.${str}`);
  }

  let temp = [];
  let tempNum = 0;
  let isNotZero = false;
  for (let i = 0; i < decimal.length; i++) {
    if (decimal[i] !== '0' && !isNotZero) {
      isNotZero = true;
    }
    if (isNotZero) {
      tempNum++;
    }
    if (tempNum <= 4) {
      temp.push(decimal[i]);
    }
  }
  return parseFloat(`${integer}.${temp.join('')}`);
}

export function shortenAddress(address = '') {
  if (address.length < 11) {
    return address;
  }

  return `${address.slice(0, 6)}...${address.slice(-5)}`;
}

export const showNewAlias = (alias) => {
  return alias?.replace('Account', 'Wallet ')
}

export const getColorByStatus = status => {
  switch (status.toLowerCase()) {
    case 'success':
      return PALETTE.success.main;
    case 'pending':
      return PALETTE.info.main;
    case 'failed':
      return PALETTE.error.main;
    default:
      return PALETTE.error.main;
  }
};

export const getPasswordStrength = (password) => {
  const StrongExp = /^(?![a-zA-z]+$)(?!\d+$)(?!\W+$)(?![a-zA-z\d]+$)(?![a-zA-z\W]+$)(?![\d\W]+$)[a-zA-Z\d\W]{8,128}$/
  const MediumExp = /^(?![a-zA-z]+$)(?!\d+$)(?!\W+$)[a-zA-Z\d\W]{8,128}$/
  if (StrongExp.test(password)) {
    return 'strong'
  } else if (MediumExp.test(password)) {
    return 'medium'
  } else {
    return 'weak'
  }
}

export const getColorByPasswordStrength = (strength) => {
  switch (strength.toLowerCase()) {
    case 'strong':
      return PALETTE.success.main;
    case 'medium':
      return PALETTE.secondary.main;
    case 'weak':
      return PALETTE.error.main;
    default:
      return PALETTE.error.main;
  }
}

export const getTokenList = () => {
  return IS_DEV
    ? _.assignIn(ethTestAssetsJson, bscTestAssetsJson)
    : _.assignIn(ethAssetsJson, bscAssetsJson);
};

export function getRandomFileName() {
  let fileName = ''
  const charBank = [
    ...'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  ]
  const fileNameLength = Math.floor(Math.random() * 7 + 6)

  for (let i = 0; i < fileNameLength; i++) {
    fileName += charBank[Math.floor(Math.random() * charBank.length)]
  }

  return fileName
}

export function exportAsFile(filename, data, type = 'text/csv') {
  // eslint-disable-next-line no-param-reassign
  filename = filename || getRandomFileName()
  // source: https://stackoverflow.com/a/33542499 by Ludovic Feltz
  const blob = new window.Blob([data], { type })
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const elem = window.document.createElement('a')
    elem.target = '_blank'
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}
