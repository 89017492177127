import { IS_DEV } from './api'

export const ROPSTEN = 'ropsten'
export const RINKEBY = 'rinkeby'
export const KOVAN = 'kovan'
export const MAINNET = 'mainnet'
export const GOERLI = 'goerli'
export const CLOVER = 'clover'
export const POLKADOT = 'polkadot'
export const MATIC = 'matic'
export const BSC_MAINNET = 'bsc_mainnet'

export const MAINNET_NETWORK_ID = '1'
export const ROPSTEN_NETWORK_ID = '3'
export const RINKEBY_NETWORK_ID = '4'
export const GOERLI_NETWORK_ID = '5'
export const KOVAN_NETWORK_ID = '42'
export const CLOVER_TESTNET_NETWORK_ID = '1024'
export const CLOVER_MAINNET_NETWORK_ID = '1024' // 1024
export const BSC_MAINNET_NETWORK_ID = '56'
export const BSC_TESTNET_NETWORK_ID = '97'
export const FANTOM_MAINNET_NETWORK_ID = '250'
export const FANTOM_TESTNET_NETWORK_ID = '4002'
export const HECO_MAINNET_NETWORK_ID = '128'
export const HECO_TESTNET_NETWORK_ID = '256'
export const XDAI_NETWORK_ID = '100'
export const MATIC_TESTNET_NETWORK_ID = '80001'
export const MATIC_MAINNET_NETWORK_ID = '137'
export const OKEX_TESTNET_NETWORK_ID = '65'
export const OKEX_MAINNET_NETWORK_ID = '66'

export const MAINNET_CHAIN_ID = '0x1'
export const ROPSTEN_CHAIN_ID = '0x3'
export const RINKEBY_CHAIN_ID = '0x4'
export const GOERLI_CHAIN_ID = '0x5'
export const KOVAN_CHAIN_ID = '0x2a'
export const CLOVER_TESTNET_CHAIN_ID = '0x400'
export const CLOVER_MAINNET_CHAIN_ID = '0x400' // 0x400
export const BSC_MAINNET_CHAIN_ID = '0x38'
export const BSC_TESTNET_CHAIN_ID = '0x61'
export const FANTOM_MAINNET_CHAIN_ID = '0xfa'
export const FANTOM_TESTNET_CHAIN_ID = '0xfa2'
export const HECO_MAINNET_CHAIN_ID = '0x80'
export const HECO_TESTNET_CHAIN_ID = '0x100'
export const XDAI_CHAIN_ID = '0x64'
export const MATIC_TESTNET_CHAIN_ID = '0x13881'
export const MATIC_MAINNET_CHAIN_ID = '0x89'
export const OKEX_TESTNET_CHAIN_ID = '0x41'
export const OKEX_MAINNET_CHAIN_ID = '0x42'
export const MOONRIVER_MAINNET_CHAIN_ID = '0x505'
export const MOONRIVER_TESTNET_CHAIN_ID = '0x507'

export const INFURA_PROVIDER_TYPES = []

export const CONTRACT_TYPE_ETH = 'eth'
export const CONTRACT_TYPE_ERC20 = 'erc20'
export const CONTRACT_TYPE_ERC721 = 'erc721'
export const CONTRACT_TYPE_ERC1155 = 'erc1155'
export const SUPPORTED_NFT_STANDARDS = new Set([CONTRACT_TYPE_ERC1155, CONTRACT_TYPE_ERC721])

export const CAVEAT_NAMES = {
  exposedAccounts: 'exposedAccounts',
}

export const WALLET_PREFIX = 'wallet_'

export const NOTIFICATION_NAMES = {
  accountsChanged: 'clover_accountsChanged',
  unlockStateChanged: 'clover_unlockStateChanged',
  chainChanged: 'clover_chainChanged',
}

export const TRANSACTION_STATUSES = {
  UNAPPROVED: 'unapproved',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  SIGNED: 'signed',
  SUBMITTED: 'submitted',
  FAILED: 'failed',
  DROPPED: 'dropped',
  CONFIRMED: 'confirmed',
}

export const TRANSACTION_ENVELOPE_TYPES = {
  LEGACY: '0x0',
  ACCESS_LIST: '0x1',
  FEE_MARKET: '0x2',
}

export const HARDFORKS = {
  BERLIN: 'berlin',
  LONDON: 'london',
}

export const GAS_ESTIMATE_TYPES = {
  FEE_MARKET: 'fee-market',
  LEGACY: 'legacy',
  ETH_GASPRICE: 'eth_gasPrice',
  NONE: 'none',
}

export const OLD_ERC721_LIST = {
  '0x06012c8cf97bead5deae237070f9587f8e7a266d': {
    name: 'Cryptokitties',
    logo: 'dapp-cryptokitty.svg',
    erc20: true,
    symbol: 'CK',
    decimals: 0,
  },
}

export const MESSAGE_TYPE = {
  ETH_DECRYPT: 'eth_decrypt',
  ETH_GET_ENCRYPTION_PUBLIC_KEY: 'eth_getEncryptionPublicKey',
  ETH_SIGN: 'eth_sign',
  ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
  PERSONAL_SIGN: 'personal_sign',
  SOLANA_SIGN: 'solana_sign',
  POLKADOT_SIGN: 'polkadot_sign',
  POLKADOT_SIGN_TRANSACTION: 'polkadot_sign_transaction',
}

export const TRANSACTION_TYPES = {
  CANCEL: 'cancel',
  RETRY: 'retry',
  TOKEN_METHOD_TRANSFER: 'transfer',
  TOKEN_METHOD_TRANSFER_FROM: 'transferFrom',
  TOKEN_METHOD_APPROVE: 'approve',
  SENT_ETHER: 'sentEther',
  COLLECTIBLE_METHOD_SAFE_TRANSFER_FROM: 'safeTransferFrom',
  CONTRACT_INTERACTION: 'contractInteraction',
  DEPLOY_CONTRACT: 'contractDeployment',
  STANDARD_TRANSACTION: 'transaction',
  SIGN: MESSAGE_TYPE.ETH_SIGN,
  SIGN_TYPED_DATA: MESSAGE_TYPE.ETH_SIGN_TYPED_DATA,
  PERSONAL_SIGN: MESSAGE_TYPE.PERSONAL_SIGN,
  ETH_DECRYPT: MESSAGE_TYPE.ETH_DECRYPT,
  ETH_GET_ENCRYPTION_PUBLIC_KEY: MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY,
}

export const POPUP_LOADED = 'popup-loaded'
export const POPUP_RESULT = 'popup_result'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const ERC1155_INTERFACE_ID = '0xd9b67a26'
export const ERC721_INTERFACE_ID = '0x80ac58cd'
export const ERC1155METADATA_INTERFACE_ID = '0x0e89341c'
export const ERC721METADATA_INTERFACE_ID = '0x5b5e139f'
export const ERC721ENUMERABLE_INTERFACE_ID = '0x780e9d63'

export const NFT_SUPPORTED_NETWORKS = {
  [MATIC]: MATIC_MAINNET_NETWORK_ID,
  [BSC_MAINNET]: BSC_MAINNET_NETWORK_ID,
  [MAINNET]: MAINNET_NETWORK_ID,
}

export const FEATURES_DEFAULT_POPUP_WINDOW = 'directories=0,titlebar=0,toolbar=0,status=0,location=0,menubar=0,height=700,width=1200'
export const FEATURES_CONFIRM_WINDOW = 'directories=0,titlebar=0,toolbar=0,status=0,location=0,menubar=0,height=700,width=450'

export const LoginType = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  DISCORD: 'discord',
}

/**
 * The largest possible chain ID we can handle.
 * Explanation: https://gist.github.com/rekmarks/a47bd5f2525936c4b8eee31a16345553
 */
export const MAX_SAFE_CHAIN_ID = 4503599627370476;

export const ROPSTEN_DISPLAY_NAME = 'Ropsten'
export const RINKEBY_DISPLAY_NAME = 'Rinkeby'
export const KOVAN_DISPLAY_NAME = 'Kovan'
export const MAINNET_DISPLAY_NAME = 'Ethereum Mainnet'
export const GOERLI_DISPLAY_NAME = 'Goerli'
export const CLOVER_DISPLAY_NAME = 'Clover'
export const BSC_MAINNET_DISPLAY_NAME = 'BNB Smart Chain'
export const BSC_TESTNET_DISPLAY_NAME = 'BSC_TESTNET'
export const FANTOM_TESTNET_DISPLAY_NAME = 'Fantom_TESTNET'
export const FANTOM_MAINNET_DISPLAY_NAME = 'Fantom'
export const HECO_TESTNET_DISPLAY_NAME = 'Heco_TESTNET'
export const HECO_MAINNET_DISPLAY_NAME = 'Heco'
export const XDAI_DISPLAY_NAME = 'xDai'
export const MATIC_TESTNET_DISPLAY_NAME = 'Matic_TESTNET'
export const MATIC_MAINNET_DISPLAY_NAME = 'Matic'
export const OKEX_TESTNET_DISPLAY_NAME = 'OKEX_TESTNET'
export const OKEX_MAINNET_DISPLAY_NAME = 'OKEX'
export const MOONRIVER_MAINNET_DISPLAY_NAME = 'Moonriver'
export const MOONRIVER_TESTNET_DISPLAY_NAME = 'Moonriver_TESTNET'

export const NETWORK_TYPE_TO_ID_MAP = {
  [ROPSTEN]: { networkId: ROPSTEN_NETWORK_ID, chainId: ROPSTEN_CHAIN_ID },
  [RINKEBY]: { networkId: RINKEBY_NETWORK_ID, chainId: RINKEBY_CHAIN_ID },
  [KOVAN]: { networkId: KOVAN_NETWORK_ID, chainId: KOVAN_CHAIN_ID },
  [GOERLI]: { networkId: GOERLI_NETWORK_ID, chainId: GOERLI_CHAIN_ID },
  [MAINNET]: { networkId: MAINNET_NETWORK_ID, chainId: MAINNET_CHAIN_ID },
}

export const NETWORK_TO_NAME_MAP = {
  [ROPSTEN]: ROPSTEN_DISPLAY_NAME,
  [RINKEBY]: RINKEBY_DISPLAY_NAME,
  [KOVAN]: KOVAN_DISPLAY_NAME,
  [MAINNET]: MAINNET_DISPLAY_NAME,
  [GOERLI]: GOERLI_DISPLAY_NAME,

  [ROPSTEN_NETWORK_ID]: ROPSTEN_DISPLAY_NAME,
  [RINKEBY_NETWORK_ID]: RINKEBY_DISPLAY_NAME,
  [KOVAN_NETWORK_ID]: KOVAN_DISPLAY_NAME,
  [GOERLI_NETWORK_ID]: GOERLI_DISPLAY_NAME,
  [MAINNET_NETWORK_ID]: MAINNET_DISPLAY_NAME,

  [ROPSTEN_CHAIN_ID]: ROPSTEN_DISPLAY_NAME,
  [RINKEBY_CHAIN_ID]: RINKEBY_DISPLAY_NAME,
  [KOVAN_CHAIN_ID]: KOVAN_DISPLAY_NAME,
  [GOERLI_CHAIN_ID]: GOERLI_DISPLAY_NAME,
  [MAINNET_CHAIN_ID]: MAINNET_DISPLAY_NAME,
}

export const RPC = 'rpc'

export const CHAIN_ID_TO_TYPE_MAP = Object.entries(
  NETWORK_TYPE_TO_ID_MAP,
).reduce((chainIdToTypeMap, [networkType, { chainId }]) => {
  chainIdToTypeMap[chainId] = networkType
  return chainIdToTypeMap
}, {})

export const CHAIN_ID_TO_NETWORK_ID_MAP = Object.values(
  NETWORK_TYPE_TO_ID_MAP,
).reduce((chainIdToNetworkIdMap, { chainId, networkId }) => {
  chainIdToNetworkIdMap[chainId] = networkId
  return chainIdToNetworkIdMap
}, {})

export const createNetwork = (host, networkName, chainId, blockExplorer, ticker, tickerName, logo, rpcUrl) => ({
  host,
  networkName,
  chainId,
  blockExplorer,
  ticker,
  logo,
  tickerName,
  rpcUrl,
})

export const createNetworkWithNetwork = (network) => {
  return createNetwork('', network.nickname, network.chainId, '', network.ticker, '', '', network.rpcUrl)
}

export const DEV_INFURA_ID = 'bd6d2612b6c8462a99385dc5c89cfd41'

export const CLOVER_NETWORK = {
  type: 'rpc',
  rpcUrl: IS_DEV ? 'https://rpc.clover.finance' : 'https://rpc-ivy.clover.finance',
  chainId: IS_DEV ? CLOVER_TESTNET_CHAIN_ID : CLOVER_MAINNET_CHAIN_ID,
  nickname: CLOVER_DISPLAY_NAME,
  ticker: 'CLV',
}

export const ETH_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://eth-04.dccn.ankr.com/',
  chainId: MAINNET_CHAIN_ID,
  nickname: MAINNET_DISPLAY_NAME,
  ticker: 'ETH',
}

export const ETH_ROPSTEN_NETWORK = {
  type: 'rpc',
  rpcUrl: `https://ropsten.infura.io/v3/${DEV_INFURA_ID}`,
  chainId: ROPSTEN_CHAIN_ID,
  nickname: ROPSTEN_DISPLAY_NAME,
  ticker: 'ETH',
}

export const BSC_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://binance.ankr.com',
  chainId: BSC_MAINNET_CHAIN_ID,
  nickname: BSC_MAINNET_DISPLAY_NAME,
  ticker: 'BNB',
}

export const BSC_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
  chainId: BSC_TESTNET_CHAIN_ID,
  nickname: BSC_TESTNET_DISPLAY_NAME,
  ticker: 'BNB',
}

export const FANTOM_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpcapi.fantom.network/',
  chainId: FANTOM_MAINNET_CHAIN_ID,
  nickname: FANTOM_MAINNET_DISPLAY_NAME,
  ticker: 'FTM',
}

export const FANTOM_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc.testnet.fantom.network/',
  chainId: FANTOM_TESTNET_CHAIN_ID,
  nickname: FANTOM_TESTNET_DISPLAY_NAME,
  ticker: 'FTM',
}

export const HECO_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://http-mainnet-node.huobichain.com/',
  chainId: HECO_MAINNET_CHAIN_ID,
  nickname: HECO_MAINNET_DISPLAY_NAME,
  ticker: 'HT',
}

export const HECO_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://http-testnet.hecochain.com/',
  chainId: HECO_TESTNET_CHAIN_ID,
  nickname: HECO_TESTNET_DISPLAY_NAME,
  ticker: 'HT',
}

export const XDAI_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc.xdaichain.com/',
  chainId: XDAI_CHAIN_ID,
  nickname: XDAI_DISPLAY_NAME,
  ticker: 'XDAI',
}

export const MATIC_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc-mainnet.maticvigil.com',
  chainId: MATIC_MAINNET_CHAIN_ID,
  nickname: MATIC_MAINNET_DISPLAY_NAME,
  ticker: 'MATIC',
}

export const MATIC_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc-mumbai.maticvigil.com',
  chainId: MATIC_TESTNET_CHAIN_ID,
  nickname: MATIC_TESTNET_DISPLAY_NAME,
  ticker: 'MATIC',
}

export const OKEX_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://exchainrpc.okex.org/',
  chainId: OKEX_MAINNET_CHAIN_ID,
  nickname: OKEX_MAINNET_DISPLAY_NAME,
  ticker: 'OKT',
}

export const OKEX_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://exchaintestrpc.okex.org',
  chainId: OKEX_TESTNET_CHAIN_ID,
  nickname: OKEX_TESTNET_DISPLAY_NAME,
  ticker: 'OKT',
}

export const MOONRIVER_MAINNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc.moonriver.moonbeam.network',
  chainId: MOONRIVER_MAINNET_CHAIN_ID,
  nickname: MOONRIVER_MAINNET_DISPLAY_NAME,
  ticker: 'MOVR',
}

export const MOONRIVER_TESTNET_NETWORK = {
  type: 'rpc',
  rpcUrl: 'https://rpc.testnet.moonbeam.network',
  chainId: MOONRIVER_TESTNET_CHAIN_ID,
  nickname: MOONRIVER_TESTNET_DISPLAY_NAME,
  ticker: 'MOVR',
}

export const CUSTOM_TESTNET_EVMS = [
  ETH_ROPSTEN_NETWORK,
  BSC_TESTNET_NETWORK,
  FANTOM_TESTNET_NETWORK,
  HECO_TESTNET_NETWORK,
  XDAI_NETWORK, // only has mainnet
  MATIC_TESTNET_NETWORK,
  OKEX_TESTNET_NETWORK,
  MOONRIVER_TESTNET_NETWORK,
]

export const CUSTOM_MAINNET_EVMS = [
  ETH_MAINNET_NETWORK,
  BSC_MAINNET_NETWORK,
  FANTOM_MAINNET_NETWORK,
  HECO_MAINNET_NETWORK,
  XDAI_NETWORK,
  MATIC_MAINNET_NETWORK,
  OKEX_MAINNET_NETWORK,
  MOONRIVER_MAINNET_NETWORK,
]

export const CUSTOM_EVMS = IS_DEV ? CUSTOM_TESTNET_EVMS : CUSTOM_MAINNET_EVMS

export const ALL_NETWORK = [CLOVER_NETWORK, ...CUSTOM_EVMS]

export const getChainIdBySymbol = (token, customEvms) => {
  const network = customEvms.find(n => n.ticker === token);
  return network ? network.chainId : undefined
}

export const getMulChainURL = (tokenOrNet, customEvms) => {
  const allNetworks = [CLOVER_NETWORK, ...customEvms]
  const ret = allNetworks.find(n => n.ticker.toUpperCase() === tokenOrNet.toUpperCase()
                          || n.chainId === tokenOrNet
                          || parseInt(n.chainId).toString(10) === (tokenOrNet === undefined ? tokenOrNet : tokenOrNet.toString()))
  if (ret) {
    return ret.rpcUrl
  }

  return undefined
}

export function getTokenByChainId(id, customEvms) {
  const allNetworks = [CLOVER_NETWORK, ...customEvms]
  const ret = allNetworks.find(n => parseInt(n.chainId).toString(10) === id.toString())
  if (ret) {
    return ret.ticker
  }

  return 'ETH'
}

export function getEvmNetworkIdBySymbol(symbol, customEvms) {
  const allNetworks = [CLOVER_NETWORK, ...customEvms]
  const ret = allNetworks.find(n => n.ticker === symbol)

  return ret ? parseInt(ret.chainId).toString(10) : parseInt(allNetworks.find(n => n.ticker === 'ETH').chainId).toString(10)
}

export function getNetByChainId(id, customEvms) {
  const allNetworks = [CLOVER_NETWORK, ...customEvms]
  const ret = allNetworks.find(n => n.chainId === id)
  if (ret) {
    return ret
  }
  return null
}

export function supportedEvmChainId(chainId) {
  return CUSTOM_EVMS.find(n => n.chainId === chainId) !== undefined
}

export function getNetworkIdFromChainId(chainId) {
  return parseInt(chainId).toString(10)
}

export function getChainIdFromNetwork(networkId) {
  return '0x' + parseInt(networkId).toString(16)
}
