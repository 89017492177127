import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M12.9998 11.0002L20.0002 11.0002L20.0002 12.9999L12.9998 12.9999V20.0003H11.0002V12.9999L3.99979 12.9999L3.99979 11.0002L11.0002 11.0002L11.0002 3.99985H12.9998L12.9998 11.0002Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
