import { AppState } from '../index'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { accountInfo, wrongNetwork, loginAccount, importedAccounts } from './actions'
import { AccountInfo, WalletAccount } from './types'

export function useAccountInfo(): AccountInfo {
  return useSelector((state: AppState) => state.wallet.accountInfo)
}

export function useAccountInfoUpdate(): (info: AccountInfo) => void {
  const dispatch = useDispatch()
  return useCallback((info: AccountInfo) => dispatch(accountInfo({accountInfo: info})), [dispatch])
}

export function useWrongNetwork(): boolean {
  return useSelector((state: AppState) => state.wallet.wrongNetwork)
}

export function useWrongNetworkUpdate(): (wrong: boolean) => void {
  const dispatch = useDispatch()
  return useCallback((wrong: boolean) => dispatch(wrongNetwork({wrongNetwork: wrong})), [dispatch])
}

export function useLoginAccount(): WalletAccount {
  return useSelector((state: AppState) => state.wallet.loginAccount)
}

export function useLoginAccountUpdate(): (account: WalletAccount) => void {
  const dispatch = useDispatch()
  return useCallback((account: WalletAccount) => dispatch(loginAccount({loginAccount: account})), [dispatch])
}

export function useImportedAccount(): Array<WalletAccount> {
  return useSelector((state: AppState) => state.wallet.importedAccounts)
}

export function useImportedAccountUpdate(): (accounts: Array<WalletAccount>) => void {
  const dispatch = useDispatch()
  return useCallback((accounts: Array<WalletAccount>) => dispatch(importedAccounts({importedAccounts: accounts})), [dispatch])
}

