import { Route, Switch } from 'react-router-dom';
import {
  AuthorizationRoutesConfig,
  AuthorizationRoutes,
} from './modules/Authorization/AuthorizationRoutes';

export const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={AuthorizationRoutesConfig.Login.path}
        render={() => <AuthorizationRoutes />}
      />
    </Switch>
  );
};
