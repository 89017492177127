import { AppState } from '../index'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { userSession} from './actions'
import { UserSession } from './types'

export function useUserSession(): UserSession {
  return useSelector((state: AppState) => state.user.userSession)
}

export function useUserSessionUpdate(): (user: UserSession) => void {
  const dispatch = useDispatch()
  return useCallback((user: UserSession) => dispatch(userSession({userSession: user})), [dispatch])
}
