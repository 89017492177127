export const dotTypes = {
  BlockLength: {
    max: 'PerDispatchClassU32'
  },
  PerDispatchClassU32: {
    normal: 'u32',
    operational: 'u32',
    mandatory: 'u32'
  },
  Address: "MultiAddress",
  LookupSource: "MultiAddress"
};
