import { makeStyles, Theme } from '@material-ui/core';

export const useTransferStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  content: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginTop: theme.spacing(12),
  },
  column: {
    flex: 1,
    margin: '0 -1px',
  },
  totalBalance: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary,
    marginTop: -20,
    marginBottom: 28,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
  },
  cross: {
    display: 'flex',
    margin: theme.spacing(0, -3),
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, 3),
    },
  },
  gasList: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.spacing(2),
    display: 'flex',
    marginBottom: theme.spacing(6),
  },
  gas: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.grey.A700,
    cursor: 'pointer',
    padding: theme.spacing(4, 5),
  },
  currentGas: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: theme.spacing(2),
  },
  divid: {
    margin: theme.spacing(0, 21),
    borderLeft: `1px solid ${theme.palette.grey.A200}`,
    width: 1,
  },
  amountCard: {
    minHeight: 148,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: '0px 7px 8px rgba(76, 191, 122, 0.07)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(8, 6.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& span': {
      color: theme.palette.grey.A400,
    },
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& span': {
      color: theme.palette.grey.A400,
      marginLeft: theme.spacing(2),
    },
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    '& > div': {
      flex: 1,
      maxWidth: '50%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.grey.A400,

      '&:first-child': {
        marginRight: '20px',
      },
      '& span': {
        color: theme.palette.grey.A400,
      },
    },
  },
  whiteSpan: {
    color: `${theme.palette.text.primary} !important`,
  },
}));
