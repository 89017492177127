import { makeStyles, Theme, Typography } from '@material-ui/core';
import { WarningIcon } from 'modules/icons/WarningIcon';

interface IWarningProps {
  content: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4.5, 4.5, 4.5, 13),
    position: 'relative',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.grey[500],
    marginTop: 20,
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(4.5),
    left: theme.spacing(4.5),
  },
}));

export const Warning = ({ content }: IWarningProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Typography variant="body1">{content}</Typography>
    </div>
  );
};
