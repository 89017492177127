import React from 'react';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { SendConfirmIcon } from 'modules/icons/SendConfirmIcon';
import { useCurrentAccount } from '../../state/account/hooks';

interface INFTSendConfirmDialogProps {
  currentSelectNft: any;
  currentCollection: any;
  isOpen: boolean;
  toAddress: string;
  selFee: string;
  onClose: () => void;
  sendTx: () => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  title: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  row: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    textAlign: 'right',
    wordBreak: 'break-all',
    '& h5': {
      minWidth: 80,
      color: theme.palette.common.white,
      textAlign: 'left',
      marginRight: theme.spacing(2),
    },
  },
}));

const NFTSendConfirmDialog = ({
  currentSelectNft,
  currentCollection,
  isOpen,
  toAddress,
  selFee,
  onClose,
  sendTx,
}: INFTSendConfirmDialogProps) => {
  const currentAccount = useCurrentAccount();
  const classes = useStyles();

  return (
    <DialogModal
      title={t('trans-confirm-dialog.title')}
      open={isOpen}
      onClose={onClose}
      handleDialog={sendTx}
    >
      <Typography variant="h4" className={classes.title}>
        <SendConfirmIcon />
        {currentSelectNft.name ??
          `${currentCollection.name} ${currentSelectNft.id}`}
      </Typography>
      <div className={classes.row}>
        <Typography variant="h5">{t('trans-confirm-dialog.from')}</Typography>
        <Typography variant="body1">{currentAccount.evmAddress}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h5">{t('trans-confirm-dialog.to')}</Typography>
        <Typography variant="body1">{toAddress}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h5">
          {t('trans-confirm-dialog.txn-fee')}
        </Typography>
        <Typography variant="body1">{selFee}</Typography>
      </div>
    </DialogModal>
  );
};

export default NFTSendConfirmDialog;
