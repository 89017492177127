import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ApproveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.79279 10.6214C7.40227 11.0119 6.7691 11.0119 6.37858 10.6214L4.29289 8.53573C3.90237 8.14521 3.90237 7.51205 4.29289 7.12152C4.68342 6.731 5.31658 6.731 5.70711 7.12152L7.08569 8.5001L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
