import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import TransferConfirmDialog from '../../components/Dialogs/TransferConfirmDialog';
import TransferComponent, { TransferType } from '../../components/Transfer';

import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useCurrentAccount } from '../../state/account/hooks';
import { useUserSession } from '../../state/user/hooks';

import {
  validAddress,
  getAddressFromAccount,
} from '../../services/WalletService';
import { getPolkadotFee } from '../../services/FeeService';
import {
  getPolkadotDecimals,
  signTransaction,
  getLatestBlockNumber,
} from '../../services/DotApiService';
import { combineSignKey } from '../../services/loginService';
import {
  useTransactionStateUpdate,
  TransactionStatus,
} from '../../services/TransactionService';

import { INPUT_NUMBER_REGEX } from '../../constants/regex';
import { CUSTOM_EVMS } from '../../constants/netEnums';
import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';
import { logoutWallet } from '../../services/logoutService';
var CryptoJS = require('crypto-js');
export default function TransferPolkaFamilyPage() {
  const history = useHistory();
  const [openTransferConfirm, setOpenTransferConfirm] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const [amountErr, setAmountErr] = useState('');
  const [confirming, setConfirming] = useState(false);

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const userSession = useUserSession();
  const updateTransactionState = useTransactionStateUpdate();
  const updateBalance = useUpdateBalance();

  const setPolkadotFee = useCallback(
    async amount => {
      try {
        const from = getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        );
        const fee = await getPolkadotFee(
          from,
          from,
          amount,
          selectedAsset.tokenSymbol,
        );
        const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
        const txnFee = {
          feeBn: fee.totalFee,
          decimals,
          fee: toRealNumber(fee.totalFee, decimals).toFixed(6),
        };
        setTxnFee(txnFee);
      } catch (e) {
        console.log(e);
      }
    },
    [currentAccount, selectedAsset.tokenSymbol],
  );

  const throttle = useMemo(
    () => _.throttle(setPolkadotFee, 1000, { trailing: true, leading: false }),
    [setPolkadotFee],
  );

  const onToAddressChange = e => {
    const input = e.target.value.trim();
    setToAddress(input);
    if (!input || !validAddress(input, selectedAsset)) {
      setToAddressErr('Invalid address.');
      return;
    }

    setToAddressErr('');
  };

  const checkBalanceSufficient = async () => {
    const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
    const amountBn = toBigNumber(amount, decimals);
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const totalBn = amountBn.plus(fee);
    if (totalBn.lte(balance)) {
      setTotal(toRealNumber(totalBn.toFixed(), decimals).toFixed(4));
    }
    return totalBn.lte(balance);
  };

  const onAmountChange = e => {
    const input = e.target.value.trim();
    if (input !== '' && !INPUT_NUMBER_REGEX.test(input)) {
      return;
    }

    setTxnFee({});
    setAmountErr('');
    setAmount(input);
    throttle(input);
  };

  const onMax = async () => {
    setTxnFee({});
    const decimals = await getPolkadotDecimals(selectedAsset.tokenSymbol);
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const amountBn = balance.minus(fee);
    const amount = amountBn.lt(0)
      ? '0'
      : toRealNumber(amountBn.toFixed(), decimals).toString(10);
    setAmount(amount);
    setPolkadotFee(amount);
  };

  const btnDisabled = useMemo(
    () =>
      amount === '' ||
      toAddress === '' ||
      toAddressErr !== '' ||
      amountErr !== '' ||
      _.isEmpty(txnFee) ||
      confirming,
    [amount, toAddress, toAddressErr, amountErr, txnFee, confirming],
  );

  const onConfirm = () => {
    setConfirming(true);
    checkBalanceSufficient()
      .then(ret => {
        if (ret) {
          setOpenTransferConfirm(true);
        } else {
          setAmountErr('Insufficient balance.');
        }
      })
      .finally(() => {
        setConfirming(false);
      });
  };

  const sendTransaction = async () => {
    try {
      const amountBn = toBigNumber(amount, txnFee.decimals);
      const feeBn = toBigNumber(txnFee.feeBn, 0);
      const totalBn = amountBn.plus(feeBn);
      const latestBlockNumber = await getLatestBlockNumber(
        selectedAsset.tokenSymbol,
      );
      const transaction = {
        txnType: TransferType.POLKA_FAMILY,
        from: getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        ),
        to: toAddress,
        amount: amount,
        amountBn: amountBn.toFixed(),
        feeBn: txnFee.feeBn,
        fee: `${txnFee.fee} ${selectedAsset.tokenSymbol}`,
        totalBn: totalBn.toFixed(),
        total: total,
        tokenSymbol: selectedAsset.tokenSymbol,
        networkSymbol: selectedAsset.tokenSymbol,
        latestBlockNumber, // this for query transaction status
      };
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedwords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedwords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedwords === null) {
          throw new Error('Invalid seed words.');
        }
      }

      const signedTransaction = await signTransaction(
        seedwords,
        currentAccount.keypairType,
        transaction,
      );
      const txnHash = signedTransaction.hash.toHex();
      updateTransactionState(transaction, txnHash, TransactionStatus.PENDING);
      setOpenTransferConfirm(false);
      history.push(
        `/homePage/activityDetail/${
          selectedAsset.tokenSymbol
        }/${getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        )}`,
      );
      signedTransaction.send(async result => {
        if (!result || !result.status) {
          return;
        }

        console.log(`transaction : status :: ${JSON.stringify(result)}`);

        const { status } = result;

        if (status.isFinalized) {
          result.events
            .filter(({ event: { section } }) => section === 'system')
            .forEach(({ event: { method } }) => {
              if (method === 'ExtrinsicFailed') {
                updateTransactionState(
                  transaction,
                  txnHash,
                  TransactionStatus.FAIL,
                );
              } else if (method === 'ExtrinsicSuccess') {
                updateTransactionState(
                  transaction,
                  txnHash,
                  TransactionStatus.SUCCESS,
                );
                updateBalance(selectedAsset.tokenSymbol, CUSTOM_EVMS);
              }
            });
        }
        if (
          result.isError ||
          status.isDropped ||
          status.isInvalid ||
          status.isUsurped
        ) {
          updateTransactionState(transaction, txnHash, TransactionStatus.FAIL);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const onSend = () => {
    sendTransaction();
  };

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
  }, [history, selectedAsset, updateSelectedAsset]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    setPolkadotFee('0');

    return () => {
      throttle.cancel();
    };
  }, [selectedAsset, setPolkadotFee, throttle]);

  return (
    <div>
      <TransferComponent
        toAddress={toAddress}
        toAddressErr={toAddressErr}
        onToAddressChange={onToAddressChange}
        onMax={onMax}
        amount={amount}
        amountErr={amountErr}
        onAmountChange={onAmountChange}
        transferType={TransferType.POLKA_FAMILY}
        txnFee={txnFee}
        btnDisabled={btnDisabled}
        onConfirm={onConfirm}
        total
      />
      {openTransferConfirm && (
        <TransferConfirmDialog
          isOpen={openTransferConfirm}
          token={selectedAsset.tokenSymbol}
          from={getAddressFromAccount(
            selectedAsset.tokenSymbol,
            currentAccount,
            CUSTOM_EVMS,
          )}
          to={toAddress}
          txnFee={`${txnFee.fee} ${selectedAsset.tokenSymbol}`}
          total={total}
          amount={amount}
          onClose={() => setOpenTransferConfirm(false)}
          onSend={onSend}
        />
      )}
    </div>
  );
}
