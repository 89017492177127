import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import TransferConfirmDialog from '../../components/Dialogs/TransferConfirmDialog';
import TransferComponent, { TransferType } from '../../components/Transfer';

import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useCurrentAccount } from '../../state/account/hooks';
import { getAddressFromAccount } from '../../services/WalletService';
import {
  useTransactionStateUpdate,
  TransactionStatus,
} from '../../services/TransactionService';
import { INPUT_NUMBER_REGEX } from '../../constants/regex';
import { CUSTOM_EVMS } from '../../constants/netEnums';
import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import { logoutWallet } from '../../services/logoutService';
import { BigNumber } from 'bignumber.js';
import * as bip32 from 'bip32';
import { TRON_NETWORK } from '../../constants/networks';
import {
  checkTrxAddress,
  fullNode,
  getTronEnergyAndBindwidth,
} from '../../services/TrxService';
import { combineSignKey } from '../../services/loginService';
import { useUserSession } from '../../state/user/hooks';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';

const bip39 = require('bip39');
const TronWeb = require('tronweb');
const dayjs = require('dayjs');
var CryptoJS = require('crypto-js');

export default function TransferTrxPage() {
  const history = useHistory();
  const [openTransferConfirm, setOpenTransferConfirm] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const [amountErr, setAmountErr] = useState('');
  const [confirming, setConfirming] = useState(false);
  const [gasTron, setGasTron] = useState({
    energy: 0,
    energyUsed: 0,
    netLimit: 0,
    netUsed: 0,
  });

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const updateTransactionState = useTransactionStateUpdate();
  const userSession = useUserSession();
  const updateBalance = useUpdateBalance();

  const getEnergyAndBanwidth = useCallback(async () => {
    const gasTron = await getTronEnergyAndBindwidth(currentAccount.trxAddress);
    setGasTron(gasTron);
  }, [currentAccount.trxAddress]);

  const setTrxFee = async () => {
    const fee = 0;
    const txnFee = {
      feeBn: fee,
      decimals: TRON_NETWORK.decimals,
      fee: toRealNumber(fee, TRON_NETWORK.decimals).toFixed(),
    };
    setTxnFee(txnFee);
  };

  const onToAddressChange = e => {
    const input = e.target.value.trim();
    setToAddress(input);
    setToAddressErr(checkTrxAddress(input) ? '' : 'Invalid address.');
  };

  const checkBalanceSufficient = async () => {
    const amountBn = toBigNumber(amount, TRON_NETWORK.decimals);
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const totalBn = amountBn.plus(fee);
    if (totalBn.lte(balance)) {
      setTotal(
        toRealNumber(totalBn.toFixed(), TRON_NETWORK.decimals).toFixed(4),
      );
    }
    return totalBn.lte(balance);
  };

  const onAmountChange = e => {
    const input = e.target.value.trim();
    if (input !== '' && !INPUT_NUMBER_REGEX.test(input)) {
      return;
    }
    setAmountErr('');
    setAmount(input);
  };

  const onMax = async () => {
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const amountBn = balance.minus(fee);
    const amount = amountBn.lt(0)
      ? '0'
      : toRealNumber(amountBn.toFixed(), TRON_NETWORK.decimals).toString(10);
    setAmount(amount);
  };

  const btnDisabled = useMemo(
    () =>
      amount === '' ||
      toAddress === '' ||
      toAddressErr !== '' ||
      amountErr !== '' ||
      _.isEmpty(txnFee) ||
      confirming,
    [amount, toAddress, toAddressErr, amountErr, txnFee, confirming],
  );

  const onConfirm = () => {
    setConfirming(true);
    checkBalanceSufficient()
      .then(ret => {
        if (ret) {
          setOpenTransferConfirm(true);
        } else {
          setAmountErr('Insufficient balance.');
        }
      })
      .finally(() => {
        setConfirming(false);
      });
  };

  const sendTransaction = async () => {
    const base = new BigNumber(10).pow(TRON_NETWORK.decimals);
    const amountBn = new BigNumber(amount).times(base);

    let transRecord;
    let txnHash;
    try {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      const seed = await bip39.mnemonicToSeed(seedWords);
      const node = bip32.fromSeed(seed);
      const child = node.derivePath(`m/44'/195'/0'/0/0`);
      const privateKey = child.privateKey.toString('hex');
      const tronWeb = new TronWeb(fullNode, fullNode, fullNode, privateKey);
      const result = await tronWeb.trx.sendTransaction(
        toAddress,
        amount * Math.pow(10, TRON_NETWORK.decimals),
      );
      const fromAddress = getAddressFromAccount(
        selectedAsset.tokenSymbol,
        currentAccount,
        CUSTOM_EVMS,
      );
      transRecord = {
        txnType: TransferType.TRX_TXN,
        from: fromAddress,
        to: toAddress,
        amount,
        amountBn: amountBn.toFixed(0),
        feeBn: txnFee.feeBn,
        fee: `${txnFee.fee} TRX`,
        total: total,
        tokenSymbol: selectedAsset.tokenSymbol,
        networkSymbol: selectedAsset.tokenSymbol,
        sendDate: dayjs().format(),
        internal: {
          address: currentAccount.address,
        },
      };
      if (result.result) {
        txnHash = result.txid;
        await updateTransactionState(
          transRecord,
          result.txid,
          TransactionStatus.PENDING,
        );
        history.push(
          `/homePage/activityDetail/${selectedAsset.tokenSymbol}/${fromAddress}`,
        );
        let id = setInterval(() => {
          tronWeb.trx.getConfirmedTransaction(result.txid).then(res => {
            updateTransactionState(
              transRecord,
              txnHash,
              TransactionStatus.SUCCESS,
            );
            updateBalance('', CUSTOM_EVMS);
            clearInterval(id);
          });
        }, 5000);
      } else {
        updateTransactionState(transRecord, txnHash, TransactionStatus.FAIL);
      }
      return transRecord;
    } catch (e) {
      if (transRecord && txnHash) {
        await updateTransactionState(
          transRecord,
          txnHash,
          TransactionStatus.FAIL,
        );
        return;
      }
      throw new Error('Failed to submit transaction');
    }
  };
  const onSend = () => {
    sendTransaction();
  };

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
  }, [history, selectedAsset, updateSelectedAsset]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }
    getEnergyAndBanwidth();
    const refreshEnergyAndBnadwidth = setInterval(() => {
      getEnergyAndBanwidth();
    }, 5000);
    setTrxFee();
    return () => {
      clearInterval(refreshEnergyAndBnadwidth);
    };
  }, [selectedAsset, getEnergyAndBanwidth]);

  return (
    <div>
      <TransferComponent
        toAddress={toAddress}
        toAddressErr={toAddressErr}
        onToAddressChange={onToAddressChange}
        onMax={onMax}
        amount={amount}
        amountErr={amountErr}
        onAmountChange={onAmountChange}
        transferType={TransferType.TRX_TXN}
        gasTron={gasTron}
        txnFee={txnFee}
        btnDisabled={btnDisabled}
        onConfirm={onConfirm}
        total
      />
      {openTransferConfirm && (
        <TransferConfirmDialog
          isOpen={openTransferConfirm}
          token={selectedAsset.tokenSymbol}
          from={getAddressFromAccount(
            selectedAsset.tokenSymbol,
            currentAccount,
            CUSTOM_EVMS,
          )}
          to={toAddress}
          txnFee={`${txnFee.fee} ${selectedAsset.tokenSymbol}`}
          total={total}
          amount={amount}
          onClose={() => setOpenTransferConfirm(false)}
          onSend={onSend}
        />
      )}
    </div>
  );
}
