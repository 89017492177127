import { createReducer } from '@reduxjs/toolkit';

import {
  transactions
} from './actions'
import { Transaction } from './types';

export interface TransactionState {
  transactions: Array<Transaction>,
}

const initialState: TransactionState = {
  transactions: []
}

export default createReducer(initialState, builder =>
  builder
  .addCase(transactions, (state, action) => {
    const { transactions } = action.payload
    state.transactions = transactions
  })
);
