import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'modules/i18n/intl';
import { Button, Typography, LinearProgress, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import ReceiveDialog from '../../components/Dialogs/ReceiveDialog';
import AddTokenDialog from '../../components/Dialogs/AddTokenDialog';
import AddCustomTokenDialog from '../../components/Dialogs/AddCustomTokenDialog';
import { AddIcon } from 'modules/icons/AddIcon';
import { SendIcon } from 'modules/icons/SendIcon';
import { ReceiveIcon } from 'modules/icons/ReceiveIcon';
import { CrossIcon } from 'modules/icons/CrossIcon';
import { shortenAddress } from '../../utils/helper';
import { useCurrentAccount } from '../../state/account/hooks';
import { emptyAccount } from '../../services/AccountService';
import {
  getMainTokenLogo,
  mainAssets,
  polkadotAsset,
  useAccountContractAssetsUpdate,
} from '../../services/AssetServcie';
import {
  getAddressFromAccount,
  useAccountTotal,
} from '../../services/WalletService';
import { CUSTOM_EVMS } from '../../constants/netEnums';
import { useSelectedAssetUpdate } from '../../state/app/hooks';
import {
  isErc20Asset,
  effectiveBalance,
  shortenText,
} from '../../utils/helper';
import { canCrosschain, CROSS_CHAIN_LIST } from '../../utils/crossChain';
import TokenLogoWithChain from '../../components/TokenLogoWithChain';
import { getErc20Balance } from '../../services/tokenService';
import { setSelectedAssetToSessionStorage } from '../../services/LocalstorageService';
import BigNumber from 'bignumber.js';
import { ToastTooltip } from '../../components/ToastTooltip';
import { useWalletStyles } from './useWalletStyles';
import { CopyIcon } from 'modules/icons/CopyIcon';
import { CorrectIcon } from 'modules/icons/CorrectIcon';

export default function WalletComponent() {
  const [assetsUI, setAssetsUI] = useState([]);
  const [showReceiveDialog, setShowReceiveDialog] = useState(false);
  const [showAddTokenDialog, setShowAddTokenDialog] = useState(false);
  const [showAddCustomTokenDialog, setShowAddCustomTokenDialog] =
    useState(false);
  const [currentAsset, setCurrentAsset] = useState({});
  const history = useHistory();
  const currentAccount = useCurrentAccount();
  const accountTotal = useAccountTotal();
  const [copyIndex, setCopyIndex] = useState();
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const updateSelectedAsset = useSelectedAssetUpdate();
  const updateAccountContractAssets = useAccountContractAssetsUpdate();

  const getTokenPrice = (account, tokenSymbol) => {
    const price = account[tokenSymbol]?.marketData?.USDT ?? 0;
    const amount = account[tokenSymbol]?.amount ?? '0';
    const amountRMB = amount * price;

    return !isNaN(amountRMB) ? amountRMB : 0;
  };

  const copyAddress = addr => {
    navigator.clipboard.writeText(addr);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  const autoAddToken = useCallback(
    async (contractAssets, autoAddArr) => {
      try {
        let assets = await Promise.all(
          autoAddArr.map(async item => {
            const balance = await getErc20Balance(
              item.tokenAddress,
              currentAccount.evmAddress,
              item.chain,
            );
            const amount = balance / Math.pow(10, 18);
            const token = {
              tokenAddress: item.tokenAddress,
              chain: item.chain,
              decimals: 18,
              symbol: item.symbol,
              balance,
              amount: new BigNumber(amount).toFixed(),
              isHide: true,
            };
            return token;
          }),
        );
        assets = _.sortBy(_.concat(contractAssets, assets), [
          t => {
            return t.chain === 'ETH';
          },
        ]);
        await updateAccountContractAssets(currentAccount.address, assets);
      } catch (e) {
        console.log(e);
      }
    },
    [
      currentAccount.address,
      currentAccount.evmAddress,
      updateAccountContractAssets,
    ],
  );

  useEffect(() => {
    if (_.isEmpty(currentAccount)) {
      return;
    }

    const contractAssets = currentAccount.contractAssets ?? [];
    const autoAddArr = [];
    CROSS_CHAIN_LIST.map(item => {
      if (
        !contractAssets.find(
          t => t.tokenAddress === item.tokenAddress && t.chain === item.chain,
        )
      ) {
        autoAddArr.push(item);
      }
    });
    autoAddToken(contractAssets, autoAddArr);
  }, [currentAccount.address]);

  useEffect(() => {
    if (emptyAccount(currentAccount)) {
      return;
    }
    let total = accountTotal(currentAccount.address);

    const assetList = _.difference(mainAssets, currentAccount.hiddenAssets);

    const assets = assetList.map(tokenSymbol => {
      const price = getTokenPrice(currentAccount, tokenSymbol);
      const tempObj = {
        tokenSymbol,
        address: getAddressFromAccount(
          tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        ),
        amount: effectiveBalance(currentAccount[tokenSymbol]?.amount ?? '0'),
        price: price.toFixed(2),
        propotion: total === 0 ? 0 : ((price * 100) / total).toFixed(2),
        isAdd: false,
        isPolkadotFamily: null,
      };
      tempObj.isPolkadotFamily = polkadotAsset(tempObj);
      return tempObj;
    });
    let contractAssets = _.filter(
      currentAccount.contractAssets,
      asset => !asset.isHide,
    );

    contractAssets = !_.isEmpty(contractAssets)
      ? contractAssets.map(asset => {
          const amountRMB =
            (asset?.amount ?? '0') * (asset?.marketData?.USDT ?? 0);
          const price = !isNaN(amountRMB) ? amountRMB : 0;
          return {
            ...asset,
            tokenSymbol: asset.symbol,
            address: isErc20Asset(asset.tokenAddress)
              ? currentAccount.evmAddress
              : asset.tokenAddress,
            amount: effectiveBalance(asset?.amount ?? '0'),
            price: price.toFixed(2),
            propotion: total === 0 ? 0 : ((price * 100) / total).toFixed(2),
            isAdd: asset.isAdd,
            tokenAddress: asset.tokenAddress,
            chain: asset.chain,
            isCrosschain: asset.symbol === 'CLV' || asset.symbol === 'SKU',
            isPolkadotFamily: false,
          };
        })
      : [];

    const allAssets = _.concat(assets, contractAssets);
    const mainCLV = allAssets.filter(token => token.tokenSymbol === 'CLV');
    const mainSKU = allAssets.filter(token => token.tokenSymbol === 'SKU');
    const mainOther = allAssets.filter(
      token => token.tokenSymbol !== 'CLV' && token.tokenSymbol !== 'SKU',
    );

    setAssetsUI(_.concat(mainCLV, mainSKU, mainOther));
  }, [currentAccount.contractAssets, accountTotal]);

  const classes = useWalletStyles();

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <Typography variant="h3">{t('wallet.title')}</Typography>
        <Button
          variant="contained"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => setShowAddTokenDialog(true)}
        >
          {t('wallet.add-token')}
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.gridHead}>
          <Typography variant="body1">
            {t('wallet.head-title.asset')}
          </Typography>
          <Typography variant="body1">
            {t('wallet.head-title.balance')}
          </Typography>
          <Typography variant="body1">
            {t('wallet.head-title.propotion')}
          </Typography>
          <Typography variant="body1" style={{ textAlign: 'right' }}>
            {t('wallet.head-title.price')}
          </Typography>
        </div>
        <div className={classes.gridBody}>
          {assetsUI.map((asset, index) => (
            <div
              key={`asset_${index}`}
              className={classes.gridRow}
              onClick={() => {
                setSelectedAssetToSessionStorage(asset);
                updateSelectedAsset(asset);
                history.push(
                  `/homePage/activityDetail/${asset.tokenSymbol}/${asset.address}`,
                );
              }}
            >
              <div className={classes.asset}>
                {asset.isAdd ? (
                  <TokenLogoWithChain
                    tokenAddress={asset.tokenAddress}
                    chain={asset.chain}
                  />
                ) : asset.isCrosschain ? (
                  <div className={classes.chainLogo}>
                    <img
                      width={30}
                      height={30}
                      src={getMainTokenLogo(asset.tokenSymbol)}
                      alt=""
                    />
                    <img
                      width={16}
                      height={16}
                      src={getMainTokenLogo(asset.chain)}
                      alt=""
                    />
                  </div>
                ) : (
                  <img
                    width={30}
                    height={30}
                    src={getMainTokenLogo(asset.tokenSymbol)}
                    alt=""
                  />
                )}
                <div>
                  <Typography variant="body1">{asset.tokenSymbol}</Typography>
                  {!!asset.address && (
                    <ToastTooltip
                      open={copyIndex === index && copyTooltipOpen}
                      message="Copied!"
                      icon={<CorrectIcon />}
                    >
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.address}
                        onClick={e => {
                          e.stopPropagation();
                          setCopyTooltipOpen(true);
                          setCopyIndex(index);
                          copyAddress(asset.address);
                        }}
                      >
                        {shortenAddress(asset.address)}
                        <CopyIcon className={classes.copyIcon} />
                      </Typography>
                    </ToastTooltip>
                  )}
                </div>
              </div>
              <Typography variant="body1">
                {shortenText(asset.amount, 12)}
              </Typography>
              <div style={{ width: 50 }}>
                <span>{asset.propotion}%</span>
                <LinearProgress
                  variant="determinate"
                  value={Number(asset.propotion)}
                />
              </div>
              <Typography variant="body1" style={{ textAlign: 'right' }}>
                {t('price.usd', { price: asset.price })}
              </Typography>
              <div className={classes.btns}>
                <Tooltip title="Send" placement="top" arrow>
                  <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentAsset(asset);
                      setSelectedAssetToSessionStorage(asset);
                      updateSelectedAsset(asset);
                      if (asset.tokenSymbol === 'SOL') {
                        history.push(`/homePage/transfer/sol`);
                      } else if (asset.tokenSymbol === 'TRX') {
                        history.push(`/homePage/transfer/trx`);
                      } else if (
                        asset.tokenAddress &&
                        isErc20Asset(asset.tokenAddress)
                      ) {
                        history.push(`/homePage/transfer/erc20`);
                      } else if (polkadotAsset(asset)) {
                        history.push(`/homePage/transfer/p`);
                      } else {
                        history.push(`/homePage/transfer/e`);
                      }
                    }}
                  />
                </Tooltip>
                <Tooltip title="Receive" placement="top" arrow>
                  <Button
                    variant="contained"
                    startIcon={<ReceiveIcon />}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentAsset(asset);
                      setShowReceiveDialog(true);
                    }}
                  />
                </Tooltip>
                {asset.tokenSymbol !== 'CLV' && canCrosschain(asset.tokenSymbol, asset.tokenAddress) && (
                  <Tooltip title="Cross-chain" placement="top" arrow>
                    <Button
                      variant="contained"
                      startIcon={<CrossIcon />}
                      onClick={e => {
                        e.stopPropagation();
                        setCurrentAsset(asset);
                        setSelectedAssetToSessionStorage(asset);
                        updateSelectedAsset(asset);
                        history.push(`/homePage/transfer/crossChain`);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showReceiveDialog && (
        <ReceiveDialog
          address={currentAsset.address}
          token={currentAsset.tokenSymbol}
          isOpen={showReceiveDialog}
          onClose={() => setShowReceiveDialog(false)}
        />
      )}
      {showAddTokenDialog && (
        <AddTokenDialog
          isOpen={showAddTokenDialog}
          onClose={() => setShowAddTokenDialog(false)}
          addCustomToken={() => {
            setShowAddTokenDialog(false);
            setShowAddCustomTokenDialog(true);
          }}
        />
      )}
      {showAddCustomTokenDialog && (
        <AddCustomTokenDialog
          isOpen={showAddCustomTokenDialog}
          onClose={() => {
            setShowAddTokenDialog(true);
            setShowAddCustomTokenDialog(false);
          }}
        />
      )}
    </div>
  );
}
