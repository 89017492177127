import log from 'loglevel'
import pump from 'pump'
import stream from 'stream'

import { isMain } from '../utils/utils'
import cloverWebManager from './CloverWebManager'
import store from '../state'
import { showLogin, requestChainName } from '../state/popup/actions'
import { getNetByChainId, CUSTOM_EVMS } from '../constants/netEnums'

if (!isMain) {
  const passthroughStream = new stream.PassThrough({ objectMode: true })
  passthroughStream.on('data', (...arguments_) => {
    log.info('p data:', arguments_)
  })

  // Oauth section
  cloverWebManager.communicationMux.getStream('oauth').on('data', (chunk) => {
    const { name, data: {chainName} } = chunk
    if (name === 'oauth_modal') {
      // show login
      store.dispatch(showLogin({showLogin: true}))
      store.dispatch(requestChainName({requestChainName: chainName}))
    } else if (name === 'oauth') {
      // login progress
    }
  })
  pump(cloverWebManager.communicationMux.getStream('oauth'), passthroughStream, (error) => {
    if (error) log.error(error)
  })

  const initStream = cloverWebManager.communicationMux.getStream('init_stream')
  initStream.on('data', (chunk) => {
    const {
      name,
      data: {
        enableLogging = true
      },
    } = chunk
    if (name === 'init_stream') {
      if (enableLogging) {
        log.enableAll()
      } else {
        log.disableAll()
      }
      initStream.write({
        name: 'init_complete',
        data: { success: true },
      })
    }
  })

  const providerChangeStream = cloverWebManager.communicationMux.getStream('provider_change')
  providerChangeStream.on('data', (chunk) => {
    if (chunk.name === 'show_provider_change') {
      if (chunk.data.override) {
        const network = getNetByChainId(chunk.data.network.chainId, CUSTOM_EVMS)
        log.info('provider:', network)
        if (!network) {
          providerChangeStream.write({
            name: 'provider_change_status',
            data: {
              success: false,
              err: 'Unsupported network',
            },
          })
        } else {
          setTimeout(() => {
            cloverWebManager.cloverController.setCustomRpc(network.rpcUrl, network.chainId, network.ticker, network.nickname).then(() => {
              providerChangeStream.write({
                name: 'provider_change_status',
                data: {
                  success: true,
                },
              })
            })
            .catch((err) => {
              providerChangeStream.write({
                name: 'provider_change_status',
                data: {
                  success: false,
                  err: err.message || 'Provider change status error',
                },
              })
            })
          }, 1000)
        }
        
        return
      }
    }
  })

  // Logout
  cloverWebManager.communicationMux.getStream('logout').on('data', (chunk) => {
    if (chunk.name === 'logOut') {
      cloverWebManager.communicationMux.getStream('status').write({ loggedIn: false })
      cloverWebManager.cloverController.prefsController.store.putState({ selectedAddress: '', solAddress: '' })
    }
  })
}

export default cloverWebManager

