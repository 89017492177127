import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1580_14814)">
          <g opacity="0.7">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.5 21.3332C4.75 21.3332 3.625 20.1665 3.625 15.4998C3.625 10.8332 4.75 9.6665 11.5 9.6665C18.25 9.6665 19.375 10.8332 19.375 15.4998C19.375 20.1665 18.25 21.3332 11.5 21.3332Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.02201 10.0048C7.01076 9.60234 6.99951 9.1695 6.99951 8.70634C6.99951 3.83317 8.12451 2.6665 11.4995 2.6665C14.8745 2.6665 15.9995 3.83317 15.9995 8.70634C15.9995 9.1695 15.9894 9.60234 15.9781 10.0048"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 14.3335V16.6668"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1580_14814">
            <rect width="24" height="24" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
