import React, { useEffect, useState, useCallback } from 'react';
import { Button, Typography } from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { useSettingsStyles } from './useSettingsStyles';
import { DeviceIcon } from 'modules/icons/DeviceIcon';
import { LockIcon } from 'modules/icons/LockIcon';
import { SuccessIcon } from 'modules/icons/SuccessIcon';
import { RejectIcon } from 'modules/icons/RejectIcon';
import { ApproveIcon } from 'modules/icons/ApproveIcon';
import { KeyIcon } from 'modules/icons/settings/KeyIcon';
import { SeedIcon } from 'modules/icons/settings/SeedIcon';
import { SettedIcon } from 'modules/icons/settings/SettedIcon';
import { PasswordIcon } from 'modules/icons/settings/PasswordIcon';
import { FailIcon } from 'modules/icons/FailIcon';
import { QuestionMarkIcon } from 'modules/icons/QuestionMarkIcon';
import { DeleteIcon } from 'modules/icons/DeleteIcon';

import classNames from 'classnames';
import { useUserSession } from '../../state/user/hooks';
import {
  getDeviceAndMigrationRequest,
  deleteDevice,
  deleteMigrationDevice,
  getShare,
} from '../../services/backendService';
import {
  rejectMigrationRequest,
  acceptMigrationRequest,
} from '../../services/loginService';
import { calcSharedC } from '../../utils/cryptoUtils';
import SaveShareDialog from '../../components/Dialogs/SaveShareDialog';
import { getLocalDate } from '../../utils/timeUtils';
import SetRecoveryPasswordDialog from '../../components/Dialogs/SetRecoveryPassword';
import ShowSeedPhraseDialog from '../../components/Dialogs/ShowSeedPhrase';
import classnames from 'classnames';
import { CorrectIcon } from '../../modules/icons/CorrectIcon';
import { ToastTooltip } from '../../components/ToastTooltip';
import { useCurrentAccount } from '../../state/account/hooks';
import { LoadingContent } from 'components/LoadingContent';
var CryptoJS = require('crypto-js');

export default function SettingsComponent() {
  const [currentIndex, setCurrentIndex] = useState();
  const [deviceAndReqeust, setDeviceAndRequest] = useState([]);
  const [saveShareOpen, setSaveShareOpen] = useState(false);
  const [sharedC, setSharedC] = useState('');
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showSeedPhrase, setShowSeedPhrase] = useState(false);
  const [isSeedPhrase, setIsSeedPhrase] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const currentAccount = useCurrentAccount();

  const userSession = useUserSession();
  const userInfo = localStorage.getItem('userInfo');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userSession.userId >= 0) {
      let password =
        userInfo !== null
          ? CryptoJS.AES.decrypt(
              JSON.parse(userInfo)[userSession.userId]?.password ?? '',
              userSession.publicKey,
            )
          : '';
      setUserPassword(password.toString(CryptoJS.enc.Utf8));
    }
  }, [userSession, userInfo]);

  const loadDevice = useCallback(async () => {
    setLoading(true);
    getDeviceAndMigrationRequest(
      userSession.userId,
      userSession.token,
      userSession.uuid,
    )
      .then(data => {
        if (data.success) {
          const list = [...data.migrationRequests, ...data.devices];
          setDeviceAndRequest(list);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [userSession.userId, userSession.token, userSession.uuid]);

  const getHeaderLogo = device => {
    if (!device) {
      return;
    }

    if (device.isDevice || device.status === 'approve') {
      return <SuccessIcon />;
    }

    if (device.status === 'reject') {
      return <FailIcon />;
    }

    return <QuestionMarkIcon />;
  };

  const reject = async device => {
    await rejectMigrationRequest(device, userSession);
    loadDevice();
  };

  const approve = async device => {
    const ret = await acceptMigrationRequest(device, userSession);
    if (ret.success) {
      device.status = 'approve';
      setDeviceAndRequest([...deviceAndReqeust]);
      setTimeout(loadDevice, 30 * 1000);
    } else {
      loadDevice();
    }
  };

  const deleteDeviceOrRequest = async device => {
    try {
      if (device.isDevice) {
        await deleteDevice(device.id, userSession.userId, userSession.token);
      } else {
        await deleteMigrationDevice(
          device.id,
          userSession.uuid,
          userSession.userId,
          userSession.token,
        );
      }

      loadDevice();
    } catch (e) {
      console.log(e);
    }
  };

  const getDeviceButton = device => {
    if (device.isDevice || device.status === 'reject') {
      if (device.uuid !== userSession.uuid) {
        return (
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => deleteDeviceOrRequest(device)}
          >
            {t('action.delete')}
          </Button>
        );
      } else {
        return null;
      }
    }

    if (!device.isDevice && device.status === 'approve') {
      return null;
    }

    return (
      <div className={classes.buttons}>
        <Button
          variant="contained"
          startIcon={<RejectIcon />}
          onClick={() => reject(device)}
        >
          {t('action.reject')}
        </Button>
        <Button
          variant="contained"
          startIcon={<ApproveIcon />}
          onClick={() => approve(device)}
        >
          {t('action.approve')}
        </Button>
      </div>
    );
  };

  const getShareC = async () => {
    let sharedB = null;
    try {
      sharedB = await getShare(userSession.userId, userSession.token);
    } catch (e) {
      // todo toast
    }

    const sharedC = calcSharedC(userSession.sharedA, sharedB);

    setSharedC(sharedC);
    setSaveShareOpen(true);
  };

  const handleShowSeedPhrase = () => {
    if (!userPassword || userPassword === '') {
      setIsSeedPhrase(true);
      setShowSetPassword(true);
    } else {
      setIsSeedPhrase(false);
      setShowSeedPhrase(true);
    }
  };

  const onClose = () => {
    setSaveShareOpen(false);
    setSharedC('');
  };

  useEffect(() => {
    if (userSession.userId >= 0 && userSession.token) {
      loadDevice();
    }
  }, [userSession.userId, userSession.token, loadDevice]);

  const classes = useSettingsStyles();

  return (
    <div className={classes.root}>
      <ToastTooltip
        open={isSuccess}
        message={'Set successfully '}
        icon={<CorrectIcon />}
      />
      <div className={classes.header}>
        <Typography variant="h3">{t('settings.title')}</Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.column}>
          <Typography variant="h4" component="div" className={classes.subTitle}>
            <DeviceIcon />
            {t('settings.device-management')}
          </Typography>
          {loading ? (
            <LoadingContent />
          ) : (
            deviceAndReqeust.map((device, index) => (
              <div key={`device_${index}`}>
                <div
                  className={classNames(
                    classes.device,
                    index === currentIndex && classes.currentDevice,
                  )}
                  onClick={() => {
                    setCurrentIndex(index);
                  }}
                >
                  <div className={classes.info}>
                    {getHeaderLogo(device)}
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 4,
                        }}
                      >
                        {device.info.browserName}
                        {device.isDevice &&
                          device.uuid === userSession.uuid && (
                            <span className={classes.active}></span>
                          )}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {device.latest_login_date
                          ? getLocalDate(device.latest_login_date)
                          : ''}
                      </Typography>
                    </div>
                  </div>
                  {getDeviceButton(device)}
                </div>
                {index === currentIndex && (
                  <div className={classes.detail}>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.item}
                    >
                      {t('settings.browser')}
                      <Typography>
                        {`${device.info.browserName} ${device.info.browserMajorVersion}`}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.item}
                    >
                      {t('settings.os')}
                      <Typography>
                        {`${device.info.osName} ${device.info.osVersion}`}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.item}
                    >
                      {t('settings.last-active-time')}
                      <Typography>
                        {device.latest_login_date
                          ? getLocalDate(device.latest_login_date)
                          : ''}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.item}
                    >
                      {t('settings.register-time')}
                      <Typography>
                        {getLocalDate(device.create_date)}
                      </Typography>
                    </Typography>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        <div className={classes.divid}></div>
        <div className={classes.column}>
          <Typography variant="h4" className={classes.subTitle}>
            <LockIcon />
            {t('settings.key-share')}
          </Typography>
          <div className={classes.privacyWrapper}>
            {currentAccount.isLoginAccount && (
              <div className={classes.privacyItem} onClick={getShareC}>
                <KeyIcon />
                <span>{t('settings.show-key-share')}</span>
              </div>
            )}
            <div className={classes.privacyItem} onClick={handleShowSeedPhrase}>
              <SeedIcon />
              <span>{t('settings.show-seed-phrase')}</span>
            </div>
            {userPassword !== '' ? (
              <div className={classnames(classes.privacyItem, classes.setted)}>
                <div>
                  <SettedIcon />
                  {'set'}
                </div>
                <span>{t('settings.show-recovery-password')}</span>
              </div>
            ) : (
              <>
                <div
                  className={classnames(
                    classes.privacyItem,
                    classes.setPassword,
                  )}
                  onClick={() => setShowSetPassword(true)}
                >
                  <PasswordIcon />
                  <span>{t('settings.show-recovery-password')}</span>
                </div>
                <div className={classes.setPasswordRemind}>
                  <FailIcon />
                  <div>{t('settings.remind')}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {saveShareOpen && (
        <SaveShareDialog
          isOpen={saveShareOpen}
          share={sharedC}
          onClose={onClose}
        />
      )}
      {showSetPassword && (
        <SetRecoveryPasswordDialog
          isOpen={showSetPassword}
          onClose={() => {
            setShowSetPassword(false);
            setIsSeedPhrase(false);
          }}
          onConfirm={() => {
            if (isSeedPhrase) {
              setShowSetPassword(false);
              setShowSeedPhrase(true);
            } else {
              setShowSetPassword(false);
            }
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
            }, 2 * 1000);
          }}
        />
      )}
      {showSeedPhrase && (
        <ShowSeedPhraseDialog
          isOpen={showSeedPhrase}
          onClose={() => setShowSeedPhrase(false)}
          hiddenPassword={isSeedPhrase}
        />
      )}
    </div>
  );
}
