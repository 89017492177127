import { useEffect, useState, useCallback } from 'react';
import {
  Button,
  DialogActions,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { AddIcon } from 'modules/icons/AddIcon';
import { RemoveIcon } from 'modules/icons/RemoveIcon';
import { SearchIcon } from 'modules/icons/SearchIcon';
import { useCurrentAccount } from '../../state/account/hooks';
import {
  mainAssets,
  getMainTokenLogo,
  getNetBySymbol,
  useAccountHiddenAssetsUpdate,
  useAccountContractAssetsUpdate,
} from '../../services/AssetServcie';
import { getTokenList, isErc20Asset } from '../../utils/helper';
import TokenLogoWithChain from '../../components/TokenLogoWithChain';
import RemoveTokenDialog from './RemoveTokenDialog';
import _ from 'lodash';
import { getErc20Balance } from '../../services/tokenService';
import BigNumber from 'bignumber.js';
import { DialogModal } from 'components/DialogModal';
import { canCrosschain } from '../../utils/crossChain';

interface AddTokenDialogProps {
  isOpen: boolean;
  onClose: () => void;
  addCustomToken: () => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  search: {
    height: 52,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey.A100,
    borderRadius: 8,
    padding: theme.spacing(0, 4),
  },
  input: {
    fontSize: 14,
    height: 52,
    lineHeight: 52,
    marginLeft: theme.spacing(3),
    backgroundColor: 'transparent',
    border: 0,
    outline: 'none',
    color: theme.palette.text.secondary,
    '&::-webkit-input-placeholder': {
      fontSize: '12px',
    },
  },
  list: {
    overflowY: 'auto',
    maxHeight: 350,
  },
  noAssets: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assetItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  symbol: {
    marginRight: theme.spacing(3),
  },
}));

const AddTokenDialog = ({
  isOpen,
  onClose,
  addCustomToken,
}: AddTokenDialogProps) => {
  const currentAccount = useCurrentAccount();
  const updateAccountHiddenAssets = useAccountHiddenAssetsUpdate();
  const updateAccountContractAssets = useAccountContractAssetsUpdate();
  const [hiddenAssets, setHiddenAssets] = useState<Array<string>>([]);
  const [contractAssets, setContractAssets] = useState<any>([]);
  const [allTokens, setAllTokens] = useState<any>([]);
  const [assetList, setAssetList] = useState<any>([]);
  const [searchVal, setSearchVal] = useState('');
  const [filterConfigTokenList, setFilterConfigTokenList] = useState<any>([]);
  const [showRemoveTokenDialog, setShowRemoveTokenDialog] = useState(false);
  const [currentToken, setCurrentToken] = useState();

  const configTokenList = _.map(getTokenList(), (value, key) => {
    return _.assignIn(value, { tokenAddress: key.toLowerCase() });
  });

  const handleFilterAssets = useCallback(
    (searchValue: string) => {
      setSearchVal(searchValue);
      const assetList = _.difference(mainAssets, hiddenAssets);
      const tokens = _.sortBy(allTokens, [
        t => {
          return !assetList.includes(t.symbol);
        },
        t => {
          return !t.isAdd;
        },
      ]);
      setAssetList(filterToken(tokens, searchValue));
      setFilterConfigTokenList(
        !searchValue || searchValue === ''
          ? []
          : filterToken(
              _.differenceBy(configTokenList, tokens, 'tokenAddress'),
              searchValue,
            ),
      );
    },
    [allTokens, configTokenList, hiddenAssets],
  );


  useEffect(() => {
    const assets = _.difference(mainAssets, currentAccount.hiddenAssets);
    const showMainTokens = assets
      .filter(t => t !== 'CLV' && t !== 'ETH')
      .map(t => {
        return {
          symbol: t,
          chain: t,
          isAdd: false,
          tokenAddress: '',
        };
      });
    const hiddenMainTokens = currentAccount.hiddenAssets
      .filter((t: string) => t !== 'CLV' && t !== 'ETH')
      .map((t: string) => {
        return {
          symbol: t,
          chain: t,
          isAdd: false,
          tokenAddress: '',
        };
      });
    setHiddenAssets(
      currentAccount && currentAccount.hiddenAssets
        ? currentAccount.hiddenAssets
        : [],
    );
    const contracts =
      currentAccount && currentAccount.contractAssets
        ? currentAccount.contractAssets
        : [];
    setContractAssets(contracts);
    let arr = _.concat(showMainTokens, contracts, hiddenMainTokens);
    setAllTokens(arr);
    setAssetList(arr);
  }, [currentAccount.contractAssets]);

  const filterToken = (tokens: any, str: string) => {
    return _.filter(
      tokens,
      asset =>
        (asset.name &&
          _.includes(asset.name.toLowerCase(), str.toLowerCase())) ||
        (asset.symbol &&
          _.includes(asset.symbol.toLowerCase(), str.toLowerCase())) ||
        (str.length === 42 &&
          asset.tokenAddress &&
          _.includes(asset.tokenAddress.toLowerCase(), str.toLowerCase())),
    );
  };

  const handleHiddenAsset = (token: any) => async () => {
    if (canCrosschain(token.symbol, token.tokenAddress) && token.tokenAddress) {
      const assets = _.map(contractAssets, (asset: any) => {
        if (asset.tokenAddress === token.tokenAddress) {
          return { ...asset, isHide: !asset.isHide };
        }
        return asset;
      });
      try {
        await updateAccountContractAssets(currentAccount.address, assets);
      } catch (e) {
        console.log(e);
      }
    } else {
      let hiddenArr: Array<string> = [...hiddenAssets];
      if (_.includes(hiddenAssets, token.symbol)) {
        hiddenArr = _.filter(hiddenArr, t => t !== token.symbol);
      } else {
        hiddenArr.push(token.symbol);
      }
      try {
        await updateAccountHiddenAssets(currentAccount.address, hiddenArr);
        setHiddenAssets(hiddenArr);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleDeleteToken = (token: any) => async () => {
    const assets: Array<object> = _.filter(
      contractAssets,
      asset =>
        !(
          asset.tokenAddress === token.tokenAddress &&
          asset.chain === token.chain
        ),
    );
    try {
      await updateAccountContractAssets(currentAccount.address, assets);
      setShowRemoveTokenDialog(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddToken = (token: any) => async () => {
    try {
      const balance =
        token.chain === 'ETH' && isErc20Asset(token.tokenAddress)
          ? (await getErc20Balance(
              token.tokenAddress,
              currentAccount.evmAddress,
              token.chain,
            )) / Math.pow(10, token.decimals)
          : 0;
      const amount = balance / Math.pow(10, token.decimals);
      const assets = _.concat(contractAssets, [
        _.assignIn(token, {
          isAdd: true,
          balance,
          amount: new BigNumber(amount).toFixed(),
        }),
      ]);
      setContractAssets(assets);
      await updateAccountContractAssets(currentAccount.address, assets);
    } catch (e) {
      console.log(e);
    }
  };

  const classes = useStyles();

  const handleChange = (e: any) => {
    const searchValue = e.target.value;
    handleFilterAssets(searchValue);
  };

  return (
    <>
      <DialogModal
        title={t('add-token-dialog.title')}
        open={isOpen}
        onClose={onClose}
      >
        <div className={classes.search}>
          <SearchIcon />
          <input
            type="text"
            className={classes.input}
            placeholder={t('add-token-dialog.search-placehold')}
            value={searchVal}
            onChange={handleChange}
          />
        </div>
        <div className={classes.list}>
          {assetList.length > 0 &&
            assetList.map((token: any, index: number) => (
              <AssetItem
                key={`token_${index}`}
                token={token}
                hiddenAssets={hiddenAssets}
                handleHiddenAsset={handleHiddenAsset}
                handleDeleteToken={(token: any) => {
                  setShowRemoveTokenDialog(true);
                  setCurrentToken(token);
                }}
                checked={
                  (!_.includes(hiddenAssets, token.symbol) && !token.isHide) ||
                  (token.symbol === 'SKU' &&
                    token.tokenAddress !== '' &&
                    !token.isHide)
                }
              />
            ))}
          {filterConfigTokenList.length > 0 &&
            filterConfigTokenList.map((token: any, index: number) => (
              <AssetItem
                key={`config_${index}`}
                token={token}
                hiddenAssets={hiddenAssets}
                handleAddAsset={handleAddToken}
                handleDeleteToken={handleDeleteToken}
                checked={_.find(
                  allTokens,
                  t => t.tokenAddress === token.tokenAddress,
                )}
                isConfig
              />
            ))}
          {assetList.length === 0 && filterConfigTokenList.length === 0 && (
            <Typography variant="caption" className={classes.noAssets}>
              {t('add-token-dialog.no-assets')}
            </Typography>
          )}
        </div>
        <DialogActions>
          <Button
            style={{ marginTop: 20 }}
            variant="outlined"
            fullWidth
            size="large"
            startIcon={<AddIcon />}
            onClick={addCustomToken}
          >
            {t('add-custom-token-dialog.title')}
          </Button>
        </DialogActions>
      </DialogModal>
      {showRemoveTokenDialog && (
        <RemoveTokenDialog
          isOpen={showRemoveTokenDialog}
          onClose={() => setShowRemoveTokenDialog(false)}
          confirmRemoveToken={handleDeleteToken(currentToken)}
          token={currentToken}
        />
      )}
    </>
  );
};

const AssetItem = (props: any) => {
  const {
    token,
    checked,
    handleDeleteToken,
    handleHiddenAsset,
    isConfig,
    handleAddAsset,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.assetItem}>
      <div className={classes.column}>
        {isConfig || token.isAdd || token.tokenAddress ? (
          <TokenLogoWithChain
            tokenAddress={token.tokenAddress}
            chain={token.chain}
            crossTokenIcon={
              canCrosschain(token.symbol, token.tokenAddress)
                ? getMainTokenLogo(token.symbol)
                : undefined
            }
          />
        ) : (
          <img
            width={30}
            className={classes.symbol}
            src={getMainTokenLogo(token.symbol)}
            alt={token.isAdd ? token.name : getNetBySymbol(token.chain)}
          />
        )}
        <Typography variant="body1" component="div" color="textPrimary">
          {isConfig || token.isAdd ? token.name : getNetBySymbol(token.chain)}
          <Typography variant="body1" color="textSecondary">
            {token.symbol}
          </Typography>
        </Typography>
      </div>
      {isConfig ? (
        <Switch
          checked={checked}
          onChange={checked ? handleDeleteToken(token) : handleAddAsset(token)}
        />
      ) : token.isAdd ? (
        <Button
          variant="outlined"
          size="small"
          startIcon={<RemoveIcon />}
          onClick={() => handleDeleteToken(token)}
        ></Button>
      ) : (
        <Switch checked={checked} onChange={handleHiddenAsset(token)} />
      )}
    </div>
  );
};

export default AddTokenDialog;
