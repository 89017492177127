import { AppState } from '../index'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { showLogin } from './actions'

export function useShowLogin(): boolean {
  return useSelector((state: AppState) => state.popup.showLogin)
}

export function useShowLoginUpdate(): (showLogin: boolean) => void {
  const dispatch = useDispatch()
  return useCallback((show: boolean) => dispatch(showLogin({showLogin: show})), [dispatch])
}

export function usePopupState(): any {
  const popupPayload = useSelector((state: AppState) => state.popup.popupPayload)
  const popupRequest = useSelector((state: AppState) => state.popup.popupRequest)
  return {popupPayload, popupRequest}
}

export function useRequestChainName(): string {
  return useSelector((state: AppState) => state.popup.requestChainName)
}


