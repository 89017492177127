import React, { useState } from 'react';
import { t } from 'modules/i18n/intl';
import { Button, Typography } from '@material-ui/core';
import { DialogModal } from 'components/DialogModal';
import { EyeCloseIcon } from 'modules/icons/EyeCloseIcon';
import { EyeIcon } from 'modules/icons/EyeIcon';
import { CopyAddress } from 'components/CopyAddress/CopyAddress';
import { Warning } from 'components/Warning/Warning';

interface SaveShareDialogProps {
  isOpen: boolean;
  onClose: (state: string) => void;
  share: string;
}

const SaveShareDialog = ({ isOpen, onClose, share }: SaveShareDialogProps) => {
  const [showShare, setShowShare] = useState(false);

  const onDismiss = () => {
    onClose('close');
  };

  const onClickButton = () => {
    setShowShare(!showShare);
  };
  return (
    <DialogModal
      subtitle={t('save-share-dialog.subtitle')}
      open={isOpen}
      onClose={onDismiss}
    >
      <Typography variant="body1" color="textSecondary">
        {t('save-share-dialog.tip')}
      </Typography>
      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={onClickButton}
        style={{ marginTop: 40, marginBottom: 20 }}
        startIcon={showShare ? <EyeCloseIcon /> : <EyeIcon />}
      >
        <Typography variant="body1">
          {showShare
            ? t('save-share-dialog.hide-your-key-share')
            : t('save-share-dialog.show-your-key-share')}
        </Typography>
      </Button>
      {showShare && <CopyAddress address={share} />}
      <Warning content={t('save-share-dialog.warning')} />
    </DialogModal>
  );
};

export default SaveShareDialog;
