const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export function getLocalDate(utcDateStr) {
  const timeZone = dayjs.tz.guess()
  return dayjs(utcDateStr).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')
}

