import { alpha, makeStyles, Theme } from '@material-ui/core';

export const useCollectionDetailStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  content: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    height: 'calc(100vh - 120px)',
    marginTop: theme.spacing(12),
  },
  collection: {
    width: 424,
    height: 424,
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(20),
  },
  info: {
    width: theme.spacing(124),
    height: '100%',
    overflow: 'auto',
    paddingBottom: theme.spacing(10),
  },
  infoTitle: {
    marginBottom: theme.spacing(2),
  },
  infoCard: {
    borderRadius: 8,
    marginBottom: 10,
    background: theme.palette.grey[400],
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.05)}`,
    '& p:first-child': {
      color: theme.palette.text.secondary,
    },
  },
}));
