import { makeStyles, Theme, Typography } from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { useAccountTotal } from '../../../services/WalletService';

const useBalanceStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(5, 6),
  },
}));

export const MyBalance = () => {
  const classes = useBalanceStyles();

  const totalAccount = useAccountTotal();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">{t('my-balance.title')}</Typography>
      <Typography variant="caption">
        {t('price.usd', { price: totalAccount().toFixed(2) })}
      </Typography>
    </div>
  );
};
