import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DiscordIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M10 0C4.48602 0 2.68754e-05 4.08579 2.68754e-05 9.10801C2.68754e-05 10.8635 0.548693 12.564 1.58936 14.0344C1.39236 16.2396 0.864359 17.8767 0.0976934 18.6522C-0.00363978 18.7547 -0.0289731 18.9116 0.0350268 19.0411C0.0916934 19.1568 0.20836 19.228 0.33336 19.228C0.348693 19.228 0.364026 19.227 0.379693 19.2247C0.514693 19.2054 3.65069 18.7487 5.91902 17.4236C7.20702 17.9495 8.57902 18.216 10 18.216C15.514 18.216 20 14.1302 20 9.10801C20 4.08579 15.514 0 10 0ZM5.33335 10.4573C4.59802 10.4573 4.00002 9.85217 4.00002 9.10801C4.00002 8.36385 4.59802 7.75868 5.33335 7.75868C6.06869 7.75868 6.66668 8.36385 6.66668 9.10801C6.66668 9.85217 6.06869 10.4573 5.33335 10.4573ZM10 10.4573C9.26468 10.4573 8.66668 9.85217 8.66668 9.10801C8.66668 8.36385 9.26468 7.75868 10 7.75868C10.7353 7.75868 11.3333 8.36385 11.3333 9.10801C11.3333 9.85217 10.7353 10.4573 10 10.4573ZM14.6667 10.4573C13.9313 10.4573 13.3333 9.85217 13.3333 9.10801C13.3333 8.36385 13.9313 7.75868 14.6667 7.75868C15.402 7.75868 16 8.36385 16 9.10801C16 9.85217 15.402 10.4573 14.6667 10.4573Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
