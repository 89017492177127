import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SeedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M4.05 0C5.424 0 6.752 0.487 7.797 1.373C8.841 2.25681 9.53473 3.48466 9.753 4.835C10.7586 3.83601 12.1196 3.27708 13.537 3.281H17.25V5.331C17.2504 6.03197 17.1119 6.72606 16.8424 7.37316C16.5729 8.02026 16.1778 8.60752 15.68 9.101C14.6723 10.1031 13.3082 10.6645 11.887 10.662H9.825V18H8.175V8.202H6.525C4.993 8.202 3.525 7.597 2.441 6.52C1.90537 5.98818 1.48021 5.35568 1.19 4.65889C0.899787 3.96211 0.750251 3.21481 0.75 2.46V0H4.05ZM15.6 4.921H13.537C12.553 4.921 11.609 5.31 10.912 6.002C10.5677 6.3439 10.2944 6.75051 10.1078 7.19844C9.92129 7.64637 9.82517 8.12678 9.825 8.612V9.022H11.888C12.872 9.022 13.816 8.633 14.513 7.941C14.8574 7.59898 15.1308 7.19221 15.3173 6.74409C15.5039 6.29598 15.6 5.81539 15.6 5.33V4.92V4.921ZM4.05 1.641H2.4V2.461C2.4 3.548 2.835 4.591 3.608 5.361C4.38371 6.13052 5.43235 6.56191 6.525 6.561H8.175V5.741C8.17527 5.20177 8.06865 4.66784 7.86131 4.17007C7.65396 3.6723 7.35 3.22057 6.967 2.841C6.19191 2.07055 5.14287 1.639 4.05 1.641V1.641Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
