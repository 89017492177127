import { createReducer } from '@reduxjs/toolkit';
import { createAccountInfo } from '../../utils/AccountUtils';
import { accountList, accountInfo, wrongNetwork, stakeInfo, auctionInfos, loginAccount, importedAccounts } from './actions';
import { AccountInfo, WalletAccount } from './types';

export interface WalletState {
  accountList: any,
  accountInfo: AccountInfo,
  wrongNetwork: boolean,
  stakeInfo: any,
  auctionInfos: any,
  loginAccount: WalletAccount,
  importedAccounts: Array<WalletAccount>,
}

export const emptyWalletAccount = {
  clvAddress: '',
  clvBalance: '0',
  ksmAddress: '',
  ksmBalance: '0',
  evmAddress: '',
  ethBalance: '0',
  bscBalance: '0',
}
const initialState: WalletState = {
  accountList: [],
  accountInfo: createAccountInfo('', '', '', '', []),
  wrongNetwork: false,
  stakeInfo: {},
  auctionInfos: {},
  loginAccount: emptyWalletAccount,
  importedAccounts: []
}

export default createReducer(initialState, builder =>
  builder
    .addCase(accountList, (state, action) => {
      const {accountList} = action.payload
      state.accountList = accountList
    })
    .addCase(accountInfo, (state, action) => {
      const {accountInfo} = action.payload
      state.accountInfo = accountInfo
    })
    .addCase(wrongNetwork, (state, action) => {
      const {wrongNetwork} = action.payload
      state.wrongNetwork = wrongNetwork
    })
    .addCase(stakeInfo, (state, action) => {
      const {stakeInfo} = action.payload
      state.stakeInfo = stakeInfo
    })
    .addCase(auctionInfos, (state, action) => {
      const {auctionInfos} = action.payload
      state.auctionInfos = auctionInfos
    })
    .addCase(loginAccount, (state, action) => {
      const {loginAccount} = action.payload
      state.loginAccount = loginAccount
    })
    .addCase(importedAccounts, (state, action) => {
      const {importedAccounts} = action.payload
      state.importedAccounts = importedAccounts
    })
);
