import React from 'react';
import { SvgIconProps } from '@material-ui/core';

export const SettedIcon = (props: SvgIconProps) => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
      <path
        d="M8.5 1L3.60711 5.89289L1 3.28578"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
