import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Social } from 'components/Social/Social';
import { tHTML } from 'modules/i18n/intl';

const useFooterStyle = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.text.secondary,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 30px',
      '& > div': {
        marginBottom: '10px'
      }
    }
  },
}));

const Footer = () => {
  const classes = useFooterStyle();
  return (
    <footer className={classes.root}>
      <Social />
      {tHTML('footer.content')}
    </footer>
  );
};

export default Footer;
