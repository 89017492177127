import React, { useMemo, useState, useEffect } from 'react';

import _ from 'lodash';
import { t } from 'modules/i18n/intl';
import { Button, Typography, LinearProgress } from '@material-ui/core';
import { useTransferStyles } from './useTransferStyles';
import { DataLoadingIcon } from 'modules/icons/loading/DataLoadingIcon';
import Header from '../../components/Header';
import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { getMainTokenLogo, getNetBySymbol } from '../../services/AssetServcie';
import { useCurrentAccount } from '../../state/account/hooks';
import { getCrossChainListByAsset } from '../../utils/crossChain';
import { effectiveBalance } from '../../utils/helper';
import { InputField } from 'components/InputField/InputField';
import classNames from 'classnames';
import { SelectField } from 'components/SelectField/SelectField';

export enum TransferType {
  SOL_TRX = 'sol_trx',
  TRX_TXN = 'trx_txn',
  POLKA_FAMILY = 'polka-family',
  ETH_FAMILY = 'eth-family',
  ERC20 = 'erc20',
  CROSS_CHAIN = 'cross-chain',
}

interface TansferComponentProps {
  title?: string;
  toAddress: string;
  toAddressErr: string;
  onToAddressChange: (e: any) => void;
  onMax: () => void;
  amount: string;
  amountErr: string;
  onAmountChange: (e: any) => void;
  transferType: TransferType;
  txnFee: any;
  btnDisabled: boolean;
  onConfirm: () => void;
  total: string;
  setTransactionFee: (txnFee: object) => void;
  crossChainTxnFee?: any;
  crossChainToChain?: string;
  gasTron?: any;
  setCrossChainToChain: (chain: string) => void;
}

const TransferComponent = ({
  title,
  toAddress,
  toAddressErr,
  onToAddressChange,
  onMax,
  amount,
  amountErr,
  onAmountChange,
  transferType,
  txnFee,
  btnDisabled,
  onConfirm,
  total,
  setTransactionFee,
  crossChainTxnFee,
  crossChainToChain,
  gasTron,
  setCrossChainToChain,
}: TansferComponentProps) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  const selectedAsset = useSelectedAsset();
  const currentAccount = useCurrentAccount();
  const updateSelectedAsset = useSelectedAssetUpdate();

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    let currentAsset;
    if (selectedAsset.isAdd || selectedAsset.isCrosschain) {
      currentAsset = _.find(
        currentAccount.contractAssets,
        ass => ass.tokenAddress === selectedAsset.tokenAddress,
      );
    } else {
      currentAsset = currentAccount[selectedAsset.tokenSymbol];
    }
    if (currentAsset) {
      updateSelectedAsset({
        ...selectedAsset,
        balance: currentAsset.balance,
        amount: effectiveBalance(currentAsset.amount),
      });
    }
    // eslint-disable-next-line
  }, []);

  const needFeeSelect = useMemo(() => {
    if (
      (transferType === TransferType.ETH_FAMILY &&
        selectedAsset.tokenSymbol === 'ETH') ||
      transferType === TransferType.ERC20 ||
      (transferType === TransferType.CROSS_CHAIN &&
        selectedAsset.chain === 'ETH')
    ) {
      return true;
    }
    return false;
  }, [selectedAsset, transferType]);

  const gasTronEnergy = useMemo(() => gasTron?.energy, [gasTron]);
  const gasTronEnergyUsed = useMemo(() => gasTron?.energyUsed, [gasTron]);
  const gasTronEnergyRatio = useMemo(() => {
    const result = _.toNumber(
      (((gasTronEnergy - gasTronEnergyUsed) * 100) / gasTronEnergy).toFixed(2),
    );
    return isNaN(result) ? 0 : result;
  }, [gasTronEnergy, gasTronEnergyUsed]);

  const gasTronNetLimit = useMemo(() => gasTron?.netLimit, [gasTron]);
  const gasTronNetUsed = useMemo(() => gasTron?.netUsed, [gasTron]);
  const gasTronNetRatio = useMemo(() => {
    const result = _.toNumber(
      (((gasTronNetLimit - gasTronNetUsed) * 100) / gasTronNetLimit).toFixed(2),
    );
    return isNaN(result) ? 0 : result;
  }, [gasTronNetLimit, gasTronNetUsed]);

  const tokenSymbol = useMemo(
    () => selectedAsset.chain ?? selectedAsset.tokenSymbol,
    [selectedAsset],
  );

  const classes = useTransferStyles();

  return (
    selectedAsset && (
      <div className={classes.root}>
        <Header title={title ?? t('transfer.title')} />
        <div className={classes.content}>
          <div className={classes.column}>
            {transferType !== TransferType.CROSS_CHAIN ? (
              <InputField
                disabled={true}
                label={t('transfer.network')}
                leftContent={
                  <img
                    src={getMainTokenLogo(tokenSymbol)}
                    alt={getNetBySymbol(tokenSymbol)}
                  />
                }
                value={getNetBySymbol(tokenSymbol)}
              />
            ) : (
              <div className={classes.cross}>
                <InputField
                  label={t('transfer.from')}
                  disabled={true}
                  leftContent={
                    <img
                      src={getMainTokenLogo(tokenSymbol)}
                      alt={getNetBySymbol(tokenSymbol)}
                    />
                  }
                  value={getNetBySymbol(tokenSymbol)}
                />
                {crossChainToChain &&
                  (crossChainToChain === 'CLV' ||
                    crossChainToChain === 'SKU') && (
                    <InputField
                      label={t('transfer.to')}
                      disabled={true}
                      leftContent={
                        <img
                          src={getMainTokenLogo(crossChainToChain)}
                          alt={getNetBySymbol(crossChainToChain)}
                        />
                      }
                      value={getNetBySymbol(crossChainToChain)}
                    />
                  )}
                {crossChainToChain &&
                  crossChainToChain !== 'CLV' &&
                  crossChainToChain !== 'SKU' && (
                    <SelectField
                      label={t('transfer.to')}
                      handleChange={event => {
                        const chain = event.target.value;
                        setCrossChainToChain(chain);
                      }}
                      options={getCrossChainListByAsset(
                        selectedAsset.tokenSymbol,
                      ).map(item => ({
                        label: getNetBySymbol(item.chain),
                        icon: (
                          <img
                            src={getMainTokenLogo(item.chain)}
                            alt={item.chain}
                          />
                        ),
                        value: item.chain,
                      }))}
                      value={crossChainToChain}
                    />
                  )}
              </div>
            )}
            <InputField
              label={t('transfer.recipient')}
              value={toAddress}
              errorInfo={toAddressErr}
              placeHolder={t('transfer.recipient-placeholder')}
              onChange={e => onToAddressChange(e)}
            />
            <InputField
              label={
                <>
                  {t('transfer.amount-label', {
                    token: selectedAsset.tokenSymbol,
                  })}
                  <Button
                    variant="text"
                    onClick={onMax}
                    style={{ marginBottom: -12 }}
                  >
                    {t('transfer.max')}
                  </Button>
                </>
              }
              errorInfo={amountErr}
              placeHolder={t('transfer.amount-placeholder')}
              value={amount}
              onChange={e => onAmountChange(e)}
              rightContent={selectedAsset.tokenSymbol}
            />
            <Typography variant="body1" className={classes.totalBalance}>
              {`${selectedAsset.amount} ${selectedAsset.tokenSymbol}`}
            </Typography>
            <Typography className={classes.row} component="div">
              <Typography variant="subtitle1">
                {t('transfer.txn-fee')}
              </Typography>
              {!needFeeSelect && _.isEmpty(txnFee) ? (
                <DataLoadingIcon />
              ) : (
                <Typography component="div">
                  {t('transfer.token-fee', {
                    fee: txnFee.fee,
                    token: tokenSymbol,
                  })}
                </Typography>
              )}
            </Typography>
            {needFeeSelect && txnFee.gasList && (
              <div className={classes.gasList}>
                {txnFee.gasList.map((fee: any, index: number) => (
                  <div
                    key={`fee_${index}`}
                    className={classNames(
                      classes.gas,
                      currentIndex === index && classes.currentGas,
                    )}
                    onClick={() => {
                      setTransactionFee({
                        ...txnFee,
                        feeSelected: txnFee.gasList[index],
                      });
                      setCurrentIndex(index);
                    }}
                  >
                    <Typography variant="h4">{fee.level}</Typography>
                    <Typography variant="body2">{fee.fee}</Typography>
                    <Typography variant="body2">${fee.total}</Typography>
                  </div>
                ))}
              </div>
            )}
            {crossChainTxnFee && (
              <Typography className={classes.row} component="div">
                <Typography variant="subtitle1">
                  {t('transfer.cross-fee', {
                    token: selectedAsset.tokenSymbol,
                  })}
                </Typography>
                {!crossChainTxnFee ? (
                  <DataLoadingIcon />
                ) : (
                  <Typography component="div">
                    {t('transfer.token-fee', {
                      fee: crossChainTxnFee,
                      token: selectedAsset.tokenSymbol,
                    })}
                  </Typography>
                )}
              </Typography>
            )}
            <Button
              variant="contained"
              size="large"
              fullWidth
              disabled={btnDisabled}
              onClick={onConfirm}
            >
              {t('action.continue')}
            </Button>
          </div>
          <div className={classes.divid}></div>
          <div className={classes.column}>
            <div className={classes.amountCard}>
              <Typography variant="h4" className={classes.info}>
                {t('transfer.account-balance')}
                {currentAccount[selectedAsset.tokenSymbol]?.marketData
                  ?.USDT && (
                  <Typography variant="body1" component="span">{`1 ${
                    selectedAsset.tokenSymbol
                  } = ${currentAccount[
                    selectedAsset.tokenSymbol
                  ]?.marketData?.USDT.toFixed(2)} USD`}</Typography>
                )}
              </Typography>
              <Typography
                variant="h1"
                component="div"
                className={classes.amount}
              >
                {selectedAsset.amount}
                <Typography variant="body1" component="span"></Typography>
              </Typography>
              {gasTron && (
                <div className={classes.progressWrapper}>
                  <div>
                    <Typography variant="body1" component="span">
                      <span className={classes.whiteSpan}>
                        {t('transfer.energy')}
                      </span>{' '}
                      {gasTronEnergy - gasTronEnergyUsed} / {gasTronEnergy}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={gasTronEnergyRatio}
                    />
                  </div>
                  <div>
                    <Typography variant="body1" component="span">
                      <span className={classes.whiteSpan}>
                        {t('transfer.bandwidth')}
                      </span>{' '}
                      {gasTron.netLimit - gasTron.netUsed} / {gasTron.netLimit}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={gasTronNetRatio}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TransferComponent;
