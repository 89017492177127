import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ethers } from 'ethers';
import { hexToUtf8 } from 'web3-utils';
import log from 'loglevel';
import {
  useShowLogin,
  useShowLoginUpdate,
  usePopupState,
  useRequestChainName,
} from '../../state/popup/hooks';
import cloverWebManager from '../../services/channelSetupService';
import PopupLoginDialog from '../../components/Dialogs/PopupLoginDialog';
import PopupWithBcHandler from '../../handlers/Popup/PopupWithBcHandler';
import {
  FEATURES_CONFIRM_WINDOW,
  TRANSACTION_ENVELOPE_TYPES,
  TRANSACTION_TYPES,
  MESSAGE_TYPE,
} from '../../constants/netEnums';
import { LoginActionName, combineSignKey } from '../../services/loginService';
import { getUser } from '../../services/PopupService';
import {
  getAddressFromMnemonic,
  getWallet,
} from '../../services/SolanaService';
import { getAddress } from '../../services/DotApiService';
import { combineKey } from '../../utils/cryptoUtils';
import MigrationRequestDialog from '../../components/Dialogs/MigrationRequestDialog';
import SaveShareDialog from '../../components/Dialogs/SaveShareDialog';

export default function PopupPage() {
  const [loginResult, setLoginResult] = useState({});
  const [migrationRequestOpen, setMigrationRequestOpen] = useState(false);
  const [showSharedC, setShowSharedC] = useState(false);
  const [sharedC, setSharedC] = useState('');

  const showLogin = useShowLogin();
  const updateShowLogin = useShowLoginUpdate();
  const { popupPayload, popupRequest } = usePopupState();
  const requestChainName = useRequestChainName();

  function getLatestMessageParameters(id) {
    const states = cloverWebManager.cloverController.getState();
    let message = null;
    let type = '';
    if (states.unapprovedMsgs[id]) {
      message = states.unapprovedMsgs[id];
      type = MESSAGE_TYPE.ETH_SIGN;
    } else if (states.unapprovedPersonalMsgs[id]) {
      message = states.unapprovedPersonalMsgs[id];
      type = MESSAGE_TYPE.PERSONAL_SIGN;
    } else if (states.unapprovedSolanaMsgs[id]) {
      message = states.unapprovedSolanaMsgs[id];
      type = MESSAGE_TYPE.SOLANA_SIGN;
    } else if (states.unapprovedPolkadotMsgs[id]) {
      message = states.unapprovedPolkadotMsgs[id];
      type = MESSAGE_TYPE.POLKADOT_SIGN;
    } else if (states.unapprovedPolkadotTxnMsgs[id]) {
      message = states.unapprovedPolkadotTxnMsgs[id];
      type = MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION;
    }

    // handle hex-based messages and convert to text
    if (
      type !== '' &&
      type !== MESSAGE_TYPE.SOLANA_SIGN &&
      type !== MESSAGE_TYPE.POLKADOT_SIGN &&
      type !== MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION
    ) {
      if (message) {
        let finalMessage;
        try {
          finalMessage = hexToUtf8(message.msgParams.data);
        } catch {
          finalMessage = message.msgParams.data;
        }
        message.msgParams.message = finalMessage;
      }
    }

    if (states.unapprovedTypedMessages[id]) {
      message = states.unapprovedTypedMessages[id];
      message.msgParams.typedMessages = message.msgParams.data; // TODO: use for differentiating msgs later on
      type = MESSAGE_TYPE.ETH_SIGN_TYPED_DATA;
    }

    if (states.unapprovedEncryptionPublicKeyMsgs[id]) {
      message = states.unapprovedEncryptionPublicKeyMsgs[id];
      type = MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY;
    }

    if (states.unapprovedDecryptMsgs[id]) {
      message = states.unapprovedDecryptMsgs[id];
      type = MESSAGE_TYPE.ETH_DECRYPT;
    }

    return message ? { msgParams: message.msgParams, id, type } : {};
  }

  useEffect(() => {
    if (_.isEmpty(popupRequest)) {
      return;
    }

    const isTx = popupPayload && typeof popupPayload === 'object';
    const windowId = isTx ? popupPayload.id : popupPayload;
    const channelName = `clover_channel_${windowId}`;
    let changablePayload = { ...popupPayload };
    if (isTx) {
      changablePayload.type = TRANSACTION_TYPES.STANDARD_TRANSACTION;
    } else {
      const { msgParams, type } = getLatestMessageParameters(popupPayload);
      changablePayload.msgParams = { msgParams, id: windowId };
      changablePayload.type = type;
      changablePayload.id = windowId;
    }

    // todo get url from window.origin
    const finalUrl = `${window.location.origin}/#/confirm?instanceId=${windowId}&integrity=true&id=${windowId}`;
    const openConfirmWindow = async () => {
      try {
        const confirmWindow = new PopupWithBcHandler({
          url: finalUrl,
          target: '_blank',
          features: FEATURES_CONFIRM_WINDOW,
          channelName,
          preopenInstanceId: popupRequest.preopenInstanceId,
        });
        const result = await confirmWindow.handleWithHandshake({
          payload: changablePayload,
        });
        const { approve = false } = result;
        if (approve) onConfirm({ data: result });
        else onDeny(changablePayload.id, changablePayload.type);
      } catch {
        onDeny(changablePayload.id, changablePayload.type);
      }
    };

    openConfirmWindow();
  }, [popupPayload, popupRequest]);

  const onDeny = async (id, txType) => {
    if (txType === TRANSACTION_TYPES.STANDARD_TRANSACTION) {
      cloverWebManager.cloverController.cancelTransaction(
        Number.parseInt(id, 10),
      );
    }
    if (txType === MESSAGE_TYPE.PERSONAL_SIGN) {
      cloverWebManager.cloverController.cancelPersonalMessage(
        Number.parseInt(id, 10),
      );
    } else if (txType === MESSAGE_TYPE.ETH_SIGN) {
      cloverWebManager.cloverController.cancelMessage(Number.parseInt(id, 10));
    } else if (txType === MESSAGE_TYPE.ETH_SIGN_TYPED_DATA) {
      cloverWebManager.cloverController.cancelTypedMessage(
        Number.parseInt(id, 10),
      );
    } else if (txType === MESSAGE_TYPE.SOLANA_SIGN) {
      cloverWebManager.cloverController.cancelSolanaMessage(
        Number.parseInt(id, 10),
      );
    } else if (txType === MESSAGE_TYPE.POLKADOT_SIGN) {
      cloverWebManager.cloverController.cancelPolkadotMessage(
        Number.parseInt(id, 10),
      );
    } else if (txType === MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION) {
      cloverWebManager.cloverController.cancelPolkadotTransaction(
        Number.parseInt(id, 10),
      );
    }
    // else if (txType === TRANSACTION_TYPES.STANDARD_TRANSACTION) {
    //   cloverController.cancelTransaction(Number.parseInt(id, 10))
    // } else if (txType === MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY) {
    //   cloverController.cancelEncryptionPublicKey(Number.parseInt(id, 10))
    // } else if (txType === MESSAGE_TYPE.ETH_DECRYPT) {
    //   cloverController.cancelDecryptMessage(Number.parseInt(id, 10))
    // }
  };

  const onConfirm = async result => {
    const states = cloverWebManager.cloverController.getState();
    let txMeta = _.find(
      states.unapprovedTxs,
      x => x.id.toString() === result.data.id,
    );
    log.info('STANDARD TX PARAMS:', txMeta);

    if (result.data.txType === TRANSACTION_TYPES.STANDARD_TRANSACTION) {
      if (
        result.data.gasPrice ||
        (result.data.maxPriorityFeePerGas && result.data.maxFeePerGas) ||
        result.data.gas ||
        result.data.customNonceValue
      ) {
        const newTxMeta = JSON.parse(JSON.stringify(txMeta));
        if (result.data.gasPrice) {
          log.info('Changed gas price to:', result.data.gasPrice);
          newTxMeta.txParams.gasPrice = result.data.gasPrice;
          delete newTxMeta.txParams.maxFeePerGas;
          delete newTxMeta.txParams.maxPriorityFeePerGas;
        }
        // todo support london hf
        // if (result.data.maxPriorityFeePerGas && result.data.maxFeePerGas) {
        //   newTxMeta.txParams.maxPriorityFeePerGas = result.data.maxPriorityFeePerGas
        //   newTxMeta.txParams.maxFeePerGas = result.data.maxFeePerGas
        //   delete newTxMeta.txParams.gasPrice
        // }
        // if (result.data.txEnvelopeType) {
        //   newTxMeta.txParams.type = result.data.txEnvelopeType
        // }
        // if (result.data.gas) {
        //   log.info('Changed gas limit to:', result.data.gas)
        //   newTxMeta.txParams.gas = result.data.gas
        // }
        newTxMeta.txParams.gasLimit = newTxMeta.txParams.gas;
        newTxMeta.txParams.type = TRANSACTION_ENVELOPE_TYPES.LEGACY;
        if (result.data.customNonceValue) {
          log.info('Changed nonce to:', result.data.customNonceValue);
          newTxMeta.txParams.customNonceValue = result.data.customNonceValue;
        }
        cloverWebManager.cloverController.txController.updateTransaction(
          newTxMeta,
        );
        txMeta = newTxMeta;
        log.info('New txMeta: ', txMeta);
      }
      cloverWebManager.cloverController.updateAndApproveTransaction(txMeta);
    } else if (result.data.txType === MESSAGE_TYPE.PERSONAL_SIGN) {
      const { msgParams } = states.unapprovedPersonalMsgs[result.data.id];
      log.info('PERSONAL MSG PARAMS:', msgParams);
      msgParams.metamaskId = Number.parseInt(result.data.id, 10);
      cloverWebManager.cloverController.signPersonalMessage(msgParams);
    } else if (result.data.txType === MESSAGE_TYPE.ETH_SIGN) {
      const { msgParams } = states.unapprovedMsgs[result.data.id];
      log.info(' MSG PARAMS:', msgParams);
      msgParams.metamaskId = Number.parseInt(result.data.id, 10);
      cloverWebManager.cloverController.signMessage(msgParams);
    } else if (result.data.txType === MESSAGE_TYPE.ETH_SIGN_TYPED_DATA) {
      const { msgParams } = states.unapprovedTypedMessages[result.data.id];
      log.info('TYPED MSG PARAMS:', msgParams);
      msgParams.metamaskId = Number.parseInt(result.data.id, 10);
      cloverWebManager.cloverController.signTypedMessage(msgParams);
    } else if (result.data.txType === MESSAGE_TYPE.SOLANA_SIGN) {
      const { msgParams } = states.unapprovedSolanaMsgs[result.data.id];
      log.info('SOLANA MSG PARAMS:', msgParams);
      cloverWebManager.cloverController.signSolanaMessage(
        Number.parseInt(result.data.id, 10),
      );
    } else if (result.data.txType === MESSAGE_TYPE.POLKADOT_SIGN) {
      const { msgParams } = states.unapprovedPolkadotMsgs[result.data.id];
      log.info('POLKADOT MSG PARAMS:', msgParams);
      cloverWebManager.cloverController.signPolkadotMessage(
        Number.parseInt(result.data.id, 10),
      );
    } else if (result.data.txType === MESSAGE_TYPE.POLKADOT_SIGN_TRANSACTION) {
      const { msgParams } = states.unapprovedPolkadotTxnMsgs[result.data.id];
      log.info('POLKADOT MSG PARAMS:', msgParams);
      cloverWebManager.cloverController.signPolkadotTransaction(
        Number.parseInt(result.data.id, 10),
      );
    } else {
      throw new Error('No new transactions.');
    }
  };

  const onClose = state => {
    const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
    updateShowLogin(false);
    if (state === 'cancel') {
      oauthStream.write({
        name: 'oauth_modal',
        err: 'Cancel Login',
      });
    } else if (state === 'failed') {
      oauthStream.write({
        name: 'oauth_modal',
        err: 'Login failed',
      });
    }
  };

  const processSeedWords = async seedWords => {
    if (requestChainName === 'solana') {
      const solAddress = await getAddressFromMnemonic(seedWords);
      const solWallet = await getWallet(seedWords);
      const user = getUser();
      user.solWallet = solWallet;
      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      cloverWebManager.cloverController.setSolAccount(solAddress);
      oauthStream.write({
        name: 'oauth_modal',
        selectedAddress: solAddress,
      });
    } else if (requestChainName === 'polkadot') {
      const dotAddress = getAddress(seedWords);
      const user = getUser();
      const accountsWithoutSeedWords = [
        {
          address: dotAddress,
          name: user.userName,
          meta: {
            name: user.userName,
            source: 'clover',
          },
        },
      ];

      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      cloverWebManager.cloverController.setPolkadotAccount(
        accountsWithoutSeedWords,
      );
      oauthStream.write({
        name: 'oauth_modal',
        selectedAddress: dotAddress,
      });
    } else {
      const eth = ethers.Wallet.fromMnemonic(seedWords);
      cloverWebManager.cloverController.addAccount(eth.privateKey, eth.address);
      cloverWebManager.cloverController.setSelectedAccount(eth.address);
      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      oauthStream.write({
        name: 'oauth_modal',
        selectedAddress: eth.address,
      });
    }

    const statusStream = cloverWebManager.communicationMux.getStream('status');
    statusStream.write({ loggedIn: true, rehydrate: true, verifier: 'google' });
  };

  const onLoginSuccess = result => {
    try {
      const user = getUser();
      if (result.loginAction === LoginActionName.NewUser) {
        if (_.isEmpty(user) || !user.sharedA || user.sharedA === '') {
          throw new Error('Invalid user data');
        } else if (!result.sharedC) {
          throw new Error('Invalid login result');
        }

        setShowSharedC(true);
        setSharedC(result.sharedC);
        // const seedWords = combineKey(user.sharedA, result.sharedC)
        // processSeedWords(seedWords)
      } else if (result.loginAction === LoginActionName.PopupSuccess) {
        if (_.isEmpty(user) || !user.sharedA || user.sharedA === '') {
          throw new Error('Invalid user data');
        }

        combineSignKey(user.sharedA, user.userId, user.token)
          .then(seedWords => {
            processSeedWords(seedWords);
          })
          .catch(err => {
            throw new Error('Invalid user data. Fail to get user key.');
          });
      } else if (result.loginAction === LoginActionName.RestoreAccount) {
        setLoginResult(result);
        setMigrationRequestOpen(true);
      } else {
        throw new Error('Invalid result');
      }
    } catch (e) {
      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      oauthStream.write({
        name: 'oauth_modal',
        err: 'Login failed',
      });
      log.error(e);
    }
  };

  const onMigrationClose = msg => {
    if (msg === 'cancel') {
      setMigrationRequestOpen(false);
      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      oauthStream.write({
        name: 'oauth_modal',
        err: 'Cancel Login',
      });
      return;
    }

    // here msg is seed words
    processSeedWords(msg);
    setMigrationRequestOpen(false);
  };

  const onSaveShareClose = state => {
    try {
      const user = getUser();
      const seedWords = combineKey(user.sharedA, sharedC);
      processSeedWords(seedWords);
    } catch (e) {
      const oauthStream = cloverWebManager.communicationMux.getStream('oauth');
      oauthStream.write({
        name: 'oauth_modal',
        err: 'Login failed',
      });
      log.error(e);
    }
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      {showLogin && (
        <PopupLoginDialog
          isOpen={showLogin}
          onClose={onClose}
          onLoginSuccess={onLoginSuccess}
        ></PopupLoginDialog>
      )}
      {migrationRequestOpen && (
        <MigrationRequestDialog
          isOpen={migrationRequestOpen}
          userId={loginResult.userId}
          token={loginResult.token}
          publicKey={loginResult.publicKey}
          userName={loginResult.userName}
          email={loginResult.email}
          loginType={loginResult.loginType}
          fromPopup={true}
          onlyRecover={true}
          onClose={onMigrationClose}
        />
      )}
      {showSharedC && (
        <SaveShareDialog
          isOpen={showSharedC}
          share={sharedC}
          onClose={onSaveShareClose}
        />
      )}
    </div>
  );
}
