import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SuccessStatusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 126 129">
      <svg
        width="126"
        height="129"
        viewBox="0 0 126 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.2" cx="63" cy="62.5" r="62.5" fill="#06E594" />
        <g filter="url(#filter0_d_1541_11301)">
          <circle cx="63" cy="62.5" r="33.5" fill="#06E594" />
          <circle
            cx="63"
            cy="62.5"
            r="33"
            stroke="white"
            stroke-opacity="0.15"
          />
        </g>
        <path
          d="M50.5 63.3636L58.625 72L76.5 53"
          stroke="white"
          strokeWidth="5"
        />
        <defs>
          <filter
            id="filter0_d_1541_11301"
            x="5.5"
            y="14"
            width="115"
            height="115"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="9" />
            <feGaussianBlur stdDeviation="12" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.298039 0 0 0 0 0.74902 0 0 0 0 0.478431 0 0 0 0.35 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1541_11301"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1541_11301"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
