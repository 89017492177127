import { createReducer } from '@reduxjs/toolkit';
import { userSession } from './actions';
import { UserSession } from './types';

export interface UserSessionState {
  userSession: UserSession
}

export const emptyUserSession = {
  userId: -1,
  userName: '',
  email: '',
  loginType: '',
  token: '',
  publicKey: '',
  uuid: '',
  sharedA: '',
  password: '',
}

const initialState: UserSessionState = {
  userSession: emptyUserSession
}


export default createReducer(initialState, builder =>
  builder
    .addCase(userSession, (state, action) => {
      const {userSession} = action.payload
      state.userSession = userSession
    })
);
