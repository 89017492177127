import axios from 'axios';
import {host,getSignature} from './AccountService';

export const getCollections = async (accountId: string, requestPrivateKey: string) => {
    const val = `get|/api/wallets/${accountId}/nfts|{}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.get(`${host}wallets/${accountId}/nfts`,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}

export const getNftCollection = async (accountId: string, requestPrivateKey: string, networkId: number,tokenAddress:string) => {
    const val = `get|/api/wallets/${accountId}/nfts?address=${tokenAddress}&networkId=${networkId}|{}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.get(`${host}wallets/${accountId}/nfts?address=${tokenAddress}&networkId=${networkId}`,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}

export const getNftTransactions = async (accountId: string, requestPrivateKey: string, networkId: number,tokenAddress:string) => {
    const val = `get|/api/wallets/${accountId}/txs?networkId=${networkId}&tokenAddress=${tokenAddress}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.get(`${host}wallets/${accountId}/txs?networkId=${networkId}&tokenAddress=${tokenAddress}`,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}

export const getSendNftLevels = async(accountId: string,requestPrivateKey: string, networkId: number) => {
    const val = `get|/api/feelevels?networkId=${networkId}&type=erc721_transfer|{}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.get(`${host}feelevels?networkId=${networkId}&type=erc721_transfer`,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}

export const buildNftTx = async(accountId: string,requestPrivateKey: string,query:object) => {
    var timeStamp = new Date().getTime();
    const jsonStr = JSON.stringify(query);
    const val = `post|/api/txs?timeStamp=${timeStamp}|${jsonStr}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.post(`${host}txs`,
        query,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}

export const sendNftTx = async(accountId: string,requestPrivateKey: string,query:object) => {
    var timeStamp = new Date().getTime();
    const jsonStr = JSON.stringify(query);
    const val = `post|/api/txs/send?timeStamp=${timeStamp}|${jsonStr}`;
    const signature = getSignature(val, requestPrivateKey);
    let res = await axios.post(`${host}txs/send`,
        query,
        {
            headers: { 'X-Signature': signature, 'X-Identity': accountId }
        }
    );
    return res.data;
}
