import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { t } from 'modules/i18n/intl';
import { LogoIcon } from 'modules/icons/logo/LogoIcon';
import {
  LoginActionName,
  loginFromBackend,
  useLoginToBackend,
} from '../../services/loginService';
import StatusDialog from '../../components/Dialogs/StatusDialog';
import MigrationRequestDialog from '../../components/Dialogs/MigrationRequestDialog';
import { useUserSessionUpdate } from '../../state/user/hooks';
import { emptyUserSession } from '../../state/user/reducer';
import { getToast, toastFail } from '../../services/toastService';
import { useShareCUpdate } from '../../state/app/hooks';
import { setUserSessionToSessionStorage } from '../../services/LocalstorageService';
import { useLoginStyles } from './useLoginStyles';
import { Typography, Button } from '@material-ui/core';
import { LoginType } from '../../constants/netEnums';
import { SocialAccount } from './SocialAccount';

export default function LoginIn() {
  const classes = useLoginStyles();

  const history = useHistory();
  const loginToBackend = useLoginToBackend();
  const [migrationRequestOpen, setMigrationRequestOpen] = useState(false);
  const [loginResult, setLoginResult] = useState({});
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [migrateResult, setMigrateResult] = useState('fail');
  const [onlyRecover, setOnlyRecover] = useState(false);

  const updateUserSession = useUserSessionUpdate();

  const updateShareC = useShareCUpdate();

  useEffect(() => {
    updateUserSession(emptyUserSession);
    setUserSessionToSessionStorage(emptyUserSession);
  }, [updateUserSession]);

  const onLoginSuccess = async result => {
    try {
      setLoginResult(result);
      if (result.loginAction === LoginActionName.NewUser) {
        updateShareC({
          userId: result.userId,
          shareC: result.sharedC,
        });
        history.push('/homePage/wallet');
      } else if (result.loginAction === LoginActionName.MigrationProcss) {
        history.push('/homePage/wallet');
      } else if (result.loginAction === LoginActionName.MigrationRequest) {
        setMigrationRequestOpen(true);
      } else if (result.loginAction === LoginActionName.RestoreAccount) {
        setMigrationRequestOpen(true);
        setOnlyRecover(true);
      } else if (result.loginAction === LoginActionName.NoDevice) {
        setMigrationRequestOpen(true);
      }
    } catch (e) {
      console.log(e);
      toastFail('Login Failed. Please try later.');
    }
  };

  const onLoginFailure = async res => {
    console.log('login failed:', res);
    toastFail('Login Failed. Please try later.');
  };

  const onMigrationClose = info => {
    setMigrationRequestOpen(false);

    if (info === 'ok') {
      setStatusDialogOpen(true);
      setMigrateResult('success');
    } else if (info === 'okRecover') {
      history.push('/homePage/wallet');
    } else if (info !== 'cancel') {
      setStatusDialogOpen(true);
      setMigrateResult('fail');
    }
  };

  const onStatusDialogClose = info => {
    setStatusDialogOpen(false);
    if (info === 'ok') {
      history.push('/homePage/wallet');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.back}></div>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.landing}>
            <LogoIcon className={classes.logoIcon} />
            <Typography variant="h1" className={classes.title}>
              {t('authorization.landing.content')}
            </Typography>
            <Button variant="contained">
              <a
                href="https://docs.clover.finance/wallet-and-dapp/clover-web-wallet"
                target="_blank"
                rel="noreferrer"
              >
                {t('authorization.landing.view-docs')}
              </a>
            </Button>
          </div>
          <div className={classes.login}>
            <Typography variant="body1" className={classes.tip}>
              {t('authorization.tip')}
            </Typography>
            <SocialAccount
              handleGoogleLogin={() => {
                loginFromBackend(
                  LoginType.GOOGLE,
                  loginToBackend,
                  onLoginSuccess,
                  onLoginFailure,
                );
              }}
              hanldeFaceBookLogin={() => {
                loginFromBackend(
                  LoginType.FACEBOOK,
                  loginToBackend,
                  onLoginSuccess,
                  onLoginFailure,
                );
              }}
              handleTwitterLogin={() => {
                loginFromBackend(
                  LoginType.TWITTER,
                  loginToBackend,
                  onLoginSuccess,
                  onLoginFailure,
                );
              }}
              handleDiscordLogin={() => {
                loginFromBackend(
                  LoginType.DISCORD,
                  loginToBackend,
                  onLoginSuccess,
                  onLoginFailure,
                );
              }}
            />
            <Typography variant="body2" className={classes.note}>
              {t('authorization.note')}
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
      {getToast()}
      {migrationRequestOpen && (
        <MigrationRequestDialog
          isOpen={migrationRequestOpen}
          userId={loginResult.userId}
          token={loginResult.token}
          publicKey={loginResult.publicKey}
          userName={loginResult.userName}
          email={loginResult.email}
          loginType={loginResult.loginType}
          onlyRecover={onlyRecover}
          onClose={onMigrationClose}
        />
      )}
      {statusDialogOpen && (
        <StatusDialog
          isOpen={statusDialogOpen}
          status={migrateResult}
          onClose={onStatusDialogClose}
        />
      )}
    </div>
  );
}
