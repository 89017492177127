import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoLoadingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <g transform="rotate(0 50 50)">
        <rect
          x="38"
          y="21"
          rx="12"
          ry="12"
          width="24"
          height="24"
          fill="#27A577"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8333333333333333s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect
          x="38"
          y="21"
          rx="12"
          ry="12"
          width="24"
          height="24"
          fill="#27A577"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5555555555555556s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="38"
          y="21"
          rx="12"
          ry="12"
          width="24"
          height="24"
          fill="#27A577"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.2777777777777778s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect
          x="38"
          y="21"
          rx="12"
          ry="12"
          width="24"
          height="24"
          fill="#27A577"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </SvgIcon>
  );
};
