import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ContentLoadingIcon } from 'modules/icons/loading/ContentLoadingIcon';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    width: '100%',
    height: 400,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  icon: {
    width: 100,
    height: 100,
  },
}));

interface ILoadingContentProps {
  message?: string;
}

export const LoadingContent = ({ message }: ILoadingContentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ContentLoadingIcon className={classes.icon} />
      <Typography variant="body1">
        {message ? message : 'Loading...'}
      </Typography>
    </div>
  );
};
