import abi from 'human-standard-token-abi'
import {CUSTOM_EVMS, getMulChainURL} from "../constants/netEnums";
const Web3 = require('web3');

export function getContractAtAddress(tokenAddress, chain) {
  const chainUrl = getMulChainURL(chain, CUSTOM_EVMS)
  const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl))
  const contract = new web3.eth.Contract(abi, tokenAddress)
  return contract
}
