import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CrossIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M10.95 8.53337L14.25 11.8334L10.95 15.1334L10.0073 14.1907L11.698 12.4994L2.91667 12.5V11.1667H11.698L10.0073 9.47603L10.95 8.53337ZM5.55 1.8667L6.49267 2.80937L4.802 4.50003H13.5833V5.83337H4.802L6.49267 7.52403L5.55 8.4667L2.25 5.1667L5.55 1.8667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
