import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20">
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10.5" cy="10" r="10" fill="#06E594" />
        <path
          d="M5.5 10L8.625 13.125L15.5 6.25"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
};
