import { useSelector, useDispatch } from "react-redux"
import { useCallback } from 'react'
import { AppState } from '../index'
import { restoreFromStorage, enterPageFirstRefresh, selectedAsset, selectedCollection, shareC } from './actions'

export function useRestoreFromStorage(): boolean {
  return useSelector((state: AppState) => state.app.restoreFromStorage)
}

export function useRestoreFromStorageUpdate(): (isRestore: boolean) => void {
  const dispatch = useDispatch()
  return useCallback((isRestore: boolean) => dispatch(restoreFromStorage({isRestore: isRestore})), [dispatch])
}

export function useEnterPageFirstRefresh(): boolean {
  return useSelector((state: AppState) => state.app.enterPageFirstRefresh)
}

export function useEnterPageFirstRefreshUpdate(): (isFirstRefresh: boolean) => void {
  const dispatch = useDispatch()
  return useCallback((isFirstRefresh: boolean) => dispatch(enterPageFirstRefresh({isFirstRefresh: isFirstRefresh})), [dispatch])
}

export function useSelectedAsset(): any {
  return useSelector((state: AppState) => state.app.selectedAsset)
}

export function useSelectedAssetUpdate(): (asset: any) => void {
  const dispatch = useDispatch()
  return useCallback((asset: any) => dispatch(selectedAsset({asset: asset})), [dispatch])
}

export function useSelectedCollection(): any {
  return useSelector((state: AppState) => state.app.selectedCollection)
}

export function useSelectedCollectionUpdate(): (collection: any) => void {
  const dispatch = useDispatch()
  return useCallback((collection: any) => dispatch(selectedCollection({collection: collection})), [dispatch])
}

export function useShareC(): object {
  return useSelector((state: AppState) => state.app.shareC)
}

export function useShareCUpdate(): (share: object) => void {
  const dispatch = useDispatch()
  return useCallback((share: object) => dispatch(shareC({share: share})), [dispatch])
}

