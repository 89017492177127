import _ from 'lodash';
import { useCallback } from 'react';
import axios from 'axios';
import { CUSTOM_EVMS } from '../constants/netEnums';
import { getAddressFromAccount, getBalance, getEvmBalance } from './WalletService';
import { SUCCESS, FAILURE } from '../constants/api';
import { mainAssets } from './AssetServcie';
import { useCurrentAccount, useBalancesUpdate, usePrices, usePricesUpdate } from '../state/account/hooks';
import { saveStoreToLocal } from './LocalstorageService';
import { getErc20Balance } from './tokenService';
import { getSolBalance } from './SolanaService';
import store from '../state';
import { combineSignKey } from './loginService';
import { getTrxAddressFromMnemonic, getTrxBalance } from './TrxService';

var CryptoJS = require('crypto-js');
const PRICE_INTERVAL = 3 * 60 * 1000;
const ZERO_BALANCE_INTERVAL = 1 * 60 * 1000;
let lastPriceRefresh = 0;
let tokenRefreshTime: any = {};

function needRefresh(account: any, tokenSymbol: string) {
  const now = new Date().getTime();
  if (!tokenRefreshTime[tokenSymbol]) {
    tokenRefreshTime[tokenSymbol] = now;
    return true;
  }
  if (!account[tokenSymbol]) {
    return true;
  }
  if (account[tokenSymbol].balance === '0' && now - tokenRefreshTime[tokenSymbol] < ZERO_BALANCE_INTERVAL) {
    return false;
  }
  return true;
}

export async function getMainTokenPrice() {
  try {
    const result = await axios.get('https://wallet-api.clover.finance/api/prices');
    const data = _.reduce(result.data, (r: any, c) => {
      r[c.fsym] = { USDT: c.value };
      return r;
    }, {});
    result.data = data;
    return result;
  } catch (err) {
    return null;
  }
}

function usePriceRefresh(): (account: any) => Promise<void> {
  const pricesUpdate = usePricesUpdate();
  return useCallback(async (account: any) => {
    if (!account) {
      return;
    }

    const mainTokenPrices = await getMainTokenPrice();
    if (!mainTokenPrices || mainTokenPrices.status !== SUCCESS || !mainTokenPrices.data) {
      return;
    }
    const clvAddress = getAddressFromAccount('CLV', account, CUSTOM_EVMS);
    //here update price
    pricesUpdate({
      clvAddress,
      prices: mainTokenPrices.data,
    });
  }, [pricesUpdate]);
}

const updateBalanceOfToken = async (account: any, tokenSymbol: string, customEvms: Array<any>) => {
  const { currentAccount } = store.getState().account;
  const { userSession } = store.getState().user;

  try {
    if (!needRefresh(account, tokenSymbol)) {
      return null;
    }

    let res;
    let clvAddress = getAddressFromAccount('CLV', account, customEvms);

    if (tokenSymbol === 'CLV') {
      res = await getBalance(clvAddress, tokenSymbol);
    } else if (tokenSymbol === 'SKU') {
      res = await getBalance(clvAddress, tokenSymbol);
    } else if (tokenSymbol === 'DOT') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'KSM') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'EDG') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'SOL') {
      let bytes = CryptoJS.AES.decrypt(currentAccount.seedWords, userSession.publicKey);
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(userSession.sharedA, userSession.userId, userSession.token);
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      res = await getSolBalance(seedWords);
    } else if (tokenSymbol === 'CRU') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'TRX') {
      let bytes = CryptoJS.AES.decrypt(currentAccount.seedWords, userSession.publicKey);
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(userSession.sharedA, userSession.userId, userSession.token);
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      const address = await getTrxAddressFromMnemonic(seedWords);
      res = await getTrxBalance(address);
    } else if (tokenSymbol === 'KAR') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'SDN') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'PHA') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'BNC') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'KILT') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else if (tokenSymbol === 'KMA') {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getBalance(address, tokenSymbol);
    } else {
      const address = getAddressFromAccount(tokenSymbol, account, customEvms);
      res = await getEvmBalance(address, tokenSymbol, customEvms);
    }
    tokenRefreshTime[tokenSymbol] = new Date().getTime();

    if (res.status === FAILURE && account[tokenSymbol] && account[tokenSymbol].amount) {
      return null;
    }

    return res;
  } catch (e) {
    console.error(e);
  }
};

export function useUpdateBalance(): (tokenSymbol: string, customEvms: Array<any>) => Promise<void> {
  const account = useCurrentAccount();
  const priceRefresh = usePriceRefresh();
  const prices: any = usePrices();
  const balancesUpdate = useBalancesUpdate();

  return useCallback(async (tokenSymbol: string, customEvms: Array<any>) => {
    if (_.isEmpty(account)) {
      return;
    }
    priceRefresh(account);
    let contractAssets = account.contractAssets;
    if (!_.isEmpty(prices) && !_.isEmpty(account.contractAssets)) {
      contractAssets = await Promise.all(
        account.contractAssets.map(async (asset: any) => {
          const balance = await getErc20Balance(asset.tokenAddress, account.evmAddress, asset.chain);
          const amount = balance / Math.pow(10, asset.decimals);
          return {
            ...asset,
            marketData: prices.prices[asset.symbol] ?? {},
            balance,
            amount,
          };
        }),
      );
    }

    let targetTokens = account && account.hiddenAssets ? _.difference(mainAssets, account.hiddenAssets) : mainAssets;
    if (tokenSymbol && tokenSymbol !== '') {
      targetTokens = mainAssets.filter((t) => t === tokenSymbol);
    }

    let balances = [];
    for (let t of targetTokens) {
      const b = await updateBalanceOfToken(account, t, customEvms);
      if (!_.isEmpty(b)) {
        balances.push(b);
      }
    }

    const now = new Date().getTime();
    const noMarketPriceToken = targetTokens.find((t) => account[t] && _.isEmpty(account[t].marketData) && t !== 'SKU');
    if (noMarketPriceToken || (now - lastPriceRefresh > PRICE_INTERVAL)) {
      lastPriceRefresh = now;
      priceRefresh(account);
    }

    if (!_.isEmpty(balances)) {
      let clvAddress = getAddressFromAccount('CLV', account, customEvms);
      const balanceToUpdate = {
        clvAddress,
        balances,
        contractAssets,
      };

      balancesUpdate(balanceToUpdate);
      saveStoreToLocal();
    }
  }, [account, prices, priceRefresh, balancesUpdate]);
}
