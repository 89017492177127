import eventEmitter, { EVENT_PAGE_OPERATION, EVENT_LOGOUT } from './eventService'

const AutoLogoutTime = 15 * 60 * 1000
const ActivityCheckInterval = 1 * 60 * 1000

let lastActiveTime = Date.now()
let interval: any

function onPageChange() {
  // console.log('page change:', lastActiveTime)
  lastActiveTime = Date.now()
}

function activityCheck() {
  if (Date.now() - lastActiveTime >= AutoLogoutTime) {
    eventEmitter.emit(EVENT_LOGOUT)
    stopListenAcivity()
  }
}

function stopListenAcivity() {
  eventEmitter.off(EVENT_PAGE_OPERATION, onPageChange)
  clearInterval(interval)
  interval = undefined
}

export function startListenActive() {
  lastActiveTime = Date.now()
  if (!interval) {
    eventEmitter.on(EVENT_PAGE_OPERATION, onPageChange)
    interval = setInterval(activityCheck, ActivityCheckInterval)
  }
}

export function logoutWallet(history: any) {
  if (history) {
    history.replace('/')
    window.location.reload()
  }
}
