import React from 'react';
import { SvgIconProps } from '@material-ui/core';

export const KeyIcon = (props: SvgIconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_1580_14819)">
        <path
          d="M18.9523 7.95255L8.95231 7.76208L18.9523 7.95255ZM2.28564 10.6192C0.476119 8.71446 0.476119 7.47636 2.47612 5.47636C4.47612 3.47636 5.71421 3.47636 7.61898 5.28589C9.4285 7.09541 9.4285 8.42874 7.4285 10.4287C5.4285 12.4287 4.09517 12.4287 2.28564 10.6192Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 7V4H17V7"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 8.42871L16.1904 12.6192H19.0475"
          stroke="currentColor"
          strokeWidth="1.211"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0476 13.5718C19.5736 13.5718 20 13.1454 20 12.6194C20 12.0934 19.5736 11.667 19.0476 11.667C18.5216 11.667 18.0952 12.0934 18.0952 12.6194C18.0952 13.1454 18.5216 13.5718 19.0476 13.5718Z"
          fill="currentColor"
        />
        <path
          d="M10.0952 12.4287H11.7143L13.3333 14.8097"
          stroke="currentColor"
          strokeWidth="1.211"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1583 16.0802C14.5936 15.785 14.7073 15.1928 14.4121 14.7574C14.1169 14.3221 13.5247 14.2084 13.0893 14.5036C12.654 14.7988 12.5403 15.391 12.8355 15.8264C13.1307 16.2617 13.7229 16.3754 14.1583 16.0802Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1580_14819">
          <rect
            width="20"
            height="13.2381"
            fill="currentColor"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
