import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#EF4C56" />
      <path
        d="M10.967 4.81818H9.04297L9.20206 12.0071H10.8029L10.967 4.81818ZM10.0025 15.1094C10.5891 15.1094 11.0913 14.6222 11.0962 14.0156C11.0913 13.419 10.5891 12.9318 10.0025 12.9318C9.39595 12.9318 8.90376 13.419 8.90874 14.0156C8.90376 14.6222 9.39595 15.1094 10.0025 15.1094Z"
        fill="white"
      />
    </SvgIcon>
  );
};
