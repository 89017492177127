import { makeStyles, Theme } from '@material-ui/core';

export const useActivityDetailStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
  },
  tokenInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10),
    textAlign: 'center',
    marginBottom: theme.spacing(10),
  },
  buttons: {
    marginTop: 20,
    '& button': {
      margin: '0 12px',
    },
  },
  crossChainLogo: {
    position: 'relative',
    width: 60,
    height: 60,
    '& img:last-child': {
      position: 'absolute',
      bottom: 0,
      right: -5,
    },
  },
  noContent: {
    marginTop: 120,
    color: theme.palette.text.secondary,
    fontSize: 14,
    textAlign: 'center',
  },
  list: {
    marginTop: theme.spacing(10),
  },
  listContent: {
    height: `calc(100vh - ${theme.spacing(110)}px)`,
    overflow: 'auto',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '30% 30% 25% 15%',
    alignItems: 'center',
    padding: theme.spacing(4),
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.grey.A200}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  current: {},
  info: {
    display: 'flex',
    alignItems: 'center',
    '& svg:first-child': {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(6),
    },
    '& img:last-child': {
      marginLeft: 8,
    },
  },
  rowDetail: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.grey.A100,
    padding: theme.spacing(4, 12),
  },
  item: {
    width: '50%',
    marginBottom: theme.spacing(4),
    whiteSpace: 'nowrap',
    '& svg': {
      width: 16,
      height: 16,
    },
    '& span:last-child': {
      marginLeft: theme.spacing(3),
    },
    '& p:first-child': {
      color: theme.palette.text.secondary,
    },
  },
}));
