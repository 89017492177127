import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { InputField } from 'components/InputField/InputField';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { exportAsFile } from '../../utils/helper';
import { combineSignKey } from '../../services/loginService';
import { useUserSession } from '../../state/user/hooks';
import { Warning } from '../Warning/Warning';
import { CorrectIcon } from '../../modules/icons/CorrectIcon';
import { ToastTooltip } from '../ToastTooltip';
import { useCurrentAccount } from '../../state/account/hooks';
import { DialogActionsModal } from 'components/DialogModal/DialogActionsModal';
var CryptoJS = require('crypto-js');

interface ShowSeedPhraseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  hiddenPassword: boolean;
}

const useShowSeedPhraseStyles = makeStyles<Theme>(theme => ({
  seedWrapper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(7),
  },
  seedContent: {
    marginTop: theme.spacing(5),
  },
  seedWord: {
    background: 'rgba(243, 244, 245, 0.16)',
    borderRadius: 8,
    padding: 18,
    marginTop: 8,
  },
}));

const ShowSeedPhraseDialog = ({
  isOpen,
  onClose,
  hiddenPassword,
}: ShowSeedPhraseDialogProps) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [step, setStep] = useState(hiddenPassword ? 2 : 1);
  const [seedWords, setSeedWords] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

  const userSession = useUserSession();
  const currentAccount = useCurrentAccount();
  const userInfo = localStorage.getItem('userInfo');

  const getSeedWords = useCallback(async () => {
    if (userSession.userId >= 0) {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      setSeedWords(seedWords);
    }
  }, [
    currentAccount.isLoginAccount,
    currentAccount.seedWords,
    userSession.publicKey,
    userSession.sharedA,
    userSession.token,
    userSession.userId,
  ]);

  useEffect(() => {
    if (userSession.userId >= 0) {
      getSeedWords();
      let password =
        userInfo !== null
          ? CryptoJS.AES.decrypt(
              JSON.parse(userInfo)[userSession.userId]?.password ?? '',
              userSession.publicKey,
            )
          : '';
      setUserPassword(password.toString(CryptoJS.enc.Utf8));
    }
  }, [userSession, userInfo, getSeedWords]);

  const onDismiss = () => {
    onClose();
  };

  const handlePasswordChange = (value: string) => {
    let error: any = '';

    if (value && value !== userPassword) {
      error = <span>{t('show-seed-phrase-dialog.passwordError')}</span>;
    }

    setPassword(value);
    setPasswordError(error);
  };

  const isValid = useMemo(() => {
    if (!password || passwordError !== '') {
      return false;
    }
    return true;
  }, [password, passwordError]);

  const handleContinue = () => {
    setStep(2);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(seedWords);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 2 * 1000);
  };

  const classes = useShowSeedPhraseStyles();

  return (
    <DialogModal
      subtitle={t('show-seed-phrase-dialog.title')}
      open={isOpen}
      onClose={onDismiss}
      hideClose
    >
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: 20 }}
      >
        {t('show-seed-phrase-dialog.tip')}
      </Typography>
      {step === 1 ? (
        <InputField
          label={t('show-seed-phrase-dialog.enterPassword')}
          value={password}
          errorInfo={passwordError}
          placeHolder={t('show-seed-phrase-dialog.enterPassword')}
          type="password"
          onChange={(e: any) => handlePasswordChange(e.target.value)}
        />
      ) : (
        <div className={classes.seedWrapper}>
          <Warning content={t('show-seed-phrase-dialog.warning')} />
          <div className={classes.seedContent}>
            <Typography variant="h4">
              {t('show-seed-phrase-dialog.yourSeedPhrase')}
            </Typography>
            <ToastTooltip
              open={copyTooltipOpen}
              message={'Copied'}
              icon={<CorrectIcon />}
            >
              <div className={classes.seedWord}>{seedWords}</div>
            </ToastTooltip>
          </div>
        </div>
      )}

      {step === 1 ? (
        <DialogActionsModal
          onCancel={onDismiss}
          handleDialog={handleContinue}
          disabled={!isValid}
        />
      ) : (
        <DialogActionsModal
          onCancel={copyAddress}
          cancelText={t('show-seed-phrase-dialog.copy')}
          handleVariant="outlined"
          handleDialog={() => exportAsFile('', seedWords)}
          handleText={t('show-seed-phrase-dialog.save')}
        />
      )}
    </DialogModal>
  );
};

export default ShowSeedPhraseDialog;
