/* get account address, get account balance related function put here */

import _ from 'lodash';
import { useCallback } from 'react';
import BN from 'bn.js';
import { ethers } from 'ethers';
import {
  formatBalance,
} from '@polkadot/util';
import { isAddress } from '@ethersproject/address';
import { useCurrentAccount } from '../state/account/hooks';
import { emptyAccount } from './AccountService';
import { mainAssets } from './AssetServcie';
import { getApiByToken } from './DotApiService';
import { SUCCESS, FAILURE, IS_DEV } from '../constants/api';
import { getMulChainURL } from '../constants/netEnums';
import { polkadotAsset } from './AssetServcie';
import BigNumber from 'bignumber.js';

const { checkAddress } = require('@polkadot/util-crypto');

const { Keyring } = require('@polkadot/api');
const Web3 = require('web3');

export enum ChainSS58 {
  CLV = 42,
  SKU = 42,
  KSM = 2,
  DOT = 0,
  EDG = 7,
  CRU = 66,
  KAR = 8,
  SDN = IS_DEV ? 42 : 5,
  PHA = 30,
  BNC = 6,
  KILT = 38,
  KMA = 78,
}

export enum KeyPairType {
  ed25519 = 'ed25519',
  sr25519 = 'sr25519',
}

export function getSS58BySymbol(tokenSymbol: string) {
  if (tokenSymbol === 'CLV') {
    return 42;
  }
  if (tokenSymbol === 'DOT') {
    return 0;
  }
  if (tokenSymbol === 'KSM') {
    return 2;
  }
  if (tokenSymbol === 'EDG') {
    return 7;
  }
  if (tokenSymbol === 'CRU') {
    return 66;
  }
  if (tokenSymbol === 'KAR') {
    return 8;
  }
  if (tokenSymbol === 'SDN') {
    return IS_DEV ? 42 : 5;
  }
  if (tokenSymbol === 'PHA') {
    return 30;
  }
  if (tokenSymbol === 'BNC') {
    return 6;
  }
  if (tokenSymbol === 'KILT') {
    return 38;
  }
  if (tokenSymbol === 'KMA') {
    return 78;
  }

  return 42;
}

export function getDecimalsBySymbol(tokenSymbol: string) {
  if (tokenSymbol === 'CLV') {
    return 18;
  }
  if (tokenSymbol === 'DOT') {
    return 10;
  }
  if (tokenSymbol === 'EDG') {
    return 18;
  }
  if (tokenSymbol === 'KSM') {
    return 12;
  }
  if (tokenSymbol === 'SOL') {
    return 9;
  }
  if (tokenSymbol === 'CRU') {
    return 12;
  }
  if (tokenSymbol === 'KAR') {
    return 12;
  }
  if (tokenSymbol === 'SDN') {
    return 18;
  }
  if (tokenSymbol === 'PHA') {
    return 12;
  }
  if (tokenSymbol === 'BNC') {
    return 12;
  }
  if (tokenSymbol === 'KILT') {
    return 15;
  }
  if (tokenSymbol === 'KMA') {
    return 12;
  }

  return 18;
}

export function getSubstrateAddress(seedWords: string, ss58Format: ChainSS58, keypairType: string) {
  try {
    const keyring = new Keyring({ type: keypairType });
    keyring.setSS58Format(ss58Format);
    const pairAlice = keyring.addFromUri(seedWords);
    const { address } = pairAlice;
    return address;
  } catch (err) {
    return null;
  }
}

export function getEthAddress(seedWords: string) {
  const eth = ethers.Wallet.fromMnemonic(seedWords);
  return eth.address.toLowerCase();
}

export const getAddressFromAccount = (asset: string, account: any, customEvms: Array<any>) => {
  if (!asset || emptyAccount(account)) {
    return '';
  }

  if (asset === 'CLV') {
    return account.address;
  }
  if (asset === 'SKU') {
    return account.skuAddress;
  }
  if (asset === 'DOT') {
    return account.dotAddress;
  }
  if (asset === 'EDG') {
    return account.edgAddress;
  }
  if (asset === 'KSM') {
    return account.ksmAddress;
  }
  if (asset === 'SOL') {
    return account.solAddress;
  }
  if (asset === 'CRU') {
    return account.cruAddress;
  }
  if (asset === 'TRX') {
    return account.trxAddress;
  }
  if (asset === 'KAR') {
    return account.karAddress;
  }
  if (asset === 'SDN') {
    return account.sdnAddress;
  }
  if (asset === 'PHA') {
    return account.phaAddress;
  }
  if (asset === 'BNC') {
    return account.bncAddress;
  }
  if (asset === 'KILT') {
    return account.kiltAddress;
  }
  if (asset === 'KMA') {
    return account.kmaAddress;
  }

  if (!customEvms.find(evm => evm.ticker.toUpperCase() === asset.toUpperCase())) {
    return '';
  }
  // todo: here should use customevm check
  return account.evmAddress;
};

export function validAddress(address: string, asset: any) {
  if (polkadotAsset(asset)) {
    return validPolkadotAddress(address, asset.tokenSymbol);
  } else {
    return address.startsWith('0x') && isAddress(address);
  }
}

export function validPolkadotAddress(address: string, tokenSymbol: string) {
  const result = checkAddress(address.trim(), getSS58BySymbol(tokenSymbol));
  return result[0];
}

export function useAccountTotal(): () => number {
  const currentAccount = useCurrentAccount();
  return useCallback(() => {
    if (_.isEmpty(currentAccount)) {
      return 0;
    }

    const assetList = _.difference(mainAssets, currentAccount.hiddenAssets);
    let total = 0;
    assetList.forEach((tokenSymbol) => {
      const price = currentAccount[tokenSymbol]?.marketData?.USDT ?? 0;
      const amount = currentAccount[tokenSymbol]?.amount ?? '0';
      const amountRMB = amount * price;
      total += !isNaN(amountRMB) ? amountRMB : 0;
    });
    if (currentAccount.contractAssets && currentAccount.contractAssets.length) {
      currentAccount.contractAssets.forEach((asset: any) => {
        const amountRMB = (asset?.amount ?? '0') * (asset?.marketData?.USDT ?? 0);
        total += !isNaN(amountRMB) ? amountRMB : 0;
      });
    }
    return total;
  }, [currentAccount]);
}

export const getBalance = async (address: string, tokenSymbol: string) => {
  formatBalance.setDefaults({ unit: tokenSymbol });
  const initialValue = '0 ' + tokenSymbol;
  const decimals = getDecimalsBySymbol(tokenSymbol);
  try {
    const api = await getApiByToken(tokenSymbol);

    const { parentHash } = await api.rpc.chain.getHeader();
    const {
      data: { free: balance, miscFrozen: frozen },
    } = await api.query.system.account.at(parentHash, address);
    const transferable = new BN(balance.toString()).sub(new BN(frozen.toString()));
    let balanceFormatted = initialValue;
    if (transferable.gt(new BN(0))) {
      balanceFormatted = formatBalance(transferable, {}, decimals);
    }
    const tokenBalance = formatBalance(transferable, { forceUnit: tokenSymbol.toLowerCase(), withSi: true }, decimals);
    let amount = tokenBalance.split(' ')[0];
    amount = amount.split(',').join('');
    const balanceObj = {
      address,
      token: tokenSymbol,
      balance: transferable.toString(),
      amount,
      marketData: {},
      balanceFormatted,
      status: SUCCESS,
    };
    return balanceObj;
  } catch (err) {
    console.error(err);
    const balanceObj = {
      address,
      token: tokenSymbol,
      balance: '0',
      amount: '0',
      marketData: '0',
      balanceFormatted: initialValue,
      status: FAILURE,
    };
    return balanceObj;
  }
};

export const getEvmBalance = async (address: string, tokenSymbol: string, customEvms: Array<any>) => {
  formatBalance.setDefaults({ unit: 'ETH' });
  const initialValue = '0 ' + tokenSymbol;
  try {
    const chainUrl = getMulChainURL(tokenSymbol, customEvms);
    const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl));
    let balance = await web3.eth.getBalance(address);
    balance = new BigNumber(balance).toFixed(0);

    let balanceFormatted = initialValue;
    if (parseInt(balance.toString()) > 0) {
      balanceFormatted = formatBalance(balance.toString(), undefined, 18);
    }
    const ethBalance = formatBalance(balance.toString(), { forceUnit: 'eth', withSi: true }, 18);
    const balanceObj = {
      address: address,
      token: tokenSymbol,
      balance: balance.toString(),
      amount: ethBalance.split(' ')[0].replace(',', ''),
      marketData: {},
      balanceFormatted,
      status: SUCCESS,
    };
    return balanceObj;
  } catch (err) {
    console.error(err);
    const balanceObj = {
      address,
      token: tokenSymbol,
      balance: '0',
      amount: '0',
      marketData: '0',
      balanceFormatted: '0' + tokenSymbol,
      status: FAILURE,
    };
    return balanceObj;
  }
};
