import { BigNumber } from 'bignumber.js';
import { derivePath } from 'ed25519-hd-key';
import nacl from 'tweetnacl';
import { Account, Connection } from '@solana/web3.js';
import * as API from '../constants/api';
import * as solanaWeb3 from '@solana/web3.js';

const bip39 = require('bip39');
let connection: any;
export const solDecimals = 9;
export const solTokenName = 'SOL';
export const solEndpoint = 'https://solana-api.projectserum.com';

export function getConnection() {
  if (connection) {
    return connection;
  }

  connection = new Connection(solEndpoint, 'recent');
  return connection;
}

export async function mnemonicToSeed(mnemonic: string) {
  if (!bip39.validateMnemonic(mnemonic)) {
    throw new Error('Invalid seed words');
  }
  const seed = await bip39.mnemonicToSeed(mnemonic);
  return Buffer.from(seed).toString('hex');
}

function deriveSeed(seed: string) {
  const path44Change = `m/44'/501'/0'/0'`;
  return derivePath(path44Change, seed).key;
}

export function getAccountFromSeed(seed: string) {
  const derivedSeed = deriveSeed(seed);
  const account = new Account(
    nacl.sign.keyPair.fromSeed(derivedSeed).secretKey,
  );
  return account;
}

export async function getAccountFromMnemonic(mnemonic: string) {
  try {
    const seed = await mnemonicToSeed(mnemonic);
    return getAccountFromSeed(seed);
  } catch (e) {
    return undefined;
  }
}

export async function getAddressFromMnemonic(mnemonic: string) {
  const account = await getAccountFromMnemonic(mnemonic);

  if (account) {
    return account.publicKey.toBase58();
  }

  return '';
}

export async function getSolBalance(seedWords: string) {
  let account: any;
  try {
    getConnection()
    account = await getAccountFromMnemonic(seedWords);
    const balance = await connection.getBalance(account.publicKey);

    const base = new BigNumber(10).pow(solDecimals);
    const amount = new BigNumber(balance.toString()).div(base).toFixed();
    const balanceObj = {
      address: account.publicKey.toBase58(),
      token: solTokenName,
      balance: balance.toString(),
      amount,
      marketData: {},
      balanceFormatted: amount + solTokenName,
      status: API.SUCCESS,
    };

    return balanceObj;
  } catch (e) {
    const balanceObj = {
      address: account ? account.publicKey.toBase58() : '',
      token: solTokenName,
      balance: '0',
      amount: '0',
      marketData: '0',
      balanceFormatted: '0' + solTokenName,
      status: API.FAILURE,
    };

    return balanceObj;
  }
}

export async function getSolFees() {
  try {
    const conn = getConnection();
    const { feeCalculator } = await conn.getRecentBlockhash();
    return feeCalculator.lamportsPerSignature;
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getWallet(mnemonicStr: string) {
  const seed = await bip39.mnemonicToSeed(mnemonicStr);
  const deriveSeed = derivePath(
    `m/44'/501'/0'/0'`,
    Buffer.from(seed).toString('hex'),
  ).key;
  var wallet = solanaWeb3.Keypair.fromSeed(deriveSeed);
  return wallet;
}
