import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_1660_2038)">
        <path
          d="M9.6 4.84204H3.6C2.72 4.84204 2 5.55257 2 6.42099V12.342C2 13.2105 2.72 13.921 3.6 13.921H9.6C10.48 13.921 11.2 13.2105 11.2 12.342V6.49994C11.28 5.55257 10.56 4.84204 9.6 4.84204ZM10.4 12.421C10.4 12.7368 10.08 13.0526 9.68 13.0526H3.6C3.28 13.0526 2.96 12.8157 2.96 12.421V6.42099C2.96 6.1052 3.2 5.78941 3.6 5.78941H9.76C10.08 5.78941 10.4 6.02625 10.4 6.42099V12.421Z"
          fill="currentColor"
        />
        <path
          d="M13.9999 10.4474H12.8799V3.34211C12.8799 3.18421 12.7999 3.10526 12.6399 3.10526H5.43994V2H12.6399C13.4399 2 13.9999 2.63158 13.9999 3.34211V10.4474Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1660_2038">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
