import { makeStyles } from '@material-ui/styles';

const DEFAULT_SIZE = 40;

interface ISizeProp {
  size?: number;
}

export const useSpinnerStyles = makeStyles({
  spinner: {
    animationName: '$spin',
    animationDuration: '1s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    willChange: 'transform',
    margin: 'auto',
    width: ({ size }: ISizeProp) => size ?? DEFAULT_SIZE,
    height: ({ size }: ISizeProp) => size ?? DEFAULT_SIZE,
  },
  '@keyframes spin': {
    '100%': { transform: 'rotate(360deg)' },
  },
  centered: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
});
