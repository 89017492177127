import { EventEmitter } from 'events'

const eventEmitter = new EventEmitter()

export const EVENT_MIGRATE_REQUEST_CANCEL = 'EVENT_MIGRATE_REQUEST_CANCEL'
export const EVENT_MIGRATE_REQUEST_ACCEPT = 'EVENT_MIGRATE_REQUEST_ACCEPT'
export const EVENT_MIGRATE_REQUEST_REJECT = 'EVENT_MIGRATE_REQUEST_REJECT'
export const EVENT_MIGRATE_REQUEST_SAVE = 'EVENT_MIGRATE_REQUEST_SAVE'

export const EVENT_PAGE_OPERATION = 'EVENT_PAGE_OPERATION'
export const EVENT_LOGOUT = 'EVENT_LOGOUT'

export const NOOP = () => {}
export default eventEmitter