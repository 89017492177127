import _ from 'lodash';
import { deviceDetect } from 'react-device-detect';
import { useCallback } from 'react';
import {
  uploadShare,
  uploadDeviceInfo,
  getMigrationVerifyRequest,
  getShare,
  getDeviceInfoForUser,
  sendMigrateRequest,
  getMigrateResult,
  acceptMigReq,
  rejectMigReq,
  getMigrateRecord,
  getLoginUrl,
  getInfoForLogin,
  googleAuth,
} from './backendService';
import { useUserSessionUpdate } from '../state/user/hooks';
import { UserSession } from '../state/user/types';
import {
  createSeedWordsAndSSS,
  encryptWithPublicKey,
  decryptMessage,
  generateKey,
  getUuid,
  combineKey,
} from '../utils/cryptoUtils';
import randomId from '../utils/random-id';
import eventEmitter, {
  EVENT_MIGRATE_REQUEST_ACCEPT,
  EVENT_MIGRATE_REQUEST_CANCEL,
  EVENT_MIGRATE_REQUEST_REJECT,
  NOOP,
} from './eventService';
import {
  LOCAL_STORES,
  saveUserInfo,
  setUserSessionToSessionStorage,
} from './LocalstorageService';
import { setUser } from './PopupService';
import PopupWithBcHandler from '../handlers/Popup/PopupWithBcHandler';
import { FEATURES_DEFAULT_POPUP_WINDOW } from '../constants/netEnums';
import { sha256 } from 'js-sha256';

var CryptoJS = require('crypto-js');
const getUserInfo = (userId: number) => {
  const userInfo = localStorage.getItem('userInfo');

  return userInfo !== null ? JSON.parse(userInfo)[userId] : null;
};

const uploadShareToServer = async (
  shareStr: string,
  publicKey: string,
  userId: number,
  token: string,
) => {
  const cipherMessage = await encryptWithPublicKey(shareStr, publicKey);
  await uploadShare(cipherMessage, userId, token);
};

const getDeviceInfo = async (userId: number, token: string) => {
  const devices = await getDeviceInfoForUser(userId, token);
  return devices;
};

const saveUserMigrationRequest = (
  userId: number,
  requestId: number,
  key: string,
) => {
  const userInfo = localStorage.getItem('userInfo');
  const info = {
    ...(userInfo !== null ? JSON.parse(userInfo) : {}),
    [userId]: {
      requestId: requestId,
      key: key,
    },
  };
  localStorage.setItem('userInfo', JSON.stringify(info));
};

const getUserMigrationRequesst = (userId: number) => {
  const userInfo = localStorage.getItem('userInfo');
  if (userInfo === null) {
    return null;
  }

  const info = JSON.parse(userInfo);
  const userRequest = info[userId];
  if (!userRequest || !userRequest.requestId || !userRequest.key) {
    return null;
  }

  const requestKey = JSON.parse(userRequest.key);
  if (!requestKey || !requestKey.publicKey || !requestKey.privateKey) {
    return null;
  }

  return {
    requestId: userRequest.requestId,
    requestKey,
  };
};

// const getVerifyCode = () => {
//   const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
//   const arr1 = _.shuffle(arr)
//   return _.join(arr1, '').substring(0, 6)
// }

export async function startMigration(userId: number, token: string) {
  const request = getUserMigrationRequesst(userId);
  let key: any;
  let requestId: number;
  if (request !== null) {
    key = request.requestKey;
    requestId = request.requestId;
    let record = null;
    try {
      record = await getMigrateRecord(requestId, userId, token);
    } catch (e) {
    }

    if (record === null) {
      key = generateKey();
      const loginPublickKey = key.publicKey;
      const loginDeviceInfo = JSON.stringify(deviceDetect());
      requestId = await sendMigrateRequest(
        loginPublickKey,
        loginDeviceInfo,
        userId,
        token,
      );
      saveUserMigrationRequest(userId, requestId, JSON.stringify(key));
    }
  } else {
    key = generateKey();
    const loginPublickKey = key.publicKey;
    const loginDeviceInfo = JSON.stringify(deviceDetect());
    requestId = await sendMigrateRequest(
      loginPublickKey,
      loginDeviceInfo,
      userId,
      token,
    );
    saveUserMigrationRequest(userId, requestId, JSON.stringify(key));
  }

  const interval = setInterval(async () => {
    let migResult = null;
    try {
      migResult = await getMigrateResult(requestId, userId, token);
    } catch (e) {
      console.log(e);
    }

    if (!_.isEmpty(migResult)) {
      clearInterval(interval);

      if (migResult.status === 'accept') {
        const sharedA = await decryptMessage(key.privateKey, migResult.share);
        eventEmitter.emit(EVENT_MIGRATE_REQUEST_ACCEPT, sharedA);
      } else {
        eventEmitter.emit(EVENT_MIGRATE_REQUEST_REJECT, NOOP);
      }
    }
  }, 10 * 1000);

  eventEmitter.on(EVENT_MIGRATE_REQUEST_CANCEL, () => {
    clearInterval(interval);
    eventEmitter.off(EVENT_MIGRATE_REQUEST_CANCEL, NOOP);
  });
}

export const combineSignKey = async (
  shareAStr: string,
  userId: number,
  token: string,
) => {
  const share = await getShare(userId, token);

  if (share === null || _.isEmpty(shareAStr)) {
    return null;
  }

  return combineKey(shareAStr, share);
};

export const getMigrationRequest = async (userSession: UserSession) => {
  try {
    const verifyRequest = await getMigrationVerifyRequest(
      userSession.userId,
      userSession.token,
    );
    return verifyRequest;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const acceptMigrationRequest = async (
  verifyRequest: any,
  userSession: UserSession,
) => {
  try {
    const encryptedShare = await encryptWithPublicKey(
      userSession.sharedA,
      verifyRequest.login_public_key,
    );
    const ret = await acceptMigReq(
      {
        requestId: verifyRequest.id,
        share: encryptedShare,
        verifyDeviceUuid: userSession.uuid,
      },
      userSession.userId,
      userSession.token,
    );
    return ret;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const rejectMigrationRequest = async (
  verifyRequest: any,
  userSession: UserSession,
) => {
  try {
    const ret = await rejectMigReq(
      {
        requestId: verifyRequest.id,
        verifyDeviceUuid: userSession.uuid,
      },
      userSession.userId,
      userSession.token,
    );
    return ret;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export async function deviceInfoUpload(userSession: UserSession) {
  if (userSession.userId < 0) {
    return;
  }

  try {
    const deviceInfo = deviceDetect();
    const info = await encryptWithPublicKey(
      JSON.stringify(deviceInfo),
      userSession.publicKey,
    );
    await uploadDeviceInfo(
      info,
      userSession.uuid,
      userSession.userId,
      userSession.token,
    );
  } catch (e) {
    console.log('Failed to upload device info!');
  }
}

export function useSaveUser(): (user: UserSession) => void {
  const updateUserSession = useUserSessionUpdate();
  return useCallback(
    (user: UserSession) => {
      const userInfo = localStorage.getItem('userInfo');
      const info = {
        ...userInfo !== null ? JSON.parse(userInfo) : {},
        [user.userId]: {
          ...userInfo !== null ? JSON.parse(userInfo)[user.userId] : {},
          sharedA: CryptoJS.AES.encrypt(user.sharedA, user.publicKey).toString(),
          uuid: user.uuid,
        },
      };

      localStorage.setItem('userInfo', JSON.stringify(info));
      setUserSessionToSessionStorage(user);
      updateUserSession(user);
    },
    [updateUserSession],
  );
}

export function removeLocalUser(userId: number) {
  const userInfo = localStorage.getItem('userInfo');

  const info = {
    ...(userInfo !== null ? JSON.parse(userInfo) : {}),
    [userId]: undefined,
  };
  localStorage.setItem('userInfo', JSON.stringify(info));

  const stores = localStorage.getItem(LOCAL_STORES);
  if (stores === null) {
    return;
  }

  const storeInfo = {
    ...(stores !== null ? JSON.parse(stores) : {}),
    [userId]: undefined,
  };
  localStorage.setItem(LOCAL_STORES, JSON.stringify(storeInfo));
}

export enum LoginActionName {
  MigrationRequest = 'migration request',
  RestoreAccount = 'restore account',
  MigrationProcss = 'migration process',
  NewUser = 'new user',
  NoDevice = 'no device',
  PopupSuccess = 'popup success',
  Noop = 'noop',
}

export interface LoginResult {
  loginAction: LoginActionName;
  userId?: number;
  sharedC?: string;
  publicKey?: string;
  token?: string;
  userName?: string;
  email?: string;
  loginType?: string;
  devices?: Array<any>;
}

export function useLoginToBackend(): (data: any) => Promise<LoginResult> {
  const saveUser = useSaveUser();
  return useCallback(
    async (data: any) => {
      let loginResult: LoginResult = {
        loginAction: LoginActionName.Noop,
      };

      try {
        const result = data;
        const token = data.token;
        let userSession;

        if (!result.existedUser) {
          // shareStrs[0] is sharedA, store locally; shareStrs[1] is sharedB save to backend;
          // shareStrs[2] is sharedC, display it and tell user to store it
          const shareStrs = createSeedWordsAndSSS();

          // Alert! must support localstorage, or the app could not work
          if (localStorage === undefined || sessionStorage === undefined) {
            throw new Error('LocalStorage is not usable.');
          }

          const userId = result.userId;
          const uuid = getUuid();
          const newUser: UserSession = {
            userId,
            userName: result.userName,
            email: result.email,
            loginType: result.loginType,
            token,
            publicKey: result.key,
            uuid,
            sharedA: shareStrs[2],
          };

          await uploadShareToServer(
            shareStrs[0],
            result.key,
            newUser.userId,
            newUser.token,
          );

          saveUser(newUser);
          await deviceInfoUpload(newUser);
          userSession = newUser;
          loginResult.loginAction = LoginActionName.NewUser;
          loginResult.sharedC = shareStrs[1];
          loginResult.userId = result.userId;
        } else {
          const user = getUserInfo(result.userId);
          const devices = await getDeviceInfo(result.userId, token);
          if (_.isEmpty(user) || !user.sharedA || !user.uuid) {
            if (_.isEmpty(devices)) {
              loginResult.loginAction = LoginActionName.RestoreAccount;
            } else {
              loginResult.loginAction = LoginActionName.MigrationRequest;
            }
            loginResult.userId = result.userId;
            loginResult.devices = devices;
            loginResult.token = token;
            loginResult.publicKey = result.key;
            loginResult.userName = result.userName;
            loginResult.email = result.email;
            loginResult.loginType = result.loginType;
          } else {
            if (!_.find(devices, d => d.uuid === user.uuid)) {
              removeLocalUser(result.userId);
              loginResult.loginAction = LoginActionName.NoDevice;
              loginResult.userId = result.userId;
              loginResult.devices = devices;
              loginResult.token = token;
              loginResult.publicKey = result.key;
              loginResult.userName = result.userName;
              loginResult.email = result.email;
              loginResult.loginType = result.loginType;
            } else {
              let bytes = CryptoJS.AES.decrypt(user.sharedA, result.key);
              userSession = {
                userId: result.userId,
                userName: result.userName,
                email: result.email,
                loginType: result.loginType,
                token,
                publicKey: result.key,
                uuid: user.uuid === 'sdk' ? getUuid() : user.uuid,
                sharedA: bytes.toString(CryptoJS.enc.Utf8),
              };

              saveUser(userSession);
              loginResult.loginAction = LoginActionName.MigrationProcss;
              await deviceInfoUpload(userSession);
            }
          }
        }

        return loginResult;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    [saveUser],
  );
}

export const loginForPopup = async (data: any) => {
  let loginResult: LoginResult = {
    loginAction: LoginActionName.Noop,
  };

  try {
    const result = data;
    const token = data.token;

    if (!result.existedUser) {
      // shareStrs[0] is sharedA, store locally; shareStrs[1] is sharedB save to backend;
      // shareStrs[2] is sharedC, display it and tell user to store it
      const shareStrs = createSeedWordsAndSSS();

      const userId = result.userId;
      const newUser: UserSession = {
        userId,
        userName: result.userName,
        email: result.email,
        loginType: result.loginType,
        token,
        publicKey: result.key,
        uuid: 'sdk',
        sharedA: shareStrs[2],
      };

      await uploadShareToServer(
        shareStrs[0],
        result.key,
        newUser.userId,
        newUser.token,
      );

      setUser(newUser);
      saveUserInfo(newUser);
      // await deviceInfoUpload(newUser)
      loginResult.loginAction = LoginActionName.NewUser;
      loginResult.sharedC = shareStrs[1];
      loginResult.userId = result.userId;
    } else {
      const userInfo = getUserInfo(result.userId);

      let user: UserSession = {
        userId: result.userId,
        userName: result.userName,
        email: result.email,
        loginType: result.loginType,
        token,
        publicKey: result.key,
        uuid: userInfo?.uuid ?? 'sdk',
        sharedA: '',
      };
      if (userInfo && userInfo.sharedA) {
        let bytes = CryptoJS.AES.decrypt(userInfo.sharedA, result.key);
        user.sharedA = bytes.toString(CryptoJS.enc.Utf8);
        loginResult.loginAction = LoginActionName.PopupSuccess;
      } else {
        loginResult.loginAction = LoginActionName.RestoreAccount;
      }

      setUser(user);
      saveUserInfo(user);
      loginResult.userId = result.userId;
      loginResult.token = token;
      loginResult.publicKey = result.key;
      loginResult.userName = result.userName;
      loginResult.email = result.email;
      loginResult.loginType = result.loginType;
    }

    return loginResult;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export async function loginFromBackend(
  provider: string,
  login: (data: any) => Promise<LoginResult>,
  onSuccess: (data: any) => void,
  onFailure: (error: any) => void,
) {
  const openId = randomId();
  const finalUrl = getLoginUrl(provider, openId);
  const channelName = `clover_login_channel_${openId}`;
  try {
    const loginWindow = new PopupWithBcHandler({
      url: finalUrl,
      target: '_blank',
      features: FEATURES_DEFAULT_POPUP_WINDOW,
      channelName,
      preopenInstanceId: null,
    });
    const result = await loginWindow.handle();
    if (result.message) {
      onFailure && onFailure(result.message);
      return;
    }

    const data = await getInfoForLogin(result.userId, result.token);
    if (data) {
      const loginResult = await login(data);
      onSuccess && onSuccess(loginResult);
    } else {
      onFailure && onFailure('Fail to get login info data.');
    }
  } catch (e) {
    console.log(e);
    onFailure && onFailure(e);
  }
}

export function useLoginToBackendForTest(): (googleTokenId: string) => Promise<LoginResult> {
  const saveUser = useSaveUser();
  return useCallback(async (googleTokenId: string) => {
    let loginResult: LoginResult = {
      loginAction: LoginActionName.Noop,
    };

    try {
      const result = await googleAuth(googleTokenId);
      const token = sha256(googleTokenId);
      let userSession;

      if (!result.existedUser) {
        // shareStrs[0] is sharedA, store locally; shareStrs[1] is sharedB save to backend;
        // shareStrs[2] is sharedC, display it and tell user to store it
        const shareStrs = createSeedWordsAndSSS();

        // Alert! must support localstorage, or the app could not work
        if (localStorage === undefined || sessionStorage === undefined) {
          throw new Error('LocalStorage is not usable.');
        }

        const userId = result.userId;
        const uuid = getUuid();
        const newUser: UserSession = {
          userId,
          userName: result.userName,
          email: result.email,
          loginType: result.loginType,
          token,
          publicKey: result.key,
          uuid,
          sharedA: shareStrs[2],
        };

        await uploadShareToServer(shareStrs[0], result.key, newUser.userId, newUser.token);

        saveUser(newUser);
        await deviceInfoUpload(newUser);
        userSession = newUser;
        loginResult.loginAction = LoginActionName.NewUser;
        loginResult.sharedC = shareStrs[1];
        loginResult.userId = result.userId;
      } else {
        const user = getUserInfo(result.userId);
        const devices = await getDeviceInfo(result.userId, token);
        if (_.isEmpty(user) || !user.sharedA || !user.uuid) {
          if (_.isEmpty(devices)) {
            loginResult.loginAction = LoginActionName.RestoreAccount;
          } else {
            loginResult.loginAction = LoginActionName.MigrationRequest;
          }
          loginResult.userId = result.userId;
          loginResult.devices = devices;
          loginResult.token = token;
          loginResult.publicKey = result.key;
          loginResult.userName = result.userName;
          loginResult.email = result.email;
          loginResult.loginType = result.loginType;
        } else {
          if (!_.find(devices, d => d.uuid === user.uuid)) {
            removeLocalUser(result.userId);
            loginResult.loginAction = LoginActionName.NoDevice;
            loginResult.userId = result.userId;
            loginResult.devices = devices;
            loginResult.token = token;
            loginResult.publicKey = result.key;
            loginResult.userName = result.userName;
            loginResult.email = result.email;
            loginResult.loginType = result.loginType;
          } else {
            let bytes = CryptoJS.AES.decrypt(user.sharedA, result.key);
            userSession = {
              userId: result.userId,
              userName: result.userName,
              email: result.email,
              loginType: result.loginType,
              token,
              publicKey: result.key,
              uuid: user.uuid,
              sharedA: bytes.toString(CryptoJS.enc.Utf8),
            };

            saveUser(userSession);
            loginResult.loginAction = LoginActionName.MigrationProcss;
            await deviceInfoUpload(userSession);
          }
        }
      }

      return loginResult;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }, [saveUser]);
}

export function useLoginToBackendForTestPopup(): (googleTokenId: string) => Promise<LoginResult> {
  return useCallback(async (googleTokenId: string) => {
    let loginResult: LoginResult = {
      loginAction: LoginActionName.Noop,
    };

    try {
      const result = await googleAuth(googleTokenId);
      const token = sha256(googleTokenId);

      if (!result.existedUser) {
        // shareStrs[0] is sharedA, store locally; shareStrs[1] is sharedB save to backend;
        // shareStrs[2] is sharedC, display it and tell user to store it
        const shareStrs = createSeedWordsAndSSS();

        // Alert! must support localstorage, or the app could not work
        if (localStorage === undefined || sessionStorage === undefined) {
          throw new Error('LocalStorage is not usable.');
        }

        const userId = result.userId;
        const uuid = getUuid();
        const newUser: UserSession = {
          userId,
          userName: result.userName,
          email: result.email,
          loginType: result.loginType,
          token,
          publicKey: result.key,
          uuid,
          sharedA: shareStrs[2],
        };

        await uploadShareToServer(shareStrs[0], result.key, newUser.userId, newUser.token);

        setUser(newUser);
        loginResult.loginAction = LoginActionName.NewUser;
        loginResult.sharedC = shareStrs[1];
        loginResult.userId = result.userId;
      } else {
        const userInfo = getUserInfo(result.userId);

        let user: UserSession = {
          userId: result.userId,
          userName: result.userName,
          email: result.email,
          loginType: result.loginType,
          token,
          publicKey: result.key,
          uuid: 'sdk',
          sharedA: '',
        };
        if (userInfo && userInfo.sharedA) {
          let bytes = CryptoJS.AES.decrypt(userInfo.sharedA, result.key);
          user.sharedA = bytes.toString(CryptoJS.enc.Utf8);
          loginResult.loginAction = LoginActionName.PopupSuccess;
        } else {
          loginResult.loginAction = LoginActionName.RestoreAccount;
        }

        setUser(user);
        loginResult.userId = result.userId;
        loginResult.token = token;
        loginResult.publicKey = result.key;
        loginResult.userName = result.userName;
        loginResult.email = result.email;
        loginResult.loginType = result.loginType;
      }

      return loginResult;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }, []);
}
