import { createWalletMiddleware } from 'eth-json-rpc-middleware'
import { createAsyncMiddleware, createScaffoldMiddleware, mergeMiddleware } from 'json-rpc-engine'

export default function createMetamaskMiddleware({
  version,
  getAccounts,
  getSolAccount,
  getPolkadotAccount,
  processTransaction,
  processEthSignMessage,
  processTypedMessage,
  processTypedMessageV3,
  processTypedMessageV4,
  processPersonalMessage,
  processSolanaMessage,
  processPolkadotMessage,
  processPolkadotTransaction,
  getPendingNonce,
  getPendingTransactionByHash,
  processEncryptionPublicKey,
  processDecryptMessage,
}) {
  const metamaskMiddleware = mergeMiddleware([
    createScaffoldMiddleware({
      // staticSubprovider
      eth_syncing: false,
      web3_clientVersion: `CloverWeb/v${version}`,
    }),
    createWalletMiddleware({
      getAccounts,
      processTransaction,
      processEthSignMessage,
      processTypedMessage,
      processTypedMessageV3,
      processTypedMessageV4,
      processPersonalMessage,
      processEncryptionPublicKey,
      processDecryptMessage,
    }),
    createRequestAccountsMiddleware({ getAccounts }),
    createPendingNonceMiddleware({ getPendingNonce }),
    createPendingTxMiddleware({ getPendingTransactionByHash }),
    createSolanaMiddleware({getSolAccount, processSolanaMessage}),
    createPolkadotMiddleware({getPolkadotAccount, processPolkadotMessage, processPolkadotTransaction})
  ])
  return metamaskMiddleware
}

export function createPendingNonceMiddleware({ getPendingNonce }) {
  return createAsyncMiddleware(async (request, response, next) => {
    if (request.method !== 'eth_getTransactionCount') return next()
    const address = request.params[0]
    const blockReference = request.params[1]
    if (blockReference !== 'pending') return next()
    response.result = await getPendingNonce(address)
    return undefined
  })
}

export function createPendingTxMiddleware({ getPendingTransactionByHash }) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method, params } = request
    if (method !== 'eth_getTransactionByHash') return next()

    const [hash] = params
    const txMeta = getPendingTransactionByHash(hash)
    if (!txMeta) {
      return next()
    }
    response.result = formatTxMetaForRpcResult(txMeta)
    return undefined
  })
}

export function createRequestAccountsMiddleware({ getAccounts }) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method } = request
    if (method !== 'eth_requestAccounts') return next()

    if (!getAccounts) throw new Error('WalletMiddleware - opts.getAccounts not provided')
    const accounts = await getAccounts(request)
    response.result = accounts
    return undefined
  })
}

export function formatTxMetaForRpcResult(txMeta) {
  const { r, s, v, hash, txReceipt, txParams } = txMeta
  const { to, data, nonce, gas, from, value, gasPrice, accessList, maxFeePerGas, maxPriorityFeePerGas } = txParams

  const formattedTxMeta = {
    v,
    r,
    s,
    to,
    gas,
    from,
    hash,
    nonce,
    input: data || '0x',
    value: value || '0x0',
    accessList: accessList || null,
    blockHash: txReceipt?.blockHash || null,
    blockNumber: txReceipt?.blockNumber || null,
    transactionIndex: txReceipt?.transactionIndex || null,
  }

  if (maxFeePerGas && maxPriorityFeePerGas) {
    formattedTxMeta.maxFeePerGas = maxFeePerGas
    formattedTxMeta.maxPriorityFeePerGas = maxPriorityFeePerGas
  } else {
    formattedTxMeta.gasPrice = gasPrice
  }

  return formattedTxMeta
}

export function createSolanaMiddleware({getSolAccount, processSolanaMessage}) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method, params } = request

    if (!getSolAccount) throw new Error('SolanaMiddleware - opts.getSolAccount not provided')
    if (!processSolanaMessage) throw new Error('SolanaMiddleware - opts.processSolanaMessage not provided')

    if (method === 'sol_requestAccount' || method === 'sol_account') {
      const accounts = await getSolAccount()
      response.result = accounts
    } else if (method === 'sol_signTransaction') {
      const transaction = params[0]
      if (Array.isArray(transaction)) throw new Error('SolanaMiddleware - Invalid Parameter, Transaction is needed.')
      const signedMsg = await processSolanaMessage(transaction, request)
      response.result = signedMsg
    } else if (method === 'sol_signAllTransactions') {
      const transactions = params[0]
      if (!Array.isArray(transactions)) throw new Error('SolanaMiddleware - Invalid Parameter, Array is needed.')
      const signedMsgs = await processSolanaMessage(transactions, request)
      response.result = signedMsgs
    } else {
      return next()
    }

    return undefined
  })
}

export function createPolkadotMiddleware({getPolkadotAccount, processPolkadotMessage, processPolkadotTransaction}) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method, params } = request

    if (!getPolkadotAccount) throw new Error('WalletMiddleware - opts.getAccounts not provided')
    if (!processPolkadotMessage) throw new Error('PolkadotMiddleware - opts.processPolkadotMessage not provided')

    if (method === 'dot_enable') {
      response.result = 'enable'
    } else if (method === 'dot_requestAccount') {
      const accounts = await getPolkadotAccount()
      response.result = accounts
    } else if (method === 'dot_signMessage') {
      const signedMsg = await processPolkadotMessage(params[0], request)
      response.result = signedMsg
    } else if (method === 'dot_signTransaction') {
      const signedMsg = await processPolkadotTransaction(params[0], request)
      response.result = signedMsg
    } else {
      return next()
    }

    return undefined
  })
}
