import { BigNumber } from "bignumber.js";

const ZERO = new BigNumber(0)
export const toBigNumber = (numberStr, decimals) => {
  if (!numberStr) {
    return ZERO
  }
  try {
    const number = new BigNumber(numberStr)
    const exponential = new BigNumber(10).pow(decimals);
    return number.multipliedBy(exponential)
  } catch (e) {
    return ZERO
  }
}

export const toRealNumber = (numberStr, decimals) => {
  if (!numberStr) {
    return ZERO
  }
  try {
    const number = new BigNumber(numberStr)
    const exponential = new BigNumber(10).pow(decimals);
    return number.div(exponential)
  } catch (e) {
    return ZERO
  }
}