import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { ethers } from 'ethers';
import { formatBalance } from '@polkadot/util';

import TransferConfirmDialog from '../../components/Dialogs/TransferConfirmDialog';
import TransferComponent, { TransferType } from '../../components/Transfer';

import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useCurrentAccount } from '../../state/account/hooks';
import { useUserSession } from '../../state/user/hooks';

import {
  validAddress,
  getAddressFromAccount,
} from '../../services/WalletService';
import { getEthFee, getGasInfo } from '../../services/FeeService';
import { combineSignKey } from '../../services/loginService';
import {
  useTransactionStateUpdate,
  TransactionStatus,
} from '../../services/TransactionService';
import {
  EthFamilyDecimals,
  EthNativeGasAmount,
} from '../../services/AssetServcie';

import { INPUT_NUMBER_REGEX } from '../../constants/regex';
import {
  CUSTOM_EVMS,
  getMulChainURL,
  getChainIdBySymbol,
} from '../../constants/netEnums';

import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';
import { logoutWallet } from '../../services/logoutService';

const Web3 = require('web3');
const Tx = require('ethereumjs-tx');
const dayjs = require('dayjs');
var CryptoJS = require('crypto-js');
export default function TransferEthFamilyPage() {
  const history = useHistory();
  const [openTransferConfirm, setOpenTransferConfirm] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const [amountErr, setAmountErr] = useState('');

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const userSession = useUserSession();
  const updateTransactionState = useTransactionStateUpdate();
  const updateBalance = useUpdateBalance();

  const setEthFamilyFee = useCallback(async () => {
    try {
      const gasFee = await getGasInfo(selectedAsset.tokenSymbol, CUSTOM_EVMS);

      const feeBn = gasFee * 1.05 * EthNativeGasAmount;
      const fee = toRealNumber(feeBn, 18);

      const txnFee = {
        gasPrice: gasFee,
        feeBn: feeBn.toFixed(),
        decimals: EthFamilyDecimals,
        fee: fee.toFixed(6),
      };
      setTxnFee(txnFee);
    } catch (e) {
      console.log(e);
    }
  }, [selectedAsset.tokenSymbol]);

  const getFeeObj = () => {
    return selectedAsset.tokenSymbol === 'ETH' ? txnFee.feeSelected : txnFee;
  };

  const onToAddressChange = e => {
    const input = e.target.value.trim();
    setToAddress(input);
    if (!input || !validAddress(input, selectedAsset)) {
      setToAddressErr('Invalid address.');
      return;
    }

    setToAddressErr('');
  };

  const checkBalanceSufficient = () => {
    const amountBn = toBigNumber(amount, EthFamilyDecimals);
    const fee = toBigNumber(getFeeObj().feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const totalBn = amountBn.plus(fee);
    if (totalBn.lte(balance)) {
      setTotal(toRealNumber(totalBn.toFixed(), EthFamilyDecimals).toFixed(4));
    }
    return totalBn.lte(balance);
  };

  const onAmountChange = e => {
    const input = e.target.value.trim();
    if (input !== '' && !INPUT_NUMBER_REGEX.test(input)) {
      return;
    }

    setAmountErr('');
    setAmount(input);
  };

  const onMax = async () => {
    const fee = toBigNumber(getFeeObj().feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const amountBn = balance.minus(fee);
    const amount = amountBn.lt(0)
      ? '0'
      : toRealNumber(amountBn.toFixed(), EthFamilyDecimals).toString(10);
    setAmount(amount);
  };

  const btnDisabled = useMemo(
    () =>
      amount === '' ||
      toAddress === '' ||
      toAddressErr !== '' ||
      amountErr !== '' ||
      _.isEmpty(txnFee),
    [amount, toAddress, toAddressErr, amountErr, txnFee],
  );

  const onConfirm = () => {
    if (checkBalanceSufficient()) {
      setOpenTransferConfirm(true);
    } else {
      setAmountErr('Insufficient balance.');
    }
  };

  const sendTransaction = async () => {
    let txnHash;
    let tx;
    try {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }

      const eth = ethers.Wallet.fromMnemonic(seedWords);
      const chainUrl = getMulChainURL(selectedAsset.tokenSymbol, CUSTOM_EVMS);
      const web3 = new Web3(new Web3.providers.HttpProvider(chainUrl));
      const nonce = await web3.eth.getTransactionCount(
        getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        ),
        'pending',
      );

      const actualAmountValue = toBigNumber(
        amount.toString(),
        EthFamilyDecimals,
      );
      const actualAmount = actualAmountValue.toFixed();
      const rawTx = {
        nonce: '0x' + nonce.toString(16),
        gasPrice: '0x' + parseInt(getFeeObj().gasPrice).toString(16),
        gasLimit: '0x5208',
        to: toAddress,
        chainId: getChainIdBySymbol(selectedAsset.tokenSymbol, CUSTOM_EVMS),
        value: '0x' + actualAmountValue.toString(16),
      };
      tx = new Tx(rawTx);
      tx.sign(new Buffer(eth.privateKey.replace('0x', ''), 'hex'));
      let txnHash = undefined;
      let transaction = undefined;
      web3.eth
        .sendSignedTransaction('0x' + tx.serialize().toString('hex'))
        .on('transactionHash', function (hash) {
          console.log(hash);
          txnHash = hash;

          formatBalance.setDefaults({ unit: selectedAsset.tokenSymbol });
          transaction = {
            txnType: TransferType.ETH_FAMILY,
            from: getAddressFromAccount(
              selectedAsset.tokenSymbol,
              currentAccount,
              CUSTOM_EVMS,
            ),
            to: toAddress,
            amount,
            amountBn: actualAmount,
            feeBn: getFeeObj().feeBn,
            fee: `${getFeeObj().fee} ${selectedAsset.tokenSymbol}`,
            nonce: '0x' + nonce.toString(16),
            gasPrice: '0x' + parseInt(getFeeObj().gasPrice).toString(16),
            gasLimit: '0x5208',
            total: total,
            tokenSymbol: selectedAsset.tokenSymbol,
            networkSymbol: selectedAsset.tokenSymbol,
            sendDate: dayjs().format(),
          };
          updateTransactionState(
            transaction,
            txnHash,
            TransactionStatus.PENDING,
          );
          history.push(
            `/homePage/activityDetail/${
              selectedAsset.tokenSymbol
            }/${getAddressFromAccount(
              selectedAsset.tokenSymbol,
              currentAccount,
              CUSTOM_EVMS,
            )}`,
          );
        })
        .on('receipt', function (receipt) {
          console.log(receipt);
          if (txnHash && transaction) {
            updateTransactionState(
              transaction,
              txnHash,
              receipt.status
                ? TransactionStatus.SUCCESS
                : TransactionStatus.FAIL,
            );
            updateBalance(selectedAsset.tokenSymbol, CUSTOM_EVMS);
          }
        })
        .on('error', function (error) {
          console.log(error);
          if (txnHash && transaction) {
            updateTransactionState(
              transaction,
              txnHash,
              TransactionStatus.FAIL,
            );
          }
        });
    } catch (e) {
      if (tx && txnHash) {
        await updateTransactionState(tx, txnHash, TransactionStatus.FAIL);
        return;
      }
      console.error(e);
      throw new Error('Failed to submit transaction');
    }
  };

  const onSend = () => {
    sendTransaction();
  };

  const setDatas = useCallback(async () => {
    if (selectedAsset.tokenSymbol !== 'ETH') {
      await setEthFamilyFee();
    } else {
      const ethFee = await getEthFee(EthNativeGasAmount);
      setTxnFee(ethFee);
    }
  }, [selectedAsset.tokenSymbol, setEthFamilyFee]);

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
  }, [history, selectedAsset, updateSelectedAsset]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    setDatas();
  }, [selectedAsset, setDatas]);

  return (
    <div>
      <TransferComponent
        toAddress={toAddress}
        toAddressErr={toAddressErr}
        onToAddressChange={onToAddressChange}
        onMax={onMax}
        amount={amount}
        amountErr={amountErr}
        onAmountChange={onAmountChange}
        transferType={TransferType.ETH_FAMILY}
        txnFee={txnFee}
        btnDisabled={btnDisabled}
        onConfirm={onConfirm}
        total
        setTransactionFee={setTxnFee}
      />
      {openTransferConfirm && (
        <TransferConfirmDialog
          isOpen={openTransferConfirm}
          token={selectedAsset.tokenSymbol}
          from={getAddressFromAccount(
            selectedAsset.tokenSymbol,
            currentAccount,
            CUSTOM_EVMS,
          )}
          to={toAddress}
          txnFee={`${getFeeObj().fee} ${selectedAsset.tokenSymbol}`}
          total={total}
          amount={amount}
          onClose={() => setOpenTransferConfirm(false)}
          onSend={onSend}
        />
      )}
    </div>
  );
}
