import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { DialogModal } from 'components/DialogModal';
import { TrashIcon } from 'modules/icons/TrashIcon';

interface RemoveTokenDialogProps {
  isOpen: boolean;
  onClose: () => void;
  confirmRemoveToken: () => void;
  token: any;
}

const useStyles = makeStyles<Theme>(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(8),
  },
  text: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(8),
  },
}));

const RemoveTokenDialog = ({
  isOpen,
  onClose,
  confirmRemoveToken,
  token,
}: RemoveTokenDialogProps) => {
  const classes = useStyles();
  return (
    <DialogModal
      open={isOpen}
      onClose={onClose}
      handleDialog={confirmRemoveToken}
      isWarning={true}
    >
      <div className={classes.content}>
        <TrashIcon className={classes.icon} />
        <Typography variant="h2" component="div">
          {t('remove-token-dialog.title', { token: token.symbol })}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t('remove-token-dialog.content')}
        </Typography>
      </div>
    </DialogModal>
  );
};

export default RemoveTokenDialog;
