import { useSelector, useDispatch } from "react-redux"
import { useCallback } from 'react'
import { AppState } from '../index'
import { transactions } from './actions'

export function useTransactions(): Array<any> {
  return useSelector((state: AppState) => state.transaction.transactions)
}

export function useTransactionsUpdate(): (trans: Array<any>) => void {
  const dispatch = useDispatch()
  return useCallback((trans: Array<any>) => dispatch(transactions({transactions: trans})), [dispatch])
}
