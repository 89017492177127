import React, { useMemo } from 'react';
import { t, tHTML } from 'modules/i18n/intl';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { DialogModal } from 'components/DialogModal';
import { SuccessStatusIcon } from 'modules/icons/SuccessStatusIcon';
import { FailStatusIcon } from 'modules/icons/FailStatusIcon';

interface StatusDialogProps {
  isOpen: boolean;
  onClose: (state: string) => void;
  status: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(13),
    '& svg': {
      width: 125,
      height: 125,
    },
  },
  title: {
    margin: theme.spacing(5, 0, 2.5, 0),
  },
  message: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(10),
  },
}));

const StatusDialog = ({ isOpen, onClose, status }: StatusDialogProps) => {
  const classes = useStyles();
  const onDismiss = () => {
    onClose(status === 'success' ? 'ok' : 'close');
  };

  const isSuccess = useMemo(() => status === 'success', [status]);

  return (
    <DialogModal open={isOpen} onClose={onDismiss} hideClose={true}>
      <div className={classes.root}>
        {isSuccess ? <SuccessStatusIcon /> : <FailStatusIcon />}
        <Typography variant="h2" className={classes.title}>
          {isSuccess
            ? t('status-dialog.approved')
            : t('status-dialog.rejected')}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {isSuccess
            ? t('status-dialog.approved-message')
            : tHTML('status-dialog.rejected-message')}
        </Typography>
        <Button variant="contained" size="large" fullWidth onClick={onDismiss}>
          {t('action.continue')}
        </Button>
      </div>
    </DialogModal>
  );
};

export default StatusDialog;
