import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MediumIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 27 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM23 7.5C23 11.6421 21.433 15 19.5 15C17.567 15 16 11.6421 16 7.5C16 3.35786 17.567 0 19.5 0C21.433 0 23 3.35786 23 7.5ZM25.3 14C26.018 14 26.6 11.0899 26.6 7.5C26.6 3.91015 26.018 1 25.3 1C24.582 1 24 3.91015 24 7.5C24 11.0899 24.582 14 25.3 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
