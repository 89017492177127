import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DiscordLogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <g clipPath="url(#clip0_2056_15177)">
        <circle cx="12.5" cy="12" r="12" fill="var(--discord-color, #404EED)" />
        <path
          d="M18.0645 7.008C17.0215 6.536 15.9137 6.192 14.7575 6C14.6119 6.248 14.4502 6.584 14.337 6.848C13.1072 6.672 11.8862 6.672 10.6734 6.848C10.5602 6.584 10.3904 6.248 10.2529 6C9.08858 6.192 7.98084 6.536 6.94507 7.008C4.85088 10.064 4.28488 13.048 4.56788 15.992C5.95862 16.992 7.30085 17.6 8.61963 18C8.94305 17.568 9.23414 17.104 9.4848 16.616C9.00774 16.44 8.55494 16.224 8.11832 15.968C8.23152 15.888 8.34472 15.8 8.44983 15.712C11.0858 16.904 13.9408 16.904 16.5444 15.712C16.6576 15.8 16.7627 15.888 16.8759 15.968C16.4393 16.224 15.9865 16.44 15.5094 16.616C15.7601 17.104 16.0512 17.568 16.3746 18C17.6925 17.6 19.0429 16.992 20.4263 15.992C20.774 12.584 19.8757 9.62402 18.0645 7.008ZM9.84865 14.176C9.05624 14.176 8.40939 13.464 8.40939 12.592C8.40939 11.72 9.04007 11.008 9.84865 11.008C10.6491 11.008 11.304 11.72 11.2879 12.592C11.2879 13.464 10.6491 14.176 9.84865 14.176ZM15.1618 14.176C14.3693 14.176 13.7217 13.464 13.7217 12.592C13.7217 11.72 14.3532 11.008 15.1618 11.008C15.9622 11.008 16.6172 11.72 16.601 12.592C16.601 13.464 15.9703 14.176 15.1618 14.176Z"
          fill="var(--fill-color, white)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2056_15177">
          <rect
            width="24"
            height="24"
            fill="var(--fill-color, white)"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
