import { useCallback } from 'react';
import _ from 'lodash';
import { selectedCollection } from '../state/app/actions';
import store from '../state';
import { useRestoreFromStorage, useRestoreFromStorageUpdate } from '../state/app/hooks';
import { transactions } from '../state/transaction/actions';
import { UserSession } from '../state/user/types';
import { migrationAccount } from './migrationService';
import { account } from '../state/account/actions';

const CryptoJS = require('crypto-js');

export const LOCAL_STORES = 'stores';
export const SS_USER_SESSION = 'userSession';
export const SS_SELECTED_ASSET = 'selectedAsset';
export const SS_SELECTED_COLLECTION = 'selectedCollection';
export const SS_USER_INFO = 'userInfo';

export function saveUserInfo(user: UserSession) {
  const userInfo = localStorage.getItem(SS_USER_INFO);
  const info = {
    ...userInfo !== null ? JSON.parse(userInfo) : {},
    [user.userId]: {
      ...userInfo !== null ? JSON.parse(userInfo)[user.userId] : {},
      sharedA: CryptoJS.AES.encrypt(user.sharedA, user.publicKey).toString(),
      uuid: user.uuid,
    },
  };

  localStorage.setItem(SS_USER_INFO, JSON.stringify(info));
}

export function setUserSessionToSessionStorage(user: UserSession) {
  sessionStorage.setItem(SS_USER_SESSION, JSON.stringify(user));
}

export function getUserSessionFromSessionStorage() {
  const userStr = sessionStorage.getItem(SS_USER_SESSION);
  if (!userStr) {
    return null;
  }

  const user = JSON.parse(userStr);
  if (user.userId < 0 || _.isEmpty(user.token) || _.isEmpty(user.sharedA)) {
    return null;
  }

  return user;
}

export function setSelectedAssetToSessionStorage(asset: any) {
  sessionStorage.setItem(SS_SELECTED_ASSET, JSON.stringify(asset));
}

export function getSelectedAssetFromSessionStorage() {
  const assetStr = sessionStorage.getItem(SS_SELECTED_ASSET);
  if (!assetStr) {
    return null;
  }

  const asset = JSON.parse(assetStr);
  return asset;
}

export function setSelectedCollectionToSessionStorage(collection: any) {
  sessionStorage.setItem(SS_SELECTED_COLLECTION, JSON.stringify(collection));
}

export function getSelectedCollectionFromSessionStorage() {
  const collectionStr = sessionStorage.getItem(SS_SELECTED_COLLECTION);
  if (!collectionStr) {
    return null;
  }

  const collection = JSON.parse(collectionStr);
  return collection;
}

export function saveStoreToLocal() {
  const userSession = getUserSessionFromSessionStorage();
  if (_.isEmpty(userSession) || userSession.userId < 0) {
    return;
  }
  const { user, app, api, wallet, ...storeData } = { ...store.getState() };
  const stores = localStorage.getItem(LOCAL_STORES);
  const info = {
    ...stores !== null ? JSON.parse(stores) : {},
    [userSession.userId]: storeData,
  };
  localStorage.setItem(LOCAL_STORES, JSON.stringify(info));
}

export function useInitStoreFromStorage(): () => Promise<void> {
  const updateRestoreFromStorage = useRestoreFromStorageUpdate();
  const restoreFromStorage = useRestoreFromStorage();
  return useCallback(async () => {
    const user = getUserSessionFromSessionStorage();
    if (_.isEmpty(user) || user.userId < 0) {
      return;
    }

    if (restoreFromStorage) {
      return;
    }

    updateRestoreFromStorage(true);

    const stores = localStorage.getItem(LOCAL_STORES);
    if (stores === null) {
      return;
    }

    const allStoreData = JSON.parse(stores);

    const storeData = allStoreData[user.userId];
    if (_.isEmpty(storeData)) {
      return;
    }
    const collection = getSelectedCollectionFromSessionStorage();

    store.dispatch(account({ account: storeData?.account }));
    const migrationAcc = await migrationAccount(storeData?.account);

    store.dispatch(selectedCollection({ collection: collection }));
    store.dispatch(account({ account: migrationAcc }));
    store.dispatch(transactions({ transactions: storeData?.transaction?.transactions }));
    return;
  }, [restoreFromStorage, updateRestoreFromStorage]);
}
