import { makeStyles, Theme } from '@material-ui/core';

export const useHomeStyles = makeStyles<Theme>(theme => ({
  home: {
    minHeight: '100vh',
    paddingLeft: 240,
    backgroundColor: theme.palette.background.default,
  },
  sideNav: {
    backgroundColor: theme.palette.background.paper,
    width: 240,
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  tabs: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(6),
  },
  tab: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    marginBottom: 32,
    borderRight: '1px solid transparent',
    lineHeight: '20px',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
    borderRightColor: theme.palette.primary.main,
  },
  copyright: {
    padding: theme.spacing(0, 10.5),
    marginBottom: theme.spacing(4.5),
  },
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '& svg': {
      width: 20,
      height: 20,
    },
  },
}));
