import { web3Accounts, web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { AccountInfo, TokenAmount } from '../state/wallet/types';
import BigNum from '../types/bigNum'
import { api } from './apiUtils'
import keyring from '@polkadot/ui-keyring';
import { BigNumber as BN } from "bignumber.js";
import { SERVICE_ADDRESS } from '../constants'

import _ from 'lodash'

let keyringInited = false
export const originName = 'clv';
declare global {
  interface Window {
      send:any;
  }
}

export function getAddress (addr: string): string {
    if (_.size(addr) < 17) {
      return addr
    }

    const prefix = addr.substring(0, 11)
    const suffix = addr.substring(_.size(addr) - 4, _.size(addr))

    return `${prefix}..${suffix}`
  }

export function createAccountInfo(clvAddress: string, ksmAddress: string, name: string, walletName: string, tokenAmounts: TokenAmount[]): AccountInfo {
  return {
    clvAddress, ksmAddress, name, walletName, tokenAmounts
  }
}

export async function loadAllTokenAmount(addr: string): Promise<TokenAmount[]|null> {
  const rawApi = api.getApi();
  const { parentHash } = await rawApi.rpc.chain.getHeader();
  const {
    data: { free: balance },
  } = await rawApi.query.system.account.at(parentHash, addr);
  return [{
    tokenType: {
      id: -1,
      name: ''
    },
    amount: balance.toString(),
    amountBN: BigNum.fromBigNum(balance.toString()).toSerizableBigNum()
  }];
}

function invalidWalletNetwork(allAccounts: InjectedAccountWithMeta[]): boolean {
  if (!keyringInited) {
    keyring.loadAll({ ss58Format: 2 }, allAccounts);
    keyringInited = true
  }
  const accounts = keyring.getAccounts();
  const addrs = _.map(accounts, (acc) => acc.address)
  return _.some(allAccounts, (acc) => !_.includes(addrs, acc.address))
}

export async function loadAccount(updateAccountList: (list: any) => void, updateWrongNetwork: (wrong: boolean) => void, updateAccountInfo: (info: AccountInfo) => void, updateStakeInfo: any): Promise<Object> {
  const injected = await web3Enable(originName);
  window.send && window.send("log", injected)
  if (!injected.length) {
    return {
      message: "Not found wallet",
      status: 'error'
    };
  }

  // const cloverWallet: any = _.find(injected, (w: any) => isCloverWallet(w))
  // if (_.isEmpty(cloverWallet)) {
  //   return {
  //     message: "Not found wallet",
  //     status: 'error'
  //   };
  // }

  const allAccounts = await web3Accounts({ss58Format: 2});
  window.send && window.send("log", allAccounts)
  if (!allAccounts.length) {
    return {
      message: "Add account",
      status: 'error'
    }
  }

  if (invalidWalletNetwork(allAccounts)) {
    updateWrongNetwork(true)
    return {
      message: "Change to valid network",
      status: 'error'
    }
  }

  updateAccountList(
      _.map(allAccounts, account => {
        const decodeAddress = keyring.decodeAddress(account.address)
        return {
          clvAddress: keyring.encodeAddress(decodeAddress, 42),
          ksmAddress: keyring.encodeAddress(decodeAddress, 2),
          name: account.meta?.name?? '',
          walletName: 'Clover Wallet'
        }
      })
  )

  if (allAccounts.length === 1) {
    const decodeAddress = keyring.decodeAddress(allAccounts[0].address)
    const obj = {
      clvAddress: keyring.encodeAddress(decodeAddress, 42),
      ksmAddress: keyring.encodeAddress(decodeAddress, 2),
      name: allAccounts[0].meta?.name?? '',
      walletName: 'Clover Wallet'
    }
    await selectAccount(obj, updateAccountInfo, updateStakeInfo)
    return {
      message: "Select success",
      status: 'success'
    }
  }

  return {
    message: "Connect success",
    status: 'success'
  }
}

export async function selectAccount(account: any, updateAccountInfo: (info: AccountInfo) => void, updateStakeInfo: any) {
  const { ksmAddress, clvAddress, name, walletName } = account
  const tokenAmounts = await loadAllTokenAmount(ksmAddress)
  if (tokenAmounts === null) {
    return {
      message: "Add account",
      status: 'error'
    }
  }

  const info = createAccountInfo(clvAddress, ksmAddress, name, walletName, tokenAmounts ?? [])
  updateAccountInfo(info)

  await getStakeInfo(ksmAddress, updateStakeInfo)
}

async function getSigner(addr: string) {
  try {
    const injected = await web3FromAddress(addr)
    return injected.signer || null
  } catch (e) {
    console.log("error"+e)
    return null
  }
}

export async function submitStake(transaction: any, updateStakeInfo: any, onEnd: any) {
  const { ksmAddress, clvAddress, auctionAmount, email, userCode } = transaction
  const rawApi = api.getApi();
  const signer = await getSigner(clvAddress)
  rawApi.setSigner(signer)
  const toAddr = 'DXuShaYiV3gqYspg7mzdDmweS9p79Z9u3wEY9FH3rHaj6yN';

  const { tokenDecimals } = await rawApi.rpc.system.properties();
  const decimals = tokenDecimals.value[0].toNumber();
  const amount = (auctionAmount * Math.pow(10, decimals)).toString()
  try {
    await rawApi.tx.balances
        .transfer(toAddr, amount)
        .signAndSend(clvAddress, async (result: any) => {
          let obj = {};
          if (result.status.isFinalized) {
            const blockHash = `${result.status.asFinalized}`;
            let txIndex = -1;
            result.events.forEach((event: any) => {
              if (`${event.event.section}.${event.event.method}` === 'balances.Transfer') {
                txIndex = event.phase.asApplyExtrinsic.toNumber();
              }
            });
            if (txIndex !== -1) {
              const signedBlock = await rawApi.rpc.chain.getBlock(blockHash);
              const txHash = signedBlock.block.extrinsics[txIndex].hash.toHex();
              obj = {
                ksmAddress: ksmAddress,
                clvAddress: clvAddress,
                email,
                userCode,
                txHash: txHash,
                blockHash: result.status.asFinalized.toHex(),
                txIndex: txIndex.toString(),
                auctionAmount: new BN(auctionAmount).toFixed()
              }
              const res = await stakeKSM(obj, updateStakeInfo)
              onEnd(res)
            }
            onEnd({
              success: false,
              message: ''
            })
          }
        })
  } catch (e) {
    onEnd({
          success: false,
          message: e
        })

    if (!_.isEmpty(onEnd)) {
      onEnd({})
    }
  }
}

async function stakeKSM(data: any, updateStakeInfo: any) {
  const jsonRpcResponse = await window.fetch(`${SERVICE_ADDRESS}api/record`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }).then(async (httpResponse) => {
    return httpResponse.json()
  })
  if (jsonRpcResponse.success) {
    await getStakeInfo(data.ksmAddress, updateStakeInfo)
  }

  return jsonRpcResponse
}

export async function getStakeInfo(ksmAddress: string, updateStakeInfo: any) {
  const stakeTotal = await window.fetch(`${SERVICE_ADDRESS}api/record/total`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const myStake = ksmAddress !== '' ? await window.fetch(`${SERVICE_ADDRESS}api/record/${ksmAddress}/total`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json()) : {}

  const invite = ksmAddress !== '' ? await window.fetch(`${SERVICE_ADDRESS}api/code/${ksmAddress}`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json()) : {}

  updateStakeInfo({
    totalAddressCount: stakeTotal.totalAddressCount > 0 ? stakeTotal.totalAddressCount : '-',
    totalStakeAmount: stakeTotal.totalStakeAmount > 0 ? _.toNumber(stakeTotal.totalStakeAmount).toFixed(4) : '-',
    totalSkuAirdroped: stakeTotal.totalSkuAirDropped > 0 ? _.toNumber(stakeTotal.totalSkuAirDropped).toFixed(4) : '-',
    myStakeAmount: myStake.totalStakeAmount > 0 ? _.toNumber(myStake.totalStakeAmount).toFixed(4) : '-',
    myTotalSku: myStake.totalSkuAirDropped > 0 ? _.toNumber(myStake.totalSkuAirDropped).toFixed(4) : '-',
    invited: myStake.invited > 0 ? myStake.invited : '-',
    inviteCode: invite?.data?? '',
  })
}
