import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';
import { SuccessIcon } from 'modules/icons/SuccessIcon';
import { FailIcon } from 'modules/icons/FailIcon';
import { PALETTE } from 'modules/theme/mainTheme';

const ToastWrapper = styled.div`
  border-left: 4px solid
    ${props => (props.success ? PALETTE.success.main : PALETTE.error.main)};
  border-radius: 9px;
  background: ${PALETTE.background.paper};
  padding: 14px 20px;
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 12px;
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${PALETTE.text.primary};
`;

export const toastSuccess = msg => {
  toast.custom(
    <ToastWrapper success={true}>
      <SuccessIcon width={20} height={20} />
      <Text>{msg}</Text>
    </ToastWrapper>,
  );
};

export const toastFail = msg => {
  toast.custom(
    <ToastWrapper success={false}>
      <FailIcon width={20} height={20} />
      <Text>{msg}</Text>
    </ToastWrapper>,
  );
};

export const toastLoading = msg => {
  toast.loading(msg);
};

export const toastDismiss = id => {
  if (id) {
    toast.dismiss(id);
  } else {
    toast.dismiss();
  }
};

export const getToast = () => {
  return <Toaster></Toaster>;
};
