import { IS_DEV } from '../constants/api'

export const SAKURA_ETH_CROSS_CHAIN = {
  chain: 'ETH',
  chainNameForBg: 'Ethereum',
  sakuraToErc20: ['SKU', 'ETH'],
  erc20ToSakura: ['ETH', 'SKU'],
  tokenAddress: (IS_DEV ? '0x105c8989d37ffD4cb8CCfC3c3F7e6eAA7Ad1f8e4' : '0xab7d26316f5c0adD393C2dDA2E6dA43D750DD399').toLowerCase(),
  bridgeAddress: (IS_DEV ? '0xC6855A06F67e9F88dfA59e088fD4400dc876e1eB' : '0xE3c59f5F22f9FA4Db57E479C09730EdEEC04082c').toLowerCase(),
  symbol: 'SKU'
}

export const SAKURA_BSC_CROSS_CHAIN = {
  chain: 'BNB',
  chainNameForBg: 'BSC',
  sakuraToErc20: ['SKU', 'BNB'],
  erc20ToSakura: ['BNB', 'SKU'],
  tokenAddress: (IS_DEV ? '0xed4d0FAd222126d73BD44Af6a033c4A299e4aDF4' : '0x664E9A36a18a2e1152aeD79a2F1Cc8833FD37115').toLowerCase(),
  bridgeAddress: (IS_DEV ? '0x2194FeBF1B652BC90859c2E5e589254B35ef07d1' : '0x77776351410d5150692f14cd3030e2ae42fFf483').toLowerCase(),
  symbol: 'SKU'
}

export const CLOVER_ETH_CROSS_CHAIN = {
  chain: 'ETH',
  chainNameForBg: 'Ethereum',
  cloverToErc20: ['CLV', 'ETH'],
  erc20ToClover: ['ETH', 'CLV'],
  tokenAddress: (IS_DEV ? '0x654F17eAB141F47Ee882CA762dcFDEFA9EefD237':'0x80C62FE4487E1351b47Ba49809EBD60ED085bf52').toLowerCase(),
  bridgeAddress: (IS_DEV ? '0x2C3281ac62136017aB204c8d1227D13A70287bbE':'0x6fA962b86C743d44b9Cf7213C49f3C4556eFC34b').toLowerCase(),
  symbol: 'CLV'
}

export const CLOVER_BSC_CROSS_CHAIN = {
  chain: 'BNB',
  chainNameForBg: 'BSC',
  cloverToErc20: ['CLV', 'BNB'],
  erc20ToClover: ['BNB', 'CLV'],
  tokenAddress: (IS_DEV ? '0x7c9b951d88fa66820fb3b62e5800d5e82fd490b3' : '0x09E889BB4D5b474f561db0491C38702F367A4e4d').toLowerCase(),
  bridgeAddress: (IS_DEV ? '0xa972ca1045b481347958640e210852165412a265' : '0x547381493E83CaD5D6eBeE8E37AB3E2C8f6A4f79').toLowerCase(),
  symbol: 'CLV'
}

export const CROSS_CHAIN_LIST = [CLOVER_BSC_CROSS_CHAIN, CLOVER_ETH_CROSS_CHAIN, SAKURA_BSC_CROSS_CHAIN ,SAKURA_ETH_CROSS_CHAIN]

export const getCrossChainListByAsset = (asset) => {
  if (asset === 'CLV') {
    return [CLOVER_BSC_CROSS_CHAIN, CLOVER_ETH_CROSS_CHAIN]
  } else {
    return [SAKURA_BSC_CROSS_CHAIN ,SAKURA_ETH_CROSS_CHAIN]
  }
}

export const canCrosschain = (asset, tokenAddress) => {
  return asset === 'CLV' || asset === 'SKU' || CROSS_CHAIN_LIST.filter(item => item.chain === asset && item.tokenAddress === tokenAddress).length > 0
}

export const crossChainAsset = (asset) => {
  return ['CLV', 'SKU'].filter(t => t === asset).length > 0
}

export const crossChainEvmChain = (asset) => {
  return CROSS_CHAIN_LIST.filter(c => c.chain === asset).length > 0
}

export const getCrossChainItemByChainAndTokenAddress = (chain, tokenAddress) => CROSS_CHAIN_LIST.find(item => item.chain === chain && item.tokenAddress === tokenAddress)

export const cloverToErc20 = (from, to) => getCrossChainListByAsset('CLV').find(item => item.cloverToErc20[0] === from && item.cloverToErc20[1] === to)

export const erc20ToClover = (from, to) => getCrossChainListByAsset('CLV').find(item => item.erc20ToClover[0] === from && item.erc20ToClover[1] === to)

export const sakuraToErc20 = (from, to) => getCrossChainListByAsset('SKU').find(item => item.sakuraToErc20[0] === from && item.sakuraToErc20[1] === to)

export const erc20ToSakura = (from, to) => getCrossChainListByAsset('SKU').find(item => item.erc20ToSakura[0] === from && item.erc20ToSakura[1] === to)

export const getCrossChainItemByChainPair = (chain1, chain2) => cloverToErc20(chain1, chain2) || erc20ToClover(chain1, chain2)
    || sakuraToErc20(chain1, chain2) || erc20ToSakura(chain1, chain2)

export const crossETH = (from, to) => from === CLOVER_ETH_CROSS_CHAIN.chain  // all 'ETH' chain
    || to === CLOVER_ETH_CROSS_CHAIN.chain

export const crossBSC = (from, to) => from === CLOVER_BSC_CROSS_CHAIN.chain // all 'BSC' chain
    || to === CLOVER_BSC_CROSS_CHAIN.chain
