import React from 'react';
import { DialogModal } from 'components/DialogModal';
import { t } from 'modules/i18n/intl';
import { Typography } from '@material-ui/core';

interface MigrationVerifyDialogProps {
  isOpen: boolean;
  onClose: (state: string) => void;
}

const MigrationVerifyDialog = ({
  isOpen,
  onClose,
}: MigrationVerifyDialogProps) => {
  const onApprove = () => {
    onClose('ok');
  };

  const onCancel = () => {
    onClose('cancel');
  };
  return (
    <DialogModal
      subtitle={t('new-device-login-request-dialog.title')}
      open={isOpen}
      onClose={onCancel}
      maxWidth="lg"
      handleText={t('new-device-login-request-dialog.manage')}
      handleDialog={onApprove}
    >
      <Typography
        variant="h4"
        color="textSecondary"
        style={{ marginTop: 40, marginBottom: 96 }}
      >
        {t('new-device-login-request-dialog.content')}
      </Typography>
    </DialogModal>
  );
};

export default MigrationVerifyDialog;
