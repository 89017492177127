import { ReactNode } from 'react';
import {
  InputLabel,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  OutlinedInput,
} from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: 'transparent',
  },
  selectMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  listIconRoot: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
    width: 20,
    height: 20,
  },
}));

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
  },
}));

interface ISelectFieldProps {
  label: string;
  readonly?: boolean;
  disabled?: boolean;
  value: string;
  options: { label: ReactNode; icon?: ReactNode; value: any }[];
  customOutlinedStyles?: any;
  handleChange?: (event: any) => void;
}

export const SelectField = ({
  label,
  readonly,
  disabled,
  value,
  options,
  customOutlinedStyles,
  handleChange,
}: ISelectFieldProps) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles()

  return (
    <div className={classes.selectRoot}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Select
        variant="outlined"
        fullWidth
        classes={{
          root: classes.root,
          selectMenu: classes.selectMenu,
        }}
        readOnly={!!readonly}
        disabled={!!disabled}
        value={value}
        onChange={handleChange && handleChange}
        input={
          <OutlinedInput
            name="age"
            id="outlined-age-simple"
            classes={customOutlinedStyles ?? outlinedInputClasses}
          />
        }
      >
        {options.map((item, index) => (
          <MenuItem
            key={`${item.label}-${index}`}
            className={classes.item}
            value={item.value}
          >
            {item.icon && (
              <ListItemIcon classes={{ root: classes.listIconRoot }}>
                {item.icon}
              </ListItemIcon>
            )}
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
