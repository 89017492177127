import React, { Suspense } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { AppBase } from 'modules/layout/AppBase/AppBase';
import LoginIn from 'pages/login';
import HomePage from 'pages/homePage';
import PopupPage from 'pages/popup';
import RedirectPage from 'pages/redirect';
import ConfirmPage from 'pages/confirm';
import AuthEndPage from 'pages/authEnd';

export default function App() {
  return (
    <Suspense fallback={null}>
      <AppBase>
        {/* <HashRouter>
          <Routes />
        </HashRouter> */}
        <HashRouter>
          <Switch>
            <Route exact strict path="/" component={LoginIn} />
            <Route strict path="/homePage" component={HomePage} />
            <Route strict path="/popup" component={PopupPage} />
            <Route strict path="/redirect" component={RedirectPage} />
            <Route strict path="/confirm" component={ConfirmPage} />
            <Route strict path="/auth/end" component={AuthEndPage} />
          </Switch>
        </HashRouter>
      </AppBase>
    </Suspense>
  );
}
