import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import TransferConfirmDialog from '../../components/Dialogs/TransferConfirmDialog';
import TransferComponent, { TransferType } from '../../components/Transfer';

import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useCurrentAccount } from '../../state/account/hooks';
import { useUserSession } from '../../state/user/hooks';

import { getAddressFromAccount } from '../../services/WalletService';
import {
  useTransactionStateUpdate,
  TransactionStatus,
} from '../../services/TransactionService';

import { INPUT_NUMBER_REGEX } from '../../constants/regex';
import { CUSTOM_EVMS } from '../../constants/netEnums';
import { toRealNumber, toBigNumber } from '../../utils/numberUtils';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';
import { logoutWallet } from '../../services/logoutService';
import {
  getSolFees,
  solDecimals,
  solTokenName,
  getConnection,
  getAccountFromMnemonic,
} from '../../services/SolanaService';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { formatBalance } from '@polkadot/util';
import { BigNumber } from 'bignumber.js';
import { combineSignKey } from '../../services/loginService';
import bs58 from 'bs58';

const dayjs = require('dayjs');
var CryptoJS = require('crypto-js');
export default function TransferSolPage() {
  const history = useHistory();
  const [openTransferConfirm, setOpenTransferConfirm] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [txnFee, setTxnFee] = useState({});
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');
  const [toAddressErr, setToAddressErr] = useState('');
  const [amountErr, setAmountErr] = useState('');
  const [confirming, setConfirming] = useState(false);

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const userSession = useUserSession();
  const updateTransactionState = useTransactionStateUpdate();
  const updateBalance = useUpdateBalance();

  const setSolFee = async () => {
    try {
      const fee = await getSolFees();
      const txnFee = {
        feeBn: fee,
        decimals: solDecimals,
        fee: toRealNumber(fee, solDecimals).toFixed(),
      };
      setTxnFee(txnFee);
    } catch (e) {
      console.log(e);
    }
  };

  const onToAddressChange = e => {
    const input = e.target.value.trim();
    setToAddress(input);
    const decoded = bs58.decode(input || '');
    const isErrorAddress = decoded.length !== 32;
    setToAddressErr(isErrorAddress ? 'Invalid address.' : '');
  };

  const checkBalanceSufficient = async () => {
    const amountBn = toBigNumber(amount, solDecimals);
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const totalBn = amountBn.plus(fee);
    if (totalBn.lte(balance)) {
      setTotal(toRealNumber(totalBn.toFixed(), solDecimals).toFixed(4));
    }
    return totalBn.lte(balance);
  };

  const onAmountChange = e => {
    const input = e.target.value.trim();
    if (input !== '' && !INPUT_NUMBER_REGEX.test(input)) {
      return;
    }
    setAmountErr('');
    setAmount(input);
  };

  const onMax = async () => {
    const fee = toBigNumber(txnFee.feeBn, 0);
    const balance = toBigNumber(
      currentAccount[selectedAsset.tokenSymbol]?.balance,
      0,
    );
    const amountBn = balance.minus(fee);
    const amount = amountBn.lt(0)
      ? '0'
      : toRealNumber(amountBn.toFixed(), solDecimals).toString(10);
    setAmount(amount);
  };

  const btnDisabled = useMemo(
    () =>
      amount === '' ||
      toAddress === '' ||
      toAddressErr !== '' ||
      amountErr !== '' ||
      _.isEmpty(txnFee) ||
      confirming,
    [amount, toAddress, toAddressErr, amountErr, txnFee, confirming],
  );

  const onConfirm = () => {
    setConfirming(true);
    checkBalanceSufficient()
      .then(ret => {
        if (ret) {
          setOpenTransferConfirm(true);
        } else {
          setAmountErr('Insufficient balance.');
        }
      })
      .finally(() => {
        setConfirming(false);
      });
  };

  const signTransaction = async (transaction, account) => {
    transaction.partialSign(account);
    return transaction;
  };
  const sendTransaction = async () => {
    let txnHash;
    let transRecord;
    try {
      let bytes = CryptoJS.AES.decrypt(
        currentAccount.seedWords,
        userSession.publicKey,
      );
      let seedWords = bytes.toString(CryptoJS.enc.Utf8);
      if (currentAccount.isLoginAccount) {
        seedWords = await combineSignKey(
          userSession.sharedA,
          userSession.userId,
          userSession.token,
        );
        if (seedWords === null) {
          throw new Error('Invalid seed words.');
        }
      }
      const connection = getConnection();
      const base = new BigNumber(10).pow(solDecimals);
      const amountBn = new BigNumber(amount).times(base);
      const account = await getAccountFromMnemonic(seedWords);

      let transaction = new Transaction({ feePayer: account.publicKey }).add(
        SystemProgram.transfer({
          fromPubkey: account.publicKey,
          toPubkey: new PublicKey(toAddress),
          lamports: Math.round(Number(amountBn.toFixed(0))),
        }),
      );

      transaction.recentBlockhash = (
        await connection.getRecentBlockhash('max')
      ).blockhash;

      transaction = await signTransaction(transaction, account);
      const rawTransaction = transaction.serialize();
      txnHash = await connection.sendRawTransaction(rawTransaction, {
        skipPreflight: false,
        preflightCommitment: 'single',
      });

      formatBalance.setDefaults({ unit: solTokenName });
      transRecord = {
        txnType: TransferType.SOL_TRX,
        from: getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        ),
        to: toAddress,
        amount,
        amountBn: amountBn.toFixed(0),
        feeBn: txnFee.feeBn,
        fee: `${txnFee.fee} SOL`,
        total: total,
        tokenSymbol: selectedAsset.tokenSymbol,
        networkSymbol: selectedAsset.tokenSymbol,
        sendDate: dayjs().format(),
        internal: {
          address: currentAccount.address,
        },
      };
      await updateTransactionState(
        transRecord,
        txnHash,
        TransactionStatus.PENDING,
      );
      history.push(
        `/homePage/activityDetail/${
          selectedAsset.tokenSymbol
        }/${getAddressFromAccount(
          selectedAsset.tokenSymbol,
          currentAccount,
          CUSTOM_EVMS,
        )}`,
      );
      connection
        .confirmTransaction(txnHash, 'recent')
        .then(result => {
          updateTransactionState(
            transRecord,
            txnHash,
            result.value.err
              ? TransactionStatus.FAIL
              : TransactionStatus.SUCCESS,
          );
          updateBalance(selectedAsset.tokenSymbol, CUSTOM_EVMS);
        })
        .catch(e => {
          updateTransactionState(transRecord, txnHash, TransactionStatus.FAIL);
        });
      return transRecord;
    } catch (e) {
      if (transRecord && txnHash) {
        await updateTransactionState(
          transRecord,
          txnHash,
          TransactionStatus.FAIL,
        );
        return;
      }
      console.log(e);
      throw new Error('Failed to submit transaction');
    }
  };
  const onSend = () => {
    sendTransaction();
  };

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
  }, [selectedAsset, updateSelectedAsset, history]);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    setSolFee();
  }, [selectedAsset]);

  return (
    <div>
      <TransferComponent
        toAddress={toAddress}
        toAddressErr={toAddressErr}
        onToAddressChange={onToAddressChange}
        onMax={onMax}
        amount={amount}
        amountErr={amountErr}
        onAmountChange={onAmountChange}
        transferType={TransferType.SOL_TRX}
        txnFee={txnFee}
        btnDisabled={btnDisabled}
        onConfirm={onConfirm}
        total
      />
      {openTransferConfirm && (
        <TransferConfirmDialog
          isOpen={openTransferConfirm}
          token={selectedAsset.tokenSymbol}
          from={getAddressFromAccount(
            selectedAsset.tokenSymbol,
            currentAccount,
            CUSTOM_EVMS,
          )}
          to={toAddress}
          txnFee={`${txnFee.fee} ${selectedAsset.tokenSymbol}`}
          total={total}
          amount={amount}
          onClose={() => setOpenTransferConfirm(false)}
          onSend={onSend}
        />
      )}
    </div>
  );
}
